<template>
  <div v-if="d_nlpPhraseWdm" style="margin-bottom: 50px;">

<!--
  <template v-if="d_columnListLoading">
    <b-spinner variant="primary" label="Spinning"></b-spinner>
  </template>
  <template v-else>
  </template>
-->
  <b-row style="margin: 0px; padding: 3px; background-color: lightgreen;">
    <b-col lg="5">
      <strong>İfade Yönetim Modülü</strong>
      <span v-if="d_selectedNlpPhraseData && d_selectedNlpPhraseData.phrase_name">
        [{{ d_selectedNlpPhraseData.phrase_name }}]
      </span>
    </b-col>
    <b-col lg="3" style="text-align: right;"> 
      <template v-if="user && user.permissions_result && user.permissions_result.indexOf('wisdom_nlp') !== -1">
        <b-button @click="f_nlpPhraseTransfer()" variant="white" size="sm" title="global veritabanlarında bulunan ifade verilerinin lokal kurulu ortama transferini sağlamaktadır.">
          globalden lokale transfer <i class="fa fa-send-o"></i>
        </b-button>
      </template>
    </b-col>
    <b-col lg="2">
      <template>
        <b-form-select class="form-control" v-model="d_nlpLayerType">
          <option value="global">global</option>
          <option value="user">kullanıcı</option>
        </b-form-select>
      </template>
    </b-col>
    <b-col lg="2" style="text-align: right;"> 
      <b-button @click="f_openInNewWindow()" variant="white" size="sm">
        yeni sekme <i class="fa fa-external-link"></i>
      </b-button>
    </b-col>
  </b-row>

    <b-tabs>


      <b-tab :active="d_selectedTab === 'nlp_phrase_list'" @click="d_selectedTab = 'nlp_phrase_list'">
        <template slot="title">
          İfade Listesi
        </template>
        <template v-if="d_selectedTab === 'nlp_phrase_list'">
          <b-row>
            <b-col sm="12" lg="3">
              <b-row>
                <b-col sm="12" lg="12">ifade grubu</b-col>
              </b-row>
              <b-row>
                <b-col sm="12" lg="12"><b-form-input type="text" v-model="d_nlpPhraseGroupNameSearchText" placeholder="ifade grubu filtresi"></b-form-input></b-col>
              </b-row>
            </b-col>
            <b-col sm="12" lg="3">
              <b-row>
                <b-col sm="12" lg="12">ifade adı</b-col>
              </b-row>
              <b-row>
                <b-col sm="12" lg="12"><b-form-input type="text" v-model="d_nlpPhraseNameSearchText" placeholder="ifade adı filtresi"></b-form-input></b-col>
              </b-row>
            </b-col>
            <b-col sm="12" lg="2">
              <b-row>
                <b-col sm="12" lg="12">ifade seçenek</b-col>
              </b-row>
              <b-row>
                <b-col sm="12" lg="12"><b-form-input type="text" v-model="d_nlpPhraseOptionNameSearch" placeholder="ifade seçenek filtresi"></b-form-input></b-col>
              </b-row>
            </b-col>
            <b-col sm="12" lg="2">
              <b-row>
                <b-col sm="12" lg="12">durum</b-col>
              </b-row>
              <b-row>
                <b-col sm="12" lg="12">
                  <b-form-select class="form-control" v-model="d_nlpPhraseStatus">
                    <option value="0">pasif</option>
                    <option value="1">aktif</option>
                    <option value="">hepsi</option>
                  </b-form-select>

                </b-col>
              </b-row>
            </b-col>
            <b-col sm="12" lg="2" style="text-align: right;">
              <b-button @click="f_nlpPhraseList()" size="md" style="width: 100%;" variant="outline-primary">
                <i class="fa fa-search"></i>
              </b-button>
            </b-col>
          </b-row>

          <hr>
          <template v-if="d_nlpPhraseListLoading">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </template>

          <template v-for="(nlp_phrase_item, nlp_phrase_index) in d_nlpPhraseList">
              <!-- 
                  fields =
                {
                    "data": [
                    {
                        "id_tree": [
                        {
                            "id": ["wdm233_1", "wdm329_7", "wdm418_26", "L845_1"]
                        }],
                        "list": [
                        {
                            "val":
                            {
                                "value": "0",
                                "label": "pasif"
                            }
                        }],
                        "id": "L845_1"
                    }],
                    "label": "layer845.0.0"
                },

               -->
            <b-row :style="d_selectedNlpPhraseIndex === nlp_phrase_index ? 'background: lightgreen; margin: 3px; padding: 3px; border-bottom: solid 1px lightblue;' : 'margin: 3px; padding: 3px; border-bottom: solid 1px lightblue;'" @click="d_selectedNlpPhraseIndex = nlp_phrase_index">
              <b-col sm="12" lg="1">
                {{ nlp_phrase_index + 1 }}
              </b-col>
              <b-col sm="12" lg="1">
                <template v-if="nlp_phrase_item.fields.length > 0 && nlp_phrase_item.fields[0].data.length > 0 && nlp_phrase_item.fields[0].data[0].list[0].val.label">
                  {{ nlp_phrase_item.fields[0].data[0].list[0].val.label }}
                </template>
              </b-col>
              <b-col sm="12" lg="2">
                <template v-if="nlp_phrase_item.fields.length > 0 && nlp_phrase_item.fields[5].data.length > 0 && nlp_phrase_item.fields[5].data[0].list[0].val">
                  {{ nlp_phrase_item.fields[6].data[0].list[0].val }}
                </template>
              </b-col>
              <b-col sm="12" lg="2">
                <template v-if="nlp_phrase_item.fields.length > 0 && nlp_phrase_item.fields[1].data.length > 0 && nlp_phrase_item.fields[1].data[0].list[0].val">
                  {{ nlp_phrase_item.fields[1].data[0].list[0].val }}
                </template>
              </b-col>
              <b-col sm="12" lg="5">
                <template v-if="nlp_phrase_item.fields.length > 0 && nlp_phrase_item.fields[2].data.length > 0 && nlp_phrase_item.fields[2].data[0].list[0].val">
                  {{ nlp_phrase_item.fields[2].data[0].list[0].val }}
                </template>

                <div>
                  <small>
                    {{ nlp_phrase_item.client_id }} [{{ nlp_phrase_item.id }}]
                  </small>
                </div>

              </b-col>
<!--               <b-col sm="12" lg="2">
               <template v-if="nlp_phrase_item.fields.length > 0 && nlp_phrase_item.fields[4].data.length > 0 && nlp_phrase_item.fields[4].data[0].list[0].val.label">
                  {{ nlp_phrase_item.fields[4].data[0].list[0].val.label }}
                </template>
              </b-col>
 -->              <b-col sm="12" lg="1">
                <b-button @click="f_selectNlpPhrase(nlp_phrase_index)" size="md" style="width: 100%;" variant="outline-primary">
                  <i class="fa fa-send"></i>
                </b-button>
              </b-col>
            </b-row>
          </template>
        </template>
      </b-tab>





      <b-tab :active="d_selectedTab === 'nlp_phrase_selected'" @click="d_selectedTab = 'nlp_phrase_selected'">
        <template slot="title">
          İfade Detayı
        </template>
        <template v-if="d_selectedTab === 'nlp_phrase_selected'">

          <template v-if="d_selectedNlpPhraseData">
            <b-row>
              <b-col sm="12" lg="9">
                <span style="color: green;">{{ d_nlpPhraseClientId }} </span> | <span style="color: blue;">{{ d_nlpPhraseWdmrKey }}</span>
              </b-col>
              <b-col sm="12" lg="3" style="text-align: right;">
                <template v-if="d_phraseEditPermissionEligible">

                  <b-button @click="f_nlpPhraseRecord('edit')" size="sm" variant="outline-primary" style="margin-right: 3px;">
                    <i class="fa fa-save"></i> kaydet
                  </b-button>
                </template>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="12" lg="6">

                <b-row style="margin: 3px;">
                  <b-col sm="12" lg="4">
                    {{ d_nlpPhraseWdm['0']['parameters']['0']['name']['translation'][StoreLang] }}
                  </b-col>
                  <b-col sm="12" lg="8">
                    <select class="form-control" v-model="d_selectedNlpPhraseData.status" :disabled="!d_phraseEditPermissionEligible">
                      <option v-for="(op, op_index) in d_nlpPhraseWdm['0']['parameters']['0'].options" :value="op.value">
                        {{ op.translation[StoreLang] }}
                      </option>
                    </select>
                  </b-col>
                </b-row>
                <b-row style="margin: 3px;">
                  <b-col sm="12" lg="4">
                    {{ d_nlpPhraseWdm['0']['parameters']['12']['name']['translation'][StoreLang] }}
                  </b-col>
                  <b-col sm="12" lg="8">
                    <select class="form-control" v-model="d_selectedNlpPhraseData.regx_usage_type" :disabled="!d_phraseEditPermissionEligible">
                      <option v-for="(op, op_index) in d_nlpPhraseWdm['0']['parameters']['12'].options" :value="op.value">
                        {{ op.translation[StoreLang] }}
                      </option>
                    </select>
                  </b-col>
                </b-row>

    <!--             <b-row style="margin: 3px;">
                  <b-col sm="12" lg="4">
                    {{ d_nlpPhraseWdm['0']['parameters']['8']['name']['translation'][StoreLang] }}
                  </b-col>
                  <b-col sm="12" lg="8">
                    <select class="form-control" v-model="d_selectedNlpPhraseData.who_can_use">
                      <option v-for="(op, op_index) in d_nlpPhraseWdm['0']['parameters']['8'].options" :value="op.value">
                        {{ op.translation[StoreLang] }}
                      </option>
                    </select>
                  </b-col>
                </b-row>
     -->
                <b-row style="margin: 3px;">
                  <b-col sm="12" lg="4">
                    {{ d_nlpPhraseWdm['0']['parameters']['1']['name']['translation'][StoreLang] }}
                  </b-col>
                  <b-col sm="12" lg="8">
                    <template v-if="d_phraseEditPermissionEligible">
                      <b-form-input v-model="d_selectedNlpPhraseData.phrase_group_name"></b-form-input>
                    </template>
                    <template v-else>
                      {{ d_selectedNlpPhraseData.phrase_group_name }}
                    </template>
                  </b-col>
                </b-row>

                <b-row style="margin: 3px;">
                  <b-col sm="12" lg="4">
                    {{ d_nlpPhraseWdm['0']['parameters']['2']['name']['translation'][StoreLang] }}
                  </b-col>
                  <b-col sm="12" lg="8">
                    <template v-if="d_phraseEditPermissionEligible">
                      <b-form-input v-model="d_selectedNlpPhraseData.phrase_name"></b-form-input>
                    </template>
                    <template v-else>
                      {{ d_selectedNlpPhraseData.phrase_name }}
                    </template>
                  </b-col>
                </b-row>

                <template v-if="d_nlpPhraseRecordLoading">
                  <b-spinner variant="primary" label="Spinning"></b-spinner>
                </template>

              </b-col>

              <b-col sm="12" lg="6">

                <b-row style="margin: 3px; padding: 3px;" v-if="d_phraseEditPermissionEligible">
                  <b-col cols="4">
                    json ile regx kodlar
                  </b-col>
                  <b-col cols="7">
                    <b-form-textarea rows="1" v-model="d_phraseOptionAndRegxCodes.json_text" placeholder="JSON yapısı bu şekilde dizayn ediniz. [{'regx_name': '', 'regx_code': '', 'phrase_option': '', }]"></b-form-textarea>
                  </b-col>
                  <b-col cols="1">
                    <b-button v-if="d_phraseOptionAndRegxCodes.json_text" variant="outline-success" @click="f_phraseOptionAddFromJsonText()" size="sm" style="margin-right: 3px;" title="ekle" >
                      <i class="fa fa-plus"></i>
                    </b-button>
                  </b-col>
                </b-row>

              </b-col>
            </b-row>

            <hr>

            <div style="overflow-x: hidden; overflow-y: auto; height: 500px;">
              <b-row style="margin: 3px;" v-if="d_phraseEditPermissionEligible">
                <b-col sm="12" lg="6">
                  <b-button v-if="d_selectedNlpPhraseData.phrase_option && d_selectedNlpPhraseData.phrase_option.length > 0" variant="outline-success" @click="f_phraseOptionClear()" size="sm" style="margin-right: 3px;" title="seçenekleri temizle">
                    <i class="fa fa-trash"></i> seçenekleri temizle
                  </b-button>

                  <b-button variant="outline-primary" @click="f_addNewPhraseOptionItem('edit')" size="sm" style="margin-right: 3px;">
                    <i class="fa fa-plus-circle"></i> yeni seçenek
                  </b-button>
                </b-col>
                <b-col sm="12" lg="6">
                </b-col>
              </b-row>

              <template v-if="d_selectedNlpPhraseData.phrase_option.length > 0">
                <template v-if="d_phraseEditPermissionEligible">
                  
                  <table border="1" align="center" style="width: 100%; table-layout: fixed; overflow-wrap: break-word; border-color: lightcyan;">
                    <thead>
                      <tr>
                        <th style="width: 5%; background-color: lightcyan;">no</th>
                        <th style="width: 7%; background-color: lightcyan;">copy</th>
                        <th style="width: 18%; background-color: lightcyan;">seçenek adı</th>
                        <th style="width: 20%; background-color: lightcyan;">regx adı</th>
                        <th style="width: 60%; background-color: lightcyan;">regx kodu</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(phrase_option, phrase_option_index) in d_selectedNlpPhraseData.phrase_option">
                        <template v-for="(regx_item, regx_item_index) in phrase_option.regx">
                          <tr>
                            <td style="text-align: center;">
                              <div :style="f_calculateSingleEvenStyle(phrase_option_index)">
                                {{ phrase_option_index + 1 }}.{{ regx_item_index + 1 }}
                              </div>
                            </td>
                            <td>
                              <template v-if="d_phraseEditPermissionEligible">                            
                                <b-button variant="outline-success" @click="f_copyRegxItem('edit', phrase_option_index, regx_item_index)" size="sm" style="margin-right: 3px;">
                                  <i class="fa fa-copy"></i>
                                </b-button>
                                <b-button variant="outline-danger" @click="f_deleteRegxItem('edit', phrase_option_index, regx_item_index)" size="sm" style="margin-right: 3px;">
                                  <i class="fa fa-trash"></i>
                                </b-button>
                              </template>
                            </td>
                            <td @click="f_selectCell(phrase_option_index, regx_item_index, '1')">
                              <template v-if="phrase_option_index.toString() + '_' + regx_item_index.toString() + '_1' === d_selectedCell && d_phraseEditPermissionEligible">
                                <b-form-textarea :id="phrase_option_index.toString() + '_' + regx_item_index.toString() + '_1'" rows="1" v-model="phrase_option.phrase_option_name" style="padding: 0px;"></b-form-textarea>
                              </template>
                              <template v-else>
                                {{ phrase_option.phrase_option_name }}
                              </template>
                            </td>
                            <td @click="f_selectCell(phrase_option_index, regx_item_index, '2')">
                              <template v-if="phrase_option_index.toString() + '_' + regx_item_index.toString() + '_2' === d_selectedCell && d_phraseEditPermissionEligible">
                                <b-form-textarea :id="phrase_option_index.toString() + '_' + regx_item_index.toString() + '_2'" rows="1" v-model="regx_item.regx_name" style="padding: 0px;"></b-form-textarea>
                              </template>
                              <template v-else>
                                {{ regx_item.regx_name }}
                              </template>
                            </td>
                            <td @click="f_selectCell(phrase_option_index, regx_item_index, '3')">
                              <template v-if="d_phraseEditPermissionEligible">
                                <template v-if="phrase_option_index.toString() + '_' + regx_item_index.toString() + '_3' === d_selectedCell">
                                  <b-form-textarea :id="phrase_option_index.toString() + '_' + regx_item_index.toString() + '_3'" rows="1" v-model="regx_item.regx_code" style="padding: 0px;"></b-form-textarea>
                                </template>
                                <template v-else>
                                  {{ regx_item.regx_code }}
                                </template>
                              </template>
                            </td>
                          </tr>
                        </template>
                      </template>
                    </tbody>
                  </table>
                </template>

                <template v-else>
                  <template v-for="(phrase_option, phrase_option_index) in d_selectedNlpPhraseData.phrase_option">
                    <div>
                      {{ phrase_option_index + 1 }} ) 
                      {{ phrase_option.phrase_option_name }}
                    </div>
                  </template>
                </template>
              </template>

              <b-row style="margin: 3px;" v-if="d_phraseEditPermissionEligible">
                <b-col sm="12" lg="6">
                  <b-button variant="outline-primary" @click="f_addNewPhraseOptionItem('edit')" size="sm">
                    <i class="fa fa-plus-circle"></i> yeni seçenek
                  </b-button>
                </b-col>
                <b-col sm="12" lg="6">
                </b-col>
              </b-row>
            </div>

          </template>
        </template>
      </b-tab>





      <b-tab :active="d_selectedTab === 'nlp_phrase_combination'" @click="d_selectedTab = 'nlp_phrase_combination'" v-if="d_phraseEditPermissionEligible">
        <template slot="title">
          Kombinasyon Çalışması
        </template>
        <template v-if="d_selectedTab === 'nlp_phrase_combination'">

          <template v-if="d_selectedNlpPhraseData">

              <b-row style="margin: 3px;">
                <b-col sm="12" lg="3">
                  <b-form-select class="form-control" v-model="d_selectedPhraseCombination.name" @change="f_selectPhraseCombinationWork()">
                    <option value="">kombinasyon seçiniz ya da yeni ekleyiniz</option>
                    <option v-for="(text, text_ind) in d_phraseCombWorkList" :value="text">
                      {{ text }}
                    </option>
                  </b-form-select>
                </b-col>
                <b-col sm="12" lg="2">
                  <b-button v-if="d_selectedPhraseCombination.name" variant="outline-success" @click="f_recordPhraseCombinationWork('edit')" size="sm" style="margin-right: 3px;" title="sil">
                    <i class="fa fa-save"></i> güncelle
                  </b-button>
                </b-col>
                <b-col sm="12" lg="3">
                  <b-form-input v-model="d_newCompinationName" style="padding: 0px;" autocomplete="off" placeholder="yeni kombinasyon çalışma başlığı"></b-form-input>
                </b-col>
                <b-col sm="12" lg="2">
                  <b-button v-if="d_newCompinationName" variant="outline-success" @click="f_recordPhraseCombinationWork('new')" size="sm" style="margin-right: 3px;" title="yeni kombinasyon çalışması olarak kaydet">
                    <i class="fa fa-save"></i> yeni kaydet
                  </b-button>
                </b-col>

                <b-col sm="12" lg="2">
                  <b-dropdown class="pull-right" variant="outline-primary" size="sm" right>
                    <template v-slot:button-content>
                      <i class="fa fa-list"></i>
                    </template>
                      
                      <b-dropdown-item v-if="d_selectedPhraseCombination.list.length > 0" @click="f_addCombinationsToPhrase('no')"> 
                        <i class="fa fa-plus"></i> kombinasyonları ekle
                      </b-dropdown-item>
                      <b-dropdown-item v-if="d_selectedPhraseCombination.list.length > 0" @click="f_addCombinationsToPhrase('yes')"> 
                        <i class="fa fa-plus-square"></i> ifade seçeneklerini resetle ve kombinasyonları ekle
                      </b-dropdown-item>

                  </b-dropdown>

                </b-col>

              </b-row>


            <hr>

            <b-row style="margin: 3px;">
              <b-col sm="12" lg="4">
                <b-button variant="outline-success" @click="f_newPhraseCombination()" size="sm" style="margin-right: 3px;" title="sil">
                  <i class="fa fa-plus"></i> yeni kombinasyon
                </b-button>
              </b-col>
              <b-col sm="12" lg="4"></b-col>
              <b-col sm="12" lg="4"></b-col>
            </b-row>

            <div style="overflow-x: auto; overflow-y: auto; max-height: 500px;">

              <template v-for="(comb, comb_ind) in d_selectedPhraseCombination.list">
                <b-row style="margin: 3px; background-color: lightcyan; border: solid 1px lightgreen;">
                  <b-col sm="12" lg="2">
                    <b-button variant="outline-danger" @click="f_deleteListItem(d_selectedPhraseCombination.list, comb_ind)" size="sm" style="margin-right: 3px;" title="kombinasyonu sil">
                      <i class="fa fa-trash"></i>
                    </b-button>
                    <b-button variant="outline-success" @click="f_copyListItem(d_selectedPhraseCombination.list, comb_ind)" size="sm" style="margin-right: 3px;" title="kombinasyonu sil">
                      <i class="fa fa-copy"></i>
                    </b-button>
                  </b-col>

                  <b-col sm="12" lg="10">
                    <template v-if="d_selectedNlpPhraseData">
                      <b-row>
                        <b-col cols="1">
                          {{ comb_ind + 1 }} )
                        </b-col>
                        <b-col cols="4">
                          <b-form-select class="form-control" v-model="comb.ph_op_name_use" style="background-color: lightblue; font-weight: bold;" @change="comb.phrase_option_name = comb.ph_op_name_use">
                            <option value="">seçimi yapınız</option>
                            <option v-for="(ph_op, ph_op_index) in d_selectedNlpPhraseData.phrase_option">
                              {{ ph_op.phrase_option_name }}
                            </option>
                          </b-form-select>
                        </b-col>
                        <b-col cols="4">
                          <b-form-input type="text" v-model="comb.phrase_option_name"></b-form-input>
                        </b-col>
                        <b-col cols="3"></b-col>
                      </b-row>
                    </template>
                    <template v-else>
                      <small>ifade seçili değil</small>
                    </template>
                  </b-col>
                </b-row>


                <b-row style="margin: 3px;">
                  <b-col sm="12" lg="12">

                    <template v-for="(group, group_ind) in comb.group_list">
                      <b-row style="margin: 3px; border: solid 1px lightblue;">
                        <b-col sm="12" lg="1">
                          {{ group_ind + 1 }}
                          <b-button variant="outline-danger" @click="f_deleteListItem(comb.group_list, group_ind)" size="sm" style="margin-right: 3px;" title="grup sil">
                            <i class="fa fa-trash"></i>
                          </b-button>

  <!--                         <b-row>
                            <b-col lg="12">
                              <b-button variant="outline-danger" @click="f_deleteListItem(comb.group_list, group_ind)" size="sm" style="margin-right: 3px;" title="grup sil">
                                <i class="fa fa-trash"></i>
                              </b-button>
                            </b-col>
                          </b-row>
   -->
                        </b-col>
                        <b-col sm="12" lg="11">

                          
                            <b-row style="width: 4000px;">
                              <template v-for="(column, column_ind) in group.column_list">
                                <b-col cols="1">
                                  <b-row style="margin: 3px; border: solid 1px lightpink;">
                                    <b-col sm="12" lg="6">
                                      {{ column_ind + 1 }}
                                        <b-button variant="outline-danger" @click="f_deleteListItem(group.column_list, column_ind)" size="sm" style="margin-right: 3px; padding: 2px;" title="kolon sil">
                                          <i class="fa fa-trash"></i>
                                        </b-button>
                                    </b-col>
                                    <b-col sm="12" lg="6">
                                        <b-button variant="outline-primary" @click="f_newCombinationGroupColumnWord(comb_ind, group_ind, column_ind)" size="sm" style="margin-right: 3px; padding-top: 2px; padding-bottom: 2px;" title="yeni kelime ekle">
                                          <i class="fa fa-plus"></i>
                                        </b-button>                                      
                                        <b-button variant="outline-primary" @click="f_copyCombinationWords(comb_ind, group_ind, column_ind)" size="sm" style="margin-right: 3px; padding-top: 2px; padding-bottom: 2px;" title="kopyala">
                                          <i class="fa fa-copy"></i>
                                        </b-button>                                      
                                        <b-button variant="outline-primary" @click="f_useCopiedCombinationWords(comb_ind, group_ind, column_ind)" size="sm" style="margin-right: 3px; padding-top: 2px; padding-bottom: 2px;" title="kopyalananları ekle">
                                          <i class="fa fa-plus-square"></i>
                                        </b-button>                                      
                                    </b-col>
                                  </b-row>

                                  <b-row style="margin: 3px; border: solid 1px lightpink;">
                                    <b-col sm="12" lg="12" style="padding: 2px;">

                                      <b-row style="margin: 3px; border: solid 1px lightpink;">
                                        <b-col sm="12" lg="12" style="padding: 2px; font-size: 9px;">
                                          <b-form-select class="form-control" v-model="column.word_list_usage" style="background-color: lightyellow; font-size: 10px;" title="kelimeler sıralaması farketmeyen listenin elemanları ise, - liste - modu seçilir. Böylece kelime adedince sorgulanabilir tüm liste kombinasyonlar üretilir.">
                                            <option value="words_not_in_list_structure">liste yapısında olmayan kelimeler</option>
                                            <option value="elements_of_a_list_in_any_order_0">sıralama farketmeyen liste (minimum 0 adet) </option>
                                            <option value="elements_of_a_list_in_any_order_1">sıralama farketmeyen liste (minimum 1 adet) </option>
                                          </b-form-select>

                                        </b-col>
                                      </b-row>


                                      <template v-for="(word, word_ind) in column.word_list">
                                        <b-row style="margin: 0px;">
                                          <b-col sm="12" lg="8" style="padding: 0px;">
                                            <input type="text" v-model="word.w" autocomplete="off" style="border: solid 1px lightblue; width: 100%;">
                                          </b-col>
                                          <b-col sm="12" lg="2" style="padding: 0px;">
                                            <select v-model="word.part_type" style="background-color: lightyellow; font-size: 10px; padding: 3px; border: solid 1px lightblue; width: 100%;" title="kelimenin tam eşleşmesi isteniyorsa x olarak, başlangıcı eşleşecekse x+++ olarak seçim yapılır.">
                                              <option value="all">x</option>
                                              <option value="start">x+++</option>
                                            </select>
                                          </b-col>
                                          <b-col sm="12" lg="2" style="padding: 0px; text-align: right;">
                                            <b-button variant="outline-danger" @click="f_deleteListItem(column.word_list, word_ind)" size="sm" title="kelime sil" style="padding-top: 2px; padding-bottom: 2px; margin: 0px;">
                                              <i class="fa fa-trash"></i>
                                            </b-button>
                                          </b-col>
                                        </b-row>
                                      </template>

                                    </b-col>
                                  </b-row>


                                </b-col>
                              </template>

                              <b-cols cols="1">
                                <b-button variant="outline-primary" @click="f_newCombinationGroupColumn(comb_ind, group_ind)" size="sm" style="margin-right: 3px;" title="yeni kelime ekle">
                                  <i class="fa fa-plus">yeni kolon</i> ({{ group.column_list.length }})
                                </b-button>
                              </b-cols>

                            </b-row>
                          


                        </b-col>
                      </b-row>
                    </template>

                    <b-row style="margin: 0px;">
                      <b-col sm="12" lg="6">
                        <b-button variant="outline-success" @click="f_newCombinationGroup(comb_ind)" size="sm" style="margin-right: 3px;" title="yeni grup">
                          <i class="fa fa-plus">yeni grup</i> ({{ comb.group_list.length }})
                        </b-button>
                      </b-col>
                      <b-col sm="12" lg="6">
                      </b-col>
                    </b-row>

                  </b-col>
                </b-row>
              </template>


            </div>

          </template>
        </template>
      </b-tab>






      <b-tab :active="d_selectedTab === 'nlp_phrase_new'" @click="d_selectedTab = 'nlp_phrase_new'" v-if="user.permissions_result.indexOf('wisdom_nlp') !== -1 || d_nlpLayerType === 'user'">
        <template slot="title">
          Yeni İfade
        </template>
        <template v-if="d_selectedTab === 'nlp_phrase_new'">

            <b-row>
              <b-col sm="12" lg="10"></b-col>
              <b-col sm="12" lg="2" style="text-align: right;">
                <b-button @click="f_nlpPhraseRecord('new')" size="md" style="width: 100%;" variant="outline-primary">
                  <i class="fa fa-save"></i> kaydet
                </b-button>
              </b-col>
            </b-row>

            <b-row style="margin: 3px;">
              <b-col sm="12" lg="4">
                {{ d_nlpPhraseWdm['0']['parameters']['0']['name']['translation'][StoreLang] }}
              </b-col>
              <b-col sm="12" lg="8">
                <select class="form-control" v-model="d_nlpPhraseDataNew.status">
                  <option v-for="(op, op_index) in d_nlpPhraseWdm['0']['parameters']['0'].options" :value="op.value">
                    {{ op.translation[StoreLang] }}
                  </option>
                </select>
              </b-col>
            </b-row>

            <b-row style="margin: 3px;">
              <b-col sm="12" lg="4">
                {{ d_nlpPhraseWdm['0']['parameters']['12']['name']['translation'][StoreLang] }}
              </b-col>
              <b-col sm="12" lg="8">
                <select class="form-control" v-model="d_nlpPhraseDataNew.regx_usage_type">
                  <option v-for="(op, op_index) in d_nlpPhraseWdm['0']['parameters']['12'].options" :value="op.value">
                    {{ op.translation[StoreLang] }}
                  </option>
                </select>
              </b-col>
            </b-row>

<!--             <b-row style="margin: 3px;">
              <b-col sm="12" lg="4">
                {{ d_nlpPhraseWdm['0']['parameters']['8']['name']['translation'][StoreLang] }}
              </b-col>
              <b-col sm="12" lg="8">
                <select class="form-control" v-model="d_nlpPhraseDataNew.who_can_use">
                  <option v-for="(op, op_index) in d_nlpPhraseWdm['0']['parameters']['8'].options" :value="op.value">
                    {{ op.translation[StoreLang] }}
                  </option>
                </select>
              </b-col>
            </b-row>
 -->
            <b-row style="margin: 3px;">
              <b-col sm="12" lg="4">
                {{ d_nlpPhraseWdm['0']['parameters']['1']['name']['translation'][StoreLang] }}
              </b-col>
              <b-col sm="12" lg="8">
                <b-form-textarea rows="1" v-model="d_nlpPhraseDataNew.phrase_group_name"></b-form-textarea>
              </b-col>
            </b-row>

            <b-row style="margin: 3px;">
              <b-col sm="12" lg="4">
                {{ d_nlpPhraseWdm['0']['parameters']['2']['name']['translation'][StoreLang] }}
              </b-col>
              <b-col sm="12" lg="8">
                <b-form-textarea rows="1" v-model="d_nlpPhraseDataNew.phrase_name"></b-form-textarea>
              </b-col>
            </b-row>

            <template v-if="d_nlpPhraseRecordLoading">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </template>


            <template v-if="d_nlpPhraseDataNew.phrase_option.length > 0">
              <table border="1" align="center" style="width: 100%; table-layout: fixed; overflow-wrap: break-word; border-color: lightcyan;">
                <thead>
                  <tr>
                    <th style="width: 3%; background-color: lightcyan;">no</th>
                    <th style="width: 7%; background-color: lightcyan;">copy</th>
                    <th style="width: 20%; background-color: lightcyan;">seçenek adı</th>
                    <th style="width: 40%; background-color: lightcyan;">regx adı</th>
                    <th style="width: 40%; background-color: lightcyan;">regx kodu</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(phrase_option, phrase_option_index) in d_nlpPhraseDataNew.phrase_option">
                    <template v-for="(regx_item, regx_item_index) in phrase_option.regx">
                      <tr>
                        <td style="text-align: center;">
                          <div :style="f_calculateSingleEvenStyle(phrase_option_index, 'lightgray')">
                            {{ phrase_option_index + 1 }}.{{ regx_item_index + 1 }}
                          </div>
                        </td>
                        <td>
                          <b-button variant="outline-success" @click="f_copyRegxItem('edit', phrase_option_index, regx_item_index)" size="sm" style="margin-right: 3px;">
                            <i class="fa fa-copy"></i>
                          </b-button>
                          <b-button variant="outline-danger" @click="f_deleteRegxItem('edit', phrase_option_index, regx_item_index)" size="sm" style="margin-right: 3px;">
                            <i class="fa fa-trash"></i>
                          </b-button>
                        </td>
                        <td @click="f_selectCell(phrase_option_index, regx_item_index, '1')">
                          <template v-if="phrase_option_index.toString() + '_' + regx_item_index.toString() + '_1' === d_selectedCell">
                            <b-form-textarea :id="phrase_option_index.toString() + '_' + regx_item_index.toString() + '_1'" rows="1" v-model="phrase_option.phrase_option_name" style="padding: 0px;"></b-form-textarea>
                          </template>
                          <template v-else>
                            {{ phrase_option.phrase_option_name }}
                          </template>
                        </td>
                        <td @click="f_selectCell(phrase_option_index, regx_item_index, '2')">
                          <template v-if="phrase_option_index.toString() + '_' + regx_item_index.toString() + '_2' === d_selectedCell">
                            <b-form-textarea :id="phrase_option_index.toString() + '_' + regx_item_index.toString() + '_2'" rows="1" v-model="regx_item.regx_name" style="padding: 0px;"></b-form-textarea>
                          </template>
                          <template v-else>
                            {{ regx_item.regx_name }}
                          </template>
                        </td>
                        <td @click="f_selectCell(phrase_option_index, regx_item_index, '3')">
                          <template v-if="phrase_option_index.toString() + '_' + regx_item_index.toString() + '_3' === d_selectedCell">
                            <b-form-textarea :id="phrase_option_index.toString() + '_' + regx_item_index.toString() + '_3'" rows="1" v-model="regx_item.regx_code" style="padding: 0px;"></b-form-textarea>
                          </template>
                          <template v-else>
                            {{ regx_item.regx_code }}
                          </template>
                        </td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
            </template>

            <b-row style="margin: 3px;">
              <b-col sm="12" lg="6">
                <b-button variant="outline-primary" @click="f_addNewPhraseOptionItem('new')" size="sm">
                  <i class="fa fa-plus-circle"></i> yeni seçenek
                </b-button>
              </b-col>
              <b-col sm="12" lg="6">
              </b-col>
            </b-row>


        </template>
      </b-tab>


      <b-tab :active="d_selectedTab === 'nlp_text_list'" @click="d_selectedTab = 'nlp_text_list'" v-if="d_phraseEditPermissionEligible">
        <template slot="title">
          Etiketleme
        </template>
        <template v-if="d_selectedTab === 'nlp_text_list'">
          <b-row>
            <b-col sm="12" lg="4" style="border-right: solid 1px lightskyblue;">
              <div style="overflow-x: hidden; overflow-y: auto; height: 600px;">

                <b-row>
                  <b-col sm="12" lg="12">
                    <strong>Metin Seçimi</strong>
                    <i class="fa fa-info-circle" style="color: blue; padding: 3px;" @click="d_phraseHelp.show = true;"></i>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="12" lg="12">
                    <b-form-select class="form-control" v-model="d_nlpSelectedTextName" @change="f_selectNlpWorkText()">
                      <option v-for="(text, text_ind) in d_nlpTextList" :value="text">
                        {{ text }}
                      </option>
                    </b-form-select>
                  </b-col>
                </b-row>

                <hr>

                <b-row>
                  <b-col sm="12" lg="9">
                    <strong>Filtre</strong>
                  </b-col>
                  <b-col sm="12" lg="3">
                      <b-button variant="white" @click="f_clearFilter()" size="sm" style="margin-right: 3px;" title="filtreyi temizle">
                        <i class="fa fa-close"></i>
                      </b-button>
                  </b-col>
                </b-row>

                <hr>
                <b-row>
                  <b-col sm="12" lg="6">
                    <strong>ile başlayıp</strong>
                  </b-col>
                  <b-col sm="12" lg="6">
                    <b-form-input v-model="d_startEnd.start"></b-form-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="12" lg="6">
                    <strong>ile biten</strong>
                  </b-col>
                  <b-col sm="12" lg="6">
                    <b-form-input v-model="d_startEnd.end"></b-form-input>
                  </b-col>
                </b-row>

                <b-row v-if="d_startEnd.start && d_startEnd.end">
                  <b-col sm="12" lg="12">
                    <b-button variant="outline-success" @click="f_addCombinationsFromStartEnd()" size="sm" style="margin-right: 3px;" title="çalışma notlarından başlangıç ve bitiş kullanarak kombinasyon üret">
                      <i class="fa fa-star">kombinasyon</i>
                    </b-button>
                  </b-col>
                </b-row>
                <hr>

                <b-row>
                  <b-col cols="12">
                    <b-form-checkbox v-model="d_showPhraseRegxPositiveRows" variant="primary">
                      İfade regx pozitif
                    </b-form-checkbox>
<!--                     <b-button :variant="d_showPhraseRegxPositiveRows ? 'primary' : 'outline-primary'" @click="d_showChangePhraseRegxPositiveRows()" size="sm" style="margin-right: 3px;">
                      <i class="fa fa-check"></i>
                    </b-button>
 -->                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="12" lg="12">
                    <b-form-input type="text" v-model="d_nlpWorkTextPagination.search" placeholder="arama"></b-form-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="12" lg="12">
                    <b-form-textarea rows="1" v-model="d_nlpWorkTextPagination.regx_search" placeholder="regx ile arama"></b-form-textarea>
                    <div v-if="d_searchRegxTextError">
                      <span style="color: red;">
                        regx hatalı lütfen düzeltiniz
                      </span>
                    </div>
                  </b-col>
                </b-row>

                <hr>

                <b-row>
                  <b-col sm="12" lg="12">
                    <strong>Yeni Regx Kayıt</strong>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12">
                    regx adı
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12">
                    <b-form-textarea rows="1" v-model="d_newPhraseOptionRegx.regx_name" placeholder="regx örnek adı"></b-form-textarea>
                  </b-col>
                </b-row>

<!--                 <b-row>
                  <b-col cols="4">
                    regx kodu
                  </b-col>
                  <b-col cols="8">
                    <b-form-textarea rows="1" v-model="d_newPhraseOptionRegx.regx_code" placeholder="regx kodu"></b-form-textarea>
                  </b-col>
                </b-row>
 -->
                <template>
                  <b-row style="padding: 3px; border-bottom: solid 1px lightblue;">
                    <b-col cols="6">
                      regx yapı
                    </b-col>
                    <b-col cols="3">
                      <b-button variant="outline-success" @click="f_createRegxStructure()" size="sm" style="margin-right: 3px;" title="regx güncelle">
                        <i class="fa fa-edit">yapı</i>
                      </b-button>
                    </b-col>
                    <b-col cols="3">
                      <b-button variant="outline-success" @click="f_updateRegxStructure()" size="sm" style="margin-right: 3px;" title="regx güncelle">
                        <i class="fa fa-edit">regx</i>
                      </b-button>
                    </b-col>
                  </b-row>
                
                  <b-row>
                    <b-col cols="12">
                      <template v-for="(txt, txt_index) in d_newPhraseOptionRegx.regx_list">
                        <b-row style="padding: 5px; border-bottom: solid 1px lightblue;">
                          <b-col cols="6">
                            <div>
                              <b-form-input v-model="txt.text"></b-form-input>
                            </div>
                            <div>
                              <b-form-input v-model="txt.start" placeholder="başlangıç karakterleri kullan"></b-form-input>
                            </div>
                          </b-col>
                          <b-col cols="6">
                            <b-form-select v-model="txt.use">
                              <option value="">{{ txt.text }}</option>
                              <option value="numeric">0-9</option>
                              <option value="alpha_numeric">tüm karakterler</option>
                              <option value="x_count_word">x adet kelime</option>
                            </b-form-select>
                            <div v-if="txt.use === 'x_count_word'">
                              <b-form-input v-model="txt.count_of_word" placeholder="kelime adedi"></b-form-input>
                            </div>
                            <b-form-select v-model="txt.output_type">
                              <option value="">varlığı yeterli</option>
                              <option value="output">çıktı olacak</option>
                            </b-form-select>
                          </b-col>
                        </b-row>
                      </template>
                    </b-col>
                  </b-row>
                </template>
              
                <b-row>
                  <b-col cols="12">
                    ifade seçenekleri
                  </b-col>
                </b-row>

                <template v-if="d_selectedNlpPhraseData">
                  <b-row>
                    <b-col cols="12">
                      <template v-for="(ph_op, ph_op_index) in d_selectedNlpPhraseData.phrase_option">
                        <b-row @click="d_newPhraseOptionRegx.phrase_option_name = ph_op.phrase_option_name" :style="d_newPhraseOptionRegx.phrase_option_name === ph_op.phrase_option_name ? 'background-color: lightgreen; border: solid 1px lightgray; padding: 3px; margin: 0px;' : 'border: solid 1px lightgray; padding: 3px; margin: 0px;'">
                          <b-col cols="2">
                            {{ ph_op_index + 1 }} -
                          </b-col>
                          <b-col cols="10">
                            <b-form-input type="text" v-model="ph_op.phrase_option_name"></b-form-input>
                          </b-col>
                        </b-row>
                      </template>
                      <b-row style="padding: 3px; margin: 0px;">
                        <b-col cols="10">
                          <b-form-input type="text" v-model="d_newPhraseOptionName"></b-form-input>
                        </b-col>
                        <b-col cols="2">
                          <b-button v-if="d_newPhraseOptionName" variant="outline-primary" @click="f_addNewPhraseOptionItem('edit', d_newPhraseOptionName)" size="sm" style="margin-right: 3px;" title="ekle">
                            <i class="fa fa-plus"></i>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" style="text-align: right;">
                        <b-button v-if="d_newPhraseOptionRegx.phrase_option_name && d_newPhraseOptionRegx.regx_name && d_nlpWorkTextPagination.regx_search" variant="outline-primary" @click="f_addNewPhraseOptionRegx()" size="sm" style="margin-right: 3px;" title="ekle">
                          <i class="fa fa-save"></i> regx kaydet
                        </b-button>
                    </b-col>
                  </b-row>
                </template>
                <template v-else>
                  <small>ifade seçili değil</small>
                </template>

                <hr>

                <b-row>
                  <b-col sm="12" lg="10">
                    <strong>Yeni satırlar</strong>
                  </b-col>
                  <b-col sm="12" lg="2">
                    <b-button v-if="d_nlpNewRows" variant="outline-success" @click="f_addNewRowsToSelectedPhraseWork()" size="sm" style="margin-right: 3px;" title="seçilenleri sil">
                      <i class="fa fa-plus"></i>
                    </b-button>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12">
                    <b-form-textarea rows="1" v-model="d_nlpNewRows" placeholder="yeni satırları ekleyiniz"></b-form-textarea>
                  </b-col>
                </b-row>


              </div>
            </b-col>

            <b-col sm="12" lg="8">
              <b-row style="margin: 3px; padding: 3px;">
<!--                 <b-col cols="1">
                  <i class="fa fa-check"> {{ d_selectRowsOfWorkTextList.length }} </i>
                </b-col>
                <b-col cols="1">
                  <b-button v-if="d_selectRowsOfWorkTextList.length > 0" variant="outline-success" @click="f_deleteSelectedWorkTextRows()" size="sm" style="margin-right: 3px;" title="seçilenleri sil">
                    <i class="fa fa-trash"></i>
                  </b-button>
                </b-col>
 -->                
                <b-col cols="5">
                  <b-button v-if="d_nlpSelectedTextName" variant="outline-primary" @click="f_recordWorkText()" size="md" style="margin-right: 3px;" title="metni kaydet">
                    <i class="fa fa-save"></i>
                  </b-button>

                  <b-button v-if="d_nlpSelectedTextName && d_selectedNlpPhraseData" variant="outline-primary" @click="f_analyzeWorkTextByPhraseOptions()" size="md" style="margin-right: 3px;" title="ifade ile analiz yap">
                    <i class="fa fa-star-half"></i>
                  </b-button>

                    <b-button v-if="d_selectedWorkTextRows.length > 0" variant="outline-primary" @click="f_clearWordSelectionsInWorkTextRows()" size="md" style="margin-right: 3px;" title="kelime seçimlerini temizle">
                      <i class="fa fa-text-width"></i>
                    </b-button>

                    <b-button v-if="d_showEligibleWorkTextIndexList.length > 0" variant="outline-primary" @click="f_deleteFilteredRows()" size="md" style="margin-right: 3px;" title="filtreli seçimleri sil">
                      <i class="fa fa-filter"></i>
                      <i class="fa fa-trash"></i>
                    </b-button>

                    <b-button v-if="d_selectedWorkTextRows.length > 0" variant="outline-primary" @click="f_copyWorkTextRows()" size="md" style="margin-right: 3px;" title="çalışma metnini kopyala">
                      <i class="fa fa-copy"></i>
                    </b-button>

                    <b-button v-if="d_nlpSelectedTextName" variant="outline-primary" @click="f_deleteWorkTextRows()" size="md" style="margin-right: 3px;" title="çalışma metnini sil">
                      <i class="fa fa-trash"></i>
                    </b-button>

                </b-col>
                <b-col cols="2">
                  <b-form-checkbox v-model="d_showRegxCodesSearch" variant="success"> regx </b-form-checkbox>
                </b-col>
                <b-col cols="2">
                  <div>
                    ( {{ d_nlpWorkTextPagination.current * d_nlpWorkTextPagination.perpage }} ) {{ d_nlpWorkTextPagination.filter_count }} / {{ d_selectedWorkTextRows.length }} 
                  </div>
                </b-col>
                <b-col cols="3">
                  <b-pagination size="sm" class="pull-right" style="margin-bottom: 0px;" aria-controls="column_pagination" v-model="d_nlpWorkTextPagination.current" :total-rows="d_nlpWorkTextPagination.all_count" :per-page="d_nlpWorkTextPagination.perpage"></b-pagination>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <b-form-input v-model="d_markText.text" placeholder="kelimeleri renklendirmek için yazınız"></b-form-input>
                </b-col>
              </b-row>

              <div style="overflow-x: hidden; overflow-y: auto; height: 500px;">
                <template v-for="(row, row_ind) in d_selectedWorkTextRows">
                  <b-row v-if="d_showEligibleWorkTextIndexList.indexOf(row_ind)  !== -1" :key="'work_text_row_' + row_ind" :style="d_selectedWorkTextRowIndex === row_ind ? 'padding: 2px; border-bottom: solid 1px lightblue; background-color: #e9f8ff;' : 'padding: 2px; border-bottom: solid 1px lightblue;'" @click="d_selectedWorkTextRowIndex = row_ind" @mouseover="d_selectedWorkTextRowIndex = row_ind">
                    <b-col sm="12" lg="1">
                      <b-button v-if="d_selectedWorkTextRowIndex === row_ind" size="sm" @click="f_deleteRowFromWorkText(row_ind)" variant="outline-danger">
                        <i class="fa fa-trash">
                          {{ row_ind + 1 }}
                        </i>
                      </b-button>
                    </b-col>
                    <b-col sm="12" lg="1">
                      <b-button v-if="d_selectedWorkTextRowIndex === row_ind" size="sm" @click="f_createRegxItemFromSelectedWords(row_ind)" variant="outline-success">
                        <i class="fa fa-star-o"></i>
                      </b-button>
                    </b-col>
                    <b-col sm="12" lg="10">
                      <b-row style="font-size: 12px;">
                        <b-col cols="1">
                          <template v-if="row.rgx && row.rgx.length > 0 && !d_showRegxCodesSearch">
                            <template v-for="(rgx, rgx_ind) in row.rgx">
                              <b-row style="margin: 0px; border-bottom: solid 1px lightcyan; font-size: 12px; min-height: 30px;">
                                <b-col sm = "12" lg="12">
                                  <div :style="'color: ' + d_phraseOptionColorList[rgx.p_op]">
                                    <strong>{{ rgx.p_op }}</strong>
                                  </div>
                                </b-col>
                              </b-row>
                            </template>
                        </template>
                        </b-col>

                        <b-col cols="11">
                          <template v-for="(word, word_index) in row.w">
                            <template v-if="word.s">
                              <span :style="word.se ? 'border-bottom: solid 3px red; padding: 2px;' : ''">
                                <strong :style="word.s === 1 ? 'padding: 2px; cursor: pointer; background-color: lightblue;' : 'padding: 2px; cursor: pointer;'" @click="word.s === 1 ? word.s = 0 : word.s = 1; $forceUpdate();">
                                  {{ word.x }} 
                                </strong>
                              </span>
                            </template>
                            <template v-else>
                              <span :style="word.se ? 'border-bottom: solid 3px red; padding: 2px;' : ''">
                                <strong :style="word.m === 1 ? 'padding: 2px; cursor: pointer; background-color: lightgreen;' : 'padding: 2px; cursor: pointer;'" @click="word.s === 1 ? word.s = 0 : word.s = 1; $forceUpdate();">
                                  {{ word.x }}
                                </strong>
                              </span>
                            </template>
                          </template>
                        </b-col>

                      </b-row>
                      <!-- <hr v-if="row.rgx && row.rgx.length > 0"> -->
                      <template v-if="row.rgx && row.rgx.length > 0 && d_showRegxCodesSearch">
                        <template v-for="(rgx, rgx_ind) in row.rgx">
                          <b-row style="margin: 0px; border-bottom: solid 1px lightcyan; font-size: 12px; min-height: 30px;">
                            <b-col sm = "12" lg="1">
                              {{ rgx_ind + 1 }} )
                              <template v-if="d_selectedWorkTextRowIndex === row_ind">
                                <b-button size="sm" @click="f_deletePhraseOptionRegx(rgx)" variant="outline-danger" title="regx ifade seçeneğinden sil">
                                  <i class="fa fa-trash"></i>
                                </b-button>
                              </template>
                            </b-col>
                            <b-col sm = "12" lg="11">
                              <div :style="'color: ' + d_phraseOptionColorList[rgx.p_op]">
                                <i class="fa fa-angle-right"></i> {{ rgx.p_op }}
                              </div>
                              <template v-if="d_showRegxCodesSearch">
                                <div style="overflow-x: auto; overflow-y: hidden; white-space: nowrap; height: 35px;">
                                   {{ rgx.r_name }}
                                </div>
                                <div  style="overflow-x: auto; overflow-y: hidden; white-space: nowrap; height: 35px;">
                                    {{ rgx.r_code }}
                                </div>
                              </template>
                            </b-col>
                          </b-row>
                        </template>
                      </template>
                    </b-col>
                  </b-row>
                </template>
              </div>

            </b-col>
          </b-row>

        </template>
      </b-tab>

      <b-tab :active="d_selectedTab === 'new_nlp_text_list'" @click="d_selectedTab = 'new_nlp_text_list'">
        <template slot="title">
          Yeni Etiket Metni
        </template>
        <template v-if="d_selectedTab === 'new_nlp_text_list'">
          <b-row>
            <b-col sm="12" lg="4">
              
            </b-col>
            <b-col sm="12" lg="4">
              <b-button @click="f_deleteAllWorkText()" size="md" style="width: 100%;" variant="outline-primary">
                <i class="fa fa-plus-circle"></i> tüm çalışma metinlerini temizle
              </b-button>
            </b-col>
            <b-col sm="12" lg="4">
              <b-button v-if="d_workTextNew.name && d_workTextNew.content" @click="f_addNewWorkText()" size="md" style="width: 100%;" variant="outline-primary">
                <i class="fa fa-plus-circle"></i> çalışma metnini kaydet
              </b-button>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12" lg="2">
              Çalışma metni adı
            </b-col>
            <b-col sm="12" lg="10">
              <b-form-textarea rows="10" v-model="d_workTextNew.name"></b-form-textarea>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12" lg="2">
              Çalışma metni satırlar
            </b-col>
            <b-col sm="12" lg="10">
              <b-form-textarea rows="10" v-model="d_workTextNew.content"></b-form-textarea>
            </b-col>
          </b-row>          
        </template>
      </b-tab>

      <b-tab :active="d_selectedTab === 'phrase_settings'" @click="d_selectedTab = 'phrase_settings'">
        <template slot="title">
          İfade ayarları
        </template>
        <template v-if="d_selectedTab === 'phrase_settings'">
          <template v-if="d_selectedPhraseSettings">

            <b-row style="margin: 0px;">
              <b-col sm="12" lg="3">
                <strong>metin konumu</strong>
              </b-col>
              <b-col sm="12" lg="6">
                <b-form-select class="form-control" v-model="d_selectedPhraseSettings.text_location">
                  <option value="start">metnin başında</option>
                  <option value="end">metnin sonunda</option>
                  <option value="any_where">herhangi bir yerde (metnin başı ya da sonu farketmez)</option>
                  <option value="start_or_any_where_with_spaces">başlangıçta veya herhangi bir yerde (metnin başı boşluk yapısı içersin)</option>
                </b-form-select>
              </b-col>
            </b-row>

            <hr>

            <b-row style="margin: 0px;">
              <b-col sm="12" lg="3">
                <strong>tüm karakterleri küçük harf yap</strong>
              </b-col>
              <b-col sm="12" lg="6">
                <b-form-select class="form-control" v-model="d_selectedPhraseSettings.all_chars_lower_case">
                  <option value="yes">evet</option>
                  <option value="no">hayır</option>
                </b-form-select>
              </b-col>
            </b-row>

            <hr>

            <b-row style="margin: 0px;">
              <b-col sm="12" lg="3">
                <strong>kombinasyon üretme tipi</strong>
              </b-col>
              <b-col sm="12" lg="6">
                <b-form-select class="form-control" v-model="d_selectedPhraseSettings.combination_type">
                  <option value="all_in_one">hepsi bir regxte</option>
                  <option value="one_by_one">ayrı ayrı</option>
                </b-form-select>
              </b-col>
            </b-row>

            <hr>

            <b-row style="margin: 0px;">
              <b-col sm="12" lg="3">
                <strong>boşluk modu</strong>
              </b-col>
              <b-col sm="12" lg="6">
                <b-form-select class="form-control" v-model="d_selectedPhraseSettings.space_mode">
                  <option value="one_or_more">bir ya da daha fazla karakter</option>
                  <option value="zero_or_more">sıfır ya da daha fazla karakter</option>
                </b-form-select>
              </b-col>
            </b-row>

            <hr>

            <b-row>
              <b-col lg="4">
                <b-row style="margin: 0px;">
                  <b-col sm="12" lg="12">
                    <strong>Düzenli ifade üretirken boşluğa dönüştürülecek karakterler</strong>
                  </b-col>
                </b-row>
                <b-row style="margin: 0px;">
                  <b-col sm="12" lg="12">
                    <template v-for="(x, x_ind) in d_selectedPhraseSettings.replace_char_as_space">
                      <b-row>
                        <b-col sm="12" lg="4">
                          <b-form-input type="text" v-model="x.char" style="font-size: 20px;" autocomplete="off" placeholder="karakter yazınız"></b-form-input>
                        </b-col>
                        <b-col sm="12" lg="4">
                          <b-button variant="outline-danger" @click="f_deleteListItem(d_selectedPhraseSettings.replace_char_as_space, x_ind)" size="sm" title="kelime sil" style="padding: 2px; margin: 0px;">
                            <i class="fa fa-trash"></i>
                          </b-button>
                        </b-col>
                      </b-row>
                    </template>
                    <b-row>
                      <b-col sm="12" lg="12">
                        <b-button variant="outline-success" @click="f_newListItem(d_selectedPhraseSettings.replace_char_as_space, {'char': ''})" size="sm" title="kelime sil" style="padding: 2px; margin: 0px;">
                          <i class="fa fa-plus"></i>
                        </b-button>                    
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="4">
                <b-row style="margin: 0px;">
                  <b-col sm="12" lg="12">
                    <strong>Boşluk olarak tanımlanan aralıklarda bu karakterler olabilir</strong>
                  </b-col>
                </b-row>

                <b-row style="margin: 0px;">
                  <b-col sm="12" lg="12">
                    <template v-for="(x, x_ind) in d_selectedPhraseSettings.spaces_can_include">
                      <b-row>
                        <b-col sm="12" lg="4">
                          <b-form-input type="text" v-model="x.char" style="font-size: 20px;" autocomplete="off" placeholder="karakter yazınız"></b-form-input>
                        </b-col>
                        <b-col sm="12" lg="4">
                          <b-button variant="outline-danger" @click="f_deleteListItem(d_selectedPhraseSettings.spaces_can_include, x_ind)" size="sm" title="kelime sil" style="padding: 2px; margin: 0px;">
                            <i class="fa fa-trash"></i>
                          </b-button>
                        </b-col>
                      </b-row>
                    </template>
                    <b-row>
                      <b-col sm="12" lg="12">
                        <b-button variant="outline-success" @click="f_newListItem(d_selectedPhraseSettings.spaces_can_include, {'char': ''})" size="sm" title="kelime sil" style="padding: 2px; margin: 0px;">
                          <i class="fa fa-plus"></i>
                        </b-button>                    
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

              </b-col>
              <b-col lg="4">
                <b-row style="margin: 0px;">
                  <b-col sm="12" lg="12">
                    <strong>Bu karakterleri belirtilen karakter ile değiştir</strong>
                  </b-col>
                </b-row>
                <b-row style="margin: 0px;">
                  <b-col sm="12" lg="12">
                    <template v-for="(x, x_ind) in d_selectedPhraseSettings.replace_all_chars_as_this">
                      <b-row>
                        <b-col sm="12" lg="4">
                          <b-form-input type="text" v-model="x.char" style="font-size: 20px;" autocomplete="off" placeholder="değiştirilecek karakter"></b-form-input>
                        </b-col>
                        <b-col sm="12" lg="4">
                          <b-form-input type="text" v-model="x.replace" style="font-size: 20px;" autocomplete="off" placeholder="yerine gelecek karakter"></b-form-input>
                        </b-col>
                        <b-col sm="12" lg="4">
                          <b-button variant="outline-danger" @click="f_deleteListItem(d_selectedPhraseSettings.replace_all_chars_as_this, x_ind)" size="sm" title="kelime sil" style="padding: 2px; margin: 0px;">
                            <i class="fa fa-trash"></i>
                          </b-button>
                        </b-col>
                      </b-row>
                    </template>
                    <b-row>
                      <b-col sm="12" lg="12">
                        <b-button variant="outline-success" @click="f_newListItem(d_selectedPhraseSettings.replace_all_chars_as_this, {'char': '', 'replace': ''})" size="sm" title="kelime sil" style="padding: 2px; margin: 0px;">
                          <i class="fa fa-plus"></i>
                        </b-button>                    
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="12" lg="10">
                
              </b-col>
              <b-col sm="12" lg="2">
                <b-button @click="f_savePhraseSettings()" size="md" style="width: 100%;" variant="outline-primary">
                  <i class="fa fa-save"></i>
                </b-button>
              </b-col>
            </b-row>


          </template>
        </template>
      </b-tab>


    </b-tabs>


    <b-modal v-if="d_phraseHelp.show" id="d_phraseHelp_show" v-model="d_phraseHelp.show" scrollable hide-footer title="Yardım" centered header-bg-variant="info" header-text-variant="dark" size="xl">
      <p>
        <b-row style="border-bottom: solid 1px lightblue;">
          <b-col sm="12" lg="1">
            1
          </b-col>
          <b-col sm="12" lg="11">
            <h6>İfade seçeneği başlığı ile aranan regx uyumlu olmalı.</h6>
            Örneğin brca heterozigot durumunda brca1 pozitif ya da brca2 pozitif olabilir. Bu durumda brca heterozigot seçeneği için ayrı bir etiketleme çalışması yapmak önerilir. Dolayısıyla kullanıcı aslında gerçekten de heterozigot bilgisine de zaten erişmek istiyor olabilir. Tüm ifadeleri ortak bir ifade seçeneğinde toplamaya çalışmak da ayrıca veriyi kullanıcı tarafından istenilmeyebilir. Çünkü ilgili detay ifadeyi de görmek isteyebilir. Ayrıca brca pozitif denilince brca 1 pozitif kabul etmek de aslında çok doğru değil. Çünkü aslında brca 1 veya ikisinden birisi pozitif anlamına gelecektir.
          </b-col>
        </b-row>
        <b-row style="border-bottom: solid 1px lightblue;">
          <b-col sm="12" lg="1">
            2
          </b-col>
          <b-col sm="12" lg="11">
            <h6>İfade çalışması sırasında, anahtar kelime ilişkili tespit edilen, yeni seçenekler üretilerek ifadeye eklenir.</h6>
             Dolayısıyla buna uygun bir anahtar kelime belirlemek her zaman daha mantıklıdır. Örneğin brca anahtar kelimesi için Birinci modelde, - brca 1 sonucu - ifadesini çalışmak hedeflendiğinde brca 1 negatif ya da brca 1 pozitif ifadesi etiketlenir. İkinci modelde, - brca sonucu - şeklinde bir ifade başlığı altında çalışılırsa, brca heterozigot, brca 1 negatif, brca 2 pozitif gibi farklı seçenek listeleri de üretilebilir. Her iki ifade de ayrı olarak çalışılabilir. Fakat ikinci model aslında daha kapsamlı olan modeldir.
          </b-col>
        </b-row>
        <b-row style="border-bottom: solid 1px lightblue;">
          <b-col sm="12" lg="1">
            3
          </b-col>
          <b-col sm="12" lg="11">
            <h6>Etiketleme sırasında ifade seçenekleri kombinasyonları üretmeye çalışılmaz</h6>
             Örneğin brca 1 pozitif ifadesi aslında brca 2 pozitif ifadesi için de regx üretilmesi gerekir. Fakat brca 1 çalışılırken bu kombinasyonu üretmeye çalışmak ifade etiketleme hızını yavaşlatır. brca etiketlemesi tamamlandığında etiketlerden kontrol edilerek topluca ifade seçeneklerinde kombinasyonlar üretilir.
          </b-col>
        </b-row>
        <b-row style="border-bottom: solid 1px lightblue;">
          <b-col sm="12" lg="1">
            4
          </b-col>
          <b-col sm="12" lg="11">
            <h6>Aranan ifade seçenekleri için tanım ve tanımı anlamlandırma kelimeleri birleştirilerek regx üretilir. </h6>
             Örneğin - brca mutasyonu - tanım ifadesidir.
             - brca mutasyonu let -, - brca mutasyonu + - ifadelerinde var, + kelimeleri ise anlamlandırma sağlar.
          </b-col>
        </b-row>
        <b-row style="border-bottom: solid 1px lightblue;">
          <b-col sm="12" lg="1">
            5
          </b-col>
          <b-col sm="12" lg="11">
            <h6>regx yapısında her bir kelime grubunu neden listeleniyor ?</h6>
            regx_list yapısının üretilmesinin temel nedeni üretilen her bir regx değeri için ek bir düzenleme yapmak gerekbilmektedir.
            Örneğin mutasyon değerini mut ile başlayıp diğer tüm alfa karakterler ile devam etmesini istediğimizde start kısmını da ekranda dolduruyoruz.
            Bir başka nedeni, hangi regx ifadeleri arasında boşluk olacağını görmemiz.
            Ayrıca regx içindeki kelime öbeklerini ayrı ayrı gördüğümüzde bu kısımlara regx uzun karmaşık yapısına göre daha kolay düzenleme yapabilmemizdir.

          </b-col>
        </b-row>
        <b-row style="border-bottom: solid 1px lightblue;">
          <b-col sm="12" lg="1">
            6
          </b-col>
          <b-col sm="12" lg="11">
            <h6>regx yapısında her bir kelime grubunu neden listeleniyor ?</h6>
            regx_list yapısının üretilmesinin temel nedeni üretilen her bir regx değeri için ek bir düzenleme yapmak gerekbilmektedir.
            Örneğin mutasyon değerini mut ile başlayıp diğer tüm alfa karakterler ile devam etmesini istediğimizde start kısmını da ekranda dolduruyoruz.
            Bir başka nedeni, hangi regx ifadeleri arasında boşluk olacağını görmemiz.
            Ayrıca regx içindeki kelime öbeklerini ayrı ayrı gördüğümüzde bu kısımlara regx uzun karmaşık yapısına göre daha kolay düzenleme yapabilmemizdir.

          </b-col>
        </b-row>
        <b-row style="border-bottom: solid 1px lightblue;">
          <b-col sm="12" lg="1">
            7
          </b-col>
          <b-col sm="12" lg="11">
            <h6>Metnin başı ve sonu yapılarına doğru karar verilmelidir</h6>
            Metin içinde daha geniş bulma modeli herhangi bir yerde (metnin başı ya da sonu farketmez) modelidir.
            Fakat metnin başında ilgili kelimeyi bozan başka karakterlerin olması durumu gerçekleştiğinde metnin bozulması ihtimali varsa bu durumda - başlangıçta veya herhangi bir yerde (metnin başı boşluk yapısı içersin) - şeklinde seçim yapılabilir.
            Örneğin er+ ifadesini her yerde ara dendiğinde, cer+ ifadesini de doğru kabul edecektir. Bu durumda er+ doğru bulunamayabilir. Bu durumda daha güvenilir yöntem - başlangıçta veya herhangi bir yerde (metnin başı boşluk yapısı içersin) - formatında regx üretimi yapmaktır.
            Buna ifadelerin benzersizliklerine dikkat edilerek karar verilebilir. Tabii ki - başlangıçta veya herhangi bir yerde (metnin başı boşluk yapısı içersin) - çoğu zaman en güvenilir yöntem diyebiliriz.
          </b-col>
        </b-row>
        <b-row style="border-bottom: solid 1px lightblue;">
          <b-col sm="12" lg="1">
            8
          </b-col>
          <b-col sm="12" lg="11">
            <h6>Metin arasında boşluk olmayabileceği de yönetilebilir.</h6>
            <div>[], parantez içindeki karakterlerin herhangi bir kombinasyonu</div>
            <div>*, önceki desenin 0 veya daha fazlası</div>
            <div>+, önceki desenin 1 veya daha fazlası</div>
          </b-col>
        </b-row>
        <b-row style="border-bottom: solid 1px lightblue;">
          <b-col sm="12" lg="1">
            9
          </b-col>
          <b-col sm="12" lg="11">
            <h6>Bir düzenli ifadenin ya da karakterin kaç kez tekrar edebileceğini aşağıdaki gibi belirtiyoruz.</h6>
              <div>X, tam n adet tekrar etsin : X{n}</div>
              <div>X, en az n adet tekrar etsin : X{n,}</div>
              <div>X, en az n maksimum m adet tekrar etsin X{n,m}</div>
          </b-col>
        </b-row>
      </p>
    </b-modal>

  </div>
</template>

<script type="text/javascript">

import { ClsWdm } from '@/wam/modules/wdm';
import { ServiceFunctions } from '@/wam/modules/service';
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'NlpPhrase',
  computed: mapGetters({
    lang: 'lang',
    device: 'device'
  }),
  props: {
    p_nlpData: {
      type: Object,
      required: false
    },
  },
  components: {},
  data () {
    return {
      d_phraseOptionColorList: {},
      d_showRegxCodesSearch: false,
      d_startEnd: {
        'timeout': 0,
        'start': '',
        'end': '',
      },
      d_markText: {
        'timeout': 0,
        'text': ''
      },
      d_wordContinuingWith: '[\\wıüÜğĞşŞçÇöÖi̇İ0-9]*',
      d_defaultPhraseSettings: {
        'combination_type': 'all_in_one', // one_by_one, all_in_one
        'space_mode': 'zero_or_more', // zero_or_more, one_or_more
        'text_location': 'start_or_any_where_with_spaces', // start, end, any_where, start_or_any_where_with_spaces
        'begin_and_end_spaces_delete': 'yes',
        'all_chars_lower_case': 'yes',
        'replace_char_as_space': [
          {'char': ','},
          {'char': ':'},
          {'char': ';'},
          {'char': '\\'},
          {'char': '"'},
          {'char': '\''},
          {'char': '/'},
        ],
        'spaces_can_include': [
          {'char': '&'},
          {'char': '-'},
          {'char': ':'},
          {'char': '.'},
          {'char': '/'},
          {'char': '\''},
          {'char': '"'},
        ],
        'replace_all_chars_as_this': [
          {'char': 'i', 'replace': '.'},
          {'char': 'İ', 'replace': '.'},
          {'char': 'ı', 'replace': '.'},
          {'char': 'I', 'replace': '.'},
          {'char': 'I', 'replace': '.'},
          {'char': 'i̇', 'replace': '.'},
          {'char': 'ü', 'replace': '.'},
          {'char': 'Ü', 'replace': '.'},
          {'char': 'ğ', 'replace': '.'},
          {'char': 'Ğ', 'replace': '.'},
          {'char': 'ş', 'replace': '.'},
          {'char': 'Ş', 'replace': '.'},
          {'char': 'ç', 'replace': '.'},
          {'char': 'Ç', 'replace': '.'},
          {'char': 'ö', 'replace': '.'},
          {'char': 'Ö', 'replace': '.'},
        ],
      },
      d_selectedPhraseSettings: '',
      d_phraseCombWorkList: [],
      d_newCompinationName: '',
      d_selectedPhraseCombination: {
        'name': '',
        'list': []
      },
      d_newPhraseOptionName: '',
      d_phraseHelp: {
        'show': false,
      },
      d_showPhraseRegxPositiveRows: false,
      d_searchRegxTextError: false,
      d_newPhraseOptionRegx: {
        'regx_list': [],
        'phrase_option_name': '',
        'regx_name': '',
        'regx_code': '',
      },
      d_phraseOptionAndRegxCodes: {
        'json_text': '',
      },
      d_nlpNewRows: '',
      d_allFilteredWorkList: [],
      d_showEligibleWorkTextIndexList: [],
      d_nlpWorkTextPagination: {
        'timeout': 0,
        'start': 0,
        'search': '',
        'regx_search': '',
        'end': 10,
        'current': 1,
        'perpage': 10,
        'all_count': 0,
        'filter_count': 0,
      },
      d_selectedWorkTextRowIndex: '',
      d_nlpSelectedTextName: '',
      d_workTextNew: {
        'name': '',
        'content': ''
      },
      d_selectRowsOfWorkTextList: [],
      d_nlpTextList: [],
      d_selectedWorkTextRows: [],
      d_selectedCell: '',
      d_selectedNlpPhraseIndex: '',
      d_nlpPhraseList: [],
      d_nlpPhraseClientId: '',
      d_nlpPhraseWdmrKey: '',
      StoreLang: 'tr',
      d_nlpPhraseListLoading: false,
      d_nlpPhraseRecordLoading: false,
      d_nlpLayerType: '',
      d_nlpPhraseGroupNameSearchText: '',
      d_nlpPhraseNameSearchText: '',
      d_nlpPhraseOptionNameSearch: '',
      d_nlpPhraseStatus: '',
      d_selectedTab: 'nlp_phrase_list',
      d_nlpPhraseWdm: '',
      d_nlpPhraseDataNew: {
        'status': '0',
        'phrase_group_name': '',
        'phrase_name': '',
        'phrase_option': [],
        'who_can_use': '0',
        'approved_user': [],
      },
      d_selectedNlpPhraseData: {
        'status': '0',
        'regx_usage_type': '0',
        'phrase_group_name': '',
        'phrase_name': '',
        'phrase_option': [],
        'who_can_use': '',
        'approved_user': [],
      },
      d_phraseEditPermissionEligible: false,
    };
  },
  created: function () {
    let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
    // Open (or create) the database
    let db_name = 'NlpPhrase';
    let object_store_name = 'PhraseStore';
    let open = indexedDB.open(db_name);
    open.onupgradeneeded = function() {
      console.log(db_name, ' open.onupgradeneeded');
      let db = open.result;
      let store = db.createObjectStore(object_store_name, {keyPath: "id"});
    }
    // open.onupgradeneeded = function() {
    //   console.log('open.onupgradeneeded 1111');
    //   // let index = store.createIndex("NameIndex", ["name.last", "name.first"]);
    // };


    ClsWdm.getLayerWdm('nlp_phrase_global').then(resp_wdm_data => { this.d_nlpPhraseWdm = resp_wdm_data; });
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.p_nlpData) {
      if (this.p_nlpData.work_text) {
        this.d_selectedTab = 'new_nlp_text_list';
        this.d_workTextNew.content = this.p_nlpData.work_text;
        this.d_workTextNew.name = this.p_nlpData.work_text_label;
      }
      if (this.p_nlpData.nlp_phrase_id && this.p_nlpData.nlp_layer_type) {
        this.d_nlpLayerType = this.p_nlpData.nlp_layer_type;
        this.d_nlpPhraseClientId = this.p_nlpData.nlp_phrase_id;
        this.d_selectedTab = 'nlp_phrase_selected';
        this.f_nlpPhraseGet();
      } else {
        if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('wisdom_nlp') !== -1) {
          this.d_nlpLayerType = 'global';
        } else {
          this.d_nlpLayerType = 'user';
        }
      }
    } else {
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('wisdom_nlp') !== -1) {
        this.d_nlpLayerType = 'global';
      } else {
        this.d_nlpLayerType = 'user';
      }
    }
    this.f_prepareNlpWorkTextList();
    this.f_preparePhraseCombinationWorkList();
    this.f_prepareDefaultSetings();

  },
  mounted: function () {},
  methods: {
    f_createPhraseOptionColors: function () {
      for (let phrase_op_index in this.d_selectedNlpPhraseData.phrase_option) {
        this.d_phraseOptionColorList[this.d_selectedNlpPhraseData.phrase_option[phrase_op_index].phrase_option_name] = this.f_calculateRGBA();
      }
    },
    f_calculateRGBA: function () {
      let r = Math.floor(Math.random() * 256);
      let g = Math.floor(Math.random() * 256);
      let b = Math.floor(Math.random() * 256);
      return 'rgba(' + r.toString() + ',' + g.toString() + ',' + b.toString() + ', 1)';
    },
    f_addCombinationsFromStartEnd: function () {
      let between_start_and_end_words = [];
      let total_start_end_count = 0;
      for (let row_index in this.d_selectedWorkTextRows) {
        let row_data = this.d_selectedWorkTextRows[row_index];
        if (row_data.se) {
          total_start_end_count += 1;
          let sentence = '';
          let word_used = '';
          if (row_data.w && row_data.w.length > 0) {
            let first_word_found = false;
            for (let word_index in row_data.w) {
              sentence += row_data.w[word_index].x + ' ';
              // console.log(row_data.w[word_index].x.toLocaleLowerCase(), ' ', parseInt(word_index), ' - ', row_data.w[word_index].se);
              if (row_data.w[word_index].se) {
                let use_word = false;
                if (parseInt(word_index) === 0) {
                  //
                } else if (parseInt(word_index) === row_data.w.length - 1) {
                  //
                } else {
                  if (row_data.w[parseInt(word_index) - 1].se && row_data.w[parseInt(word_index) + 1].se) {
                    use_word = true;
                  }
                }

                // console.log('use_word : ', use_word);
                if (use_word) {
                  let new_txt = row_data.w[word_index].x.toLocaleLowerCase();
                  word_used += new_txt + ' ';
                  // console.log('before replace_char_as_space result : ', result);
                  for (let i in this.d_selectedPhraseSettings.replace_char_as_space) {
                    let chr = this.d_selectedPhraseSettings.replace_char_as_space[i].char;
                    new_txt = new_txt.replaceAll(chr, ' ');
                  }
                  // tüm boşlukları tek boşluğa dönüştürüyoruz. Çünkü kelimeler arasında birden fazla karakterli uzun boşluklar kalmış olabilir.
                  new_txt = new_txt.replaceAll(/\s+\-/gm, ' ');
                  let new_text_list = new_txt.split(' ');
                  for (let new_w of new_text_list) {
                    if (new_w) {
                      if (between_start_and_end_words.indexOf(new_w) === -1) {
                        between_start_and_end_words.push(new_w);
                      }
                    }
                  }
                }

              }
            }
          }
          // if (sentence) {
          //   console.log(sentence, ' ||| ', word_used);
          // }
        }
      }
      // console.log('total_start_end_count : ', total_start_end_count);
      if (between_start_and_end_words.length > 0) {
        let new_comb = {
          'ph_op_name_use': '',
          'phrase_option_name': '',
          'group_list': []
        };
        let new_group = {
          'column_list': []
        };
        let new_column_1 = {
          'word_list_usage': 'words_not_in_list_structure',
          'word_list': [],
        };
        let new_column_2 = {
          'word_list_usage': 'elements_of_a_list_in_any_order_0',
          'word_list': [],
        };
        let new_column_3 = {
          'word_list_usage': 'words_not_in_list_structure',
          'word_list': [],
        };
        for (let i in between_start_and_end_words) {
          let new_word = {
            'w': between_start_and_end_words[i],
            'part_type': 'start',
          };
          new_column_2.word_list.push(new_word);
        }

        let start_words = this.d_startEnd.start.split(',');
        for (let start_word of start_words) {
          let new_start_word = {
            'w': start_word,
            'part_type': 'start',
          };
          new_column_1.word_list.push(new_start_word);
        }

        let end_words = this.d_startEnd.end.split(',');
        for (let end_word of end_words) {
          let new_end_word = {
            'w': end_word,
            'part_type': 'start',
          };
          new_column_3.word_list.push(new_end_word);
        }
        new_group.column_list.push(new_column_1);
        new_group.column_list.push(new_column_2);
        new_group.column_list.push(new_column_3);
        new_comb.group_list.push(new_group);
        this.d_selectedPhraseCombination.list.push(new_comb);
      }
    },
    f_openInNewWindow: function() {
      let url = window.location.origin + '#/nlp_phrase';
      window.open(url, '_blank');
    },
    f_savePhraseSettings: function() {
      localStorage.setItem('phrase_settings', JSON.stringify(this.d_selectedPhraseSettings));
      alert('güncellendi');
    },
    f_newListItem: function(list_item, item_data) {
      list_item.push(item_data);
    },
    f_prepareDefaultSetings: function() {
      let phrase_settings = JSON.parse(localStorage.getItem('phrase_settings'));
      if (phrase_settings) {
        this.d_selectedPhraseSettings = phrase_settings;
        for (let default_var in this.d_defaultPhraseSettings) {
          if (!this.d_selectedPhraseSettings[default_var]) {
            this.d_selectedPhraseSettings[default_var] = this.d_defaultPhraseSettings[default_var];
          }
          // console.log('typeof this.d_selectedPhraseSettings[default_var] ', typeof this.d_selectedPhraseSettings[default_var]);
          if (typeof this.d_defaultPhraseSettings[default_var] === 'object') {
            for (let i in this.d_defaultPhraseSettings[default_var]) {
              if (!this.d_selectedPhraseSettings[default_var][i]) {
                this.d_selectedPhraseSettings[default_var][i] = this.d_defaultPhraseSettings[default_var][i];
              }
            }
          }
        }
      } else {
        this.d_selectedPhraseSettings = this.d_defaultPhraseSettings;
      }
    },
    f_allPossibleCases: function(arr) {
      if (arr.length == 1) {
        return arr[0];
      } else {
        let result = [];
        let allCasesOfRest = this.f_allPossibleCases(arr.slice(1)); // recur with the rest of array
        for (let i = 0; i < allCasesOfRest.length; i++) {
          for (let j = 0; j < arr[0].length; j++) {
            result.push(arr[0][j] + allCasesOfRest[i]);
          }
        }
        return result;
      }
    },
    f_addCombinationsToPhrase: function(reset='no') {
      if (reset === 'yes') {
        this.d_selectedNlpPhraseData.phrase_option = [];
      }
      let space_regx = '[\\s\\n';
      for (let i in this.d_selectedPhraseSettings.spaces_can_include) {
        let chr = this.d_selectedPhraseSettings.spaces_can_include[i].char;
        if (['(', ')', '[', ']', '.', '+', '*', '?', '-'].indexOf(chr) !== -1) {
          space_regx += '\\' + chr;
        } else {
          space_regx += chr;
        }
      }
      if (this.d_selectedPhraseSettings.space_mode === 'zero_or_more') {
        space_regx += ']*';
      } else if (this.d_selectedPhraseSettings.space_mode === 'one_or_more') {
        space_regx += ']+';
      }

      let new_regx_count = 0;
      for (let comb_ind in this.d_selectedPhraseCombination.list) {
        for (let group_ind in this.d_selectedPhraseCombination.list[comb_ind].group_list) {
          let group_array_comb = [];
          let group_array_comb_regx = [];
          for (let column_ind in this.d_selectedPhraseCombination.list[comb_ind].group_list[group_ind].column_list) {
            // her kolon için bir word listesi üretip group_array_comb içerisine kombinasyonları üretmek için ekliyoruz.
            let word_list = [];
            let word_regx_list = [];
            for (let word_index in this.d_selectedPhraseCombination.list[comb_ind].group_list[group_ind].column_list[column_ind].word_list) {
              if (this.d_selectedPhraseCombination.list[comb_ind].group_list[group_ind].column_list[column_ind].word_list[word_index].w) {
                let new_word_text = this.d_selectedPhraseCombination.list[comb_ind].group_list[group_ind].column_list[column_ind].word_list[word_index].w;
                let new_word_regx = this.f_txtDesignForRegx(new_word_text);
                new_word_regx = new_word_regx.replaceAll(' ', space_regx);
                // if (parseInt(word_index) !== this.d_selectedPhraseCombination.list[comb_ind].group_list[group_ind].column_list[column_ind].word_list.length - 1) {
                //   new_word_text += ' ';
                // }
                if (parseInt(column_ind) !== this.d_selectedPhraseCombination.list[comb_ind].group_list[group_ind].column_list.length - 1) {
                  if (this.d_selectedPhraseSettings.combination_type === 'one_by_one') {
                    if (this.d_selectedPhraseCombination.list[comb_ind].group_list[group_ind].column_list[column_ind].word_list[word_index].part_type === 'start') {
                      new_word_regx += this.d_wordContinuingWith;
                    }
                    new_word_regx += space_regx;
                  } else if (this.d_selectedPhraseSettings.combination_type === 'all_in_one') {
                    if (this.d_selectedPhraseCombination.list[comb_ind].group_list[group_ind].column_list[column_ind].word_list[word_index].part_type === 'start') {
                      new_word_regx += this.d_wordContinuingWith;
                    }
                  }
                  new_word_text += ' ';
                }
                // console.log('new_word_text : ', new_word_text);
                word_list.push(new_word_text);
                word_regx_list.push(new_word_regx);
              }
            }
            group_array_comb.push(word_list);
            group_array_comb_regx.push(word_regx_list);
          }

          // console.log('group_array_comb       ', group_array_comb);
          // console.log('group_array_comb_regx  ', group_array_comb_regx);


          if (this.d_selectedPhraseSettings.combination_type === 'one_by_one') {
            let comb_text_list = this.f_allPossibleCases(group_array_comb);
            let comb_text_regx_list = this.f_allPossibleCases(group_array_comb_regx);

            // console.log('comb_text_list : ', comb_text_list);
            for (let i in comb_text_list) {
              let result = this.f_addNewRegxToSelectedPhraseOption(comb_text_regx_list[i], comb_text_list[i], this.d_selectedPhraseCombination.list[comb_ind].phrase_option_name);
              if (result) {
                new_regx_count += 1;
              }

            }
          } else if (this.d_selectedPhraseSettings.combination_type === 'all_in_one') {
            /*
            group_array_comb =
              [
                  ["deneme ", "test "],
                  ["işlemi", "yap"]
              ]
            group_array_comb_regx =
              [
                  ["deneme[\\s\\n&\\-:\\./'\"]*", "test[\\s\\n&\\-:\\./'\"]*"],
                  [".şlem.", "yap"]
              ]
            */
            let column_combinations_name = [];
            let column_combinations_code = [];
            for (let columnindex in group_array_comb_regx) {
              let new_comb_regx_code = '';
              let new_comb_regx_name = '';
              let new_array_item_name = [];
              let new_array_item_code = [];
              // new_comb_regx_code += '(' + group_array_comb_regx[columnindex].join('|') + ')';
              new_comb_regx_code += '(' + group_array_comb_regx[columnindex].join(space_regx + '|') + space_regx + ')';
              new_comb_regx_name += '(';
              for (let z in group_array_comb[columnindex]) {
                new_comb_regx_name += group_array_comb[columnindex][z].trim();
                if (parseInt(z) !== group_array_comb[columnindex].length - 1) {
                  new_comb_regx_name += ',';
                }
              }
              new_comb_regx_name += ')';
              // if (parseInt(columnindex) !== group_array_comb.length - 1) {
              //   new_comb_regx_name += ' ';
              // }
              if (this.d_selectedPhraseCombination.list[comb_ind].group_list[group_ind].column_list[columnindex].word_list_usage === 'words_not_in_list_structure') {
                if (parseInt(columnindex) !== group_array_comb_regx.length - 1) {
                  new_comb_regx_code += space_regx;
                }
                // new_comb_regx_name = 'Bu ifadeyi içersin: ' + new_comb_regx_name + '. ';
                new_array_item_code.push(new_comb_regx_code);
                new_array_item_name.push(new_comb_regx_name);
              } else if (this.d_selectedPhraseCombination.list[comb_ind].group_list[group_ind].column_list[columnindex].word_list_usage === 'elements_of_a_list_in_any_order_0') {
                new_comb_regx_code += '{0,}'
                if (parseInt(columnindex) !== group_array_comb_regx.length - 1) {
                  new_comb_regx_code += space_regx;
                }
                new_array_item_code.push(new_comb_regx_code);
                new_array_item_name.push(new_comb_regx_name);

              } else if (this.d_selectedPhraseCombination.list[comb_ind].group_list[group_ind].column_list[columnindex].word_list_usage === 'elements_of_a_list_in_any_order_1') {
                new_comb_regx_code += '{1,}'
                if (parseInt(columnindex) !== group_array_comb_regx.length - 1) {
                  new_comb_regx_code += space_regx;
                }
                new_array_item_code.push(new_comb_regx_code);
                new_array_item_name.push(new_comb_regx_name);

              }
              column_combinations_code.push(new_array_item_code);
              column_combinations_name.push(new_array_item_name);
            }

            // console.log('column_combinations_code: ', column_combinations_code);
            let possible_combinations_code = this.f_allPossibleCases(column_combinations_code);
            // console.log('possible_combinations_code: ', possible_combinations_code);

            // console.log('column_combinations_name: ', column_combinations_name);
            let possible_combinations_name = this.f_allPossibleCases(column_combinations_name);
            // console.log('possible_combinations_name: ', possible_combinations_name);

            let new_comb_regx_code = '';
            let new_comb_regx_name = '';

            for (let columnindex in possible_combinations_code) {
              let possible_comb_regx_name = possible_combinations_name[columnindex];
              let possible_comb_regx_code = possible_combinations_code[columnindex];
              let result = this.f_addNewRegxToSelectedPhraseOption(possible_comb_regx_code, possible_comb_regx_name, this.d_selectedPhraseCombination.list[comb_ind].phrase_option_name);
              if (result) {
                new_regx_count += 1;
              }
            }


          }





        }
      }

      // console.log('new_regx_count: ', new_regx_count);
      alert('Yeni eklenen regx adedi : ' + new_regx_count.toString());

      /*
        Kaydettiğimizde öncelikle filtreyi verileri siliyoruz.
        Sonra filtre ifadelerini kaldırıyoruz.
        Sonra ifade kayıt işlemini gerçekleştiriyoruz.
        Böylece ekranda hızlıca rutinde yapılacak işlemler otomatik olarak yapılmış oluyor.
      */
      // this.f_deleteFilteredRows();
      // this.f_clearFilter();
      // this.f_recordWorkText(false);
      // this.f_nlpPhraseRecord('edit');

      this.f_createPhraseOptionColors();

    },
    f_addNewRegxToSelectedPhraseOption: function(regx_code, regx_name, use_phrase_option_name) {
      let result = false;
      let space_regx = '[\\s\\n';
      for (let i in this.d_selectedPhraseSettings.spaces_can_include) {
        let chr = this.d_selectedPhraseSettings.spaces_can_include[i].char;
        if (['(', ')', '[', ']', '.', '+', '*', '?', '-'].indexOf(chr) !== -1) {
          space_regx += '\\' + chr;
        } else {
          space_regx += chr;
        }
      }
      if (this.d_selectedPhraseSettings.space_mode === 'zero_or_more') {
        space_regx += ']*';
      } else if (this.d_selectedPhraseSettings.space_mode === 'one_or_more') {
        space_regx += ']+';
      }

      let start_mode = '^' + regx_code + space_regx;
      let any_where_mode = regx_code;
      let start_or_any_where_with_spaces_mode = '^' + regx_code + '|' + space_regx + regx_code;
      let end_mode = space_regx + regx_code + '$';

      // let start_mode = '(^' + regx_code + space_regx + ')';
      // let any_where_mode = '(' + regx_code + ')';
      // let start_or_any_where_with_spaces_mode = '(^' + regx_code + ')|(' + space_regx + regx_code + ')';
      // let end_mode = '(' + space_regx + regx_code + '$)';

      // this.d_selectedPhraseSettings.text_location => start, middle, end, any_where
      if (this.d_selectedPhraseSettings.text_location === 'start') {
        regx_code = start_mode;
      } else if (this.d_selectedPhraseSettings.text_location === 'end') {
        regx_code = end_mode;
      } else if (this.d_selectedPhraseSettings.text_location === 'any_where') {
        regx_code = any_where_mode;
      } else if (this.d_selectedPhraseSettings.text_location === 'start_or_any_where_with_spaces') {
        regx_code = start_or_any_where_with_spaces_mode;
      }
      let det = false;
      let use_phrase_index = '';
      for (let phrase_op_index in this.d_selectedNlpPhraseData.phrase_option) {
        if (this.d_selectedNlpPhraseData.phrase_option[phrase_op_index].phrase_option_name === use_phrase_option_name) {
          use_phrase_index = parseInt(phrase_op_index);
          det = true;
          break;
        }
      }
      if (!det) {
        let new_phrase_option_item = { 'phrase_option_name': use_phrase_option_name, 'regx': [] };
        this.d_selectedNlpPhraseData.phrase_option.push(new_phrase_option_item);
        use_phrase_index = this.d_selectedNlpPhraseData.phrase_option.length - 1;
      }
      if (regx_code) {
        let regx_det = false;
        for (let regx_index in this.d_selectedNlpPhraseData.phrase_option[use_phrase_index].regx) {
          if (this.d_selectedNlpPhraseData.phrase_option[use_phrase_index].regx[regx_index].regx_code === regx_code || this.d_selectedNlpPhraseData.phrase_option[use_phrase_index].regx[regx_index].regx_name === regx_name) {
            regx_det = true;
            break;
          }
        }
        if (!regx_det) {
          let new_regx_item = { 'regx_name': regx_name, 'regx_code': regx_code };
          this.d_selectedNlpPhraseData.phrase_option[use_phrase_index].regx.push(new_regx_item);
          result = true;
        }
      }
      return result;
    },
    f_selectPhraseCombinationWork: function() {
      this.d_selectedPhraseCombination.list = [];
      if (this.d_selectedPhraseCombination.name) {
        let p_comb_list = JSON.parse(localStorage.getItem('phrase_comb_list'));
        if (p_comb_list) {
          for (let i in p_comb_list.list) {
            if (p_comb_list.list[i].name === this.d_selectedPhraseCombination.name) {
              this.d_selectedPhraseCombination.list = p_comb_list.list[i].list;
              break
            }
          }
        }
      }
    },
    f_recordPhraseCombinationWork: function(type) {

      for (let comb_ind in this.d_selectedPhraseCombination.list) {
        for (let group_ind in this.d_selectedPhraseCombination.list[comb_ind].group_list) {
          for (let column_ind in this.d_selectedPhraseCombination.list[comb_ind].group_list[group_ind].column_list) {
            let asc_or_desc = 1;
            let param = 'w';
            function compare(a, b) {
              const param_a = a[param];
              const param_b = b[param];
              let comparison = 0;
              if (param_a > param_b) {
                comparison = 1;
              } else if (param_a < param_b) {
                comparison = -1;
              }
              return comparison * asc_or_desc;
            }
            this.d_selectedPhraseCombination.list[comb_ind].group_list[group_ind].column_list[column_ind].word_list.sort(compare);
          }
        }
      }


      let use_name = '';
      if (type === 'new') {
        use_name = this.d_newCompinationName;
      } else {
        use_name = this.d_selectedPhraseCombination.name;
      }
      let p_comb_list = JSON.parse(localStorage.getItem('phrase_comb_list'));
      if (p_comb_list) {
        let det = '';
        for (let i in p_comb_list.list) {
          if (use_name === p_comb_list.list[i].name) {
            det = i;
            break;
          }
        }
        if (det !== '') {
          if (type === 'new') {
            if (confirm('Bu isimde bir çalışma var. Güncellemek ister misiniz ?')) {
              p_comb_list.list[det].list = this.d_selectedPhraseCombination.list;
              localStorage.setItem('phrase_comb_list', JSON.stringify(p_comb_list));
              alert('işlem tamamlandı');
            }
          } else {
            p_comb_list.list[det].list = this.d_selectedPhraseCombination.list;
            localStorage.setItem('phrase_comb_list', JSON.stringify(p_comb_list));
            alert('işlem tamamlandı');
          }
        } else {
          let new_item = {
            'name': use_name,
            'list': this.d_selectedPhraseCombination.list,
          }
          p_comb_list.list.push(new_item);
          localStorage.setItem('phrase_comb_list', JSON.stringify(p_comb_list));
          alert('işlem tamamlandı');
        }
      } else {
        let new_p_comb_list = {
          'list': []
        }
        let new_item = {
          'name': use_name,
          'list': this.d_selectedPhraseCombination.list,
        }
        new_p_comb_list.list.push(new_item);
        localStorage.setItem('phrase_comb_list', JSON.stringify(new_p_comb_list));
        alert('işlem tamamlandı');
      }
      this.f_preparePhraseCombinationWorkList();
      this.d_newCompinationName = '';
    },
    f_preparePhraseCombinationWorkList: function() {
      this.d_phraseCombWorkList = [];
      let x = JSON.parse(localStorage.getItem('phrase_comb_list'));
      if (x) {
        for (let i in x.list) {
          this.d_phraseCombWorkList.push(x.list[i].name);
        }
      }
    },
    f_copyListItem: function (list_item, item_index) {
      let new_item = JSON.parse(JSON.stringify(list_item[item_index]));
      list_item.push(new_item);
    },
    f_deleteListItem: function (list_item, item_index) {
      list_item.splice(item_index, 1);
    },
    f_newPhraseCombination: function() {
      let new_comb = {
        'ph_op_name_use': '',
        'phrase_option_name': '',
        'group_list': []
      };
      this.d_selectedPhraseCombination.list.push(new_comb);
      this.f_newCombinationGroup(this.d_selectedPhraseCombination.list.length - 1);
    },
    f_newCombinationGroup: function(comb_ind) {
      let new_group = {
        'column_list': []
      };
      this.d_selectedPhraseCombination.list[comb_ind].group_list.push(new_group);
      this.f_newCombinationGroupColumn(comb_ind, this.d_selectedPhraseCombination.list[comb_ind].group_list.length - 1);
    },
    f_newCombinationGroupColumn: function(comb_ind, group_ind) {
      let new_column = {
        'word_list_usage': 'words_not_in_list_structure',
        'word_list': [],
      };
      this.d_selectedPhraseCombination.list[comb_ind].group_list[group_ind].column_list.push(new_column);
      this.f_newCombinationGroupColumnWord(comb_ind, group_ind, this.d_selectedPhraseCombination.list[comb_ind].group_list[group_ind].column_list.length - 1);
    },
    f_newCombinationGroupColumnWord: function(comb_ind, group_ind, column_ind) {
      let new_word = {
        'w': '',
        'part_type': 'all',
      };
      this.d_selectedPhraseCombination.list[comb_ind].group_list[group_ind].column_list[column_ind].word_list.push(new_word);
    },
    f_copyCombinationWords: function(comb_ind, group_ind, column_ind) {
      this.d_copiedCombinationWords = JSON.parse(JSON.stringify(this.d_selectedPhraseCombination.list[comb_ind].group_list[group_ind].column_list[column_ind].word_list));
    },
    f_useCopiedCombinationWords: function(comb_ind, group_ind, column_ind) {
      this.d_selectedPhraseCombination.list[comb_ind].group_list[group_ind].column_list[column_ind].word_list = JSON.parse(JSON.stringify(this.d_copiedCombinationWords));
    },
    f_codeAlert: function(rgx) {
      alert(rgx.r_code);
    },
    f_deleteWorkTextRows: function() {
      if (this.d_nlpSelectedTextName) {
        if (confirm(this.d_nlpSelectedTextName + ' çalışma metnini silmek istediğinizden emin misiniz ?')) {

          let vm = this;
          let db_name = 'NlpPhrase';
          let object_store_name = 'PhraseStore';
          let use_key = 'nlp_text_work_list';

          // This works on all devices/browsers, and uses IndexedDBShim as a final fallback 
          let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

          // Open (or create) the database
          let open = indexedDB.open(db_name, 2);

          open.onsuccess = function() {
              // Start a new transaction
              let db = open.result;
              let tx = db.transaction(object_store_name, "readwrite");
              let store = tx.objectStore(object_store_name);

              // Query the data
              let store_obj = store.get(use_key);

              store_obj.onsuccess = function() {
                let nlp_t = store_obj.result;
                if (nlp_t && nlp_t.list) {
                  let det = '';
                  for (let i in nlp_t.list) {
                    if (vm.d_nlpSelectedTextName === nlp_t.list[i].name) {
                      det = i;
                      break;
                    }
                  }
                  if (det !== '') {
                    nlp_t.list.splice(det, 1);
                    store.put({'id': use_key, 'list': nlp_t.list});
                    vm.f_prepareNlpWorkTextList();

                  }
                }

              };

              // Close the db when the transaction is done
              tx.oncomplete = function() {
                  db.close();
              };
          }
        }
      }
    },
    f_deleteWorkTextRowsOlddddddddd: function() {
      if (this.d_nlpSelectedTextName) {
        if (confirm(this.d_nlpSelectedTextName + ' çalışma metnini silmek istediğinizden emin misiniz ?')) {
          let nlp_t = JSON.parse(localStorage.getItem('nlp_text_work_list'));
          if (nlp_t && nlp_t.list) {
            let det = '';
            for (let i in nlp_t.list) {
              if (this.d_nlpSelectedTextName === nlp_t.list[i].name) {
                det = i;
                break;
              }
            }
            if (det !== '') {
              nlp_t.list.splice(det, 1);
              localStorage.setItem('nlp_text_work_list', JSON.stringify(nlp_t));
              this.f_prepareNlpWorkTextList();
            }
          }
        }
      }
    },
    f_copyWorkTextRows: function() {
      let new_text = '';
      for (let row_index in this.d_selectedWorkTextRows) {
        let row_data = this.d_selectedWorkTextRows[row_index];
        let row_text = '';
        if (row_data.w && row_data.w.length > 0) {
          for (let word_index in row_data.w) {
            row_text += row_data.w[word_index].x;
            if (parseInt(word_index) !== row_data.w.length - 1) {
              row_text += ' ';
            }
          }
        }
        new_text += row_text;
        if (parseInt(row_index) !== this.d_selectedWorkTextRows.length - 1) {
          new_text += '\n';
        }
      }
      this.d_workTextNew.name = this.d_nlpSelectedTextName;
      this.d_workTextNew.content = new_text;
      this.d_selectedTab = 'new_nlp_text_list';
    },
    f_clearFilter: function() {
      this.d_startEnd.start = '';
      this.d_startEnd.end = '';
      this.d_showPhraseRegxPositiveRows = false;
      this.d_nlpWorkTextPagination.search = '';
      this.d_nlpWorkTextPagination.regx_search = '';
      this.d_nlpWorkTextPagination.start = 0;
      this.d_nlpWorkTextPagination.end = 10;
      this.d_nlpWorkTextPagination.current = 1;
    },
    d_showChangePhraseRegxPositiveRows: function() {
      // if (this.d_showPhraseRegxPositiveRows) {
      //   this.d_showPhraseRegxPositiveRows = false;
      // } else {
      //   this.d_showPhraseRegxPositiveRows = true;
      // }
      this.f_filterWorkTextList();
    },
    f_clearWordSelectionsInWorkTextRows: function(row_index) {
      for (let row_index in this.d_selectedWorkTextRows) {
        let row_data = this.d_selectedWorkTextRows[row_index];
        let row_text = '';
        if (row_data.w && row_data.w.length > 0) {
          for (let word_index in row_data.w) {
            this.$delete(row_data.w[word_index], 's');
          }
        }
      }
    },
    f_createRegxItemFromSelectedWords: function(row_index) {
      let row_data = this.d_selectedWorkTextRows[row_index];
      let row_text = '';
      if (row_data.w && row_data.w.length > 0) {
        for (let word_index in row_data.w) {
          if (row_data.w[word_index].s) {
            row_text += row_data.w[word_index].x;
            if (parseInt(word_index) !== row_data.w.length - 1) {
              row_text += ' ';
            }
          }
        }
      }
      if (row_text) {
        this.d_newPhraseOptionRegx.regx_name = row_text.toLocaleLowerCase();
        this.f_createRegxStructure(row_text);
      }
    },
    f_txtDesignForRegx: function(txt) {
      let result = txt;
      // console.log('before replace_char_as_space result : ', result);
      for (let i in this.d_selectedPhraseSettings.replace_char_as_space) {
        let chr = this.d_selectedPhraseSettings.replace_char_as_space[i].char;
        result = result.replaceAll(chr, ' ');
      }
      // tüm boşlukları tek boşluğa dönüştürüyoruz. Çünkü kelimeler arasında birden fazla karakterli uzun boşluklar kalmış olabilir.
      result = result.replaceAll(/\s+/gm, ' ');
      // console.log('before trim result : ', result);
      if (this.d_selectedPhraseSettings.begin_and_end_spaces_delete === 'yes') {
        result = result.trim();
      }
      if (this.d_selectedPhraseSettings.all_chars_lower_case === 'yes') {
        result = result.toLocaleLowerCase();
      }

      // aşağıdaki karakterler zaten ters slaş ile düzenlenmesi gerekiyor. Çünkü regxte aslında kodsal karakterler bu karaketerler.
      let backslash_add_chars = ['(', ')', '[', ']', '.', '+', '*', '?'];
      // console.log('before backslash_add_chars result : ', result);
      for (let i in backslash_add_chars) {
        result = result.replaceAll(backslash_add_chars[i], '\\' + backslash_add_chars[i]);
      }

      // console.log('before replace_all_chars_as_this result : ', result);
      for (let i in this.d_selectedPhraseSettings.replace_all_chars_as_this) {
        let chr = this.d_selectedPhraseSettings.replace_all_chars_as_this[i].char;
        let replace_text = this.d_selectedPhraseSettings.replace_all_chars_as_this[i].replace;
        result = result.replaceAll(chr, replace_text);
      }

      // console.log('result : ', result);
      return result;
    },
    f_createRegxStructure: function() {
      let row_text = this.d_newPhraseOptionRegx.regx_name;
      row_text = this.f_txtDesignForRegx(row_text);
      let txt_list = row_text.split(' ');
      this.d_newPhraseOptionRegx.regx_list = [];
      for (let t_index in txt_list) {
        this.d_newPhraseOptionRegx.regx_list.push({'text': txt_list[t_index], 'start': '', 'use': '', 'output_type': ''});
      }
      this.f_updateRegxStructure();
    },
    f_updateRegxStructure: function() {
      /*
        regx_list yapısının üretilmesinin temel nedeni üretilen her bir değer için karşılığında ek bir tanımlama yapmak isteme olmaktadır.
        Örneğin mutasyon değerini mut ile başlayıp diğer tüm alfa karakterler ile devam etmesini istediğimizde start kısmını da ekranda dolduruyoruz.
        Bir başka nedeni, hangi regx ifadeleri arasında boşluk olacağını görmemiz.
      */
      // let regx_code = '([\\n\\s\\(\\.:=\\-,\\+;]+';
      // for (let i in this.d_newPhraseOptionRegx.regx_list) {
      //   if (this.d_newPhraseOptionRegx.regx_list[i].text) {
      //     if (this.d_newPhraseOptionRegx.regx_list[i].start) {
      //       regx_code += this.d_newPhraseOptionRegx.regx_list[i].start + this.d_wordContinuingWith;
      //     } else {
      //       regx_code += this.d_newPhraseOptionRegx.regx_list[i].text;
      //     }
      //     if (parseInt(i) !== this.d_newPhraseOptionRegx.regx_list.length -1) {
      //       regx_code += '[\\s/:,\\.\'"-]+';
      //     }
      //   }
      // }
      // regx_code += ')|(^';
      // for (let i in this.d_newPhraseOptionRegx.regx_list) {
      //   if (this.d_newPhraseOptionRegx.regx_list[i].text) {
      //     if (this.d_newPhraseOptionRegx.regx_list[i].start) {
      //       regx_code += this.d_newPhraseOptionRegx.regx_list[i].start + this.d_wordContinuingWith;
      //     } else {
      //       regx_code += this.d_newPhraseOptionRegx.regx_list[i].text;
      //     }
      //     if (parseInt(i) !== this.d_newPhraseOptionRegx.regx_list.length -1) {
      //       regx_code += '[\\s/:,\\.\'"-]+';
      //     }
      //   }
      // }
      // regx_code += ')';
      // this.d_nlpWorkTextPagination.regx_search = regx_code;


      let space_regx = '[\\s\\n';
      for (let i in this.d_selectedPhraseSettings.spaces_can_include) {
        let chr = this.d_selectedPhraseSettings.spaces_can_include[i].char;
        if (['(', ')', '[', ']', '.', '+', '*', '?', '-'].indexOf(chr) !== -1) {
          space_regx += '\\' + chr;
        } else {
          space_regx += chr;
        }
      }
      if (this.d_selectedPhraseSettings.space_mode === 'zero_or_more') {
        space_regx += ']*';
      } else if (this.d_selectedPhraseSettings.space_mode === 'one_or_more') {
        space_regx += ']+';
      }

      let regx_code = '';
      for (let i in this.d_newPhraseOptionRegx.regx_list) {
        if (this.d_newPhraseOptionRegx.regx_list[i].text) {
          if (this.d_newPhraseOptionRegx.regx_list[i].start) {
            if (this.d_newPhraseOptionRegx.regx_list[i].use === 'numeric') {
              if (this.d_newPhraseOptionRegx.regx_list[i].output_type === 'output') {
                regx_code += '([0-9]+)';
              } else {
                regx_code += '[0-9]+';
              }
            } else if (this.d_newPhraseOptionRegx.regx_list[i].use === 'alpha_numeric') {
              if (this.d_newPhraseOptionRegx.regx_list[i].output_type === 'output') {
                regx_code += '([\\w]+)';
              } else {
                regx_code += '[\\w]+';
              }
            } else if (this.d_newPhraseOptionRegx.regx_list[i].use === 'x_count_word') {
              if (this.d_newPhraseOptionRegx.regx_list[i].output_type === 'output') {
                regx_code += '(' + '([\\wıüÜğĞşŞçÇöÖi̇İ]+' + space_regx + '){0,' + this.d_newPhraseOptionRegx.regx_list[i].count_of_word + '}' + ')';
              } else {
                regx_code += '([\\wıüÜğĞşŞçÇöÖi̇İ]+' + space_regx + '){0,' + this.d_newPhraseOptionRegx.regx_list[i].count_of_word + '}';
              }
            } else {
              regx_code += this.d_newPhraseOptionRegx.regx_list[i].start + this.d_wordContinuingWith;
            }
          } else {
            if (this.d_newPhraseOptionRegx.regx_list[i].use === 'numeric') {
              if (this.d_newPhraseOptionRegx.regx_list[i].output_type === 'output') {
                regx_code += '([0-9]+)';
              } else {
                regx_code += '[0-9]+';
              }
            } else if (this.d_newPhraseOptionRegx.regx_list[i].use === 'alpha_numeric') {
              if (this.d_newPhraseOptionRegx.regx_list[i].output_type === 'output') {
                regx_code += '([\\w]+)';
              } else {
                regx_code += '[\\w]+';
              }
            } else if (this.d_newPhraseOptionRegx.regx_list[i].use === 'x_count_word') {
              if (this.d_newPhraseOptionRegx.regx_list[i].output_type === 'output') {
                regx_code += '(' + '([\\wıüÜğĞşŞçÇöÖi̇İ]+' + space_regx + '){0,' + this.d_newPhraseOptionRegx.regx_list[i].count_of_word + '}' + ')';
              } else {
                regx_code += '([\\wıüÜğĞşŞçÇöÖi̇İ]+' + space_regx + '){0,' + this.d_newPhraseOptionRegx.regx_list[i].count_of_word + '}';
              }
            } else {
              regx_code += this.d_newPhraseOptionRegx.regx_list[i].text;
            }
          }
          if (parseInt(i) !== this.d_newPhraseOptionRegx.regx_list.length -1) {
            regx_code += space_regx;
          }
        }
      }


      let start_mode = '^' + regx_code + space_regx;
      let any_where_mode = regx_code;
      let start_or_any_where_with_spaces_mode = '^' + regx_code + '|' + space_regx + regx_code;
      let end_mode = space_regx + regx_code + '$';

      // let start_mode = '(^' + regx_code + space_regx + ')';
      // let any_where_mode = '(' + regx_code + ')';
      // let start_or_any_where_with_spaces_mode = '(^' + regx_code + ')|(' + space_regx + regx_code + ')';
      // let end_mode = '(' + space_regx + regx_code + '$)';

      // this.d_selectedPhraseSettings.text_location => start, end, any_where
      if (this.d_selectedPhraseSettings.text_location === 'start') {
        this.d_nlpWorkTextPagination.regx_search = start_mode;
      } else if (this.d_selectedPhraseSettings.text_location === 'end') {
        this.d_nlpWorkTextPagination.regx_search = end_mode;
      } else if (this.d_selectedPhraseSettings.text_location === 'any_where') {
        this.d_nlpWorkTextPagination.regx_search = any_where_mode;
      } else if (this.d_selectedPhraseSettings.text_location === 'start_or_any_where_with_spaces') {
        this.d_nlpWorkTextPagination.regx_search = start_or_any_where_with_spaces_mode;
      }

    },
    f_addNewPhraseOptionRegx: function() {
      let det = false;
      let use_phrase_index = '';
      for (let phrase_op_index in this.d_selectedNlpPhraseData.phrase_option) {
        if (this.d_selectedNlpPhraseData.phrase_option[phrase_op_index].phrase_option_name === this.d_newPhraseOptionRegx.phrase_option_name) {
          use_phrase_index = parseInt(phrase_op_index);
          det = true;
          break;
        }
      }
      if (!det) {
        let new_phrase_option_item = { 'phrase_option_name': this.d_newPhraseOptionRegx.phrase_option, 'regx': [] };
        this.d_selectedNlpPhraseData.phrase_option.push(new_phrase_option_item);
        use_phrase_index = this.d_selectedNlpPhraseData.phrase_option.length - 1;
      }
      if (this.d_nlpWorkTextPagination.regx_search) {
        let regx_det = false;
        for (let regx_index in this.d_selectedNlpPhraseData.phrase_option[use_phrase_index].regx) {
          if (this.d_selectedNlpPhraseData.phrase_option[use_phrase_index].regx[regx_index].regx_code === this.d_nlpWorkTextPagination.regx_search) {
            regx_det = true;
            break;
          }
        }
        if (!regx_det) {
          let new_regx_item = { 'regx_name': '', 'regx_code': this.d_nlpWorkTextPagination.regx_search };
          if (this.d_newPhraseOptionRegx.regx_name) {
            new_regx_item.regx_name = this.d_newPhraseOptionRegx.regx_name;
          }
          this.d_selectedNlpPhraseData.phrase_option[use_phrase_index].regx.push(new_regx_item);
          /*
            Kaydettiğimizde öncelikle filtreyi verileri siliyoruz.
            Sonra filtre ifadelerini kaldırıyoruz.
            Sonra ifade kayıt işlemini gerçekleştiriyoruz.
            Böylece ekranda hızlıca rutinde yapılacak işlemler otomatik olarak yapılmış oluyor.
          */
          this.f_deleteFilteredRows();
          this.f_clearFilter();
          this.f_recordWorkText(false);
          this.f_nlpPhraseRecord('edit');
        } else {
          alert('regx zaten daha önceden eklenmiş');
        }
      }
    },
    f_deletePhraseOptionRegx: function(rgx) {
      if (confirm('Regx silmek istediğinizden emin misiniz ?')) {
        let use_phrase_index = '';
        let use_regx_index = '';
        for (let phrase_op_index in this.d_selectedNlpPhraseData.phrase_option) {
          if (this.d_selectedNlpPhraseData.phrase_option[phrase_op_index].phrase_option_name === rgx.p_op) {
            for (let regx_index in this.d_selectedNlpPhraseData.phrase_option[phrase_op_index].regx) {
              if (this.d_selectedNlpPhraseData.phrase_option[phrase_op_index].regx[regx_index].regx_code === rgx.r_code) {
                use_phrase_index = phrase_op_index;
                use_regx_index = regx_index;
              }
            }
          }
        }
        this.d_selectedNlpPhraseData.phrase_option[use_phrase_index].regx.splice(use_regx_index);
        this.f_nlpPhraseRecord('edit');
      }
    },
    f_analyzeWorkTextByPhraseOptions: function() {
      if (this.d_selectedNlpPhraseData) {
        for (let row_index in this.d_selectedWorkTextRows) {
          this.$delete(this.d_selectedWorkTextRows[row_index], 'rgx');
          let row_data = this.d_selectedWorkTextRows[row_index];
          let row_text = '';
          if (row_data.w && row_data.w.length > 0) {
            for (let word_index in row_data.w) {
              row_text += row_data.w[word_index].x + ' ';
            }
          }

          for (let phrase_option_index in this.d_selectedNlpPhraseData.phrase_option) {
            for (let regx_index in this.d_selectedNlpPhraseData.phrase_option[phrase_option_index].regx) {
              // başında a-z gibi karakterler yerine diğer karakterlerin olması kelimeyi bozmaz. Ayrıca başlangıç da hedeflenen kelimeyle başlayabilir.
              // let regx_code = '([\n\s\(\.:=\-,\+;]+';
              // regx_code += this.d_selectedNlpPhraseData.phrase_option[phrase_option_index].regx[regx_index].regx_code;
              // regx_code += ')';
              // regx_code += '(^';
              // regx_code += this.d_selectedNlpPhraseData.phrase_option[phrase_option_index].regx[regx_index].regx_code;
              // console.log('regx_code : ', regx_code);
              if (this.d_selectedNlpPhraseData.phrase_option[phrase_option_index].regx[regx_index].regx_code) {
                let regx_text = '';
                try {
                  regx_text = new RegExp(this.d_selectedNlpPhraseData.phrase_option[phrase_option_index].regx[regx_index].regx_code, 'i');
                } catch(err) {
                  console.log('err ', err);
                }
                if (regx_text) {
                  let regx_found = row_text.match(regx_text);
                  if (regx_found) {
                    // console.log('regx_found: ', regx_found);
                    // console.log('row_index : ', row_index);
                    if (!this.d_selectedWorkTextRows[row_index].rgx) {
                      this.d_selectedWorkTextRows[row_index].rgx = [];
                    }
                    let new_regx_result = {
                      'p_op': this.d_selectedNlpPhraseData.phrase_option[phrase_option_index].phrase_option_name,
                      'r_name': this.d_selectedNlpPhraseData.phrase_option[phrase_option_index].regx[regx_index].regx_name,
                      'r_code': this.d_selectedNlpPhraseData.phrase_option[phrase_option_index].regx[regx_index].regx_code,
                    }
                    this.d_selectedWorkTextRows[row_index].rgx.push(new_regx_result);
                  }
                }
              }
            }
          }
        }

      }

    },
    f_phraseOptionClear: function() {
      this.d_selectedNlpPhraseData.phrase_option = [];
    },
    f_phraseOptionAddFromJsonText: function() {
      let phrase_regx_json = JSON.parse(this.d_phraseOptionAndRegxCodes.json_text);
      if (phrase_regx_json) {
        for (let i in phrase_regx_json) {
          if (phrase_regx_json[i].phrase_option) {
            let det = false;
            let use_phrase_index = '';
            for (let phrase_op_index in this.d_selectedNlpPhraseData.phrase_option) {
              if (this.d_selectedNlpPhraseData.phrase_option[phrase_op_index].phrase_option_name === phrase_regx_json[i].phrase_option) {
                use_phrase_index = parseInt(phrase_op_index);
                det = true;
                break;
              }
            }
            if (!det) {
              let new_phrase_option_item = { 'phrase_option_name': phrase_regx_json[i].phrase_option, 'regx': [] };
              this.d_selectedNlpPhraseData.phrase_option.push(new_phrase_option_item);
              use_phrase_index = this.d_selectedNlpPhraseData.phrase_option.length - 1;
            }
            if (phrase_regx_json[i].regx_code) {
              let regx_det = false;
              for (let regx_index in this.d_selectedNlpPhraseData.phrase_option[use_phrase_index].regx) {
                if (this.d_selectedNlpPhraseData.phrase_option[use_phrase_index].regx[regx_index].regx_code === phrase_regx_json[i].regx_code) {
                  regx_det = true;
                  break;
                }
              }
              if (!regx_det) {
                let new_regx_item = { 'regx_name': '', 'regx_code': phrase_regx_json[i].regx_code };
                if (phrase_regx_json[i].regx_name) {
                  new_regx_item.regx_name = phrase_regx_json[i].regx_name;
                }
                this.d_selectedNlpPhraseData.phrase_option[use_phrase_index].regx.push(new_regx_item);
              }
            }
          }
        }
      }
    },
    f_deleteSelectedWorkTextRows: function() {
      // console.log('before this.d_selectedWorkTextRows.length : ', this.d_selectedWorkTextRows.length);
      let new_text_list = [];
      for (let i in this.d_selectedWorkTextRows) {
        if (this.d_selectRowsOfWorkTextList.indexOf(parseInt(i)) === -1) {
          new_text_list.push(this.d_selectedWorkTextRows[i]);
        }
      }
      this.d_selectRowsOfWorkTextList = [];
      this.d_selectedWorkTextRows = new_text_list;
      // console.log('after this.d_selectedWorkTextRows.length : ', this.d_selectedWorkTextRows.length);
    },
    f_selectRowsOfWorkTextList: function(row_ind) {
      if (this.d_selectRowsOfWorkTextList.indexOf(row_ind) === -1) {
        this.d_selectRowsOfWorkTextList.push(row_ind);
      } else {
        this.d_selectRowsOfWorkTextList.splice(this.d_selectRowsOfWorkTextList.indexOf(row_ind), 1);
      }
      // this.$forceUpdate();
    },
    f_recordWorkText: function(alert_mode=true) {


      let vm = this;
      let db_name = 'NlpPhrase';
      let object_store_name = 'PhraseStore';
      let use_key = 'nlp_text_work_list';

      // This works on all devices/browsers, and uses IndexedDBShim as a final fallback 
      let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

      // Open (or create) the database
      let open = indexedDB.open(db_name, 2);

      open.onsuccess = function() {
          // Start a new transaction
          let db = open.result;
          let tx = db.transaction(object_store_name, "readwrite");
          let store = tx.objectStore(object_store_name);

          // Query the data
          let store_obj = store.get(use_key);

          store_obj.onsuccess = function() {
            let nlp_t = store_obj.result;
            if (nlp_t && nlp_t.list) {
              let det = '';
              for (let i in nlp_t.list) {
                if (vm.d_nlpSelectedTextName === nlp_t.list[i].name) {
                  det = i;
                  break;
                }
              }
              if (det !== '') {
                if (alert_mode) {
                  if (confirm('Bu isimde bir çalışma var. Güncellemek ister misiniz ?')) {
                    for (let row_index in vm.d_selectedWorkTextRows) {
                      vm.$delete(vm.d_selectedWorkTextRows[row_index], 'rgx');
                    }
                    nlp_t.list[det].rows = vm.d_selectedWorkTextRows;
                    store.put({'id': use_key, 'list': nlp_t.list});
                    vm.f_analyzeWorkTextByPhraseOptions();
                  }
                } else {
                  for (let row_index in vm.d_selectedWorkTextRows) {
                    vm.$delete(vm.d_selectedWorkTextRows[row_index], 'rgx');
                  }
                  nlp_t.list[det].rows = vm.d_selectedWorkTextRows;
                  store.put({'id': use_key, 'list': nlp_t.list});
                  vm.f_analyzeWorkTextByPhraseOptions();
                }
              }
            }

          };

          // Close the db when the transaction is done
          tx.oncomplete = function() {
              db.close();
          };
      }


    },
    f_recordWorkTextOldddddddd: function(alert_mode=true) {
      let nlp_t = JSON.parse(localStorage.getItem('nlp_text_work_list'));
      if (nlp_t) {
        let det = '';
        for (let i in nlp_t.list) {
          if (this.d_nlpSelectedTextName === nlp_t.list[i].name) {
            det = i;
            break;
          }
        }
        if (det !== '') {
          if (alert_mode) {
            if (confirm('Bu isimde bir çalışma var. Güncellemek ister misiniz ?')) {
              for (let row_index in this.d_selectedWorkTextRows) {
                this.$delete(this.d_selectedWorkTextRows[row_index], 'rgx');
              }
              nlp_t.list[det].rows = this.d_selectedWorkTextRows;
              localStorage.setItem('nlp_text_work_list', JSON.stringify(nlp_t));
              this.f_analyzeWorkTextByPhraseOptions();
            }
          } else {
            for (let row_index in this.d_selectedWorkTextRows) {
              this.$delete(this.d_selectedWorkTextRows[row_index], 'rgx');
            }
            nlp_t.list[det].rows = this.d_selectedWorkTextRows;
            localStorage.setItem('nlp_text_work_list', JSON.stringify(nlp_t));
            this.f_analyzeWorkTextByPhraseOptions();
          }
        }
      }
    },
    f_deleteRowFromWorkText: function(row_ind) {
      this.d_selectedWorkTextRows.splice(row_ind, 1);
      this.f_filterWorkTextList();
    },
    f_deleteFilteredRows: function() {
      let new_rows = [];
      for (let i in this.d_selectedWorkTextRows) {
        if (this.d_allFilteredWorkList.indexOf(parseInt(i)) === -1) {
          new_rows.push(this.d_selectedWorkTextRows[i]);
        }
      }
      this.d_selectedWorkTextRows = new_rows;
      this.f_filterWorkTextList();
    },
    f_addNewRowsToSelectedPhraseWork: function() {
      let row_list = [];
      let content_list = this.d_nlpNewRows.split('\n');
      content_list.sort();
      for (let row of content_list) {
        let new_row = {'w': []};
        let word_list = row.replaceAll(/\s+/gm,' ').trim().split(' ');
        for (let w of word_list) {
          new_row.w.push({'x': w});
        }
        row_list.push(new_row);
      }

      for (let new_row_index in row_list) {
        let new_row_data = row_list[new_row_index];
        let new_row_text = '';
        if (new_row_data.w && new_row_data.w.length > 0) {
          for (let word_index in new_row_data.w) {
            new_row_text += new_row_data.w[word_index].x + ' ';
          }
        }

        let det = false;
        for (let row_index in this.d_selectedWorkTextRows) {
          let row_data = this.d_selectedWorkTextRows[row_index];
          let row_text = '';
          if (row_data.w && row_data.w.length > 0) {
            for (let word_index in row_data.w) {
              row_text += row_data.w[word_index].x + ' ';
            }
          }
          if (new_row_text === row_text) {
            det = true;
            break;
          }
        }
        // console.log('new_row_text ', new_row_text);
        // console.log('det ', det);
        if (!det) {
          this.d_selectedWorkTextRows.push(new_row_data);
        }
      }

      this.f_filterWorkTextList();

    },
    f_indexDbExample: function () {
      // This works on all devices/browsers, and uses IndexedDBShim as a final fallback 
      let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

      // Open (or create) the database
      let open = indexedDB.open("MyDatabase", 1);

      // Create the schema
      open.onupgradeneeded = function() {
        console.log('open.onupgradeneeded 444');
        let db = open.result;
        let store = db.createObjectStore("MyObjectStore", {keyPath: "id"});
        let index = store.createIndex("NameIndex", ["name.last", "name.first"]);
      };

      open.onsuccess = function() {
          // Start a new transaction
          let db = open.result;
          let tx = db.transaction("MyObjectStore", "readwrite");
          let store = tx.objectStore("MyObjectStore");
          let index = store.index("NameIndex");

          // Add some data
          store.put({id: 12345, name: {first: "John", last: "Doe"}, age: 42});
          store.put({id: 67890, name: {first: "Bob", last: "Smith"}, age: 35});
          store.put({id: 'xyz', name: {first: "Bob", last: "Smith"}, age: 35});
          
          // Query the data
          let getJohn = store.get(12345);
          let getBob = index.get(["Smith", "Bob"]);

          getJohn.onsuccess = function() {
              console.log(getJohn.result.name.first);  // => "John"
          };

          getBob.onsuccess = function() {
              console.log(getBob.result.name.first);   // => "Bob"
          };

          // Close the db when the transaction is done
          tx.oncomplete = function() {
              db.close();
          };
      }

    },
    f_indexDbSaveData: function (db_name, object_store_name, use_key, use_data) {
      // This works on all devices/browsers, and uses IndexedDBShim as a final fallback 
      let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
      // Open (or create) the database
      let open = indexedDB.open(db_name, 2);
      open.onsuccess = function() {
          // Start a new transaction
          let db = open.result;
          let tx = db.transaction(object_store_name, "readwrite");
          let store = tx.objectStore(object_store_name);
          store.put({'id': use_key, 'data': use_data});
          // Close the db when the transaction is done
          tx.oncomplete = function() {
              db.close();
          };
      }
    },
    f_deleteAllWorkText: function() {
      if (confirm('Tüm çalışma metinlerini temizlemek istediğinize emin misiniz ?')) {
        this.d_nlpTextList = [];
        let db_name = 'NlpPhrase';
        let object_store_name = 'PhraseStore';
        // This works on all devices/browsers, and uses IndexedDBShim as a final fallback 
        let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
        // Open (or create) the database
        let req = indexedDB.deleteDatabase(db_name);
        req.onsuccess = function() {
            console.log(db_name, " deleted successfully");
            let open = indexedDB.open(db_name);
            open.onupgradeneeded = function() {
              console.log(db_name, ' open.onupgradeneeded');
              let db = open.result;
              let store = db.createObjectStore(object_store_name, {keyPath: "id"});
              alert('Temizlenmiştir.');
            }
        }
      }
    },
    f_addNewWorkText: function() {
      let row_list = [];
      let content_list = this.d_workTextNew.content.split('\n');
      content_list.sort();
      for (let row of content_list) {
        let new_row = {'w': []};
        let word_list = row.replaceAll(/\s+/gm,' ').trim().split(' ');
        for (let w of word_list) {
          new_row.w.push({'x': w});
        }
        row_list.push(new_row);
      }

      let vm = this;
      let db_name = 'NlpPhrase';
      let object_store_name = 'PhraseStore';
      let use_key = 'nlp_text_work_list';

      // This works on all devices/browsers, and uses IndexedDBShim as a final fallback 
      let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

      // Open (or create) the database
      let open = indexedDB.open(db_name, 2);

      open.onsuccess = function() {
          let db = open.result;
          // Start a new transaction
          let tx = db.transaction(object_store_name, "readwrite");
          let store = tx.objectStore(object_store_name);

          // Query the data
          let store_obj = store.get(use_key);

          store_obj.onsuccess = function() {
            let nlp_t = store_obj.result;
            if (nlp_t) {
              let det = '';
              for (let i in nlp_t.list) {
                if (vm.d_workTextNew.name === nlp_t.list[i].name) {
                  det = i;
                  break;
                }
              }
              if (det !== '') {
                if (confirm('Bu isimde bir çalışma var. Güncellemek ister misiniz ?')) {
                  nlp_t.list[det].rows = row_list;
                  store.put({'id': use_key, 'list': nlp_t.list});
                }
              } else {
                let new_item = {
                  'name': vm.d_workTextNew.name,
                  'rows': row_list,
                }
                nlp_t.list.push(new_item);
                store.put({'id': use_key, 'list': nlp_t.list});
              }
            } else {
              let new_nlp_t = {
                'list': []
              }
              let new_item = {
                'name': vm.d_workTextNew.name,
                'rows': row_list,
              }
              new_nlp_t.list.push(new_item);
              store.put({'id': use_key, 'list': new_nlp_t.list});
              // Close the db when the transaction is done

            }
            alert('Eklenmiştir');
            vm.f_prepareNlpWorkTextList();
          };

          // Close the db when the transaction is done
          tx.oncomplete = function() {
              db.close();
          };
      }

    },
    f_addNewWorkTextOlddddd: function() {
      let row_list = [];
      let content_list = this.d_workTextNew.content.split('\n');
      content_list.sort();
      for (let row of content_list) {
        let new_row = {'w': []};
        let word_list = row.replaceAll(/\s+/gm,' ').trim().split(' ');
        for (let w of word_list) {
          new_row.w.push({'x': w});
        }
        row_list.push(new_row);
      }
      // console.log('row_list[0]     : ', row_list[0]);
      // console.log('row_list.length : ', row_list.length);

      let nlp_t = JSON.parse(localStorage.getItem('nlp_text_work_list'));
      if (nlp_t) {
        let det = '';
        for (let i in nlp_t.list) {
          if (this.d_workTextNew.name === nlp_t.list[i].name) {
            det = i;
            break;
          }
        }
        if (det !== '') {
          if (confirm('Bu isimde bir çalışma var. Güncellemek ister misiniz ?')) {
            nlp_t.list[det].rows = row_list;
            localStorage.setItem('nlp_text_work_list', JSON.stringify(nlp_t));
          }
        } else {
          let new_item = {
            'name': this.d_workTextNew.name,
            'rows': row_list,
          }
          nlp_t.list.push(new_item);
          localStorage.setItem('nlp_text_work_list', JSON.stringify(nlp_t));
        }
      } else {
        let new_nlp_t = {
          'list': []
        }
        let new_item = {
          'name': this.d_workTextNew.name,
          'rows': row_list,
        }
        new_nlp_t.list.push(new_item);
        localStorage.setItem('nlp_text_work_list', JSON.stringify(new_nlp_t));
      }
      this.f_prepareNlpWorkTextList();
    },
    f_prepareNlpWorkTextList: function() {
      let vm = this;
      let db_name = 'NlpPhrase';
      let object_store_name = 'PhraseStore';
      let use_key = 'nlp_text_work_list';

      this.d_nlpTextList = [];

      // This works on all devices/browsers, and uses IndexedDBShim as a final fallback 
      let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

      // Open (or create) the database
      let open = indexedDB.open(db_name, 2);

      open.onsuccess = function() {
          // Start a new transaction
          let db = open.result;
          try {
            let tx = db.transaction(object_store_name, "readwrite");
            let store = tx.objectStore(object_store_name);

            // Query the data
            let store_obj = store.get(use_key);

            store_obj.onsuccess = function() {
              let nlp_t = store_obj.result;
              if (nlp_t) {
                for (let i in nlp_t.list) {
                  vm.d_nlpTextList.push(nlp_t.list[i].name);
                }
              }
              // console.log('vm.d_nlpTextList : ', vm.d_nlpTextList);
            };
            // Close the db when the transaction is done
            tx.oncomplete = function() {
              // console.log('tx.oncomplete ');
                db.close();
            };
          } catch (err) {
            console.log('err');
            db.close();
          }
      }
    },
    f_prepareNlpWorkTextListOlddddd: function() {
      this.d_nlpTextList = [];
      let nlp_t = JSON.parse(localStorage.getItem('nlp_text_work_list'));
      if (nlp_t) {
        for (let i in nlp_t.list) {
          this.d_nlpTextList.push(nlp_t.list[i].name);
        }
      }
    },
    f_selectNlpWorkText: function() {
      this.d_selectedWorkTextRows = [];
      setTimeout(function () {
        let vm = this;
        let db_name = 'NlpPhrase';
        let object_store_name = 'PhraseStore';
        let use_key = 'nlp_text_work_list';

        // This works on all devices/browsers, and uses IndexedDBShim as a final fallback 
        let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

        // Open (or create) the database
        let open = indexedDB.open(db_name, 2);

        open.onsuccess = function() {
            // Start a new transaction
            let db = open.result;
            let tx = db.transaction(object_store_name, "readwrite");
            let store = tx.objectStore(object_store_name);

            // Query the data
            let store_obj = store.get(use_key);

            store_obj.onsuccess = function() {
              let nlp_t = store_obj.result;
              for (let i in nlp_t.list) {
                if (nlp_t.list[i].name === vm.d_nlpSelectedTextName) {
                  vm.d_selectedWorkTextRows = nlp_t.list[i].rows;
                  // console.log('vm.d_selectedWorkTextRows : ', vm.d_selectedWorkTextRows);
                  break;
                }
              }
              vm.f_filterWorkTextList();
              vm.f_analyzeWorkTextByPhraseOptions();
            };
            // Close the db when the transaction is done
            tx.oncomplete = function() {
                db.close();
            };
        }


      }.bind(this), 100);
    },
    f_selectNlpWorkTextOld: function() {
      setTimeout(function () {
        this.d_selectedWorkTextRows = [];
        let nlp_t = JSON.parse(localStorage.getItem('nlp_text_work_list'));
        if (nlp_t && nlp_t.list) {
          for (let i in nlp_t.list) {
            if (nlp_t.list[i].name === this.d_nlpSelectedTextName) {
              this.d_selectedWorkTextRows = nlp_t.list[i].rows;
              break
            }
          }
        }
        this.f_filterWorkTextList();
        this.f_analyzeWorkTextByPhraseOptions();
      }.bind(this), 100);
    },
    f_selectCell: function(phrase_option_index, regx_item_index, cell_no) {
      if (this.d_phraseEditPermissionEligible) {
        this.d_selectedCell = phrase_option_index.toString() + '_' + regx_item_index.toString() + '_' + cell_no;
        setTimeout(function () {
          let doc = document.getElementById(this.d_selectedCell);
          doc.focus();
        }.bind(this), 50);
      }
    },
    f_calculateSingleEvenStyle: function (use_index, color='lightgreen', color_2='lightblue') {
      let style = '';
      if (use_index % 2 === 0) {
        style += 'background-color: ' + color;
      } else {
        style += 'background-color: ' + color_2;
      }
      return style;
    },
    f_addNewPhraseOptionItem: function (type, phrase_option_name='') {
      let new_item = { 'phrase_option_name': phrase_option_name, 'regx': [] };
      if (type === 'edit') {
        this.d_selectedNlpPhraseData.phrase_option.push(new_item);
        this.f_addNewPhraseOptionRegxItem(type, this.d_selectedNlpPhraseData.phrase_option.length - 1);
      } else {
        this.d_nlpPhraseDataNew.phrase_option.push(new_item);
        this.f_addNewPhraseOptionRegxItem(type, this.d_nlpPhraseDataNew.phrase_option.length - 1);
      }
      this.f_createPhraseOptionColors();
    },
    f_deleteRegxItem: function (type, phrase_option_index, regx_index) {
      if (type === 'edit') {
        this.d_selectedNlpPhraseData.phrase_option[phrase_option_index].regx.splice(regx_index, 1);
        if (this.d_selectedNlpPhraseData.phrase_option[phrase_option_index].regx.length === 0) {
          this.d_selectedNlpPhraseData.phrase_option.splice(phrase_option_index, 1);
        }
      } else {
        this.d_nlpPhraseDataNew.phrase_option[phrase_option_index].regx.splice(regx_index, 1);
        if (this.d_nlpPhraseDataNew.phrase_option[phrase_option_index].regx.length === 0) {
          this.d_nlpPhraseDataNew.phrase_option.splice(phrase_option_index, 1);
        }
      }
    },
    f_copyRegxItem: function (type, phrase_option_index, regx_index) {
      if (type === 'edit') {
        this.d_selectedNlpPhraseData.phrase_option[phrase_option_index].regx.push(JSON.parse(JSON.stringify(this.d_selectedNlpPhraseData.phrase_option[phrase_option_index].regx[regx_index])));
      } else {
        this.d_nlpPhraseDataNew.phrase_option[phrase_option_index].regx.push(JSON.parse(JSON.stringify(this.d_nlpPhraseDataNew.phrase_option[phrase_option_index].regx[regx_index])));
      }
    },
    f_addNewPhraseOptionRegxItem: function (type, phrase_option_index) {
      let new_item = { 'regx_name': '', 'regx_code': '' };
      if (type === 'edit') {
        this.d_selectedNlpPhraseData.phrase_option[phrase_option_index].regx.push(new_item);
      } else {
        this.d_nlpPhraseDataNew.phrase_option[phrase_option_index].regx.push(new_item);
      }
    },
    f_selectNlpPhrase: function (nlp_phrase_index) {
      this.d_nlpPhraseClientId = this.d_nlpPhraseList[nlp_phrase_index].client_id;
      this.d_nlpPhraseWdmrKey = this.d_nlpPhraseList[nlp_phrase_index].id;
      this.d_selectedNlpPhraseIndex = nlp_phrase_index;
      this.f_nlpPhraseGet('nlp_phrase_selected');
    },
    f_nlpPhraseList: function () {
      this.d_nlpPhraseList = [];
      this.d_nlpPhraseListLoading = true;
      let data = {};
      let query = 'nlp_layer_type=' + this.d_nlpLayerType;
      if (this.d_nlpPhraseStatus) {
        query += '&phrase_status=' + this.d_nlpPhraseStatus;
      }
      if (this.d_nlpPhraseGroupNameSearchText) {
        data['phrase_group_name'] = this.d_nlpPhraseGroupNameSearchText;
      }
      if (this.d_nlpPhraseNameSearchText) {
        data['phrase_name'] = this.d_nlpPhraseNameSearchText;
      }
      if (this.d_nlpPhraseOptionNameSearch) {
        data['phrase_option_name'] = this.d_nlpPhraseOptionNameSearch;
      }
      ServiceFunctions.nlp_phrase_list(query, data)
        .then(resp => {
          this.d_nlpPhraseListLoading = false;
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.d_nlpPhraseList = resp.data.list;
            // alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_nlpPhraseTransfer: function () {
      this.d_nlpPhraseListLoading = true;
      let data = {};
      let query = 'transfer_type=global_to_local';
      if (this.d_nlpPhraseStatus) {
        query += '&phrase_status=' + this.d_nlpPhraseStatus;
      }
      if (this.d_nlpPhraseGroupNameSearchText) {
        data['phrase_group_name'] = this.d_nlpPhraseGroupNameSearchText;
      }
      if (this.d_nlpPhraseNameSearchText) {
        data['phrase_name'] = this.d_nlpPhraseNameSearchText;
      }
      if (this.d_nlpPhraseOptionNameSearch) {
        data['phrase_option_name'] = this.d_nlpPhraseOptionNameSearch;
      }
      ServiceFunctions.nlp_phrase_transfer(query, data)
        .then(resp => {
          this.d_nlpPhraseListLoading = false;
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            alert('Transfer edilen veri adedi: ' + resp.data.transfer_data_count.toString());
            // alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_nlpPhraseRecord: function (type) {
      this.d_nlpPhraseRecordLoading = true;
      let data = {};
      if (type === 'edit') {
        data.nlp_phrase_data = this.d_selectedNlpPhraseData;
      } else {
        data.nlp_phrase_data = this.d_nlpPhraseDataNew;
      }
      let query = 'nlp_layer_type=' + this.d_nlpLayerType;
      if (type === 'edit') {
        query += '&nlp_phrase_client_id=' + this.d_nlpPhraseClientId;
      }
      ServiceFunctions.nlp_phrase_record(query, data)
        .then(resp => {
          this.d_nlpPhraseRecordLoading = false;
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.d_nlpPhraseClientId = resp.data.client_id;
            this.f_nlpPhraseGet();
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_nlpPhraseGet: function (tab_view='') {
      this.d_selectedNlpPhraseData = '';
      if (tab_view) {
        this.d_selectedTab = 'nlp_phrase_selected';
      }
      let query = 'nlp_layer_type=' + this.d_nlpLayerType;
      query += '&nlp_phrase_client_id=' + this.d_nlpPhraseClientId;
      query += '&data_type=value_mode';
      let data = {};
      ServiceFunctions.nlp_phrase_get(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.data) {
              this.d_selectedNlpPhraseData = resp.data.data;
              if (this.p_nlpData) {
                this.p_nlpData.selected_phrase_data = this.d_selectedNlpPhraseData;
                this.p_nlpData.nlp_phrase_id = this.d_nlpPhraseClientId;
                this.p_nlpData.nlp_layer_type = this.d_nlpLayerType;
              }
              for (let i in this.d_selectedNlpPhraseData.phrase_option) {
                if (this.d_selectedNlpPhraseData.phrase_option[i].regx.length === 0) {
                  let new_item = { 'regx_name': '', 'regx_code': '' };
                  this.d_selectedNlpPhraseData.phrase_option[i].regx.push(new_item);
                }
              }
              if (this.d_nlpLayerType === 'user') {
                if (this.d_selectedNlpPhraseData.approved_user && this.d_selectedNlpPhraseData.approved_user.length > 0) {
                  for (let i in this.d_selectedNlpPhraseData.approved_user) {
                    if (this.d_selectedNlpPhraseData.approved_user[i].username === this.user.username) {
                      if (this.d_selectedNlpPhraseData.approved_user[i].auth && this.d_selectedNlpPhraseData.approved_user[i].auth.length > 0) {
                        for (let auth_index in this.d_selectedNlpPhraseData.approved_user[i].auth) {
                          if (['admin'].indexOf(this.d_selectedNlpPhraseData.approved_user[i].auth[auth_index].value) !== -1) {
                            this.d_phraseEditPermissionEligible = true;
                            break;
                          }
                        }
                      }
                    }
                    if (this.d_phraseEditPermissionEligible) {
                      break;
                    }
                  }
                }
              }
              this.f_createPhraseOptionColors();
              this.f_analyzeWorkTextByPhraseOptions();
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_filterWorkTextList: function () {
      let is_filtered = false;
      this.d_markText.text = this.d_markText.text.toLocaleLowerCase();
      let mark_words = [];
      let start_words = [];
      let end_words = [];
      if (this.d_startEnd.start && this.d_startEnd.end) {
        start_words = this.d_startEnd.start.split(',');
        end_words = this.d_startEnd.end.split(',');
      }
      if (this.d_markText.text) {
        mark_words = this.d_markText.text.split(',');
      }
      for (let row_index in this.d_selectedWorkTextRows) {
        let row_data = this.d_selectedWorkTextRows[row_index];
        try {
          this.$delete(row_data, 'se');
        } catch(err) {}
        if (row_data.w && row_data.w.length > 0) {
          let start_index = '';
          let end_index = '';
          for (let word_index in row_data.w) {
            try {
              this.$delete(row_data.w[word_index], 'm');
            } catch(err) {}
            try {
              this.$delete(row_data.w[word_index], 'se');
            } catch(err) {}
            // console.log('mark_words: ', mark_words);
            if (mark_words.length > 0) {
              for (let mark of mark_words) {
                if (row_data.w[word_index].x.toLocaleLowerCase().indexOf(mark) !== -1) {
                  row_data.w[word_index].m = 1;
                  break;
                }
              }
            }

            if (this.d_startEnd.start && this.d_startEnd.end) {
              for (let s_word of start_words) {
                if (row_data.w[word_index].x.toLocaleLowerCase().indexOf(s_word) !== -1) {
                  start_index = parseInt(word_index);
                  break;
                }
              }
              if (start_index !== '' && end_index === '') {
                for (let e_word of end_words) {
                  if (row_data.w[word_index].x.toLocaleLowerCase().indexOf(e_word) !== -1) {
                    end_index = parseInt(word_index);
                    break;
                  }
                }
              }
            }
          }
          if (this.d_startEnd.start && this.d_startEnd.end) {
            if (start_index !== '' && end_index !== '' && start_index < end_index) {
              row_data.se = 1;
              for (let word_index in row_data.w) {
                if (parseInt(word_index) >= start_index && parseInt(word_index) <= end_index) {
                  row_data.w[word_index].se = 1;
                }
              }
            }
          }
        }
      }

      let new_index_list = [];
      if (this.d_nlpWorkTextPagination.search && this.d_nlpWorkTextPagination.regx_search) {
        let regx_text = '';
        try {
          regx_text = new RegExp(this.d_nlpWorkTextPagination.regx_search, 'i');
        } catch(err) {
          // console.log('err ', err);
        }
        if (regx_text) {
          is_filtered = true;
          for (let row_index in this.d_selectedWorkTextRows) {
            let row_data = this.d_selectedWorkTextRows[row_index];
            if (!row_data.se || (this.d_startEnd.start && this.d_startEnd.end && row_data.se)) {
              if ((this.d_showPhraseRegxPositiveRows && row_data.rgx && row_data.rgx.length > 0) || (!this.d_showPhraseRegxPositiveRows)) {

                if (row_data.w && row_data.w.length > 0) {
                  let row_text = '';
                  for (let word_index in row_data.w) {
                    row_text += row_data.w[word_index].x + ' ';
                  }
                  let search_splitted = this.d_nlpWorkTextPagination.search.toLocaleLowerCase().split(',');
                  for (let src_item of search_splitted) {
                    if (row_text && row_text.toLocaleLowerCase().indexOf(src_item) !== -1) {
                      // const regex = /[A-Z]/g;
                      let regx_found = row_text.match(regx_text);
                      // console.log('this.d_nlpWorkTextPagination.regx_search : ', this.d_nlpWorkTextPagination.regx_search);
                      // console.log('regx_found: ', regx_found);
                      if (regx_found) {
                        new_index_list.push(parseInt(row_index));
                        break;
                      }
                    }
                  }
                }

              }
            }
          }
        }

      } else if (this.d_nlpWorkTextPagination.search) {
        is_filtered = true;
        for (let row_index in this.d_selectedWorkTextRows) {
          let row_data = this.d_selectedWorkTextRows[row_index];
          if (!row_data.se || (this.d_startEnd.start && this.d_startEnd.end && row_data.se)) {
            if ((this.d_showPhraseRegxPositiveRows && row_data.rgx && row_data.rgx.length > 0) || (!this.d_showPhraseRegxPositiveRows)) {
              if (row_data.w && row_data.w.length > 0) {
                let row_text = '';
                for (let word_index in row_data.w) {
                  row_text += row_data.w[word_index].x + ' ';
                }
                let search_splitted = this.d_nlpWorkTextPagination.search.toLocaleLowerCase().split(',');
                for (let src_item of search_splitted) {
                  if (row_text && row_text.toLocaleLowerCase().indexOf(src_item) !== -1) {
                    new_index_list.push(parseInt(row_index));
                  }
                }

              }
            }
          }
        }
      } else if (this.d_nlpWorkTextPagination.regx_search) {
        is_filtered = true;
        let regx_text = '';
        try {
          regx_text = new RegExp(this.d_nlpWorkTextPagination.regx_search, 'i');
        } catch(err) {
          // console.log('err ', err);
        }
        if (regx_text) {
          for (let row_index in this.d_selectedWorkTextRows) {
            let row_data = this.d_selectedWorkTextRows[row_index];
            if (!row_data.se || (this.d_startEnd.start && this.d_startEnd.end && row_data.se)) {
              if ((this.d_showPhraseRegxPositiveRows && row_data.rgx && row_data.rgx.length > 0) || (!this.d_showPhraseRegxPositiveRows)) {
                if (row_data.w && row_data.w.length > 0) {
                  let row_text = '';
                  for (let word_index in row_data.w) {
                    row_text += row_data.w[word_index].x + ' ';
                  }
                  if (row_text) {
                    // const regex = /[A-Z]/g;
                    let regx_found = row_text.match(regx_text);
                    // console.log('this.d_nlpWorkTextPagination.regx_search : ', this.d_nlpWorkTextPagination.regx_search);
                    // console.log('regx_found: ', regx_found);
                    if (regx_found) {
                      new_index_list.push(parseInt(row_index));
                    }
                  }
                }
              }
            }
          }
        }

      } else if (this.d_showPhraseRegxPositiveRows) {
        is_filtered = true;
        for (let row_index in this.d_selectedWorkTextRows) {
          let row_data = this.d_selectedWorkTextRows[row_index];
          if (!row_data.se || (this.d_startEnd.start && this.d_startEnd.end && row_data.se)) {
            if ((this.d_showPhraseRegxPositiveRows && row_data.rgx && row_data.rgx.length > 0) || (!this.d_showPhraseRegxPositiveRows)) {
              new_index_list.push(parseInt(row_index));
            }
          }
        }
      } else if (this.d_startEnd.start && this.d_startEnd.end) {
        is_filtered = true;
        for (let row_index in this.d_selectedWorkTextRows) {
          let row_data = this.d_selectedWorkTextRows[row_index];
          if (row_data.se) {
            new_index_list.push(parseInt(row_index));
          }
        }
      }

      if (is_filtered) {
        this.d_nlpWorkTextPagination.all_count = new_index_list.length;
        this.d_nlpWorkTextPagination.filter_count = new_index_list.length;
        this.d_allFilteredWorkList = new_index_list;
        let result_index_list = [];
        for (let row_index in new_index_list) {
          if (parseInt(row_index) + 1 >= this.d_nlpWorkTextPagination.start && parseInt(row_index) + 1 <= this.d_nlpWorkTextPagination.end) {
            result_index_list.push(new_index_list[row_index]);
          }
        }
        this.d_showEligibleWorkTextIndexList = result_index_list;
      } else {
        this.d_nlpWorkTextPagination.all_count = this.d_selectedWorkTextRows.length;
        for (let row_index in this.d_selectedWorkTextRows) {
          if (parseInt(row_index) + 1 >= this.d_nlpWorkTextPagination.start && parseInt(row_index) + 1 <= this.d_nlpWorkTextPagination.end) {
            new_index_list.push(parseInt(row_index));
          }
        }
        this.d_nlpWorkTextPagination.filter_count = 0;
        this.d_showEligibleWorkTextIndexList = new_index_list;
      }

    },
  },
  watch: {
    'd_startEnd.start': function () {
      if (this.d_startEnd.timeout) {
        clearTimeout(this.d_startEnd.timeout);
        this.d_startEnd.timeout = 0;
      }
      this.d_startEnd.timeout = setTimeout(function () {
        this.f_filterWorkTextList();
      }.bind(this), 1000);
    },
    'd_startEnd.end': function () {
      if (this.d_startEnd.timeout) {
        clearTimeout(this.d_startEnd.timeout);
        this.d_startEnd.timeout = 0;
      }
      this.d_startEnd.timeout = setTimeout(function () {
        this.f_filterWorkTextList();
      }.bind(this), 1000);
    },
    'd_showPhraseRegxPositiveRows': function () {
      setTimeout(function () {
        this.f_filterWorkTextList();
      }.bind(this), 1000);
    },
    'd_nlpWorkTextPagination.search': function () {
      if (this.d_nlpWorkTextPagination.timeout) {
        clearTimeout(this.d_nlpWorkTextPagination.timeout);
        this.d_nlpWorkTextPagination.timeout = 0;
      }
      this.d_nlpWorkTextPagination.timeout = setTimeout(function () {
        this.f_filterWorkTextList();
      }.bind(this), 1000);
    },
    'd_nlpWorkTextPagination.regx_search': function () {
      this.d_searchRegxTextError = false;
      if (this.d_nlpWorkTextPagination.regx_search) {
        try {
          let regx_check = new RegExp(this.d_nlpWorkTextPagination.regx_search);
        } catch(err) {
          console.log('err ', err);
          this.d_searchRegxTextError = true;
        }
      }
      if (this.d_nlpWorkTextPagination.timeout) {
        clearTimeout(this.d_nlpWorkTextPagination.timeout);
        this.d_nlpWorkTextPagination.timeout = 0;
      }
      this.d_nlpWorkTextPagination.timeout = setTimeout(function () {
        this.f_filterWorkTextList();
      }.bind(this), 1000);
    },
    'd_nlpWorkTextPagination.current': function () {
      setTimeout(function () {
        this.d_nlpWorkTextPagination.start = (this.d_nlpWorkTextPagination.current - 1) * this.d_nlpWorkTextPagination.perpage;
        this.d_nlpWorkTextPagination.end = (this.d_nlpWorkTextPagination.perpage * this.d_nlpWorkTextPagination.current);
        this.f_filterWorkTextList();
      }.bind(this), 100);
    },
    'd_markText.text': function () {
      if (this.d_markText.timeout) {
        clearTimeout(this.d_markText.timeout);
        this.d_markText.timeout = 0;
      }
      this.d_markText.timeout = setTimeout(function () {
        // console.log('xxx');
        this.f_filterWorkTextList();
      }.bind(this), 3000);
    },
    'd_nlpLayerType': function () {
      setTimeout(function () {
        this.d_phraseEditPermissionEligible = false;
        if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('wisdom_nlp') !== -1) {
          this.d_phraseEditPermissionEligible = true;
        } else if (this.d_nlpLayerType === 'user') {
          this.d_phraseEditPermissionEligible = true;
        }
        if (!this.d_phraseEditPermissionEligible) {
          this.d_selectedWorkTextRows = [];
        }

        // this.d_selectedTab = 'nlp_phrase_list';
        this.d_selectedNlpPhraseIndex = '';
        this.d_nlpPhraseList = []
        this.d_selectedNlpPhraseData = '';
        this.d_nlpPhraseClientId = '';
        this.d_nlpPhraseWdmrKey = '';
      }.bind(this), 500);
    }
  }
}

</script>

<style type="text/css">


</style>

