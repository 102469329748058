<template>
  <div>
    <b-card no-body class="mb-1" header-tag="header" :class="[d_fullScreen ? 'full-screen-mode' : 'normal-mode']" :style="'color:' + p_polestarSettings.style.color + '; background-color: ' + p_polestarSettings.style.backgroundColor">
      <b-card-header v-if="!p_columnSelectionMode" header-bg-variant="secondary" class="p-1" header-text-variant="dark">
        <b-row>
          <b-col cols="12">
            <strong> Kolon Bilgileri </strong>
            <b-button class="pull-right" size="md" :variant="d_fullScreen ? 'warning' : 'secondary'" :style="d_fullScreen ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreen ? d_fullScreen = false : d_fullScreen = true">
              <img src="@/icon/629153.png" :title="$t('wdm16.6589')" style="width: 2em;" />
            </b-button>
            <b-dropdown class="pull-right" variant="secondary" size="sm" right>
              <template v-slot:button-content>
                <img src="@/icon/1115721.png" title="işlemler" style="width: 2em;" />
              </template>

              <b-dropdown-item v-if="column_index === undefined" @click="f_addEditColumnList()"> <img src="@/icon/2087726.png" style="width: 2em;"> kolon düzenlemelerini kaydet </b-dropdown-item>

              <b-dropdown-item v-if="d_selectedColumnInd !== '' && column_list[d_selectedColumnInd].rule_id !== undefined" @click="f_selectFromApiList('this_project')"> <img src="@/icon/2087726.png" style="width: 2em;"> Bu Projedeki Api Listesinden Seçim Yap </b-dropdown-item>

              <b-dropdown-item v-if="d_selectedColumnInd !== '' && column_list[d_selectedColumnInd].rule_id !== undefined" @click="f_selectFromApiList('all_projects')"> <img src="@/icon/2087726.png" style="width: 2em;"> Tüm Projelerdeki Api Listesinden Seçim Yap </b-dropdown-item>

              <b-dropdown-item v-if="column_index === undefined" @click="f_updateColumnOptionsByDataset()"> <img src="@/icon/2976082.png" style="width: 2em;"> kolonlara ait seçenekleri datasetten güncelle </b-dropdown-item>

              <b-dropdown-item v-if="column_index === undefined && d_selectedColumnInd !== ''" @click="f_phraseColumnOptionMatch(d_selectedColumnInd)"> <img src="@/icon/2087726.png" style="width: 2em;"> seçili kolona ait seçenek ifade eşleştirmesi yap </b-dropdown-item>

              <b-dropdown-item v-if="column_index === undefined && d_selectedColumnInd !== ''" @click="f_analyzeAllWords(d_selectedColumnInd)"> <img src="@/icon/2087726.png" style="width: 2em;"> seçili kolon hücrelerinde kelime analizi yap </b-dropdown-item>

              <b-dropdown-item v-if="column_index === undefined && d_selectedColumnInd !== ''" @click="f_nlpPhraseWorkPrepareTextByProjectColumnContent(d_selectedColumnInd)"> <img src="@/icon/2087726.png" style="width: 2em;"> seçili kolon verilerini nlp ifade çalışması için hazırla </b-dropdown-item>

              <b-dropdown-item v-if="column_index === undefined && d_selectedColumnInd !== '' && column_list[d_selectedColumnInd].data_creation_type === 'nlp'" @click="f_bindNlpPhrase(d_selectedColumnInd)"> <i class="fa fa-star"></i> seçili kolona nlp ifadesi bağla </b-dropdown-item>
              <!-- <b-dropdown-item v-if="d_eligibleForHimsInspectionMatch" @click="f_updateCellsByHimsInspectionMatch('this', d_selectedColumnInd, 'every_cell')"> <img src="@/icon/2087726.png" style="width: 2em;"> seçenekleri HBYS alanlarından otomatik eşleştir (dolu ve boş hücreler) </b-dropdown-item> -->
              <!-- <b-dropdown-item v-if="d_eligibleForHimsInspectionMatch" @click="f_updateCellsByHimsInspectionMatch('this', d_selectedColumnInd, 'only_free')"> <img src="@/icon/2087726.png" style="width: 2em;"> seçenekleri HBYS alanlarından otomatik eşleştir (sadece boş hücreler) </b-dropdown-item> -->

              <b-dropdown-item v-if="d_eligibleForColumnOptionRuleMatch" @click="f_updateCellsByColumnOptionRule('this', d_selectedColumnInd, 'every_cell')"> <img src="@/icon/2087726.png" style="width: 2em;"> seçenekleri kolon rule analizi ile doldur (dolu ve boş hücreler) </b-dropdown-item>

              <b-dropdown-item v-if="d_eligibleForColumnOptionRuleMatch" @click="f_updateCellsByColumnOptionRule('this', d_selectedColumnInd, 'only_free')"> <img src="@/icon/2087726.png" style="width: 2em;"> seçenekleri kolon rule analizi ile doldur (sadece boş hücreler) </b-dropdown-item>

              <b-dropdown-item v-if="d_selectedColumnInd !== '' && column_list[d_selectedColumnInd].machine_learning_model_id" @click="f_updateCellsByMachineLearningModel('this', d_selectedColumnInd, 'every_cell')"> <img src="@/icon/2956643.png" style="width: 2em;"> kolonu makine öğrenmesi modeliyle doldur (tüm hücreler) </b-dropdown-item>
              
              <b-dropdown-item v-if="d_selectedColumnInd !== '' && column_list[d_selectedColumnInd].machine_learning_model_id" @click="f_updateCellsByMachineLearningModel('this', d_selectedColumnInd, 'only_free')"> <img src="@/icon/2956643.png" style="width: 2em;"> kolonu makine öğrenmesi modeliyle doldur (sadece boş hücreler) </b-dropdown-item>

              <!--
                  <b-dropdown-item v-if="d_eligibleForWdmrRuleMatch" @click="f_updateCellsByWdmrRule('this', d_selectedColumnInd, 'every_cell')"> <img src="@/icon/2087726.png" style="width: 2em;"> katman verisinden metin madenciliği yapılarak eşleştirilen seçeneği seç (dolu ve boş hücreler) </b-dropdown-item>

                  <b-dropdown-item v-if="d_eligibleForWdmrRuleMatch" @click="f_updateCellsByWdmrRule('this', d_selectedColumnInd, 'only_free')"> <img src="@/icon/2087726.png" style="width: 2em;"> katman verisinden metin madenciliği yapılarak eşleştirilen seçeneği seç (sadece boş hücreler) </b-dropdown-item>

                  <b-dropdown-item v-if="d_eligibleForColumnOptionRuleMatch && d_eligibleForWdmrRuleMatch" @click="f_updateCellsByColumnOptionMixedWdmrRule('this', d_selectedColumnInd, 'every_cell')"> <img src="@/icon/2087726.png" style="width: 2em;"> seçenekleri kolon rule ve wdmr rule analizleri beraber sağlandığında otomatik doldur (dolu ve boş hücreler) </b-dropdown-item>

                  <b-dropdown-item v-if="d_eligibleForColumnOptionRuleMatch && d_eligibleForWdmrRuleMatch" @click="f_updateCellsByColumnOptionMixedWdmrRule('this', d_selectedColumnInd, 'only_free')"> <img src="@/icon/2087726.png" style="width: 2em;"> seçenekleri kolon rule ve wdmr rule analizleri beraber sağlandığında otomatik doldur (sadece boş hücreler) </b-dropdown-item>
              -->
            </b-dropdown>
          </b-col>
        </b-row>
      </b-card-header>
      <b-row>
        <b-col cols="12" v-if="d_navColumnList === 'column_cell_stats'">
          <b-navbar v-if="!p_columnSelectionMode" style="padding: 0px;">
            <b-nav pills>
              <b-nav-item @click="d_navColumnList = 'column_list'" :active="d_navColumnList === 'column_list'"> kolonlar </b-nav-item>
              <b-nav-item @click="d_navColumnList = 'column_cell_stats'" :active="d_navColumnList === 'column_cell_stats'"> kolon hücre doldurma şekli </b-nav-item>
            </b-nav>
          </b-navbar>
          <b-card v-if="d_navColumnList === 'column_cell_stats'" no-body class="mb-1" header-tag="header" :class="[d_fullScreenColumnList ? 'full-screen-mode' : 'normal-mode']">
            <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
              <b-row>
                <b-col cols="4">
                  <strong> Kolon Listesi </strong>
                </b-col>
                <b-col cols="8">
                  <b-button class="pull-right" size="sm" :variant="d_fullScreenColumnList ? 'warning' : 'secondary'" :style="d_fullScreenColumnList ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenColumnList ? d_fullScreenColumnList = false : d_fullScreenColumnList = true">
                    <img src="@/icon/629153.png" :title="$t('wdm16.6589')" style="width: 2em;" />
                  </b-button>
                  <b-button v-if="d_projectWdmList.length > 0" class="pull-right" size="sm" :variant="d_showLabelsWdmLocationFormat ?'warning' :'secondary'" :style="d_showLabelsWdmLocationFormat ?'margin-right: 5px; padding: 0px; border: solid 2px green;' :'margin-right: 5px; padding: 0px;'" @click="d_showLabelsWdmLocationFormat ? d_showLabelsWdmLocationFormat = false : d_showLabelsWdmLocationFormat = true">
                    <img src="@/icon/3082135.png" title="Başlıkları parametre lokasyonu şeklinde göster" style="width: 2em;" />
                  </b-button>
                  <b-button class="pull-right" variant="success" @click="f_downloadCellAnalyzeExcel()"><i class="fa fa-download"></i> Download</b-button>
                </b-col>
              </b-row>
            </b-card-header>
            <b-row>
              <b-col cols="4" style="padding-right: 1px;">
                <b-form-input v-model="d_searchColumnText" type="text" placeholder="kolon filtrelemek için yazınız"></b-form-input>
              </b-col>
              <b-col cols="4" style="padding-left: 1px;">
                <b-button v-if="d_projectWdmList.length > 0" variant="secondary" size="sm" @click="d_columnSettingsFilter_show === true ? d_columnSettingsFilter_show = false : d_columnSettingsFilter_show = true; $forceUpdate();">wdm tipi ile filtre</b-button>
                <template v-if="d_columnSettings.filter_wdm_list.length > 0">
                  <br>
                  <span v-for="(w_type, w_type_ind) in d_columnSettings.filter_wdm_list">
                    {{ w_type.label }},
                  </span>
                </template>
              </b-col>
              <b-col cols="4" style="padding-left: 1px;">
                <b-pagination size="sm" class="pull-right" style="margin-bottom: 0px;" aria-controls="column_pagination" v-model="d_columnSettings.current" :total-rows="column_list.length" :per-page="d_columnSettings.perpage"></b-pagination>
              </b-col>
            </b-row>
            <template v-if="column_list.length > 0">
              <b-row>
                <b-col cols="12">
                  <div id="id_column_list" :style="f_calculateStyleColumnList()">
                    <table class="wisdomTable">
                      <tr class="wisdomTableTr">
                        <th class="wisdomTableTh" title="Kolon bilgisi"> {{ f_showThisChars('Kolon bilgisi', 20) }} </th>
                        <th class="wisdomTableTh" title="Toplam dolu"> {{ f_showThisChars('Toplam dolu', 20) }} </th>
                        <th class="wisdomTableTh" title="Toplam boş"> {{ f_showThisChars('Toplam boş', 20) }} </th>
                        <th class="wisdomTableTh" title="Toplam otomatik veri girişi"> {{ f_showThisChars('Toplam otomatik veri girişi', 20) }} </th>
                        <th class="wisdomTableTh" title="Veri katmanından kolon kuralları ile"> {{ f_showThisChars('Veri katmanından kolon kuralları ile', 20) }} </th>
                        <th class="wisdomTableTh" title="Hücrelere tıklanarak veri girişi"> {{ f_showThisChars('Hücrelere tıklanarak veri girişi', 20) }} </th>
                        <th class="wisdomTableTh" title="Excel import işlemi ile"> {{ f_showThisChars('Excel import işlemi ile', 20) }} </th>
                        <th class="wisdomTableTh" title="Kolon kural algoritmaları ile"> {{ f_showThisChars('Kolon kural algoritmaları ile', 20) }} </th>
                        <th class="wisdomTableTh" title="Matematiksel algoritmalar ile"> {{ f_showThisChars('Matematiksel algoritmalar ile', 20) }} </th>
                        <th class="wisdomTableTh" title="Kolon kural algoritmaları ve matematiksel algoritmalar ile"> {{ f_showThisChars('Kolon kural algoritmaları ve matematiksel algoritmalar ile', 20) }} </th>
                        <th class="wisdomTableTh" title="Seçenekli kolonlarda, kolon kural algoritması ile seçenek seçtirilerek"> {{ f_showThisChars('Seçenekli kolonlarda, kolon kural algoritması ile seçenek seçtirilerek', 20) }} </th>
                        <th class="wisdomTableTh" title="Seçenekli kolonlarda, katman verisinden metin madenciliği yapılarak tespit edilen ifadeler seçeneklere eşleştirilerek"> {{ f_showThisChars('Seçenekli kolonlarda, katman verisinden metin madenciliği yapılarak tespit edilen ifadeler seçeneklere eşleştirilerek', 20) }} </th>
                        <th class="wisdomTableTh" title="Rastgele veri üretimi ile"> {{ f_showThisChars('Rastgele veri üretimi ile', 20) }} </th>
                        <th class="wisdomTableTh" title="Seçenekli kolonlarda, katman verisinden medin madenciliği yapılarak tespit edilen ifadelerin eşleştirilmesi ve kolon kural algoritmaları ile eşleştirilmesi ile"> {{ f_showThisChars('Seçenekli kolonlarda, katman verisinden medin madenciliği yapılarak tespit edilen ifadelerin eşleştirilmesi ve kolon kural algoritmaları ile eşleştirilmesi ile', 20) }} </th>
                      </tr>
                      <tr class="wisdomTableTr" style="background-color: #ececec;">
                        <td class="wisdomTableTd"> <strong>PROJE GENELİ</strong> </td>
                        <td class="wisdomTableTd"> {{ f_printFillPercent(p_selectedProjectData, 'total_filled') }} </td>
                        <td class="wisdomTableTd"> {{ f_printFillPercent(p_selectedProjectData, 'total_empty') }} </td>
                        <td class="wisdomTableTd"> {{ f_printFillPercent(p_selectedProjectData, 'automatic') }} </td>
                        <td class="wisdomTableTd"> {{ f_printFillPercent(p_selectedProjectData, 'manuel') }} </td>
                        <td class="wisdomTableTd"> {{ f_printFillPercent(p_selectedProjectData, 'layer') }} </td>
                        <td class="wisdomTableTd"> {{ f_printFillPercent(p_selectedProjectData, 'excel') }} </td>
                        <td class="wisdomTableTd"> {{ f_printFillPercent(p_selectedProjectData, 'crule') }} </td>
                        <td class="wisdomTableTd"> {{ f_printFillPercent(p_selectedProjectData, 'math') }} </td>
                        <td class="wisdomTableTd"> {{ f_printFillPercent(p_selectedProjectData, 'crule_and_math') }} </td>
                        <td class="wisdomTableTd"> {{ f_printFillPercent(p_selectedProjectData, 'orule') }} </td>
                        <td class="wisdomTableTd"> {{ f_printFillPercent(p_selectedProjectData, 'wtext') }} </td>
                        <td class="wisdomTableTd"> {{ f_printFillPercent(p_selectedProjectData, 'random') }} </td>
                        <td class="wisdomTableTd"> {{ f_printFillPercent(p_selectedProjectData, 'wtext_and_orule') }} </td>
                      </tr>
                      <template v-for="(col, col_ind) in column_list">
                        <tr :key="'col_' + col_ind.toString()" v-if="f_filterColumn(col, col_ind)" class="wisdomTableTr" :style="f_calculateRowStyle(col_ind)">
                          <td class="wisdomTableTd">
                            <template v-if="d_showLabelsWdmLocationFormat && col.type === 'wdm'">
                              <wdm-parameter-location :col="col" :loc="col.loc" :col_ind="col_ind" :wdm_name="col.wdm_name" :show_wdm_name_upper="false"></wdm-parameter-location>
                            </template>
                            <template v-else>
                              <strong> ({{ col_ind + 1 }}) </strong> {{ col.label }}
                            </template>
                          </td>
                          <td class="wisdomTableTd"> {{ f_printFillPercent(col, 'total_filled') }} </td>
                          <td class="wisdomTableTd"> {{ f_printFillPercent(col, 'total_empty') }} </td>
                          <td class="wisdomTableTd"> {{ f_printFillPercent(col, 'automatic') }} </td>
                          <td class="wisdomTableTd"> {{ f_printFillPercent(col, 'manuel') }} </td>
                          <td class="wisdomTableTd"> {{ f_printFillPercent(col, 'layer') }} </td>
                          <td class="wisdomTableTd"> {{ f_printFillPercent(col, 'excel') }} </td>
                          <td class="wisdomTableTd"> {{ f_printFillPercent(col, 'crule') }} </td>
                          <td class="wisdomTableTd"> {{ f_printFillPercent(col, 'math') }} </td>
                          <td class="wisdomTableTd"> {{ f_printFillPercent(col, 'crule_and_math') }} </td>
                          <td class="wisdomTableTd"> {{ f_printFillPercent(col, 'orule') }} </td>
                          <td class="wisdomTableTd"> {{ f_printFillPercent(col, 'wtext') }} </td>
                          <td class="wisdomTableTd"> {{ f_printFillPercent(col, 'random') }} </td>
                          <td class="wisdomTableTd"> {{ f_printFillPercent(col, 'wtext_and_orule') }} </td>
                        </tr>
                      </template>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
        <b-col :cols="f_calculateLeftSideCol()" v-if="f_leftSideShow() && d_navColumnList === 'column_list'">
          <b-navbar v-if="!p_columnSelectionMode" style="padding: 0px;">
            <b-nav pills>
              <b-nav-item @click="d_navColumnList = 'column_list'" :active="d_navColumnList === 'column_list'"> kolonlar </b-nav-item>
              <b-nav-item @click="d_navColumnList = 'column_cell_stats'" :active="d_navColumnList === 'column_cell_stats'"> kolon hücre doldurma şekli </b-nav-item>
            </b-nav>
          </b-navbar>
          <b-card v-if="d_navColumnList === 'column_list'" no-body class="mb-1" header-tag="header" :class="[d_fullScreenColumnList ? 'full-screen-mode' : 'normal-mode']">
            <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
              <b-row>
                <b-col cols="4">
                  <strong> Kolon Listesi </strong>
                </b-col>
                <b-col cols="8">
                  <b-button class="pull-right" size="sm" :variant="d_fullScreenColumnList ? 'warning' : 'secondary'" :style="d_fullScreenColumnList ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenColumnList ? d_fullScreenColumnList = false : d_fullScreenColumnList = true">
                    <img src="@/icon/629153.png" :title="$t('wdm16.6589')" style="width: 2em;" />
                  </b-button>
                  <b-dropdown class="pull-right" variant="secondary" size="sm">
                    <template v-slot:button-content>
                      <img src="@/icon/1115721.png" title="işlemler" style="width: 2em;" />
                    </template>
                    <template v-if="!p_columnSelectionMode">
                      <b-dropdown-item @click="f_addNewManuelColumn()"> <img src="@/icon/109638.png" style="width: 2em;"> Yeni Manuel Kolon Ekle </b-dropdown-item>
                      <!-- <b-dropdown-item @click="f_deleteAllColumns()"> <img src="@/icon/2960590.png" style="width: 2em;"> Tüm Kolonları Sil </b-dropdown-item> -->
                      <!-- <b-dropdown-item v-if="d_projectWdmList.length > 0" @click="f_showColumnRuleModal('new')"> <img src="@/icon/1886795.png" style="width: 2em;"> Yeni WDM Kolon Ekle </b-dropdown-item> -->
                      <b-dropdown-item v-if="p_selectedProjectData && p_selectedProjectData.data.general && p_selectedProjectData.data.general.layer && p_selectedProjectData.data.general.layer.val && p_selectedProjectData.data.general.layer.val.value" @click="f_showLayerColumnRuleModal('new')"> <img src="@/icon/1886795.png" style="width: 2em;"> Yeni WDM Katman Kolonu Ekle </b-dropdown-item>
                      <b-dropdown-item v-if="p_selectedProjectData && p_selectedProjectData.data.general && p_selectedProjectData.data.general.layer && p_selectedProjectData.data.general.layer.val && p_selectedProjectData.data.general.layer.val.value" @click="f_openProjectlistForColumnSelection()"> <img src="@/icon/453632.png" style="width: 2em;"> Projelerden Kolon Seçimi Yaparak Ekle</b-dropdown-item>
                    </template>
                    <b-dropdown-item v-if="d_tableModeSettings && d_tableModeSettings.show_selected_columns.length > 0" @click="f_cancelShowSelectedColumns()"> <img src="@/icon/109638.png" style="width: 2em;"> Seçili kolon gösterimini iptal et </b-dropdown-item>
                  </b-dropdown>
                  <b-button v-if="mother === 'polestar_project_copy'" class="pull-right" title="Ekranı Böl (Sağ | Sol)" size="md" :variant="d_divideLeftTwo ?'warning' :'secondary'" :style="d_divideLeftTwo ?'margin-right: 5px; padding: 0px; border: solid 2px green;' :'margin-right: 5px; padding: 0px;'" @click="d_divideLeftTwo ? d_divideLeftTwo = false : d_divideLeftTwo = true">
                    <img src="@/icon/2349601.png" style="width: 2em;" />
                  </b-button>
                  <!-- <b-button v-if="d_projectWdmList.length > 0" class="pull-right" size="sm" :variant="d_showLabelsWdmLocationFormat ?'warning' :'secondary'" :style="d_showLabelsWdmLocationFormat ?'margin-right: 5px; padding: 0px; border: solid 2px green;' :'margin-right: 5px; padding: 0px;'" @click="d_showLabelsWdmLocationFormat ? d_showLabelsWdmLocationFormat = false : d_showLabelsWdmLocationFormat = true">
                    <img src="@/icon/3082135.png" title="Başlıkları parametre lokasyonu şeklinde göster" style="width: 2em;" />
                  </b-button> -->
                  <b-button class="pull-right" size="sm" :variant="d_showWaimBadge ?'warning' :'secondary'" :style="d_showWaimBadge ?'margin-right: 5px; padding: 0px; border: solid 2px green;' :'margin-right: 5px; padding: 0px;'" @click="d_showWaimBadge ? d_showWaimBadge = false : d_showWaimBadge = true">
                    <img src="@/icon/2324511.png" title="Wai iconlarını göster" style="width: 2em;" />
                  </b-button>
                </b-col>
              </b-row>
            </b-card-header>
            <b-row>
              <b-col cols="5" style="padding-right: 1px;">
                <b-form-input v-model="d_searchColumnText" type="text" placeholder="kolon filtrelemek için yazınız"></b-form-input>
              </b-col>
              <b-col cols="3" style="padding-left: 1px;">
                <b-button v-if="d_projectWdmList.length > 0" variant="secondary" size="sm" @click="d_columnSettingsFilter_show === true ? d_columnSettingsFilter_show = false : d_columnSettingsFilter_show = true; $forceUpdate();">wdm tipi ile filtre</b-button>
                <template v-if="d_columnSettings.filter_wdm_list.length > 0">
                  <br>
                  <span v-for="(w_type, w_type_ind) in d_columnSettings.filter_wdm_list">
                    {{ w_type.label }},
                  </span>
                </template>
              </b-col>
              <b-col cols="4" style="padding-left: 1px;">
                <b-pagination size="sm" class="pull-right" style="margin-bottom: 0px;" aria-controls="column_pagination" v-model="d_columnSettings.current" :total-rows="column_list.length" :per-page="d_columnSettings.perpage"></b-pagination>
              </b-col>
            </b-row>
            <template v-if="column_list.length > 0">
              <b-row>
                <b-col :cols="d_divideLeftTwo ? 6 : 12">
                  <div id="id_column_list" :style="f_calculateStyleColumnList()">
                    <template v-for="(col, col_ind) in column_list">
                      <b-row :key="'col_' + col_ind.toString()" v-if="f_filterColumn(col, col_ind)" :style="d_selectedColumnInd === col_ind ? 'min-height: 30px; margin: 0px; background-color: #acebd5; cursor: pointer; border: 1px solid rgba(158, 193, 179, 0.3);' : 'min-height: 30px; margin: 0px; cursor: pointer; border: 1px solid rgba(158, 193, 179, 0.3); background-color: ' + p_polestarSettings.style.backgroundColor">
                        <b-col sm="10" @click="f_selectColumn(col_ind)">
                          <template v-if="p_columnSelectionMode && d_tableModeSettings">
                            <b-button size="md" :variant="d_tableModeSettings.show_selected_columns.indexOf(col_ind) !== -1 ? 'warning' : 'secondary'" :style="d_tableModeSettings.show_selected_columns.indexOf(col_ind) !== -1 ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="f_selectToShowThisColumn(col_ind)">
                              <img src="@/icon/3162318.png" title="Göster" style="width: 2em;" />
                            </b-button>
                          </template>
                          <template v-if="!p_columnSelectionMode">
                            <b-badge v-if="d_tableModeSettings && d_tableModeSettings.show_selected_columns.indexOf(col_ind) !== -1" variant="success">*</b-badge>
                          </template>
                          <template v-if="col.rule_id !== undefined"> <img src="@/icon/1430869.png" style="width: 2em;" /> </template>
                          <strong v-if="col.rule_id === undefined"> ({{ col_ind + 1 }}) </strong>
                          <span :style="col.rule_id === undefined ? '' : 'color: blue;'">{{ col.label }}</span>
                          <template>
                            <b-badge variant="secondary" style="margin-right: 3px;"> {{ col.param_type }} </b-badge>
                          </template>
                          <template v-if="col.date_ability && col.date_ability === 'yes'">
                            <img src="@/icon/2080590.png" class="img-rounded img-responsive" style="width: 1.5em;" title="Tarih ilişkisi kurulabilen bir parametredir." />
                          </template>
                          <template v-if="col.cell_fill_count">
                            <b-badge variant="success" title="total_filled"> ({{ col.cell_fill_count.total_filled }}) </b-badge>
                          </template>
                          <template v-else>
                            <b-badge variant="success" title="Kolona ait dolu satır sayısı"> ({{ col.filled }}) </b-badge>
                          </template>
                          <template v-if="d_showWaimBadge">

                            <b-badge v-if="col.wai_input" variant="success" title="WAI input" style="background-color: #673AB7; color: #ffffff;" @click="f_waimControl(col_ind, 'wai_input')">wai input</b-badge>
                            <b-badge v-else variant="danger" title="WAI input" @click="f_waimControl(col_ind, 'wai_input')">wai input</b-badge>

                            <b-badge v-if="col.wai_output" variant="success" title="WAI output" style="background-color: #673AB7; color: #ffffff;" @click="f_waimControl(col_ind, 'wai_output')">wai output</b-badge>
                            <b-badge v-else variant="danger" title="WAI output" @click="f_waimControl(col_ind, 'wai_output')">wai output</b-badge>

                          </template>
                          <template v-else>

                            <b-badge v-if="col.wai_input" variant="success" style="background-color: #673AB7; color: #ffffff;" title="WAI input" @click="f_waimControl(col_ind, 'wai_input')">wai input</b-badge>

                            <b-badge v-if="col.wai_output" variant="success" style="background-color: #673AB7; color: #ffffff;" title="WAI output" @click="f_waimControl(col_ind, 'wai_output')">wai output</b-badge>

                          </template>
                          <template v-if="col.sms_phone">
                            <img src="@/icon/1013986.png" style="width: 1.5em;" title="Sms telefonu olarak kullanılacak olan kolon" />
                          </template>
                          <template v-if="col.person_name">
                            <img src="@/icon/9370.png" style="width: 1.5em;" title="Kişi adı olarak kullanılacak olan kolon" />
                          </template>
                          <template v-if="col.person_firstname">
                            <img src="@/icon/9370.png" style="width: 1.5em;" title="Kişi ilk adı olarak kullanılacak olan kolon" />
                          </template>
                          <template v-if="col.person_lastname">
                            <img src="@/icon/9370.png" style="width: 1.5em;" title="Kişi soyadı olarak kullanılacak olan kolon" />
                          </template>
                          <template v-if="col.person_id">
                            <img src="@/icon/934385.png" style="width: 1.5em;" title="Sms telefonu olarak kullanılacak olan kolon" />
                          </template>
                          <b-badge v-if="col.deleted" variant="white" @click="f_deleteControl(col_ind)" title="Kolon siliniyor">
                            <img src="@/icon/2960590.png" style="width: 2em;" />
                          </b-badge>
                          <b-badge v-if="col.new_column" variant="white" @click="f_openColumnFunctions(col_ind)" title="Kolon Fonksiyonları">
                            <img src="@/icon/2766751.png" style="width: 2em;" />
                          </b-badge>
                          <b-badge v-if="col.random_data_creation_now" variant="white" @click="f_randomDataCreateNow(col_ind)" title="Rastgele Verileri Yeniden Üret">
                            <img src="@/icon/453632.png" style="width: 2em;" />
                          </b-badge>
                          <b-badge v-if="col.rule_data_creation_now" variant="white" @click="f_ruleDataCreateNow(col_ind)" title="Kural Algoritması İle Verileri Yeniden Oluştur">
                            <img src="@/icon/1430869.png" style="width: 2em;" />
                          </b-badge>
                        </b-col>
                        <b-col sm="2" style="text-align: right;">
                          <b-button variant="white" size="sm" @click="f_openColumnFunctions(col_ind)" title="Kolon Fonksiyonlarını Göster">
                            <img src="@/icon/1584256.png" style="width: 2em;" />
                          </b-button>
                        </b-col>
                      </b-row>
                    </template>
                  </div>
                </b-col>
                <b-col v-if="d_divideLeftTwo" cols="6">
                  <template v-for="(col_ind, col_ind_order) in d_tableModeSettings.show_selected_columns">
                    <b-row :key="'col_' + col_ind.toString()" :style="d_selectedColumnInd === col_ind ? 'margin: 0px; background-color: #acebd5; cursor: pointer; border: 1px solid rgba(158, 193, 179, 0.3);' : 'margin: 0px; cursor: pointer; border: 1px solid rgba(158, 193, 179, 0.3); background-color: ' + p_polestarSettings.style.backgroundColor">
                      <b-col sm="10" @click="f_selectColumn(col_ind)">
                        <template v-if="p_columnSelectionMode && d_tableModeSettings">
                          <b-button size="md" :variant="d_tableModeSettings.show_selected_columns.indexOf(col_ind) !== -1 ? 'warning' : 'secondary'" :style="d_tableModeSettings.show_selected_columns.indexOf(col_ind) !== -1 ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="f_selectToShowThisColumn(col_ind)">
                            <img src="@/icon/3162318.png" title="Göster" style="width: 2em;" />
                          </b-button>
                        </template>
                        <template v-if="d_showLabelsWdmLocationFormat && column_list[col_ind].type === 'wdm'">
                          <wdm-parameter-location :col="column_list[col_ind]" :loc="column_list[col_ind].loc" :col_ind="col_ind" :wdm_name="column_list[col_ind].wdm_name" :show_wdm_name_upper="false"></wdm-parameter-location>
                        </template>
                        <template v-else>
                          <strong> ({{ col_ind + 1 }}) </strong> {{ column_list[col_ind].label }}
                        </template>
                        <template v-if="column_list[col_ind].date_ability && column_list[col_ind].date_ability === 'yes'">
                          <img src="@/icon/2080590.png" class="img-rounded img-responsive" style="width: 1.5em;" title="Tarih ilişkisi kurulabilen bir parametredir." />
                        </template>
                        <b-badge variant="success"> ({{ column_list[col_ind].filled }}) </b-badge>
                      </b-col>
                      <b-col sm="2" style="text-align: right;"> </b-col>
                    </b-row>
                  </template>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
        <b-col v-if="f_rightSideShow() && d_navColumnList === 'column_list'" :cols="column_index === undefined ? '6' : '12'">
          <b-navbar style="padding: 0px;">
            <b-nav pills>
              <b-nav-item @click="d_navColumnDetails = 'column_general_info'" :active="d_navColumnDetails === 'column_general_info'"> bilgi </b-nav-item>
              <!-- <b-nav-item @click="d_navColumnDetails = 'column_features'" :active="d_navColumnDetails === 'column_features'"> özellikler </b-nav-item> -->
              <b-nav-item v-if="column_list[d_selectedColumnInd].type === 'wdm'" @click="d_navColumnDetails = 'wdm_param_column_rule'" :active="d_navColumnDetails === 'wdm_param_column_rule'"> wdm kolon kuralı </b-nav-item>
              <b-nav-item v-if="['select', 'radiogroup', 'checkbox', 'object_list_options'].indexOf(column_list[d_selectedColumnInd].param_type) !== -1" @click="d_navColumnDetails = 'column_options'" :active="d_navColumnDetails === 'column_options'"> seçenek </b-nav-item>
              <b-nav-item v-if="column_list[d_selectedColumnInd].rule_id === undefined && column_list[d_selectedColumnInd].data_creation_type && column_list[d_selectedColumnInd].data_creation_type === 'rule'" @click="d_navColumnDetails = 'data_creation_type_rule'" :active="d_navColumnDetails === 'data_creation_type_rule'"> kolon kuralı & mat alg. </b-nav-item>
              <b-nav-item v-if="column_list[d_selectedColumnInd].rule_id === undefined && column_list[d_selectedColumnInd].data_creation_type && column_list[d_selectedColumnInd].data_creation_type === 'random'" @click="d_navColumnDetails = 'data_creation_type_random'" :active="d_navColumnDetails === 'data_creation_type_random'"> random </b-nav-item>
              <!-- <b-nav-item v-if="column_list[d_selectedColumnInd].rule_id === undefined" @click="d_navColumnDetails = 'column_search_text_list'" :active="d_navColumnDetails === 'column_search_text_list'"> ifade çalışması </b-nav-item> -->
              <b-nav-item v-if="column_list[d_selectedColumnInd].rule_id === undefined" @click="d_navColumnDetails = 'column_style_rule'" :active="d_navColumnDetails === 'column_style_rule'"> kolon stili </b-nav-item>
              <b-nav-item v-if="column_list[d_selectedColumnInd].rule_id !== undefined" @click="d_navColumnDetails = 'walgorithm_response_param_design'" :active="d_navColumnDetails === 'walgorithm_response_param_design'"> api yanıt dizaynı </b-nav-item>
              <b-nav-item v-if="column_list[d_selectedColumnInd].rule_id !== undefined" @click="d_navColumnDetails = 'walgorithm_wdm308'" :active="d_navColumnDetails === 'walgorithm_wdm308'"> dış api bağlantı dizaynı </b-nav-item>
              <b-nav-item v-if="column_list[d_selectedColumnInd].data_creation_type === 'machine_learning'" @click="d_navColumnDetails = 'machine_learning'" :active="d_navColumnDetails === 'machine_learning'">makine öğrenmesi modeli</b-nav-item>
            </b-nav>
          </b-navbar>
          <template v-if="d_navColumnDetails === 'column_features'">
            <b-card no-body class="mb-1" header-tag="header" :style="'background-color: ' + p_polestarSettings.style.backgroundColor">
              <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">Özellikler</b-card-header>
              <b-row v-if="column_list[d_selectedColumnInd].dmp_sms_operation">
                <b-col cols="6">
                  <img src="@/icon/1013986.png" style="width: 2em;"> Sms gönderimi algoritmasını onaylama işlemi
                </b-col>
                <b-col cols="6">
                  <template v-if="column_list[d_selectedColumnInd].sms_operation_applied">
                    <b-button size="md" variant="white" style="padding: 0px;" @click="f_updateColumnFeature(d_selectedColumnInd, 'sms_operation_applied', 'Sms gönderim algoritmasını onaylama', 'multi')">
                      <img src="@/icon/2147852.png" style="width: 2em;" />
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button size="md" variant="white" style="padding: 0px;" @click="f_updateColumnFeature(d_selectedColumnInd, 'sms_operation_applied', 'Sms gönderim algoritmasını onaylama', 'multi')">
                      <img src="@/icon/2147852_black.png" style="width: 2em;" />
                    </b-button>
                  </template>
                </b-col>
              </b-row>
              <b-row v-if="!column_list[d_selectedColumnInd].dmp_sms_operation && !column_list[d_selectedColumnInd].dmp_mail_operation">
                <b-col cols="6">
                  <img src="@/icon/1013986.png" style="width: 2em;"> Sms Telefonu Kolonu
                </b-col>
                <b-col cols="6">
                  <template v-if="column_list[d_selectedColumnInd].sms_phone">
                    <b-button size="md" variant="white" style="padding: 0px;" @click="f_updateColumnFeature(d_selectedColumnInd, 'sms_phone', 'sms telefonu')">
                      <img src="@/icon/2147852.png" style="width: 2em;" />
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button size="md" variant="white" style="padding: 0px;" @click="f_updateColumnFeature(d_selectedColumnInd, 'sms_phone', 'sms telefonu')">
                      <img src="@/icon/2147852_black.png" style="width: 2em;" />
                    </b-button>
                  </template>
                </b-col>
              </b-row>
              <b-row v-if="!column_list[d_selectedColumnInd].dmp_sms_operation && !column_list[d_selectedColumnInd].dmp_mail_operation">
                <b-col cols="6">
                  <img src="@/icon/1013986.png" style="width: 2em;"> Kişi Adı Kolonu
                </b-col>
                <b-col cols="6">
                  <template v-if="column_list[d_selectedColumnInd].person_name">
                    <b-button size="md" variant="white" style="padding: 0px;" @click="f_updateColumnFeature(d_selectedColumnInd, 'person_name', 'Kişi Adı')">
                      <img src="@/icon/2147852.png" style="width: 2em;" />
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button size="md" variant="white" style="padding: 0px;" @click="f_updateColumnFeature(d_selectedColumnInd, 'person_name', 'Kişi Adı')">
                      <img src="@/icon/2147852_black.png" style="width: 2em;" />
                    </b-button>
                  </template>
                </b-col>
              </b-row>
              <b-row v-if="!column_list[d_selectedColumnInd].dmp_sms_operation && !column_list[d_selectedColumnInd].dmp_mail_operation">
                <b-col cols="6">
                  <img src="@/icon/1013986.png" style="width: 2em;"> Kişi İlk Adı Kolonu
                </b-col>
                <b-col cols="6">
                  <template v-if="column_list[d_selectedColumnInd].person_firstname">
                    <b-button size="md" variant="white" style="padding: 0px;" @click="f_updateColumnFeature(d_selectedColumnInd, 'person_firstname', 'Kişi İlk Adı')">
                      <img src="@/icon/2147852.png" style="width: 2em;" />
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button size="md" variant="white" style="padding: 0px;" @click="f_updateColumnFeature(d_selectedColumnInd, 'person_firstname', 'Kişi ilk Adı')">
                      <img src="@/icon/2147852_black.png" style="width: 2em;" />
                    </b-button>
                  </template>
                </b-col>
              </b-row>
              <b-row v-if="!column_list[d_selectedColumnInd].dmp_sms_operation && !column_list[d_selectedColumnInd].dmp_mail_operation">
                <b-col cols="6">
                  <img src="@/icon/1013986.png" style="width: 2em;"> Kişi İlk SoyAdı Kolonu
                </b-col>
                <b-col cols="6">
                  <template v-if="column_list[d_selectedColumnInd].person_lastname">
                    <b-button size="md" variant="white" style="padding: 0px;" @click="f_updateColumnFeature(d_selectedColumnInd, 'person_lastname', 'Kişi SoyAdı')">
                      <img src="@/icon/2147852.png" style="width: 2em;" />
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button size="md" variant="white" style="padding: 0px;" @click="f_updateColumnFeature(d_selectedColumnInd, 'person_lastname', 'Kişi SoyAdı')">
                      <img src="@/icon/2147852_black.png" style="width: 2em;" />
                    </b-button>
                  </template>
                </b-col>
              </b-row>
              <b-row v-if="!column_list[d_selectedColumnInd].dmp_sms_operation && !column_list[d_selectedColumnInd].dmp_mail_operation">
                <b-col cols="6">
                  <img src="@/icon/1013986.png" style="width: 2em;"> Kişi Id Kolonu
                </b-col>
                <b-col cols="6">
                  <template v-if="column_list[d_selectedColumnInd].person_id">
                    <b-button size="md" variant="white" style="padding: 0px;" @click="f_updateColumnFeature(d_selectedColumnInd, 'person_id', 'Kişi ID')">
                      <img src="@/icon/2147852.png" style="width: 2em;" />
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button size="md" variant="white" style="padding: 0px;" @click="f_updateColumnFeature(d_selectedColumnInd, 'person_id', 'Kişi ID')">
                      <img src="@/icon/2147852_black.png" style="width: 2em;" />
                    </b-button>
                  </template>
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template v-if="d_navColumnDetails === 'column_search_text_list'">
            <b-card no-body class="mb-1" header-tag="header" :style="'background-color: ' + p_polestarSettings.style.backgroundColor + '; color: ' + p_polestarSettings.style.color">
              <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                İfade çalışması
                <b-dropdown class="pull-right" variant="secondary" size="sm">
                  <template v-slot:button-content>
                    <img src="@/icon/1115721.png" title="işlemler" style="width: 2em;" />
                  </template>
                  <!-- <b-dropdown-item @click="f_searchTextAnalyzeInspection()"> <img src="@/icon/2087726.png" style="width: 2em;"> ifade çalışması yap </b-dropdown-item> -->
                  <b-dropdown-item @click="f_searchTextAnalyzeOnWdmrs()" title="Katman verisi veri modelleri ile oluşturulan algoritmalar ile ifade analiz işlemi yapılacaktır"> <img src="@/icon/2087726.png" style="width: 2em;"> wdmr ifade algoritma analizi </b-dropdown-item>
                </b-dropdown>
              </b-card-header>
              <b-row>
                <b-col sm="12" md="10">
                  <b-form-input placeholder="eklemek için ifade yazınız" :style="'width: 100%; background-color: ' + p_polestarSettings.style.backgroundColor + '; color: ' + p_polestarSettings.style.color" v-model="d_searchText"></b-form-input>
                </b-col>
                <b-col sm="12" md="2">
                  <b-button @click="f_addTextToSearchList()" variant="success">ekle</b-button>
                </b-col>
              </b-row>
              <b-card v-if="column_list[d_selectedColumnInd].search_text_list" no-body v-for="(t, t_ind) in column_list[d_selectedColumnInd].search_text_list" style="margin-bottom: 5px;">
                <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                  <b-row>
                    <b-col sm="12" md="10">
                      <strong style="color: red;">* {{ t }}</strong>
                      <!-- f_showColumnRuleModal -->
                      <b-button @click="f_showTextToSearchRuleModal('', t_ind)">
                        <img src="@/icon/1430869.png" style="width: 1.5em;" title="ifade algoritması">
                        <!-- <i class="fa fa-edit"></i> Arama ifadesi kuralı düzenle -->
                      </b-button>
                    </b-col>
                    <b-col sm="12" md="2">
                      <b-button @click="f_deleteSearchText(t_ind)" variant="danger">-</b-button>
                    </b-col>
                  </b-row>
                </b-card-header>
                <template v-if="d_selectedColumnInd !== '' && column_list[d_selectedColumnInd] && column_list[d_selectedColumnInd].search_text_rule_match && column_list[d_selectedColumnInd].search_text_rule_match[t] && column_list[d_selectedColumnInd].search_text_rule_match[t].similar_regx_list && Object.keys(column_list[d_selectedColumnInd].search_text_rule_match[t].similar_regx_list).length > 0">
                  <b-row>
                    <b-col cols="12" v-b-toggle="'similar_regx_list-' + d_selectedColumnInd + '-' + t_ind" style="cursor: pointer;">
                      <b-button block size="sm" variant="primary">
                        {{ Object.keys(column_list[d_selectedColumnInd].search_text_rule_match[t].similar_regx_list).length }} adet ifade algoritma sonucu listelenmektedir.
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-collapse :id="'similar_regx_list-' + d_selectedColumnInd + '-' + t_ind">
                    <b-row v-for="(regx, regx_ind) in column_list[d_selectedColumnInd].search_text_rule_match[t].similar_regx_list">
                      <b-col sm="12" md="1"></b-col>
                      <b-col sm="12" md="11">
                        {{ regx_ind }}
                      </b-col>
                    </b-row>
                  </b-collapse>
                </template>
              </b-card>
            </b-card>
          </template>
          <template v-if="d_navColumnDetails === 'wdm_param_column_rule'">
            <b-card no-body class="mb-1" header-tag="header">
              <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                Kolon lokasyonu ve algoritması
                <!-- <b-button class="pull-right" size="sm" variant="primary" @click="f_showColumnRuleModal('edit', d_selectedColumnInd)">düzenle</b-button> -->
                <b-button v-if="p_selectedProjectData && p_selectedProjectData.data.general && p_selectedProjectData.data.general.layer && p_selectedProjectData.data.general.layer.val && p_selectedProjectData.data.general.layer.val.value" class="pull-right" size="sm" variant="primary" @click="f_showLayerColumnRuleModal('edit', '', d_selectedColumnInd)">düzenle</b-button>
              </b-card-header>
              <template v-if="d_showWdmParamColumnRule">
                <template v-if="column_list[d_selectedColumnInd].layer_rules">
                  <template v-for="(layer_rule, layer_key) in column_list[d_selectedColumnInd].layer_rules">
                    <b-card-header header-bg-variant="info" class="p-1" header-text-variant="dark">
                      <strong>ana algoritma [ {{ layer_key.split('_')[1] }} nolu katman ]</strong>
                    </b-card-header>
                    <b-row style="margin-top: 10px;">
                      <b-col sm="12" lg="12">
                        <template v-if="layer_rule.query_list && layer_rule.query_list.length > 0">
                          <rule-query :query_list="layer_rule.query_list" :compile_str="layer_rule.compile_str_array" :compile_str_date="layer_rule.compile_str_date" :mode="'view'" :compile_str_text="layer_rule.compile_str_text"></rule-query>
                        </template>
                      </b-col>
                    </b-row>
                  </template>
                </template>
              </template>
            </b-card>
          </template>
          <template v-if="d_navColumnDetails === 'column_general_info'">
            <b-card no-body class="mb-1" header-tag="header" :style="'background-color: ' + p_polestarSettings.style.backgroundColor">
              <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                {{ column_list[d_selectedColumnInd].label }}
              </b-card-header>
              <b-row>
                <b-col cols="6">
                  <img src="@/icon/2521497.png" style="width: 2em;"> Kolon ID
                </b-col>
                <b-col cols="6">
                  {{ column_list[d_selectedColumnInd].id }}
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <img src="@/icon/2521497.png" style="width: 2em;"> Kolon başlığı
                </b-col>
                <b-col cols="6">
                  <template v-if="column_list[d_selectedColumnInd].rule_id === undefined">
                    <b-form-input v-model="column_list[d_selectedColumnInd].label" :style="'background-color: ' + p_polestarSettings.style.backgroundColor + '; color: ' + p_polestarSettings.style.color"></b-form-input>
                  </template>
                  <template v-else>
                    {{ column_list[d_selectedColumnInd].label }}
                  </template>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <img src="@/icon/2521497.png" style="width: 2em;"> Kolon Açıklaması
                </b-col>
                <b-col cols="6">
                  <b-textarea v-model="column_list[d_selectedColumnInd].info" :style="'background-color: ' + p_polestarSettings.style.backgroundColor + '; color: ' + p_polestarSettings.style.color"></b-textarea>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <img src="@/icon/2521497.png" style="width: 2em;"> Kolon Resmi
                </b-col>
                <b-col cols="6">
                  <b-form-file
                      @input="f_addImageToColumnAsBase64(d_selectedColumnInd)"
                      style="height: 100px; border: dotted 2px #25bcff; background: #daf9ff;"
                      v-model="d_tmpFile"
                      :accept="f_createAcceptedDocumentTypes()"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                  >
                    <img src="@/icon/1530793.png" style="width: 2em;">
                  </b-form-file>
                  <b-button v-if="column_list[d_selectedColumnInd].image" @click="$delete(column_list[d_selectedColumnInd], 'image')" variant="danger">sil</b-button>
                  <img v-if="column_list[d_selectedColumnInd].image" :src="column_list[d_selectedColumnInd].image" style="height: 100px;">
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <img src="@/icon/2521497.png" style="width: 2em;"> Kolon tipi
                </b-col>
                <b-col cols="6">
                  <template v-if="column_list[d_selectedColumnInd].rule_id === undefined">
                    <b-form-select id="param_type" :plain="true" v-model="column_list[d_selectedColumnInd].type" :style="'background-color: ' + p_polestarSettings.style.backgroundColor + '; color: ' + p_polestarSettings.style.color">
                      <option value="manuel">manuel</option>
                      <option value="wdm">wdm</option>
                    </b-form-select>
                  </template>
                  <template v-else>
                    {{ column_list[d_selectedColumnInd].type }}
                  </template>
                  <!-- {{ column_list[d_selectedColumnInd].type }} -->
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <img src="@/icon/2521497.png" style="width: 2em;"> Kolona ait dolu hücre sayısı
                </b-col>
                <b-col cols="6">
                  {{ column_list[d_selectedColumnInd].filled }}
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <img src="@/icon/2521497.png" style="width: 2em;"> Veri tipi
                </b-col>
                <b-col cols="6">
                  <b-form-select id="param_type" :plain="true" v-model="column_list[d_selectedColumnInd].param_type" :disabled="column_list[d_selectedColumnInd].type === 'wdm' || column_list[d_selectedColumnInd].rule_id !== undefined" :style="'background-color: ' + p_polestarSettings.style.backgroundColor + '; color: ' + p_polestarSettings.style.color">
                    <option v-if="column_list[d_selectedColumnInd].type === 'manuel'" v-for="(ty, ty_ind) in param_type" :value="ty"> {{ ty }} </option>
                    <option v-if="column_list[d_selectedColumnInd].type === 'wdm'" v-for="(ty, ty_ind) in d_wdmParamTypeList" :value="ty"> {{ ty }} </option>
                  </b-form-select>
                </b-col>
              </b-row>
              <b-row v-if="column_list[d_selectedColumnInd].rule_id !== undefined">
                <b-col cols="6">
                  <img src="@/icon/2521497.png" style="width: 2em;"> Hücre Analiz ve Doldurma Tipi
                </b-col>
                <b-col cols="6">
                  <b-form-select id="cell_process_and_fill_type" :plain="true" v-model="column_list[d_selectedColumnInd].cell_process_and_fill_type" :style="'background-color: ' + p_polestarSettings.style.backgroundColor + '; color: ' + p_polestarSettings.style.color">
                    <option value="">Doğru ve Yanlış Tüm Hücrelerde Analiz Yap ve Çoklu Doldur</option>
                    <option value="process_all_fill_onlytrue">Doğru ve Yanlış Tüm Hücrelerde Analiz Yap ve Yalnız Doğruları Doldur</option>
                    <option value="process_false_fill_onefalse">Sadece Yanlış Hücrelerde Analiz yap ve Tekli Doldur</option>
                    <option value="process_false_fill_multifalse">Sadece Yanlış Hücrelerde Analiz yap ve Çoklu Doldur</option>
                  </b-form-select>
                </b-col>
              </b-row>
              <b-row v-if="column_list[d_selectedColumnInd].rule_id === undefined">
                <b-col cols="6">
                  <img src="@/icon/2521497.png" style="width: 2em;"> Anonim veri
                </b-col>
                <b-col cols="6">
                  <b-form-select v-model="column_list[d_selectedColumnInd].anonym" :style="'background-color: ' + p_polestarSettings.style.backgroundColor + '; color: ' + p_polestarSettings.style.color">
                    <option :value="1">{{ $t('wdm16.7249') }}</option>
                    <option :value="0">{{ $t('wdm16.76') }}</option>
                  </b-form-select>
                </b-col>
              </b-row>
              <template v-if="column_list[d_selectedColumnInd].type === 'wdm' && ((['select', 'checkbox', 'object_list_options', 'radiogroup'].indexOf(column_list[d_selectedColumnInd].param_type) !== -1 && !column_list[d_selectedColumnInd].param_type_real) || (column_list[d_selectedColumnInd].param_type_real && ['select', 'checkbox', 'object_list_options', 'radiogroup'].indexOf(column_list[d_selectedColumnInd].param_type_real) !== -1))">
                <b-row>
                  <b-col cols="12">
                    <img src="@/icon/2521497.png" style="width: 2em;"> Kayıttaki alan kullanımı
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="1"> </b-col>
                  <b-col cols="11">
                    <b-form-select v-model="column_list[d_selectedColumnInd].value_label" :style="'background-color: ' + p_polestarSettings.style.backgroundColor + '; color: ' + p_polestarSettings.style.color">
                      <option value="value">değer (seçenek ID, value) </option>
                      <option value="label">başlık (seçenek başlığı, label)</option>
                    </b-form-select>
                  </b-col>
                </b-row>
              </template>
              <template v-if="column_list[d_selectedColumnInd].type === 'wdm' && column_list[d_selectedColumnInd].record_count === 'many_times'">
                <b-row>
                  <b-col cols="12">
                    <img src="@/icon/2521497.png" style="width: 2em;"> Veri kullanım şekli
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="1"> </b-col>
                  <b-col cols="11">
                    <b-form-select v-model="column_list[d_selectedColumnInd].numeric_string" @change="f_controlColumnFeatures(column_list, d_selectedColumnInd, 'this')" :style="'background-color: ' + p_polestarSettings.style.backgroundColor + '; color: ' + p_polestarSettings.style.color">
                      <option value="numeric">sayısal olarak kullan (numeric)</option>
                      <option value="string">yazı formatında kullan (string)</option>
                    </b-form-select>
                  </b-col>
                </b-row>
                <template v-if="column_list[d_selectedColumnInd].numeric_string && column_list[d_selectedColumnInd].numeric_string === 'numeric'">
                  <b-row>
                    <b-col cols="12">
                      <img src="@/icon/2521497.png" style="width: 2em;"> Sayısal veri kullanım tipi
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="1"> </b-col>
                    <b-col cols="11">
                      <b-form-select v-model="column_list[d_selectedColumnInd].numeric_usage" :style="'background-color: ' + p_polestarSettings.style.backgroundColor + '; color: ' + p_polestarSettings.style.color">
                        <option value="mean">Ortalamasını al</option>
                        <option value="median">Ortanca (medyan) değerini al</option>
                        <option value="max">En büyük sayı</option>
                        <option value="min">En küçük sayı</option>
                        <option value="sum">Tümünü topla</option>
                      </b-form-select>
                    </b-col>
                  </b-row>
                </template>
                <template v-if="column_list[d_selectedColumnInd].numeric_string && column_list[d_selectedColumnInd].numeric_string === 'string'">
                  <b-row>
                    <b-col cols="12">
                      <img src="@/icon/2521497.png" style="width: 2em;"> Yazı formatında veri tipi
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="1"> </b-col>
                    <b-col cols="11">
                      <b-form-select v-model="column_list[d_selectedColumnInd].string_usage" @change="f_controlColumnFeatures(column_list, d_selectedColumnInd, 'this')" :style="'background-color: ' + p_polestarSettings.style.backgroundColor + '; color: ' + p_polestarSettings.style.color">
                        <option value="0">İlk tespit edilen kaydı al (tarihi en geçmiş olan)</option>
                        <option value="1">Son tespit edilen kaydı al (tarihi en güncel olan)</option>
                        <option value="2">Tüm tespit edilen kayıtları al</option>
                      </b-form-select>
                    </b-col>
                  </b-row>
                </template>
                <template v-if="column_list[d_selectedColumnInd].string_usage && column_list[d_selectedColumnInd].string_usage === '2'">
                  <b-row>
                    <b-col cols="12">
                      <img src="@/icon/2521497.png" style="width: 2em;">
                      <span id="multi_wdmr_approach"> Veri çoklamalarına yaklaşım </span>
                      <b-popover :target="'multi_wdmr_approach'" triggers="hover focus">
                        <template slot="title">Wanalyzer</template>
                        (örn: birden fazla laboratuar ve kreatinin değeri var. Tarih ilişkisi kontrolü var ise önce ona bakılır.)
                      </b-popover>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="1"> </b-col>
                    <b-col cols="11">
                      <b-form-select v-model="column_list[d_selectedColumnInd].multi_wdmr_approach" :style="'background-color: ' + p_polestarSettings.style.backgroundColor + '; color: ' + p_polestarSettings.style.color">
                        <option value="0">Tüm kayıtları tek bir kayıt olarak kabul et</option>
                        <option value="1">Her bir kaydı ayrı kabul et. Kayıtları [] ile ayır</option>
                      </b-form-select>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <img src="@/icon/2521497.png" style="width: 2em;">
                      <span id="list_creation_approach"> Aynı değerlere yaklaşım </span>
                      <b-popover :target="'list_creation_approach'" triggers="hover focus">
                        <template slot="title">Wanalyzer</template>
                        Bir kayıt içerisinde (wdmr) birden fazla aynı alan mevcut ise liste oluşturma yaklaşımı (ör: patolojide materyal tipi biyopsi, mastektomi,...)
                      </b-popover>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="1"> </b-col>
                    <b-col cols="11">
                      <b-form-select v-model="column_list[d_selectedColumnInd].list_creation_approach" :style="'background-color: ' + p_polestarSettings.style.backgroundColor + '; color: ' + p_polestarSettings.style.color">
                        <option value="0">Aynı değerleri kullanma</option>
                        <option value="1">Aynı değerleri kullan</option>
                      </b-form-select>
                    </b-col>
                  </b-row>
                </template>
              </template>
              <template v-if="column_list[d_selectedColumnInd].type === 'wdm'">
                <b-row>
                  <b-col cols="12">
                    <img src="@/icon/2521497.png" style="width: 2em;">
                    <span id="column_parent_relation"> Kolon Kök İlişkisi Oluşturma </span>
                    <b-popover :target="'column_parent_relation'" triggers="hover focus">
                      <template slot="title">Wanalyzer</template>
                      Bu kolondaki verinin, başka bir kolondaki verinin ilişkili altındaki verileri olması istendiğinde evet olarak seçilir.
                    </b-popover>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="1"> </b-col>
                  <b-col cols="11">
                    <b-form-select v-model="column_list[d_selectedColumnInd].parent_column_relation_type" :style="'background-color: ' + p_polestarSettings.style.backgroundColor + '; color: ' + p_polestarSettings.style.color">
                      <option value="yes">evet</option>
                      <option value="no">hayır</option>
                    </b-form-select>
                  </b-col>
                </b-row>
                <template v-if="column_list[d_selectedColumnInd].parent_column_relation_type === 'yes'">
                  <b-row>
                    <b-col cols="12">
                      <img src="@/icon/2521497.png" style="width: 2em;">
                      <span id="column_parent_relation_column"> Üst Kök İlişkili Kolon </span>
                      <b-popover :target="'column_parent_relation_column'" triggers="hover focus">
                        <template slot="title">Wanalyzer</template>
                        Bu kolondaki verinin, başka bir kolondaki verinin ilişkili altındaki verileri olması istendiğinde evet olarak seçilir.
                      </b-popover>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="1"></b-col>
                    <b-col cols="11">
                      <b-form-select :id="'resp_col_' + resp_ind" v-model="column_list[d_selectedColumnInd].parent_column_relation_column">
                        <option v-for="(col, col_ind) in column_list" :value="col_ind">{{ col.label }}</option>
                      </b-form-select>
                    </b-col>
                  </b-row>
                </template>
              </template>
              <template v-if="column_list[d_selectedColumnInd].type === 'manuel' && column_list[d_selectedColumnInd].rule_id === undefined">
                <b-row>
                  <b-col cols="12">
                    <img src="@/icon/2521497.png" style="width: 2em;">
                    <span id="data_creation_type">Manuel veri oluşturma şekli</span>
                    <b-popover :target="'data_creation_type'" triggers="hover focus">
                      <template slot="title">Wanalyzer</template>
                      (örn: Select yapısında bir kolon hazırladıysanız ve rastgele seçeneğini seçtiyseniz,
                      <br> ilgili oluşturduğunuz seçeneklerden rastgele atamalar yapar. Göz rengi => Yeşil, Mavi, Kahverengi seçeneklerinden herhangi bir tanesini atar)
                    </b-popover>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="1"> </b-col>
                  <b-col cols="11">
                    <b-form-select v-model="column_list[d_selectedColumnInd].data_creation_type" :style="'background-color: ' + p_polestarSettings.style.backgroundColor + '; color: ' + p_polestarSettings.style.color">
                      <option value="manuel">Manuel</option>
                      <option value="rule">Kolon kuralı & matematiksel algoritma ile</option>
                      <option v-if="['integer', 'float', 'select'].indexOf(column_list[d_selectedColumnInd].param_type) !== -1" value="random">Rastgele</option>
                      <option value="machine_learning">Makine Öğrenmesi Modeli Bağla</option>
                      <option value="nlp">Nlp ifadesi bağla</option>
                    </b-form-select>
                  </b-col>
                </b-row>
              </template>

              <template v-if="column_list[d_selectedColumnInd].data_creation_type === 'nlp'">
                
                <b-row>
                  <b-col cols="6">
                    <img src="@/icon/2521497.png" style="width: 2em;"> İfadenin okunacağı kolon
                  </b-col>
                  <b-col cols="6">
                    <b-form-select :id="'nlp_col_'" v-model="column_list[d_selectedColumnInd].nlp_text_use_column">
                      <template v-for="(col, col_ind) in column_list">
                        <option v-if="col_ind !== d_selectedColumnInd" :value="col_ind">{{ col.label }}</option>
                      </template>
                    </b-form-select>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <img src="@/icon/2521497.png" style="width: 2em;"> İfadenin sonuç tipi
                  </b-col>
                  <b-col cols="6">
                    <b-form-select :id="'nlp_col_'" v-model="column_list[d_selectedColumnInd].nlp_result_type">
                      <option value="first_found">ilk tespit edileni al</option>
                      <option value="all_found_list">tüm tespit edilenleri al</option>
                    </b-form-select>
                  </b-col>
                </b-row>

                <b-row v-if="column_list[d_selectedColumnInd].nlp_phrase_id">
                  <b-col cols="6">
                    <img src="@/icon/2521497.png" style="width: 2em;"> İfade id
                  </b-col>
                  <b-col cols="6">
                    {{ column_list[d_selectedColumnInd].nlp_phrase_id }}
                  </b-col>
                </b-row>

                <b-row v-if="column_list[d_selectedColumnInd].nlp_phrase_name">
                  <b-col cols="6">
                    <img src="@/icon/2521497.png" style="width: 2em;"> İfade adı
                  </b-col>
                  <b-col cols="6">
                    {{ column_list[d_selectedColumnInd].nlp_phrase_name }}
                  </b-col>
                </b-row>

                <b-row v-if="column_list[d_selectedColumnInd].nlp_layer_type">
                  <b-col cols="6">
                    <img src="@/icon/2521497.png" style="width: 2em;"> İfade kullanıcı tipi
                  </b-col>
                  <b-col cols="6">
                    {{ column_list[d_selectedColumnInd].nlp_layer_type }}
                  </b-col>
                </b-row>
              </template>



            </b-card>

          </template>
          <template v-if="d_navColumnDetails === 'column_options'">
            <template>
              <b-card no-body class="mb-1" header-tag="header" :style="'background-color: ' + p_polestarSettings.style.backgroundColor">
                <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                  <b-row>
                    <b-col cols="2">seçenek yönetimi</b-col>
                    <b-col cols="8"></b-col>
                    <b-col cols="2">
                      <b-button class="pull-right" size="sm" :variant="d_showOptionInfo ?'warning' :'secondary'" :style="d_showOptionInfo ?'margin-right: 5px; padding: 0px; border: solid 2px green;' :'margin-right: 5px; padding: 0px;'" @click="d_showOptionInfo ? d_showOptionInfo = false : d_showOptionInfo = true">
                        <img src="@/icon/487464.png" title="Seçenek bilgilerini göster" style="width: 2em;" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card-header>
                <b-row v-if="column_list[d_selectedColumnInd].data_creation_type === 'manuel' || column_list[d_selectedColumnInd].data_creation_type === 'random'">
                  <b-col cols="3">
                    <img src="@/icon/2521497.png" style="width: 2em;"> Yeni seçenek
                  </b-col>
                  <b-col cols="9">
                    <b-input-group>
                      <b-form-input v-model="d_newOption" :style="'background-color: ' + p_polestarSettings.style.backgroundColor + '; color: ' + p_polestarSettings.style.color"> </b-form-input>
                      <b-input-group-append>
                        <b-button @click="f_addNewOption()"><i class="fa fa-plus"></i></b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3">
                    <img src="@/icon/2521497.png" style="width: 2em;"> Seçenekler
                  </b-col>
                  <b-col cols="9">
                    <template v-if="column_list[d_selectedColumnInd].options && column_list[d_selectedColumnInd].options.length > 0">
                      <div :style="f_calculateStyleColumnOptions()">
                        <template v-for="(op, op_ind) in column_list[d_selectedColumnInd].options">
                          <b-row style="margin: 0px; border: 1px solid rgba(158, 193, 179, 0.3);">
                            <b-col cols="2" style="padding: 3px;">
                              <b-dropdown v-if="column_list[d_selectedColumnInd].data_creation_type === 'manuel' || column_list[d_selectedColumnInd].data_creation_type === 'random'" class="pull-right" variant="secondary" size="sm">
                                <template v-slot:button-content>
                                  <img src="@/icon/1115721.png" title="işlemler" style="width: 2em;" />
                                </template>
                                <b-dropdown-item v-if="column_list[d_selectedColumnInd].rule_id === undefined" @click="f_deleteOption(d_selectedColumnInd, op_ind)"> <i class="fa fa-trash"></i> seçeneği sil </b-dropdown-item>
                                <!-- <b-dropdown-item @click="f_openTextAnalyzeOnWdmrsMatchModal(d_selectedColumnInd, op_ind)" title="wdmr ifade analizleri üzerinden eşleştirme işlemi sağlanır"><i class="fa fa-edit"></i> wdmr ifade algoritma sonucu eşleştir </b-dropdown-item> -->
                                <b-dropdown-item v-if="column_list[d_selectedColumnInd].rule_id === undefined" @click="f_matchToPhraseWdmrModal(d_selectedColumnInd, op_ind)" title="İfade çalışmasına eşleştir"><i class="fa fa-edit"></i> ifade çalışmasına eşleştir </b-dropdown-item>
                                <b-dropdown-item v-if="column_list[d_selectedColumnInd].rule_id === undefined" @click="f_columnOptionRuleShow(op_ind)" title="tüm kolonlardaki bilgiler üzerinden algoritma kurulması sağlanır"><i class="fa fa-edit"></i> kolon kural algoritması </b-dropdown-item>
                                <b-dropdown-item v-if="column_list[d_selectedColumnInd].rule_id === undefined" @click="f_copyThisOption(op_ind)" title="Seçeneği kopyala"><i class="fa fa-edit"></i> seçeneği kopyala </b-dropdown-item>
                                <b-dropdown-item @click="f_addThisOptionToAllCells(op_ind)" title="seçeneği hücrelere ata"><i class="fa fa-edit"></i> seçeneği hücrelere ata </b-dropdown-item>
                              </b-dropdown>
                            </b-col>
                            <b-col cols="5" style="align-items: center; display: flex;">
                              <strong> {{ op_ind + 1 }}) </strong> {{ op.label }}
                            </b-col>
                            <b-col cols="5">
                              <template v-if="op.any_of_this_regx_in_wdmr_rule_data && op.any_of_this_regx_in_wdmr_rule_data.length > 0 ">
                                <b-button v-b-toggle="'any_of_this_regx_in_wdmr_rule_data-' + op_ind" size="sm" variant="primary" style="margin-left: 15px;">
                                  {{ op.any_of_this_regx_in_wdmr_rule_data.length }} adet match
                                </b-button>
                              </template>
                              <template v-if="op.rule && op.rule.query_list.length > 0 && op.any_of_this_regx_in_wdmr_rule_data && op.any_of_this_regx_in_wdmr_rule_data.length > 0 ">
                                <span style="background-color: #ffc107; cursor: pointer;" @click="f_changeMixedRuleType(op)">
                                  {{ f_showMixedRuleType(op) }}
                                </span>
                              </template>
                              <template v-if="op.rule && op.rule.query_list.length > 0">
                                <b-button @click="f_columnOptionRuleShow(op_ind)" size="md" variant="white" style="margin-right: 5px;" title="Seçenek kural algoritması oluşturmak için tıklayınız" class="pull-right">
                                  <img src="@/icon/1430869.png" style="width: 2em;">
                                </b-button>
                              </template>
                            </b-col>
                          </b-row>
                          <template v-if="op.any_of_this_regx_in_wdmr_rule_data">
                            <b-collapse :id="'any_of_this_regx_in_wdmr_rule_data-' + op_ind">
                              <b-row v-for="(any, any_ind) in op.any_of_this_regx_in_wdmr_rule_data" style="margin: 0px; border: 1px solid rgba(158, 193, 179, 0.3);">
                                <b-col cols="1" style="padding: 3px;"></b-col>
                                <b-col cols="11" style="align-items: center; display: flex;">
                                  {{ any }}
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </template>
                          <template v-if="op.phrase_match">
                            <b-row style="margin: 0px; border: 1px solid rgba(158, 193, 179, 0.3);">
                              <b-col cols="2">
                              </b-col>
                              <b-col cols="10" style="color: blue;">
                                <strong> Eşleştirilen ifade çalışması = </strong> {{ op.phrase_match.label }}
                              </b-col>
                            </b-row>
                          </template>
                          <template v-if="d_showOptionInfo">
                            <b-row style="margin: 0px; border: 1px solid rgba(158, 193, 179, 0.3);">
                              <b-col cols="2">
                                Html bilgi
                              </b-col>
                              <b-col cols="10">
                                <b-textarea v-model="op.info"></b-textarea>
                              </b-col>
                            </b-row>
                          </template>
                          <template v-if="d_showOptionInfo">
                            <b-row style="margin: 0px; border: 1px solid rgba(158, 193, 179, 0.3);">
                              <b-col cols="2">
                                Seçenek üzerine link
                              </b-col>
                              <b-col cols="10">
                                <b-textarea v-model="op.link"></b-textarea>
                              </b-col>
                            </b-row>
                          </template>
                        </template>
                      </div>
                    </template>
                    <template v-else>
                      <span style="color: red;">Henüz seçenek eklenmedi.</span>
                    </template>
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </template>
          <template v-if="d_navColumnDetails === 'data_creation_type_rule'">
            <b-card no-body class="mb-1" header-tag="header" key="data_creation_rule_info">
              <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                <b-row style="margin: 0px;">
                  <b-col cols="10">
                    <b-badge id="data_creation_type_rule_info" variant="info">i</b-badge>
                    <!-- <i class="fa fa-warning" :id="'param-info_' + param" style="padding-left: 3px; color: red"></i> -->
                    Kolon kuralı ve/veya matematiksel algoritma listesi
                    <b-popover target="data_creation_type_rule_info" triggers="hover focus">
                      <template slot="title">{{ $t('wdm16.1675') }}</template>
                      <span style="color: red;">İşleminizin aktif olabilmesi için kuralınızı yazdıktan sonra, "Kural algoritması ile verileri yeniden oluştur" şeklindeki butonu kolonda seçim yapılıp "kolon düzenlemelerini kaydet" butonuna basılmalıdır.</span>
                      <br>
                      <span style="color: blue;">Kural doğru olan son kural geçerlidir!!!</span>
                    </b-popover>
                  </b-col>
                  <b-col cols="2">
                    <b-button @click="f_addNewDataCreationRule()" variant="success" size="sm" class="pull-right"><i class="fa fa-plus"></i> yeni değer</b-button>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-row>
                <b-col cols="12">
                  <template v-if="column_list[d_selectedColumnInd].data_creation_rule">
                    <div :style="f_calculateStyleRule()">
                      <template v-for="(rule, rule_ind) in column_list[d_selectedColumnInd].data_creation_rule">
                        <b-card no-body class="mb-1" header-tag="header" :key="'data_creation_rule_' + rule_ind">
                          <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
                            <b-row style="margin: 0px;">
                              <b-col cols="8">
                                {{ rule_ind + 1 }}. Doldurulması İstenen Değer
                              </b-col>
                              <b-col cols="4">
                                <b-button @click="f_dataCreationRuleShow(rule_ind)" size="md" variant="white" style="margin-right: 5px;" class="pull-right">
                                  <img src="@/icon/1430869.png" style="width: 1.5em;">
                                </b-button>
                                <b-button @click="f_deleteDataCreationRule(rule_ind)" size="md" variant="white" style="margin-right: 5px;" class="pull-right">
                                  <i class="fa fa-trash"></i>
                                </b-button>
                                <b-button @click="f_copyColumnCreationRule(rule_ind)" size="md" variant="white" style="margin-right: 5px;" class="pull-right">
                                  <img src="@/icon/3082142.png" style="width: 1.5em;" title="Algoritma kopyala">
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-card-header>
                          <b-row>
                            <b-col cols="12">
                              <b-form-textarea v-model="rule.value" placeholder="col3 şeklinde col ve yanında kolonun nosunu ekleyerek işleme dahil edebilirsiniz. Ör: (col3 * 3) / 3.7" rows="7"></b-form-textarea>
                            </b-col>
                          </b-row>
                          <b-card no-body class="mb-1" header-tag="header" key="data_creation_rule_info">
                            <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                              <b-row style="margin: 0px;">
                                <b-col cols="12" v-b-toggle="'maths_' + rule_ind">
                                  Matematiksel Operatörler ve Fonksiyonlar
                                </b-col>
                              </b-row>
                            </b-card-header>
                            <b-collapse :visible="false" :id="'maths_' + rule_ind">
                              <b-row>
                                <b-col cols="12">
                                  <b-form-input type="text" v-model="d_functionSearchText" title="fonksiyon filtrelemek için yazınız..."></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col cols="12">
                                  <div style="width: 100%; height: 300px; overflow-y: auto; overflow-x: hidden;">
                                    <template v-for="(func, func_index) in d_functionList">
                                      <b-row v-if="!d_functionSearchText || func.label.indexOf(d_functionSearchText) !== -1" :title="func.info" v-on:dblclick="f_addToRuleValue(d_selectedColumnInd, rule_ind, func.function)" style="cursor: pointer; border-bottom: solid 1px lightblue; margin: 5px;">
                                        <b-col cols="8"> {{ func.label }} </b-col>
                                        <b-col cols="4">
                                          <b-dropdown class="pull-right" variant="secondary" size="sm">
                                            <template v-slot:button-content>
                                              <img src="@/icon/1115721.png" title="işlemler" style="width: 2em;" />
                                            </template>
                                            <b-dropdown-item @click="f_addToRuleValue(d_selectedColumnInd, rule_ind, func.function)">
                                              <img src="@/icon/2087726.png" style="width: 2em;"> fonksiyonu ekle
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="f_addToRuleValue(d_selectedColumnInd, rule_ind, func.example)">
                                              <img src="@/icon/2087726.png" style="width: 2em;"> fonksiyonu ekle (örnekli)
                                            </b-dropdown-item>
                                          </b-dropdown>
                                        </b-col>
                                      </b-row>
                                    </template>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col cols="12">
                                  <template v-for="(math_value, math_value_ind) in [1,2,3,4,5,6,7,8,9,0,'+', '*', '-', '/', '(', ')', '**']">
                                    <b-button variant="primary" @click="f_addToRuleValue(d_selectedColumnInd, rule_ind, math_value)" style="margin: 3px; width: 50px;">{{ math_value }}</b-button>
                                  </template>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-card>
                          <template v-if="rule.query_list.length > 0">
                            <rule-query :query_list="rule.query_list" :compile_str="rule.compile_str_array" :compile_str_date="rule.compile_str_date" :compile_str_text="rule.compile_str_text" :mode="'view'" :algoritm_height="algoritm_height"></rule-query>
                          </template>
                        </b-card>
                      </template>
                    </div>
                  </template>
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template v-if="d_navColumnDetails === 'data_creation_type_random'">
            <b-card no-body class="mb-1" header-tag="header" key="data_creation_rule_info" style="margin: 5px;">
              <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                <b-row style="margin: 0px;">
                  <b-col cols="12">
                    Rastgele Bilgi Özellikleri
                  </b-col>
                </b-row>
              </b-card-header>
              <template v-if="['integer', 'float'].indexOf(column_list[d_selectedColumnInd].param_type) !== -1">
                <b-row>
                  <b-col cols="12">
                    <img src="@/icon/2521497.png" style="width: 2em;">
                    <span id="minimum_random_value_integer">Minimum rastgele değeri</span>
                    <b-popover :target="'minimum_random_value_integer'" triggers="hover focus">
                      <template slot="title">Wanalyzer</template>
                      Minimum rastgele değeri olarak girdiğiniz değeri de dahil ederek rastgele bir değer ataması yapılır.
                    </b-popover>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="1"> </b-col>
                  <b-col cols="11">
                    <b-form-input type="number" v-model="column_list[d_selectedColumnInd].min_random"> </b-form-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <img src="@/icon/2521497.png" style="width: 2em;">
                    <span id="minimum_random_value_integer">Maksimum rastgele değeri</span>
                    <b-popover :target="'minimum_random_value_integer'" triggers="hover focus">
                      <template slot="title">Wanalyzer</template>
                      Maksimum rastgele değeri olarak girdiğiniz değeri de dahil ederek rastgele bir değer ataması yapılır.
                    </b-popover>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="1"> </b-col>
                  <b-col cols="11">
                    <b-form-input type="number" v-model="column_list[d_selectedColumnInd].max_random"> </b-form-input>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </template>
          <template v-if="d_navColumnDetails === 'column_style_rule'">
            <b-card no-body class="mb-1" header-tag="header" key="column_style_rule">
              <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                <b-row style="margin: 0px;">
                  <b-col cols="10">
                    <b-badge id="column_style_rule_info" variant="info">i</b-badge>
                    <!-- <i class="fa fa-warning" :id="'param-info_' + param" style="padding-left: 3px; color: red"></i> -->
                    Kolon stilini kuralla oluştur
                    <b-popover target="column_style_rule_info" triggers="hover focus">
                      <template slot="title">{{ $t('wdm16.1675') }}</template>
                      <span style="color: red;">İşleminizin aktif olabilmesi için "kolon düzenlemelerini kaydet" butonuna basılmalıdır.</span>
                      <br>
                      <span style="color: blue;">Kural doğru olan son kural geçerlidir!!!</span>
                    </b-popover>
                  </b-col>
                  <b-col cols="2">
                    <b-button @click="f_addNewColumnStyleRule()" variant="success" size="sm" class="pull-right"><i class="fa fa-plus"></i>yeni</b-button>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-row>
                <b-col cols="12">
                  <template v-if="column_list[d_selectedColumnInd].style_rule">
                    <div :style="f_calculateStyleRule()">
                      <template v-for="(rule, rule_ind) in column_list[d_selectedColumnInd].style_rule">
                        <b-card no-body class="mb-1" header-tag="header" :key="'style_rule_' + rule_ind">
                          <b-card-header header-bg-variant="success" class="p-1" header-text-variant="dark">
                            <b-row style="margin: 0px;">
                              <b-col cols="8">
                                {{ rule_ind + 1 }}. Stil kuralı
                              </b-col>
                              <b-col cols="4">
                                <b-button @click="f_styleRuleShow(rule_ind)" size="md" variant="secondary" style="margin-right: 5px;" class="pull-right"><img src="@/icon/1430869.png" style="width: 1.5em;"></b-button>
                                <b-button @click="f_deleteStyleRule(rule_ind)" size="md" variant="danger" style="margin-right: 5px;" class="pull-right"><i class="fa fa-trash"></i></b-button>
                              </b-col>
                            </b-row>
                          </b-card-header>
                          <b-row style="border-bottom: solid 1px #a4eeff;">
                            <b-col cols="6">Arka plan rengi</b-col>
                            <b-col cols="6">
                              <input style="border: 0px; padding: 0px; margin-right: 5px;" type="color" v-model="rule.style.backgroundColor">
                            </b-col>
                          </b-row>
                          <b-row style="border-bottom: solid 1px #a4eeff;">
                            <b-col cols="6">Metin rengi</b-col>
                            <b-col cols="6">
                              <input style="border: 0px; padding: 0px; margin-right: 5px;" type="color" v-model="rule.style.color">
                            </b-col>
                          </b-row>
                          <b-row style="border-bottom: solid 1px #a4eeff;">
                            <b-col cols="6">Metin Boyutu</b-col>
                            <b-col cols="6">
                              <input style="border: 0px; padding: 0px; margin-right: 5px;" type="number" placeholder="boyut yazınız" v-model="rule.style.fontSize">
                            </b-col>
                          </b-row>
                          <b-row style="border-bottom: solid 1px #a4eeff;">
                            <b-col cols="6">WAI ekrandaki yeri</b-col>
                            <b-col cols="6">
                              <select v-model="rule.style.waiLocation">
                                <option value="right_any_location">sağ çıktı alanı herhangi bir sıra</option>
                                <option value="bottom_as_card">altta kart</option>
                              </select>
                            </b-col>
                          </b-row>
                          <template v-if="rule.query_list.length > 0">
                            <rule-query :query_list="rule.query_list" :compile_str="rule.compile_str_array" :compile_str_date="rule.compile_str_date" :compile_str_text="rule.compile_str_text" :mode="'view'" :algoritm_height="algoritm_height"></rule-query>
                          </template>
                        </b-card>
                      </template>
                    </div>
                  </template>
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template v-if="d_navColumnDetails === 'walgorithm_response_param_design'">
            <b-card no-body class="mb-1" header-tag="header" key="walgorithm_response_param_design">
              <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                <b-row style="margin: 0px;">
                  <b-col cols="8">
                    API yanıtı için parametre tanımlayın
                  </b-col>
                  <b-col cols="4">
                    <b-button @click="f_openAlgorithmWalgoResponseParametersModal()" variant="success" size="sm" class="pull-right"><i class="fa fa-plus"></i> ekle / düzenle</b-button>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-row>
                <b-col cols="12">
                  <b-card no-body class="mb-1" header-tag="header">
                    <b-card-header header-bg-variant="success" class="p-1" header-text-variant="dark">
                      <b-row style="margin: 0px;">
                        <b-col cols="12">
                          Api Ayarları
                        </b-col>
                      </b-row>
                    </b-card-header>
                    <b-row style="margin: 0px;">
                      <b-col cols="5">
                        <strong>Algoritma Bilgilerini Ekleme</strong>
                      </b-col>
                      <b-col cols="7">
                        <b-form-select v-model="column_list[d_selectedColumnInd].add_algorithm_info" title="Evet olarak seçim yapılmadığı takdirde algoritma mesajı ve diğer bilgilerini apiye eklemez.">
                          <option value="yes">evet</option>
                          <option value="no">hayır</option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <template v-if="column_list[d_selectedColumnInd].walgo_response && column_list[d_selectedColumnInd].walgo_response.length > 0">
                    <b-card no-body class="mb-1" header-tag="header" :key="'walgo_response'">
                      <b-card-header header-bg-variant="success" class="p-1" header-text-variant="dark">
                        <b-row style="margin: 0px;">
                          <b-col cols="2">
                          </b-col>
                          <b-col cols="5">
                            key
                          </b-col>
                          <b-col cols="5">
                            kolon hücre verisi
                          </b-col>
                        </b-row>
                      </b-card-header>
                      <template v-for="(resp, resp_ind) in column_list[d_selectedColumnInd].walgo_response">
                        <b-row style="margin: 0px;">
                          <b-col cols="5">
                            <strong>{{ resp.key }}</strong>
                          </b-col>
                          <b-col cols="5">
                            <strong v-if="column_list[resp.col]">{{ column_list[resp.col].label }}</strong>
                          </b-col>
                        </b-row>
                      </template>
                    </b-card>
                  </template>
                  <template v-else>
                    <span>Api ile gönderilecek kolonlar ve değişkenleri yok.</span>
                  </template>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <template v-if="column_list[d_selectedColumnInd].walgo_response_static && column_list[d_selectedColumnInd].walgo_response_static.length > 0">
                    <b-card no-body class="mb-1" header-tag="header" :key="'walgo_response_static'">
                      <b-card-header header-bg-variant="success" class="p-1" header-text-variant="dark">
                        <b-row style="margin: 0px;">
                          <b-col cols="2">
                          </b-col>
                          <b-col cols="5">
                            key
                          </b-col>
                          <b-col cols="5">
                            değer
                          </b-col>
                        </b-row>
                      </b-card-header>
                      <template v-for="(resp, resp_ind) in column_list[d_selectedColumnInd].walgo_response_static">
                        <b-row style="margin: 0px;">
                          <b-col cols="5">
                            <strong>{{ resp.key }}</strong>
                          </b-col>
                          <b-col cols="5">
                            <strong>{{ resp.value }}</strong>
                          </b-col>
                        </b-row>
                      </template>
                    </b-card>
                  </template>
                  <template v-else>
                    <span>Api ile gönderilecek sabit değişkenler yok.</span>
                  </template>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-card no-body class="mb-1" header-tag="header">
                    <b-card-header header-bg-variant="success" class="p-1" header-text-variant="dark">
                      <b-row style="margin: 0px;">
                        <b-col cols="8"> Api yanıt JSON formatı </b-col>
                        <b-col cols="4">
                          <b-button @click="f_previewJsonFormat(d_selectedColumnInd)" variant="success" size="sm" class="pull-right">Api yanıtı önizle</b-button>
                        </b-col>
                      </b-row>
                    </b-card-header>
                    <template v-if="column_list[d_selectedColumnInd].api_row_type">
                      <template v-for="(row_type, row_type_ind) in api_row_type_list">
                        <div :style="column_list[d_selectedColumnInd].api_row_type === row_type.value ? 'color: blue; font-weight: bold; cursor: pointer;border-bottom: solid 1px blue; margin: 5px; padding: 3px;' : 'cursor: pointer;border-bottom: solid 1px blue; margin: 5px; padding: 3px;'" @click="f_selectThisApiRowType(d_selectedColumnInd, row_type)">
                          <i v-if="column_list[d_selectedColumnInd].api_row_type === row_type.value" class="fa fa-check"></i> {{ row_type.label }}
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <template v-for="(row_type, row_type_ind) in api_row_type_list">
                        <div :style="row_type.default ? 'color: blue; font-weight: bold; cursor: pointer; border-bottom: solid 1px blue; margin: 5px; padding: 3px;' : 'cursor: pointer;border-bottom: solid 1px blue; margin: 5px; padding: 3px;'" @click="f_selectThisApiRowType(d_selectedColumnInd, row_type)">
                          <i v-if="row_type.default" class="fa fa-check"></i> {{ row_type.label }}
                        </div>
                      </template>
                    </template>
                    <!-- For showing json format -->
                    <template v-if="column_list[d_selectedColumnInd].api_row_type">
                      <template v-for="(row_type, row_type_ind) in api_row_type_list">
                        <div v-if="column_list[d_selectedColumnInd].api_row_type === row_type.value" style="overflow-y: auto; height: 300px; white-space: pre; color: #2aab8b; background: black; " contenteditable="true">
                          {{ f_getJsonStringify(row_type.json_format) }}
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <template v-for="(row_type, row_type_ind) in api_row_type_list">
                        <div v-if="row_type.default" style="overflow-y: auto; height: 300px; white-space: pre; color: #2aab8b; background: black; " contenteditable="true">
                          {{ f_getJsonStringify(row_type.json_format) }}
                        </div>
                      </template>
                    </template>
                  </b-card>
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template v-if="d_navColumnDetails === 'walgorithm_wdm308'">
            <b-card no-body class="mb-1" header-tag="header" key="walgorithm_wdm308">
              <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                <b-row style="margin: 0px;">
                  <b-col cols="9">
                    <b-badge id="walgorithm_response_param_design_info" variant="info">i</b-badge>
                    <!-- <i class="fa fa-warning" :id="'param-info_' + param" style="padding-left: 3px; color: red"></i> -->
                    API Dokümanı Kaydet/Düzenle
                    <b-popover target="walgorithm_response_param_design_info" triggers="hover focus">
                      <template slot="title">{{ $t('wdm16.1675') }}</template>
                      <span style="color: red;">API dokümanınız uygun düzenlenmez ise api üzerinden gönderim işlemi gerçekleştirilemeyecektir</span>
                    </b-popover>
                  </b-col>
                  <b-col cols="3">
                    <template v-if="column_list[d_selectedColumnInd].wdm308_key && column_list[d_selectedColumnInd].wdm308_key !== ''">
                      <b-button @click="f_showWalgorithmColumnApiModal()" variant="success" size="sm" class="pull-right"><i class="fa fa-plus"></i> düzenle</b-button>
                    </template>
                    <template v-else>
                      <b-button @click="f_showWalgorithmColumnApiModal()" variant="success" size="sm" class="pull-right"><i class="fa fa-plus"></i> yeni</b-button>
                    </template>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-row>
                <b-col cols="12">
                  <template v-if="d_showWdm308Data">
                    <wisdom-data-show :change="'0'" :view_mode="'table'" :data="d_wdm308Data" :data_type="'wdm308'" :option_data="d_wdm308OptionData.wdm308"></wisdom-data-show>
                  </template>
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template v-if="d_navColumnDetails === 'machine_learning'">
            <b-card no-body class="mb-1" header-tag="header" key="machine_learning">
              <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                <b-row style="margin: 0px;">
                  <b-col cols="9"></b-col>
                  <b-col cols="3">
                    <b-dropdown class="pull-right" variant="secondary" size="sm">
                      <template v-slot:button-content>
                        <img src="@/icon/1115721.png" title="işlemler" style="width: 2em;" />
                      </template>
                      <b-dropdown-item @click="f_connectMachineLearningModel('this_project')"> <img src="@/icon/2087726.png" style="width: 2em;"> modeller bu proje </b-dropdown-item>
                      <b-dropdown-item @click="f_connectMachineLearningModel('all_projects')"> <img src="@/icon/2087726.png" style="width: 2em;"> modeller tüm projeler </b-dropdown-item>
                      <b-dropdown-item v-if="d_columnMachineLearningDataWdm163 && d_columnMachineLearningDataWdm163.data && d_columnMachineLearningDataWdm163.analyze_result && d_columnMachineLearningDataWdm163.analyze_result.length > 0" @click="f_connectMatchColumnsToInputVariables()"> <img src="@/icon/2087726.png" style="width: 2em;"> girdi kolonlarını seç </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-row v-if="column_list[d_selectedColumnInd].ml_input_match_column_data && column_list[d_selectedColumnInd].ml_input_match_column_data.list && column_list[d_selectedColumnInd].ml_input_match_column_data.list.length > 0" style="margin: 0px;">
                <b-col cols="12" style="border-bottom: solid 1px black;">
                  <strong> Girdi Eşleştirme Kolonları </strong>
                  <small>(solda model => sağda bu projedeki eşleştirmeleri)</small>
                </b-col>
                <b-col cols="12">
                  <b-row v-for="(match_item, match_item_ind) in column_list[d_selectedColumnInd].ml_input_match_column_data.list" style="margin: 0px; border-bottom: solid 1px #949494;">
                    <b-col cols="1">
                      <strong>{{ match_item_ind + 1 }} - </strong>
                    </b-col>
                    <b-col cols="5">
                      {{ match_item.name }} =>
                    </b-col>
                    <b-col cols="6">
                      <span v-if="column_list[match_item.selected_column]">
                        {{ column_list[match_item.selected_column].label }}
                      </span>
                    </b-col>
                    <b-col cols="12">
                      <b-row v-for="(match_item_option, match_item_option_ind) in match_item.options">
                        <b-col cols="2">
                        </b-col>
                        <b-col cols="10">
                          {{ match_item_option.option_label }} => {{ match_item_option.matched_option.label }}
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row v-if="d_columnMachineLearningDataWdm163 && d_columnMachineLearningDataWdm163.data" style="margin: 0px;">
                <b-col cols="12" style="border-bottom: solid 1px black;">
                  <strong>Makine Öğrenmesi Modeli</strong>
                </b-col>
                <b-col cols="12">
                  <wisdom-data-show :change="change" :view_mode="'table'" :data="d_columnMachineLearningDataWdm163" :data_type="'wdm163'" :option_data="option_data['wdm163']"></wisdom-data-show>
                </b-col>
              </b-row>
              <b-row v-if="d_columnMachineLearningDataWdm163 && d_columnMachineLearningDataWdm163.data && d_columnMachineLearningDataWdm163.analyze_result && d_columnMachineLearningDataWdm163.analyze_result.length > 0" style="margin: 0px;">
                <b-col cols="12">
                  <machine-learning-result :p_machineLearningResult="d_columnMachineLearningDataWdm163.analyze_result"></machine-learning-result>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-col>
      </b-row>
    </b-card>
    <modal v-if="d_dataCreationRuleModal" @close="d_dataCreationRuleModal = false" :width="'1500'">
      <h3 slot="header">Kolona ait kural algoritması</h3>
      <div slot="body">
        <wdm-rule-a :data_type="d_dataCreationRule.data_type" :option_data="d_dataCreationRule.option_data" :prepare_data="d_dataCreationRule.prepare_data" :query_list="d_dataCreationRule.query_list" :compile_str_date="d_dataCreationRule.compile_str_date" :compile_str_array="d_dataCreationRule.compile_str_array" :wdmr_change="d_dataCreationRule.wdmr_change" :p_wdmList="d_dataCreationRule.wdm_list" :mother_children="mother_children" :p_wdmParameterSelectData="d_dataCreationRule.wps_data" :p_wdmRuleAsettings="d_dataCreationRule.wra_settings" :compile_str_text="d_dataCreationRule.compile_str_text"></wdm-rule-a>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" @click="f_addEditDataCreationRule()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_dataCreationRuleModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_styleRuleModal" @close="d_styleRuleModal = false" :width="'1500'">
      <h3 slot="header">Kolona ait stil kural algoritması</h3>
      <div slot="body">
        <wdm-rule-a :data_type="d_styleRule.data_type" :option_data="d_styleRule.option_data" :prepare_data="d_styleRule.prepare_data" :query_list="d_styleRule.query_list" :compile_str_date="d_styleRule.compile_str_date" :compile_str_array="d_styleRule.compile_str_array" :wdmr_change="d_styleRule.wdmr_change" :p_wdmList="d_styleRule.wdm_list" :mother_children="mother_children" :p_wdmParameterSelectData="d_styleRule.wps_data" :p_wdmRuleAsettings="d_styleRule.wra_settings" :compile_str_text="d_styleRule.compile_str_text"></wdm-rule-a>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" @click="f_addEditStyleRule()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_styleRuleModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_columnOptionRuleModal" @close="d_columnOptionRuleModal = false" :width="'1500'">
      <h3 slot="header">Kolon seçeneğine ait kural algoritması</h3>
      <div slot="body">
        <wdm-rule-a :data_type="d_columnOptionRule.data_type" :option_data="d_columnOptionRule.option_data" :prepare_data="d_columnOptionRule.prepare_data" :query_list="d_columnOptionRule.query_list" :compile_str_date="d_columnOptionRule.compile_str_date" :compile_str_array="d_columnOptionRule.compile_str_array" :wdmr_change="d_columnOptionRule.wdmr_change" :p_wdmList="d_columnOptionRule.wdm_list" :mother_children="mother_children" :p_wdmParameterSelectData="d_columnOptionRule.wps_data" :p_wdmRuleAsettings="d_columnOptionRule.wra_settings" :compile_str_text="d_columnOptionRule.compile_str_text"></wdm-rule-a>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" @click="f_addEditColumnOptionRule()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_columnOptionRuleModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showColumnRuleModal" @close="d_showColumnRuleModal = false" :width="'1000'">
      <h3 slot="header"></h3>
      <div slot="body">
        <wdm-rule-a :data_type="d_columnRule.data_type" :option_data="d_columnRule.option_data" :prepare_data="d_columnRule.prepare_data" :query_list="d_columnRule.query_list" :compile_str_array="d_columnRule.compile_str_array" :compile_str_date="d_columnRule.compile_str_date" :wdmr_change="d_columnRule.wdmr_change" :p_wdmList="d_columnRule.wdm_list" :mother_children="mother_children" :p_wdmParameterSelectData="d_columnRule.wps_data" :p_wdmRuleAsettings="d_columnRule.wra_settings" :polestar="true" :p_isPolestarColumn="true" :compile_str_text="d_columnRule.compile_str_text"></wdm-rule-a>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" @click="f_addEditColumnRule()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_showColumnRuleModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showTextToSearchRuleModal" @close="d_showTextToSearchRuleModal = false" :width="'1000'">
      <h3 slot="header"></h3>
      <div slot="body">
        <wdm-rule-a :data_type="d_columnRule.data_type" :option_data="d_columnRule.option_data" :prepare_data="d_columnRule.prepare_data" :query_list="d_columnRule.query_list" :compile_str_array="d_columnRule.compile_str_array" :compile_str_date="d_columnRule.compile_str_date" :wdmr_change="d_columnRule.wdmr_change" :p_wdmList="d_columnRule.wdm_list" :mother_children="mother_children" :p_wdmParameterSelectData="d_columnRule.wps_data" :p_wdmRuleAsettings="d_columnRule.wra_settings" :polestar="true" :p_isPolestarColumn="false" :compile_str_text="d_columnRule.compile_str_text"></wdm-rule-a>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" @click="f_addEditTextToSearchRule()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_showTextToSearchRuleModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <b-modal v-if="d_columnSettingsFilter_show" id="d_columnSettings_filter_show" v-model="d_columnSettingsFilter_show" title="Wdm Filtreleme" centered header-bg-variant="info" header-text-variant="dark" hide-footer>
      <b-row>
        <b-col cols="12">
          <b-form-checkbox-group v-model="d_columnSettings.filter_wdm_list" stacked>
            <b-form-checkbox v-for="(opt, opt_ind) in d_projectWdmList" :key="'d_projectWdmList_' + opt_ind" :value="opt"> {{ opt.label }} </b-form-checkbox>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
    </b-modal>
    <!--  -->
    <modal v-if="d_wdmrRuleAnalyzeResultWdmrShow.show" @close="d_textAnalyzeOnWdmrsMatchOptionData.show = true; d_wdmrRuleAnalyzeResultWdmrShow.show = false" :width="'1500'">
      <h3 slot="header">İfade analizi yapılan WDMR verileri</h3>
      <div slot="body">
        <b-row>
          <b-col cols="3">
            <b-row v-for="(wdmr_info, wdmr_info_ind) in d_wdmrRuleAnalyzeResultWdmrShow.wdmr_list" :key="'d_wdmrRuleAnalyzeResultWdmrShow_' + wdmr_info_ind">
              <b-col cols="12" :style="f_calculatedWdmrInfoStyle(wdmr_info_ind)" @click="f_showSelectedWdmrOnAnalyzeResult(wdmr_info, wdmr_info_ind)">
                {{ wdmr_info.type }}, {{ wdmr_info.label }}
                <img src="@/icon/2940638.png" style="width: 2em;" class="pull-right">
              </b-col>
            </b-row>
          </b-col>
          <b-col v-if="d_wdmrRuleAnalyzeResultWdmrShow.selected_wdmr.show" cols="9">
            <wisdom-data-show :change="'0'" :view_mode="'table'" :data="d_wdmrRuleAnalyzeResultWdmrShow.selected_wdmr.data" :data_type="'wdm3'" :option_data="d_wdmrRuleAnalyzeResultWdmrShow.selected_wdmr.option_data"></wisdom-data-show>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <!-- <button type="button" class="btn btn-success" @click="f_addEditDataCreationRule()">{{ $t('wdm16.4061') }}</button> -->
        <button type="button" class="btn btn-danger" @click="d_textAnalyzeOnWdmrsMatchOptionData.show = true; d_wdmrRuleAnalyzeResultWdmrShow.show = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showWalgorithmColumnApiModal" @close="d_showWalgorithmColumnApiModal = false" :width="'750'">
      <h3 slot="header">API Düzenleme Ekranı</h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="d_wdm308Data" :data_type="'wdm308'" :option_data="d_wdm308OptionData" :rule_engine="false" :query_list="[]"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveWdm308()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_showWalgorithmColumnApiModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <b-modal v-if="d_newOptionData.show" :title="'Yeni seçenek'" centered class="modal-success" v-model="d_newOptionData.show" @ok="d_newOptionData.show = false" ok-variant="success" hide-footer>
      <b-row>
        <b-col cols="12">
          <b-card style="margin-bottom: 5px;" no-body>
            <b-row>
              <b-col cols="12" style="text-align: left;">
                <b-form-input v-model="d_newOption" :style="'background-color: ' + p_polestarSettings.style.backgroundColor + '; color: ' + p_polestarSettings.style.color"> </b-form-input>
              </b-col>
            </b-row>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-row>
              <b-col cols="12" style="text-align: left;">
                <b-button style="width: 100%;" variant="white" @click="f_addNewOptionCopy()">
                  <img src="@/icon/2766751.png" style="width: 2em;" /> kopyalanan seçeneği ekle
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_phraseMatch.show" :title="'İfade çalıştırmasına seçenek eşleştirme ekranı'" centered class="modal-success" v-model="d_phraseMatch.show" @ok="d_phraseMatch.show = false" ok-variant="success" hide-footer>
      <b-row>
        <b-col cols="12">
          <b-card v-for="(phrase, phrase_ind) in d_phraseMatch.list" no-body :style="d_phraseMatch.selected === phrase.key ? 'cursor: pointer; background: #91e0bc; margin-bottom: 5px;' : 'cursor: pointer; margin-bottom: 5px;'">
            <b-row @click="f_selectPhrase(phrase.key)">
              <b-col cols="12" style="text-align: left;">
                {{ phrase.label }}
              </b-col>
            </b-row>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-row>
              <b-col cols="12" style="text-align: left;">
                <b-button style="width: 100%;" variant="white" @click="f_addPhraseToColumnOption()">
                  <img src="@/icon/2766751.png" style="width: 2em;" /> seçilen ifadeyi seçeneğe ekle
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_algorithmColumnWalgoResponse.show && d_algorithmColumnWalgoResponse.col_ind !== '' && column_list[d_algorithmColumnWalgoResponse.col_ind]" :title="'Algoritma kolonu yanıtı (api) için parametre tanımlama ekranı'" centered class="modal-success" v-model="d_algorithmColumnWalgoResponse.show" @ok="d_algorithmColumnWalgoResponse.show = false" ok-variant="success" hide-footer size="xl">
      <b-card no-body class="mb-1" header-tag="header" key="walgorithm_response_param_design">
        <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
          <b-row style="margin: 0px;">
            <b-col cols="6">
              <b-badge id="walgorithm_response_param_design_info" variant="info">i</b-badge>
              <!-- <i class="fa fa-warning" :id="'param-info_' + param" style="padding-left: 3px; color: red"></i> -->
              API yanıtı için parametre tanımlayın
              <b-popover target="walgorithm_response_param_design_info" triggers="hover focus">
                <template slot="title">{{ $t('wdm16.1675') }}</template>
                <span style="color: red;">Keyleriniz alfanumerik ve tekil olarak dizayn edilmelidir.</span>
              </b-popover>
            </b-col>
            <b-col cols="6">
              <b-button @click="f_addNewWalgorithmResponseParam()" variant="success" size="sm" class="pull-right"><i class="fa fa-plus"></i>yeni kolon değişken</b-button>
            </b-col>
          </b-row>
        </b-card-header>
        <b-row>
          <b-col cols="12">
            <b-card v-if="d_algorithmColumnWalgoResponse.walgo_response" no-body class="mb-1" header-tag="header" :key="'walgo_response'">
              <b-card-header header-bg-variant="success" class="p-1" header-text-variant="dark">
                <b-row style="margin: 0px;">
                  <b-col cols="2"> </b-col>
                  <b-col cols="5"> key </b-col>
                  <b-col cols="5"> kolon </b-col>
                </b-row>
              </b-card-header>
              <b-row v-for="(resp, resp_ind) in d_algorithmColumnWalgoResponse.walgo_response" style="margin: 0px;">
                <b-col cols="2">
                  <b-button @click="f_removeWalgorithmResponseParam(resp_ind)" variant="danger" size="sm"><i class="fa fa-trash"></i></b-button>
                </b-col>
                <b-col cols="5">
                  <b-form-input type="text" class="form-control-warning" :id="'resp_key_' + resp_ind" v-model="resp.key" :state="f_checkRespKeyRegex(resp.key) && !f_checkRespKeyDuplicate(resp.key, resp_ind) && f_checkNotEligibleKeys(resp.key)" :formatter="f_formatRespKeyRegex" @input="f_forceUpdate" required></b-form-input>
                </b-col>
                <b-col cols="5">
                  <b-form-select :id="'resp_col_' + resp_ind" v-model="resp.col">
                    <option v-for="(col, col_ind) in column_list" :value="col_ind">{{ col.label }}</option>
                  </b-form-select>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
      <b-card no-body class="mb-1" header-tag="header" key="walgorithm_response_param_design">
        <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
          <b-row style="margin: 0px;">
            <b-col cols="6">
              <b-badge id="walgorithm_response_param_design_info" variant="info">i</b-badge>
              <!-- <i class="fa fa-warning" :id="'param-info_' + param" style="padding-left: 3px; color: red"></i> -->
              API yanıtı için sabit parametre tanımlayın
              <b-popover target="walgorithm_response_param_design_info" triggers="hover focus">
                <template slot="title">{{ $t('wdm16.1675') }}</template>
                <span style="color: red;">Keyleriniz alfanumerik ve tekil olarak dizayn edilmelidir.</span>
              </b-popover>
            </b-col>
            <b-col cols="6">
              <b-button @click="f_addNewWalgorithmResponseStaticParameter()" variant="success" size="sm" class="pull-right"><i class="fa fa-plus"></i>yeni sabit değişken</b-button>
            </b-col>
          </b-row>
        </b-card-header>
        <b-row>
          <b-col cols="12">
            <b-card v-if="d_algorithmColumnWalgoResponse.walgo_response_static" no-body class="mb-1" header-tag="header" :key="'walgo_response_static'">
              <b-card-header header-bg-variant="success" class="p-1" header-text-variant="dark">
                <b-row style="margin: 0px;">
                  <b-col cols="2"> </b-col>
                  <b-col cols="5"> key </b-col>
                  <b-col cols="5"> değer </b-col>
                </b-row>
              </b-card-header>
              <b-row v-for="(resp, resp_ind) in d_algorithmColumnWalgoResponse.walgo_response_static" style="margin: 0px;">
                <b-col cols="2">
                  <b-button @click="f_removeWalgorithmResponseParamStatic(resp_ind)" variant="danger" size="sm"><i class="fa fa-trash"></i></b-button>
                </b-col>
                <b-col cols="5">
                  <b-form-input type="text" class="form-control-warning" :id="'resp_key_' + resp_ind" v-model="resp.key" :state="f_checkRespKeyRegex(resp.key) && !f_checkRespKeyDuplicateStatic(resp.key, resp_ind) && f_checkNotEligibleKeysStatic(resp.key)" :formatter="f_formatRespKeyRegex" @input="f_forceUpdate" required></b-form-input>
                </b-col>
                <b-col cols="5">
                  <b-form-input type="text" class="form-control-warning" :id="'resp_val_' + resp_ind" v-model="resp.value" required></b-form-input>
                  <!-- <b-form-input type="text" class="form-control-warning" :id="'resp_val_' + resp_ind" v-model="resp.value" :state="f_checkRespKeyRegex(resp.value)" :formatter="f_formatRespKeyRegex" @input="f_forceUpdate" required></b-form-input> -->
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <b-button @click="f_updateColumnWalgoResponse()" variant="success" size="sm" class="pull-right"><i class="fa fa-plus"></i>kolonu güncelle</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_apiPreviewJsonFormat.show" title="Api JSON yanıtı" centered class="modal-success" v-model="d_apiPreviewJsonFormat.show" @ok="d_apiPreviewJsonFormat.show = false" ok-variant="success" hide-footer>
      <b-card no-body class="mb-1" header-tag="header">
        <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">Kolondan gelen parametreler</b-card-header>
        <b-row v-if="d_apiPreviewJsonFormat.walgo_response.length > 0" v-for="(resp, resp_ind) in d_apiPreviewJsonFormat.walgo_response" style="margin: 0px;">
          <b-col cols="5">
            <strong>{{ resp.key }}</strong>
          </b-col>
          <b-col cols="5">
            <strong v-if="column_list[resp.col]">{{ column_list[resp.col].label }}</strong>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col cols="12">
            Tanımlı değil
          </b-col>
        </b-row>
      </b-card>
      <b-card no-body class="mb-1" header-tag="header">
        <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">Api dokümanından gelen parametreler</b-card-header>
        <b-row v-if="d_apiPreviewJsonFormat.wdm308_doc.length > 0" style="margin: 0px;" v-for="(resp, resp_ind) in d_apiPreviewJsonFormat.wdm308_doc">
          <b-col cols="5">
            <strong>{{ resp.key }}</strong>
          </b-col>
          <b-col cols="5">
            <strong>{{ resp.value }}</strong>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col cols="12">
            Tanımlı değil
          </b-col>
        </b-row>
      </b-card>
      <b-card no-body class="mb-1" header-tag="header">
        <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">Json formatı</b-card-header>
        <div style="white-space: pre; color: #2aab8b; background: black; " contenteditable="true">
          {{ f_getJsonStringify(d_apiPreviewJsonFormat.preview_json) }}
        </div>
      </b-card>
    </b-modal>
    <b-modal v-if="d_columnFunctions.show && d_columnFunctions.col_ind !== '' && column_list[d_columnFunctions.col_ind]" :title="'Kolon fonksiyonları'" centered class="modal-success" v-model="d_columnFunctions.show" @ok="d_columnFunctions.show = false" ok-variant="success" hide-footer>
      <b-row>
        <b-col cols="12">
          <b-card style="margin-bottom: 5px;" no-body v-if="p_columnSelectionMode">
            <b-row>
              <b-col cols="12" style="text-align: left;">
                <b-button style="width: 100%;" variant="white" @click="f_selectToShowThisColumn(d_columnFunctions.col_ind)">
                  <div v-if="d_tableModeSettings.show_selected_columns.indexOf(d_columnFunctions.col_ind) === -1">
                    <img src="@/icon/688807.png" style="width: 2em;" /> gösterilen listesine ekle
                  </div>
                  <div v-else>
                    <img src="@/icon/2960590.png" style="width: 2em;" /> gösterilen listesinden sil
                  </div>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
          <b-card v-if="!p_columnSelectionMode" style="margin-bottom: 5px;" no-body>
            <b-card v-if="column_list[d_columnFunctions.col_ind].id === undefined" style="margin-bottom: 5px;" no-body>
              <b-row>
                <b-col cols="12" style="text-align: left;">
                  <b-button style="width: 100%;" variant="white" @click="f_deleteStillNotCreatedColumn(d_columnFunctions.col_ind)"> <img src="@/icon/2960590.png" style="width: 2em;" /> sil </b-button>
                </b-col>
              </b-row>
            </b-card>
            <b-card v-else style="margin-bottom: 5px;" no-body>
              <b-row>
                <b-col cols="12" style="text-align: left;">
                  <b-button style="width: 100%;" variant="white" @click="f_deleteControl(d_columnFunctions.col_ind)"> <img src="@/icon/2960590.png" style="width: 2em;" /> sil </b-button>
                </b-col>
              </b-row>
            </b-card>
            <b-card v-if="!column_list[d_columnFunctions.col_ind].rule_id" style="margin-bottom: 5px;" no-body>
              <b-row>
                <b-col cols="12" style="text-align: left;">
                  <b-button style="width: 100%;" variant="white" @click="f_showLayerColumnRuleModal('edit', '', d_columnFunctions.col_ind)">
                    <img src="@/icon/2063326.png" style="width: 2em;" /> wdm kolon kuralı
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
            <b-card style="margin-bottom: 5px;" no-body v-if="column_list[d_columnFunctions.col_ind].data_creation_type && column_list[d_columnFunctions.col_ind].data_creation_type === 'random'">
              <b-row>
                <b-col cols="12" style="text-align: left;">
                  <b-button style="width: 100%;" variant="white" @click="f_randomDataCreateNow(d_columnFunctions.col_ind)">
                    <img src="@/icon/2884572.png" style="width: 2em;" /> rastgele verileri yeniden üret
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
            <b-card style="margin-bottom: 5px;" no-body v-if="column_list[d_columnFunctions.col_ind].data_creation_type && column_list[d_columnFunctions.col_ind].data_creation_type === 'rule'">
              <b-row>
                <b-col cols="12" style="text-align: left;">
                  <b-button style="width: 100%;" variant="white" @click="f_ruleDataCreateNow(d_columnFunctions.col_ind)">
                    <img src="@/icon/1430869.png" style="width: 2em;" /> kural algoritması ile verileri yeniden oluştur
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
            <b-card v-if="!column_list[d_columnFunctions.col_ind].rule_id" style="margin-bottom: 5px;" no-body>
              <b-row>
                <b-col cols="12" style="text-align: left;">
                  <b-button style="width: 100%;" variant="white" @click="f_copyThisColumn(d_columnFunctions.col_ind)">
                    <img src="@/icon/3082142.png" style="width: 2em;" /> kolonu kopyala
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
            <b-card v-if="!column_list[d_columnFunctions.col_ind].rule_id" style="margin-bottom: 5px;" no-body>
              <b-row>
                <b-col cols="12" style="text-align: left;">
                  <b-button style="width: 100%;" variant="white" @click="f_openUpdateSameNameColumnsSelectedProjects(d_columnFunctions.col_ind)">
                    <img src="@/icon/3082142.png" style="width: 2em;" /> aynı adlı kolonu seçili projelerde güncelle
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_machineLearning.show" :title="'Makine Öğrenmesi Modeline Bağlama Ekranı'" size="xl" centered class="modal-success" v-model="d_machineLearning.show" @ok="d_machineLearning.show = false" ok-variant="success" hide-footer>
      <div style="overflow-x: hidden; overflow-y: auto; height: 500px;">
        <b-card style="margin-bottom: 5px;" no-body v-for="(model, model_ind) in d_machineLearning.list" :style="d_machineLearning.selected_index === model_ind ? 'background-color: #8dd9e4;' : 'background-color: white;'" @click="f_selectMachineLearningModelForColumn(model_ind)">
          <b-row>
            <b-col cols="1" style="vertical-align: middle; text-align: center;">
              <strong> {{ model_ind + 1 }} </strong>
            </b-col>
            <b-col cols="11">
              <b-row style="border-bottom: solid 1px #c6c8c9; margin: 0px;">
                <b-col cols="6">
                  <strong>Adı: </strong>
                </b-col>
                <b-col cols="6">
                  {{ model.name }}
                </b-col>
              </b-row>
              <b-row style="border-bottom: solid 1px #c6c8c9; margin: 0px;">
                <b-col cols="6">
                  <strong>Model ID </strong>
                </b-col>
                <b-col cols="6">
                  {{ model.id }}
                </b-col>
              </b-row>
              <b-row style="border-bottom: solid 1px #c6c8c9; margin: 0px;">
                <b-col cols="6">
                  <strong>Geliştirilen Proje Adı: </strong>
                </b-col>
                <b-col cols="6">
                  {{ model.project_name }}
                </b-col>
              </b-row>
              <b-row style="border-bottom: solid 1px #c6c8c9; margin: 0px;">
                <b-col cols="6">
                  <strong>Geliştirilen Proje ID: </strong>
                </b-col>
                <b-col cols="6">
                  {{ model.project_id }}
                </b-col>
              </b-row>
              <b-row style="border-bottom: solid 1px #c6c8c9; margin: 0px;">
                <b-col cols="6">
                  <strong>Proje Dataset Verileri Son Güncelleme Tarihi (Model eğitilirken hangi tarihli güncel veri kullanıldığını gösterir) </strong>
                </b-col>
                <b-col cols="6">
                  {{ model.last_update_time_of_project_cases }}
                </b-col>
              </b-row>
              <b-row style="border-bottom: solid 1px #c6c8c9; margin: 0px;">
                <b-col cols="6">
                  <strong>Model Eğitim Tarihi</strong>
                </b-col>
                <b-col cols="6">
                  {{ model.model_train_date }}
                </b-col>
              </b-row>
              <b-row style="border-bottom: solid 1px #c6c8c9; margin: 0px;">
                <b-col cols="6">
                  <strong>Model Tipi</strong>
                </b-col>
                <b-col cols="6">
                  {{ model.machine_learning_model }}
                </b-col>
              </b-row>
              <b-row style="border-bottom: solid 1px #c6c8c9; margin: 0px;">
                <b-col cols="6">
                  <strong>Toplam datasetteki örnek sayısı</strong>
                </b-col>
                <b-col cols="6">
                  {{ model.case_count_analyze_applied }}
                </b-col>
              </b-row>
              <b-row style="border-bottom: solid 1px #c6c8c9; margin: 0px;">
                <b-col cols="6">
                  <strong>Analiz uygulanan örnek sayısı</strong>
                </b-col>
                <b-col cols="6">
                  {{ model.case_rows_count }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <b-button @click="f_connectThisMachineLearningModelToColumn()" variant="success" size="sm" class="pull-right"><i class="fa fa-plus"></i>kolona bağla</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_columnInputmachineLearningMatch.show" :title="'Makine Öğrenmesi Modeli Girdilerini Düzenleme Ekranı'" size="xl" centered class="modal-success" v-model="d_columnInputmachineLearningMatch.show" @ok="d_columnInputmachineLearningMatch.show = false" ok-variant="success" hide-footer>
      <div style="overflow-x: hidden; overflow-y: auto; height: 500px;">
        <b-card style="margin-bottom: 5px;" no-body v-for="(match_item, match_item_ind) in d_columnInputmachineLearningMatch.list">
          <b-row>
            <b-col cols="6">
              <strong> {{ match_item.name }} </strong>
            </b-col>
            <b-col cols="6">
              <b-form-select v-model="match_item.selected_column">
                <option v-for="(col, col_index) in column_list" :value="col_index"> {{ col.label }} </option>
              </b-form-select>
            </b-col>
          </b-row>
          <b-row v-if="match_item.options">
            <b-col cols="12">
              <b-row v-for="(input_item_option, input_item_option_index) in match_item.options">
                <b-col cols="6">
                  <strong> {{ input_item_option_index }} </strong>
                </b-col>
                <b-col cols="6">
                  <div v-if="match_item.selected_column !== '' && column_list[match_item.selected_column].options && column_list[match_item.selected_column].options.length > 0">
                    <b-form-select v-model="input_item_option.matched_option">
                      <option v-for="(target_option, target_option_index) in column_list[match_item.selected_column].options" :value="target_option"> {{ target_option.label }} </option>
                    </b-form-select>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <b-button @click="f_connectMachineLearningColumnInputUpdate()" variant="success" size="sm" class="pull-right"><i class="fa fa-plus"></i>işlemi tamamla</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_apiList.show" :title="'Api Listesi'" size="xl" centered class="modal-success" v-model="d_apiList.show" @ok="d_apiList.show = false" ok-variant="success" hide-footer>
      <div style="overflow-x: hidden; overflow-y: auto; height: 500px;">
        <b-card style="margin-bottom: 5px;" no-body v-for="(api, api_ind) in d_apiList.list" :style="d_apiList.selected_index === api_ind ? 'background-color: #8dd9e4;' : 'background-color: white;'" @click="f_selectApi(api_ind)">
          <b-row>
            <b-col cols="1" style="vertical-align: middle; text-align: center;">
              <strong> {{ api_ind + 1 }} </strong>
            </b-col>
            <b-col cols="11">
              <b-row style="border-bottom: solid 1px #c6c8c9; margin: 0px;">
                <b-col cols="6">
                  <strong>Adı: </strong>
                </b-col>
                <b-col cols="6">
                  {{ api.name }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <b-button @click="f_addThisApiToThisColumn()" variant="success" size="sm" class="pull-right"><i class="fa fa-plus"></i>kolona bağla</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_wordAnalyzeList.show" :title="'Api Listesi'" size="xl" centered class="modal-success" v-model="d_wordAnalyzeList.show" @ok="d_wordAnalyzeList.show = false" ok-variant="success" hide-footer>
      <b-row>
        <b-col cols="6">
          <strong>Kolon Adı</strong>
        </b-col>
        <b-col cols="6">
          {{ d_wordAnalyzeList.columns[d_wordAnalyzeList.selected_column_index].label }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <strong>tekil kelime adedi:</strong>
        </b-col>
        <b-col cols="6">
          {{ d_wordAnalyzeList.columns[d_wordAnalyzeList.selected_column_index].word_order.length }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <strong>toplam satır:</strong>
        </b-col>
        <b-col cols="6">
          {{ d_wordAnalyzeList.total_rows }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <strong>Toplam kelime adedi</strong>
        </b-col>
        <b-col cols="6">
          {{ d_wordAnalyzeList.total_count }}
        </b-col>
      </b-row>
      <div v-if="d_wordAnalyzeList.selected_word_order.length > 0" style="overflow-x: hidden; overflow-y: auto; height: 300px;">
        <table border="1" align="center" style="width: 100%; table-layout: fixed; overflow-wrap: break-word;">
          <thead>
            <tr>
              <th>Kelime</th>
              <th>Kolon Başlığı</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(word, word_index) in d_wordAnalyzeList.selected_word_order">
              <tr>
                <td> {{ word }} </td>
                <td>
                  <input type="text" v-model="d_wordAnalyzeList.selected_words[word].column_label" style="width: 100%">
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div style="overflow-x: hidden; overflow-y: auto; height: 300px;">
        <table border="1" align="center" style="width: 100%; table-layout: fixed; overflow-wrap: break-word;">
          <thead>
            <tr>
              <th>Kelime</th>
              <th>Adet (tüm kelimelerde)</th>
              <th>Adet (tüm satırlarda)</th>
              <th>Yüzde (%) tüm kelimelerde</th>
              <th>Yüzde (%) tüm satırlarda</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(word, word_index) in d_wordAnalyzeList.columns[d_wordAnalyzeList.selected_column_index].word_order">
              <tr :style="d_wordAnalyzeList.selected_word_order.indexOf(word) !== -1 ? 'background-color: #1bb7ae;' : ''">
                <td> {{ word }} </td>
                <td> {{ d_wordAnalyzeList.columns[d_wordAnalyzeList.selected_column_index].words[word].n }} </td>
                <td> {{ d_wordAnalyzeList.columns[d_wordAnalyzeList.selected_column_index].words[word].r }} </td>
                <td> {{ d_wordAnalyzeList.columns[d_wordAnalyzeList.selected_column_index].words[word].p_n }} </td>
                <td> {{ d_wordAnalyzeList.columns[d_wordAnalyzeList.selected_column_index].words[word].p_r }} </td>
                <td>
                  <b-button size="sm" @click="f_addRemoveThisWord(word)">kolon üretimi</b-button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <b-button @click="f_addOrEditColumnsByIncludingTheWords('new')" variant="success" size="sm" class="pull-right"><i class="fa fa-plus"></i>yeni kolon oluştur</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <modal v-if="d_projectListData.show" @close="d_projectListData.show = false" :width="'1000'">
      <h3 slot="header">Proje Listesi Ekranı</h3>
      <div slot="body">
        <b-row style="margin: 0px;">
          <b-col cols="6">
            <b-card no-body>
              <b-card-header class="p-1">
                <strong>Proje listesi</strong>
                <b-button class="pull-right" size="sm" v-if="d_projectListData.button_column_update_on_other_projects === 'yes'" @click="f_updateSameNameColumnsSelectedProjects()" variant="success">projelerde aynı adlı kolonları güncelle</b-button>
              </b-card-header>
              <b-form-input v-model="d_projectListData.search_text" type="text" placeholder="filtrelemek için yazınız"></b-form-input>
              <div style="overflow-x: hidden; overflow-y: auto; height: 500px;">
                <b-row v-for="(project, project_ind) in d_projectListData.list" :style="d_projectListData.selected_project_ind === project_ind ? 'background-color: #e6fddf; cursor: pointer; padding: 5px; border: 1px solid rgba(158, 193, 179, 0.3); margin: 0px;' : 'cursor: pointer; padding: 5px; border: 1px solid rgba(158, 193, 179, 0.3); margin: 0px;'" v-if="d_projectListData.search_text ? project.name.toLocaleLowerCase().indexOf(d_projectListData.search_text.toLocaleLowerCase()) !== -1 : true">
                  <b-col sm="12" md="1" v-if="d_projectListData.select_mode === 'multi' || d_projectListData.select_mode === 'one_and_multi'">
                    <b-form-checkbox v-model="d_projectListData.selected_project_id_list" :value="project.id"></b-form-checkbox>
                  </b-col>
                  <b-col sm="12" md="11" @click="f_getProjectAndColumnList(project_ind)">
                    <strong>{{ project_ind + 1 }} -</strong>
                    <img src="@/icon/2930471.png" style="width: 1.5em;" /> {{ project.name }},
                    <img src="@/icon/9370.png" style="width: 1.5em;" /> {{ project.owner }}
                    <img src="@/icon/2080590.png" style="width: 1.5em;" /> {{ DateFormat(project.date) }}
                    <template v-if="d_projectListData.results_project_list[project.id] && d_projectListData.results_project_list[project.id].error && d_projectListData.results_project_list[project.id].error.length > 0">
                      <div v-for="(err, err_ind) in d_projectListData.results_project_list[project.id].error" style="color: red;">
                        {{ err }}
                      </div>
                    </template>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card no-body>
              <b-card-header class="p-1">
                <strong>Kolon Listesi</strong>
              </b-card-header>
              <div style="overflow-x: hidden; overflow-y: auto; height: 500px;">
                <b-row v-for="(col, col_ind) in d_projectListData.column_list" :style="d_projectListData.selected_column_ind === col_ind ? 'background-color: #e6fddf; cursor: pointer; padding: 5px; border: 1px solid rgba(158, 193, 179, 0.3); margin: 0px;' : 'cursor: pointer; padding: 5px; border: 1px solid rgba(158, 193, 179, 0.3); margin: 0px;'">
                  <b-col sm="12" md="10">
                    <img v-if="!col.rule_id" src="@/icon/2930471.png" style="width: 1.5em;" />
                    <img v-if="col.rule_id" src="@/icon/1430869.png" style="width: 1.5em;" />
                    <strong>{{ col_ind + 1 }} -</strong>
                    <span :style="col.rule_id === undefined ? '' : 'color: blue;'">{{ col.label }}</span>
                  </b-col>
                  <b-col sm="12" md="2">
                    <b-button v-if="d_projectListData.column_add_button === 'yes'" @click="f_addThisColumn(col_ind)" variant="success">ekle</b-button>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_projectListData.show = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>

    <b-modal v-if="d_nlpData.show" :title="'İfade Yönetim'" size="xl" centered class="modal-success" v-model="d_nlpData.show" @ok="d_nlpData.show = false" ok-variant="success" hide-footer>
      <b-row style="margin: 3px;">
        <b-col cols="12" style="text-align: right;">
          <b-button v-if="d_nlpData.selected_phrase_data" @click="f_bindNlpPhraseToColumn()" variant="outline-primary" size="sm" class="pull-right">
            <i class="fa fa-send"></i> kolona bağla
          </b-button>
        </b-col>
      </b-row>

      <div style="overflow-x: hidden; overflow-y: auto; min-height: 500px;">
        <nlp-phrase :p_nlpData="d_nlpData"></nlp-phrase>
      </div>

    </b-modal>


  </div>
</template>

<script>
import { ServiceFunctions } from '@/wam/modules/service';
import {
  default as MachineLearningResult
} from "@/components/widgets/MachineLearningResult";
import MachineLearningService from '@/services/machine_learning';
import PhraseService from '@/services/phrase';
import PoleStarService from '@/services/polestar';
import ColumnService from '@/services/column';
import LayerService from '@/services/layer';
import { ClsRule } from '@/services/public/rule';
import { ClsPolestar } from "@/services/public/polestar";
import vSelect from 'vue-select';
import GlobalService from '@/services/global';
import { WisdomRegex } from '@/services/public/functions';
import { ClsWdmrForever, ClsWdmrSave, ClsWdm } from "@/services/public/wdm";
import {
  default as RuleCompileStr
} from '@/components/widgets/RuleCompileStr';
import {
  default as RuleQuery
} from '@/components/widgets/RuleQuery';
import {
  default as Modal
} from "@/components/widgets/Modal";
import {
  default as WdmRuleA
} from '@/components/widgets/WdmRuleA';
import {
  default as WTable
} from '@/components/widgets/WTable';
import {
  default as NlpPhrase
} from '@/components/widgets/NlpPhrase';
import {
  default as WdmParameterLocation
} from '@/components/widgets/WdmParameterLocation';
import moment from 'moment';
import { mapGetters } from 'vuex';
import FileSaver from 'file-saver';

export default {
  name: 'PoleStarColumnFeature',
  computed: mapGetters({
    StoreModal: 'modal',
    lang: 'lang'
  }),
  components: {
    Modal,
    WdmParameterLocation,
    WTable,
    WdmRuleA,
    RuleQuery,
    RuleCompileStr,
    vSelect,
    MachineLearningResult,
    NlpPhrase,
    WisdomDataModal: () =>
      import ('@/components/widgets/WisdomDataModal'),
    WisdomDataShow: () =>
      import ('@/components/widgets/WisdomDataShow')
  },
  props: {
    p_selectedProjectData: {
      type: Object,
      required: true
    },
    d_tableModeSettings: {
      type: Object,
      required: false
    },
    column_list: {
      type: Array,
      required: true
    },
    mother: {
      type: String,
      required: false
    },
    column_index: {
      type: Number,
      required: false
    },
    p_columnSelectionMode: {
      type: Boolean,
      required: false
    },
    mother_children: {
      type: Object,
      required: false
    },
    p_polestarSettings: {
      type: Object,
      required: true
    },
    p_userPolestarPermissions: {
      type: Array,
      required: true
    },
    option_data: {
      type: Object,
      required: false
    },
    f_controlAndCreateTimer: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      d_showOptionInfo:  false,
      d_tmpFile:  null,
      d_nlpData: {
        'show': false,
        'col_index': '',
        'selected_phrase_data': '',
        'nlp_phrase_id': '',
        'nlp_layer_type': '',
        'work_text': '',
        'work_text_label': '',
      },
      d_projectListData: {
        'results_project_list': {},
        'target_project_id': '',
        'target_column_id': '',
        'target_column_index': '',
        'button_column_update_on_other_projects': 'no',
        'column_add_button': 'yes',
        'select_mode': 'one',
        'selected_project_id_list': [],
        'list': [],
        'search_text': '',
        'show': false,
        'project_data': '',
        'column_list': [],
        'selected_project_ind': '',
        'selected_column_ind': ''
      },
      d_wordAnalyzeList: {
        'selected_word_order': [],
        'selected_words': {},
        'show': false,
        'columns': '',
        'selected_column_index': '',
        'total_rows': '',
        'total_count': ''
      },
      d_functionSearchText: '',
      d_functionList: require('@/options/function_list').options,
      d_apiList: { 'list': [], 'selected_index': '', 'show': false },
      change: '0',
      d_columnInputmachineLearningMatch: { 'list': [], 'selected_index': '', 'show': false },
      d_columnMachineLearningDataWdm163: '',
      d_machineLearning: { 'list': [], 'selected_index': '', 'show': false },
      d_apiPreviewJsonFormat: { 'show': false, 'preview_json': '', 'walgo_response': [], 'wdm308_doc': [] },
      api_row_type_list: [{
        'label': 'Liste yapısı şeklinde (rows değişkeniyle birlikte)',
        'default': 1,
        'value': '2',
        'json_format': { 'rows': [{ 'algorithm_id': '', 'algorithm_date': '', 'message': '', 'message_image': '', 'message_image_link': '', 'operation_id': '', 'operation_info': '', 'operation_text': '' }], 'dataset_key': '', 'last_dataset': '', 'dataset_finished': '', 'status_code': '2000', 'status_message': '' },
        'preview_json': { 'rows': [{ 'algorithm_id': '', 'algorithm_date': '', 'message': '', 'message_image': '', 'message_image_link': '', 'operation_id': '', 'operation_info': '', 'operation_text': '' }], 'dataset_key': '', 'last_dataset': '', 'dataset_finished': '', 'status_code': '2000', 'status_message': 'successfully responded' }
      }, {
        'label': 'Tek bir satır olarak (Data yapısı ile camelcase yapısında)',
        'value': '1',
        'json_format': { 'Data': { 'AlgorithmDate': '', 'OperationId': '', 'OperationMsg': '', 'OperationHtml': '', 'AlgorithmId': '', 'AlgorithmMsg': '', 'AlgorithmImg': '', 'AlgorithmImgLink': '' }, 'ApiParameter1': '', 'ApiParameter2': '', 'status_code': '2000', 'status_message': '' },
        'preview_json': { 'Data': { 'AlgorithmDate': '', 'OperationId': '', 'OperationMsg': '', 'OperationHtml': '', 'AlgorithmId': '', 'AlgorithmMsg': '', 'AlgorithmImg': '', 'AlgorithmImgLink': '' }, 'status_code': '2000', 'status_message': 'successfully responded' }
      }],
      d_not_eligible_walgo_response_column_parameters: ["message", "message_image", "message_image_link", "algorithm_id", "operation_id", "operation_info", "operation_text", "algorithm_date", "AlgorithmId", "AlgorithmMsg", "AlgorithmImg", "AlgorithmImgLink", "OperationId", "AlgorithmDate", "OperationMsg", "OperationHtml"],
      d_searchText: '',
      d_algorithmColumnWalgoResponse: { 'col_ind': '', 'op_ind': '', 'show': false },
      d_phraseMatch: { 'col_ind': '', 'op_ind': '', 'show': false },
      d_newOptionData: { 'show': '', 'op_ind': '' },
      d_columnFunctions: { 'show': false, 'col_ind': '' },
      d_showWalgorithmColumnApiModal: false,
      d_eligibleForHimsInspectionMatch: false,
      d_eligibleForWdmrRuleMatch: false,
      d_eligibleForColumnOptionRuleMatch: false,
      d_showWaimBadge: false,
      d_columnSettingsFilter_show: false,
      d_divideLeftTwo: false,
      d_showColumnRuleModal: false,
      d_showTextToSearchRuleModal: false,
      d_showWdmParamColumnRule: true,
      d_fullScreenColumnList: false,
      d_columnSettings: {
        'start': 0,
        'end': 100,
        'current': 1,
        'perpage': 100,
        'filter_wdm_list': []
      },
      algoritm_height: 500,
      d_searchColumnText: '',
      d_fullScreen: false,
      d_navColumnList: 'column_list',
      d_navColumnDetails: 'column_general_info',
      d_dataCreationRuleModal: false,
      d_styleRuleModal: false,
      d_columnOptionRuleModal: false,
      d_newOption: '',
      d_selectedDateRelation: { 'col_ind': '', 'op': '' },
      param_type: ['select', 'integer', 'float', 'text', 'date', 'time', 'datetime'],
      d_wdmParamTypeList: ['select', 'integer', 'float', 'text', 'date', 'time', 'datetime', 'textarea', 'object_list_options', 'checkbox', 'radiogroup', 'plain_text'],
      d_showLabelsWdmLocationFormat: false,
      d_selectedColumnInd: '',
      d_columnRule: {},
      d_textToSearchRule: {},
      d_dataCreationRule: {},
      d_wdm308Data: {},
      d_wdm308OptionData: false,
      d_styleRule: {},
      column_rule_operation_type: 'new',
      d_projectWdmList: [],
      d_wdmRuleANew: {
        'date_ability': '',
        'wdmr_change': { 'rule': 0 },
        'data_type': '',
        'option_data': {},
        'prepare_data': {},
        'query_list': [],
        'compile_str_date': [],
        'compile_str_array': [],
        'compile_str_text': [],
        'wra_settings': {
          'mode': 'param',
          'options': [
            { 'label': 'WDM & QUERY', 'value': 'wdm_query' },
            { 'label': 'PARAM', 'value': 'param' },
            { 'label': 'PARAM & QUERY', 'value': 'param_query' }
          ]
        },
        'wps_data': [{
          'operation': '',
          'selected_value': '',
          'loc': [],
          'val_list': [],
          'selected_wdm': '',
          'd_level': 0,
          'd_level_count': 0,
          'significant': 'true'
        }],
        'wdm_list': []
      },
      d_selectedWdmrInfoInd: '',
      d_anyHimsAreaMatchOptionData: {
        'show': false,
        'text': '',
        'col_ind': '',
        'opt_ind': ''
      },
      user: {},
      d_textAnalyzeOnWdmrsMatchOptionData: {
        'show': false,
        'text': '',
        'col_ind': '',
        'opt_ind': ''
      },
      d_wdmrRuleAnalyzeResultWdmrShow: {
        'show': false,
        'wdmr_list': [],
        'selected_wdmr': { 'data': {}, 'option_data': {}, 'show': false }
      },
      d_cellFillStatsSelectedCells: [],
      d_cellFillStatsResultPageData: { 'current': 1, 'perpage': 100 },
      d_cellFillStatsColumns: [{
        value: "name",
        label: "Kolon Adı"
      }, {
        value: "userFirstAndLastName",
        label: "Kullanıcı Adı"
      }, {
        value: "hospitalGroup",
        label: "Hastane Grubu"
      }, {
        value: "hospitalCode",
        label: "Hastane Kodu"
      }, {
        value: "codeType",
        label: "Kod"
      }, {
        value: "info",
        label: "Bilgi"
      }, {
        value: "typeError",
        label: "Type Error"
      }, {
        value: "valueError",
        label: "Value Error"
      }],
    };
  },
  created: function () {
    if (this.column_index !== undefined) {
      this.d_selectedColumnInd = this.column_index;
    }
    this.d_projectWdmList = ClsPolestar.f_createProjectWdmList(this.p_selectedProjectData);
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {
    this.f_analyzeWdmrRuleMatchEligibility();
    this.f_analyzeColumnOptionRuleMatchEligibility();
    this.f_addMissingOptionOrderParametersToColumns();
    // this.f_analyzeHimsInspectionEligibility();
    // this.addEventListenerToTable();
  },
  methods: {
    f_createAcceptedDocumentTypes: function () {
      let accept = '';
      let file_types = ["jpg", "jpeg", "png"];
      for (let i in file_types) {
        accept += '.' + file_types[i] + ',';
      }
      return accept;
    },
    f_addImageToColumnAsBase64: function (column_index) {
      if (this.d_tmpFile) {
        // console.log(this.d_tmpFile);
        let file_name = this.d_tmpFile.name;
        let arr = this.d_tmpFile.name.split('.');
        let fileExtension = arr[arr.length - 1];
        if (["jpg", "jpeg", "png"].indexOf(fileExtension.toLowerCase()) === -1) {
          this.d_tmpFile = null;
          this.$forceUpdate();
          alert('Dosya tipi uygun değil');
          return;
        }
        if (this.d_tmpFile.size > 10485760) {
          this.d_tmpFile = null;
          this.$forceUpdate();
          alert('Dosya boyutu uygun değil: ', this.d_tmpFile.size);
          return;
        }
        var reader = new FileReader();
        var vm = this;
        var reader = new FileReader();
        reader.addEventListener("load", function () {
          // console.log(reader.result);
          // console.log('page_info_index ', page_info_index);
          // console.log('page_info_detail_index ', page_info_detail_index);
          // console.log('vm.d_frdWebunitData.page_info ', vm.d_frdWebunitData.page_info);
          vm.column_list[column_index].image = reader.result
          vm.$forceUpdate();
        }, false);
        reader.readAsDataURL(this.d_tmpFile);
        this.d_tmpFile = null;
        this.$forceUpdate();
      }
    },
    f_addThisOptionToAllCells: function (option_ind) {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {
        let query = 'project_id=' + this.p_selectedProjectData.id;
        let data = {
          'col_ind': this.d_selectedColumnInd,
          'option': this.column_list[this.d_selectedColumnInd].options[option_ind]
        };
        PoleStarService.project_column_option_assign(query, data)
          .then(resp => {
            if (resp.data.status_code === '2000') {
              console.log(resp);
            } else {
              alert('error', resp.data.message);
            }
          });
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_addThisColumn: function (selected_column_ind) {
      let col_data = JSON.parse(JSON.stringify(this.d_projectListData.column_list[selected_column_ind]));
      col_data.new_column = 1;
      if (col_data.filled) {
        this.$delete(col_data, 'filled');
      }
      if (col_data.id) {
        this.$delete(col_data, 'id');
      }
      if (col_data.wdm3_id) {
        this.$delete(col_data, 'wdm3_id');
      }
      if (col_data.project_id) {
        this.$delete(col_data, 'project_id');
      }
      this.column_list.push(col_data);
    },
    f_getProjectAndColumnList: function (project_index) {
      // this.d_selectedProjectData = '';
      this.d_projectListData.selected_project_ind = project_index;
      this.d_projectListData.column_list = [];
      let wdm3_id = this.d_projectListData.list[project_index].id;
      PoleStarService.get_this_project({ 'wdm3_id': wdm3_id })
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_projectListData.project_data = resp.data.result;
            this.d_projectListData.column_list = resp.data.result.design.column_list;
          } else {
            alert('error', resp.data.message);
          }
        });
    },
    f_addOrEditColumnsByIncludingTheWords: function (column_process_type) {
      let data = {
        'analyze_column_index': this.d_wordAnalyzeList.selected_column_index,
        'project_id': this.p_selectedProjectData.id,
        'column_process_type': column_process_type,
        'selected_words': this.d_wordAnalyzeList.selected_words
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İşleminiz yapılıyor. Lütfen bekleyiniz.' } });
      PhraseService.add_or_edit_columns_by_including_the_words(data)
        .then(resp => {
          if (resp.data.status === "success") {
            this.f_motherChildren('get_project_cases');
            this.$store.commit('Loading', { 'status': false, 'data': {} });
          } else {
            let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
          this.$forceUpdate();
        });
    },
    f_addRemoveThisWord: function (word) {
      if (this.d_wordAnalyzeList.selected_word_order.indexOf(word) !== -1) {
        this.d_wordAnalyzeList.selected_word_order.splice(this.d_wordAnalyzeList.selected_word_order.indexOf(word), 1);
        this.$delete(this.d_wordAnalyzeList.selected_words, word);
      } else {
        this.d_wordAnalyzeList.selected_word_order.push(word);
        this.d_wordAnalyzeList.selected_words[word] = { 'column_label': word };
      }
      this.$forceUpdate();
    },
    f_selectApi: function (api_ind) {
      this.d_apiList.selected_index = api_ind;
      this.$forceUpdate();
    },
    f_addThisApiToThisColumn: function () {
      // console.log(this.d_apiList);
      let data = {};
      let wdm308_key = this.d_apiList.list[this.d_apiList.selected_index].key;
      data.bucket = 'polestar';
      data.key = wdm308_key;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Api dokümanı hazırlanıyor. Lütfen bekleyiniz.' } });
      GlobalService.get_one_cb_document(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          this.d_wdm308Data = resp.data.result;
          if (this.d_wdm308Data.data && this.d_wdm308Data.data.general && this.d_wdm308Data.data.general.api_type && this.d_wdm308Data.data.general.api_type.val && this.d_wdm308Data.data.general.api_type.val.value === 'mssql') {
            try {
              this.$delete(this.d_wdm308Data.data.general, 'mssql_username');
            } catch (err) {}
            try {
              this.$delete(this.d_wdm308Data.data.general, 'mssql_password');
            } catch (err) {}
          }
          this.d_wdm308Data.id = 'new';
          if (this.column_list[this.d_selectedColumnInd].wdm308_key) {
            this.d_wdm308Data.id = this.column_list[this.d_selectedColumnInd].wdm308_key.split('_')[1];
          }
          if (this.d_apiList.list[this.d_apiList.selected_index].api_row_type !== undefined) {
            this.column_list[this.d_selectedColumnInd].api_row_type = this.d_apiList.list[this.d_apiList.selected_index].api_row_type;
          }
          if (this.d_apiList.list[this.d_apiList.selected_index].walgo_response !== undefined) {
            this.column_list[this.d_selectedColumnInd].walgo_response = this.d_apiList.list[this.d_apiList.selected_index].walgo_response;
          }
          if (this.d_apiList.list[this.d_apiList.selected_index].add_algorithm_info !== undefined) {
            this.column_list[this.d_selectedColumnInd].add_algorithm_info = this.d_apiList.list[this.d_apiList.selected_index].add_algorithm_info;
          }
          if (!this.d_wdm308OptionData) {
            let data = {};
            data.bucket = 'option';
            data.key = 'wdm_wisdom_wdm308_v2.0';
            this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Api Veri modeli hazırlanıyor. Lütfen bekleyiniz.' } });
            GlobalService.get_one_cb_document(data)
              .then(resp => {
                this.$store.commit('Loading', { 'status': false, 'data': {} });
                this.d_wdm308OptionData = { 'wdm308': resp.data.result };
                this.f_saveWdm308();
              });
          } else {
            this.f_saveWdm308();
          }
          this.d_apiList.show = false;
        });
    },
    f_selectFromApiList: function (list_type = 'this_project') {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {
        let query = 'project_id=' + this.p_selectedProjectData.id;
        query += '&list_type=' + list_type;
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Api listesi getiriliyor. Lütfen bekleyiniz.' } });
        PoleStarService.get_api_list(query)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status_code === "2000") {
              this.d_apiList.list = resp.data.list;
              this.d_apiList.show = true;
            } else {
              let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
            this.$forceUpdate();
          });
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_connectMachineLearningColumnInputUpdate: function () {
      let is_all_finished = true;
      for (let i in this.d_columnInputmachineLearningMatch.list) {
        if (this.d_columnInputmachineLearningMatch.list[i].selected_column === '') {
          is_all_finished = false;
        }
      }
      if (is_all_finished) {
        if (!this.column_list[this.d_selectedColumnInd].ml_input_match_column_data) {
          this.column_list[this.d_selectedColumnInd].ml_input_match_column_data = { 'list': [] };
        }
        this.column_list[this.d_selectedColumnInd].ml_input_match_column_data.list = [];
        for (let i in this.d_columnInputmachineLearningMatch.list) {
          let new_match_item = {
            'name': this.d_columnInputmachineLearningMatch.list[i].name,
            'selected_column': this.d_columnInputmachineLearningMatch.list[i].selected_column
          }
          if (this.d_columnInputmachineLearningMatch.list[i].options) {
            for (let option_label in this.d_columnInputmachineLearningMatch.list[i].options) {
              let matched_option = this.d_columnInputmachineLearningMatch.list[i].options[option_label].matched_option;
              let group_val = this.d_columnInputmachineLearningMatch.list[i].options[option_label].group_val;
              let new_option = {
                'matched_option': matched_option,
                'group_val': group_val,
                'option_label': option_label
              };
              if (!new_match_item.options) {
                new_match_item.options = [];
              }
              new_match_item.options.push(new_option);
            }
          }
          this.column_list[this.d_selectedColumnInd].ml_input_match_column_data.list.push(new_match_item);
        }
        this.d_columnInputmachineLearningMatch.show = false;
      } else {
        let modal_data = { 'type': 'alert', 'text': 'Lütfen tüm girdileri seçiniz', 'centered': true, 'title': 'Makine Öğrenmesi Modeli Eşleştirme Hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_connectMatchColumnsToInputVariables: function () {
      this.d_columnInputmachineLearningMatch.list = [];
      for (let input_index in this.d_columnMachineLearningDataWdm163.analyze_result[0].machine_learning[0].input_variables) {
        let input_name = this.d_columnMachineLearningDataWdm163.analyze_result[0].machine_learning[0].input_variables[input_index];
        for (let ml_column_index_id in this.d_columnMachineLearningDataWdm163.analyze_result[0].machine_learning[0].data.rc) {
          let input_var_data = JSON.parse(JSON.stringify(this.d_columnMachineLearningDataWdm163.analyze_result[0].machine_learning[0].data.rc[ml_column_index_id]));
          if (input_var_data.name === input_name) {
            input_var_data.selected_column = '';
            if (this.column_list[this.d_selectedColumnInd].ml_input_match_column_data && this.column_list[this.d_selectedColumnInd].ml_input_match_column_data.list.length > 0) {
              for (let i in this.column_list[this.d_selectedColumnInd].ml_input_match_column_data.list) {
                let match_item = this.column_list[this.d_selectedColumnInd].ml_input_match_column_data.list[i];
                if (match_item.name === input_var_data.name) {
                  input_var_data.selected_column = match_item.selected_column;
                  if (match_item.options) {
                    for (let match_option_index in match_item.options) {
                      let option_of_match_item = match_item.options[match_option_index];
                      if (option_of_match_item.matched_option && option_of_match_item.option_label && input_var_data.options[option_of_match_item.option_label]) {
                        input_var_data.options[option_of_match_item.option_label].matched_option = option_of_match_item.matched_option;
                      }
                    }
                  }
                  break
                }
              }
            }
            this.d_columnInputmachineLearningMatch.list.push(input_var_data);
            break;
          }
        }
      }
      this.d_columnInputmachineLearningMatch.show = true;
    },
    f_wisdomDataShowChange: function () {
      if (this.change === "0") {
        this.change = "1";
      } else {
        this.change = "0";
      }
      this.$forceUpdate();
    },
    f_getColumnMachineLearningData: function () {
      this.d_columnMachineLearningDataWdm163 = '';
      let machine_learning_key = this.column_list[this.d_selectedColumnInd].machine_learning_model_id;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
      GlobalService.get_one_cb_document({ 'key': machine_learning_key, 'bucket': 'polestar' })
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_columnMachineLearningDataWdm163 = resp.data.result;
            this.f_wisdomDataShowChange();
          } else {
            alert('error', resp.data.message);
          }
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    f_selectMachineLearningModelForColumn: function (model_ind) {
      this.d_machineLearning.selected_index = model_ind;
      this.$forceUpdate();
    },
    f_connectThisMachineLearningModelToColumn: function () {
      this.column_list[this.d_selectedColumnInd].machine_learning_model_id = this.d_machineLearning.list[this.d_machineLearning.selected_index].id;
      this.f_getColumnMachineLearningData();
      this.d_machineLearning.show = false;
    },
    f_connectMachineLearningModel: function (list_type) {
      this.d_machineLearning.list = [];
      this.d_machineLearning.selected_index = '';
      let data = { 'list_type': '' };
      if (list_type === 'all_projects') {
        data.list_type = 'all_projects';
      } else if (list_type === 'this_project') {
        data.list_type = 'this_project';
        data.this_project_id = this.p_selectedProjectData.id;
      }
      MachineLearningService.machine_learning_list(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status_code === "2000") {
            this.d_machineLearning.list = resp.data.list;
            this.d_machineLearning.show = true;
          } else {
            let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': 'Makine Öğrenmesi Listesi Hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
          this.$forceUpdate();
        });
    },
    f_previewJsonFormat: function (col_index) {
      let preview_json = '';
      let api_row_type = '';
      this.d_apiPreviewJsonFormat.wdm308_doc = [];
      this.d_apiPreviewJsonFormat.walgo_response = [];
      if (this.column_list[col_index].api_row_type) {
        for (let i in this.api_row_type_list) {
          if (this.api_row_type_list[i].value === this.column_list[col_index].api_row_type) {
            api_row_type = this.api_row_type_list[i].value;
            preview_json = JSON.parse(JSON.stringify(this.api_row_type_list[i].preview_json));
            break;
          }
        }
      } else {
        for (let i in this.api_row_type_list) {
          if (this.api_row_type_list[i].default) {
            api_row_type = this.api_row_type_list[i].value;
            preview_json = JSON.parse(JSON.stringify(this.api_row_type_list[i].preview_json));
            break;
          }
        }
      }
      // console.log('api_row_type : ', api_row_type);
      // console.log('preview_json : ', preview_json);
      if (preview_json) {
        if (this.column_list[col_index].walgo_response && this.column_list[col_index].walgo_response.length > 0) {
          this.d_apiPreviewJsonFormat.walgo_response = this.column_list[col_index].walgo_response;
          for (let i in this.column_list[col_index].walgo_response) {
            let key = this.column_list[col_index].walgo_response[i].key;
            // console.log("key : ", key);
            if (api_row_type === '1') {
              preview_json.Data[key] = '';
            } else if (api_row_type === '2') {
              preview_json.rows[0][key] = '';
            }
          }
        }
        try {
          for (let i in this.d_wdm308Data.data.general.kafka_send_param.list) {
            let key = this.d_wdm308Data.data.general.kafka_send_param.list[i].kafka_send_param_key.val;
            let value = this.d_wdm308Data.data.general.kafka_send_param.list[i].kafka_send_param_value.val;
            preview_json[key] = value;
            this.d_apiPreviewJsonFormat.wdm308_doc.push({ 'key': key, 'value': value })
          }
        } catch (err) {}
        try {
          for (let i in this.d_wdm308Data.data.general.rest_send_param.list) {
            let key = this.d_wdm308Data.data.general.rest_send_param.list[i].restful_send_param_key.val;
            let value = this.d_wdm308Data.data.general.rest_send_param.list[i].restful_send_param_value.val;
            preview_json[key] = value;
            this.d_apiPreviewJsonFormat.wdm308_doc.push({ 'key': key, 'value': value })
          }
        } catch (err) {}
        this.d_apiPreviewJsonFormat.preview_json = preview_json;
        this.d_apiPreviewJsonFormat.show = true;
      }
    },
    f_getJsonStringify: function (data) {
      return JSON.stringify(data, null, 4);
    },
    f_selectThisApiRowType: function (col_index, row_type) {
      this.column_list[col_index].api_row_type = row_type.value;
      this.$forceUpdate();
    },
    f_phraseColumnOptionMatch: function (col_index) {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {
        let data = {};
        data = {
          'col_index_list': [col_index],
          'project_id': this.p_selectedProjectData.id
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İfade eşleştirme yapılıyor. Lütfen bekleyiniz.' } });
        PhraseService.phrase_column_option_match(data)
          .then(resp => {
            if (resp.data.status === "success") {
              this.f_motherChildren('get_project_cases');
              this.$store.commit('Loading', { 'status': false, 'data': {} });
            } else {
              let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
            this.$forceUpdate();
          });
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_analyzeAllWords: function (col_index) {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {        
        let data = {};
        data = {
          'col_index_list': [col_index],
          'project_id': this.p_selectedProjectData.id
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kolon hücrelerindeki yazılarda kelime analizleri yapılıyor. Lütfen bekleyiniz.' } });
        PhraseService.word_analyze_in_column(data)
          .then(resp => {
            if (resp.data.status === "success") {
              this.d_wordAnalyzeList.total_rows = resp.data.total_rows;
              this.d_wordAnalyzeList.total_count = resp.data.total_count;
              this.d_wordAnalyzeList.columns = resp.data.columns;
              this.d_wordAnalyzeList.selected_column_index = resp.data.column_order[0];
              this.d_wordAnalyzeList.show = true;
              this.$store.commit('Loading', { 'status': false, 'data': {} });
            } else {
              let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
            this.$forceUpdate();
          });
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_nlpPhraseWorkPrepareTextByProjectColumnContent: function (col_index) {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {        
        let query = 'project_id=' + this.p_selectedProjectData.id;
        query += '&col_index_list=' + col_index.toString();
        let data = {};
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kolon hücrelerinden ifade çalışma metni hazırlanıyor' } });
        ServiceFunctions.nlp_phrase_work_prepare_text_by_project_column_content(query, data)
          .then(resp => {
            if (resp.data.status === "success") {
              this.d_nlpData.work_text = resp.data.work_text;
              this.d_nlpData.work_text_label = this.column_list[col_index].label;
              this.d_nlpData.show = true;
              this.$store.commit('Loading', { 'status': false, 'data': {} });
            } else {
              let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
            this.$forceUpdate();
          });
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_bindNlpPhrase: function (col_index) {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {
        this.d_nlpData.show = true;
        this.d_nlpData.selected_phrase_data = '';
        this.d_nlpData.nlp_phrase_id = '';
        if (this.column_list[col_index].nlp_phrase_id) {
          this.d_nlpData.nlp_phrase_id = this.column_list[col_index].nlp_phrase_id;
        }
        this.d_nlpData.nlp_layer_type = '';
        if (this.column_list[col_index].nlp_layer_type) {
          this.d_nlpData.nlp_layer_type = this.column_list[col_index].nlp_layer_type;
        }
        this.d_nlpData.work_text = '';
        this.d_nlpData.work_text_label = '';
        this.d_nlpData.col_index = col_index;
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_bindNlpPhraseToColumn: function () {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {
        this.d_nlpData.show = false;

        this.column_list[this.d_nlpData.col_index].nlp_phrase_name = this.d_nlpData.selected_phrase_data.phrase_name;
        this.column_list[this.d_nlpData.col_index].nlp_phrase_id = this.d_nlpData.nlp_phrase_id;
        this.column_list[this.d_nlpData.col_index].nlp_layer_type = this.d_nlpData.nlp_layer_type;

        if (this.d_nlpData.selected_phrase_data.regx_usage_type === '0') {
          for (let phrase_op_index in this.d_nlpData.selected_phrase_data.phrase_option) {
            if (this.d_nlpData.selected_phrase_data.phrase_option[phrase_op_index].phrase_option_name) {
              let new_option = this.d_nlpData.selected_phrase_data.phrase_option[phrase_op_index].phrase_option_name;
              let det = false;
              if (!this.column_list[this.d_nlpData.col_index].options) {
                this.column_list[this.d_nlpData.col_index].options = [];
              } else {
                for (let i in this.column_list[this.d_nlpData.col_index].options) {
                  if (this.column_list[this.d_nlpData.col_index].options[i].label === new_option) {
                    det = true;
                    break;
                  }
                }
              }
              if (!det) {
                let new_opt = { 'label': new_option, 'value': '' };
                for (let k = 0; k < 10000; k++) {
                  let new_val = 'new_' + k;
                  let val_det = false;
                  for (let i in this.column_list[this.d_nlpData.col_index].options) {
                    if (this.column_list[this.d_nlpData.col_index].options[i].value === new_val) {
                      val_det = true;
                      break;
                    }
                  }
                  if (!val_det) {
                    new_opt.value = new_val;
                    break;
                  }
                }
                this.column_list[this.d_nlpData.col_index].options.push(new_opt);
              }
            }
          }
        }

        this.$forceUpdate();

      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_selectPhrase: function (phrase_key) {
      this.d_phraseMatch.selected = phrase_key;
      this.$forceUpdate();
    },
    f_addPhraseToColumnOption: function () {
      for (let phrase_ind in this.d_phraseMatch.list) {
        if (this.d_phraseMatch.list[phrase_ind].key === this.d_phraseMatch.selected) {
          let k = {
            'key': this.d_phraseMatch.list[phrase_ind].key,
            'label': this.d_phraseMatch.list[phrase_ind].label
          };
          this.column_list[this.d_phraseMatch.col_index].options[this.d_phraseMatch.op_ind].phrase_match = k;
          break;
        }
      }
      this.d_phraseMatch.show = false;
    },
    f_matchToPhraseWdmrModal: function (col_index, op_ind) {
      let query = "project_id=" + this.p_selectedProjectData.id;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
      PhraseService.get_phrase_list(query)
        .then(resp => {
          if (resp.data.status === "success") {
            let col_op_phrase_match = this.column_list[col_index].options[op_ind].phrase_match;
            if (col_op_phrase_match && col_op_phrase_match.key) {
              this.d_phraseMatch.selected = col_op_phrase_match.key;
            } else {
              this.d_phraseMatch.selected = '';
            }
            this.d_phraseMatch.list = resp.data.result.list;
            this.d_phraseMatch.op_ind = op_ind;
            this.d_phraseMatch.col_index = col_index;
            this.d_phraseMatch.show = true;
            this.$store.commit('Loading', { 'status': false, 'data': {} });
          } else {
            let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
          this.$forceUpdate();
        });
    },
    f_copyColumnCreationRule: function (rule_ind) {
      let x = JSON.parse(JSON.stringify(this.column_list[this.d_selectedColumnInd].data_creation_rule[rule_ind]));
      this.column_list[this.d_selectedColumnInd].data_creation_rule.push(x);
      this.$forceUpdate();
    },
    f_copyThisOption: function (op_ind) {
      this.d_newOptionData.op_ind = op_ind;
      this.d_newOptionData.show = true;
    },
    f_copyThisColumn: function (col_ind) {
      let col_data = JSON.parse(JSON.stringify(this.column_list[col_ind]));
      col_data.new_column = 1;
      if (col_data.filled) {
        this.$delete(col_data, 'filled');
      }
      if (col_data.id) {
        this.$delete(col_data, 'id');
      }
      this.column_list.push(col_data);
      this.$forceUpdate();
    },
    f_checkProjects: function (project) {
      if (this.d_projectListData.selected_project_id_list.length > 0) {
        this.d_projectListData.selected_project_id_list = [];
      } else {
        this.d_projectListData.selected_project_id_list = [];
        for (let i in this.d_projectListData.list) {
          this.d_projectListData.selected_project_id_list.push(this.d_projectListData.list[i].id);
        }
      }
      this.$forceUpdate();
    },
    f_openUpdateSameNameColumnsSelectedProjects: function (col_ind) {
      this.d_columnFunctions.show = false;
      let col_data = JSON.parse(JSON.stringify(this.column_list[col_ind]));
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Proje listeniz getiriliyor. Lütfen bekleyiniz.' } });
      ClsPolestar.f_getUserPolestarProjects(this.user.username)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          // console.log(resp);
          this.d_projectListData.target_project_id = this.p_selectedProjectData.id;
          this.d_projectListData.target_column_id = this.column_list[col_ind].id;
          this.d_projectListData.target_column_index = col_ind;
          this.d_projectListData.list = resp;
          this.d_projectListData.select_mode = 'one_and_multi';
          this.d_projectListData.show = true;
          this.d_projectListData.column_add_button = 'no';
          this.d_projectListData.button_column_update_on_other_projects = 'yes';
        }, resp => {
          alert('error : ', resp);
        });
    },
    f_updateSameNameColumnsSelectedProjects: function () {
      if (this.d_projectListData.selected_project_id_list.length > 0) {
        let data = {
          'target_project_id': this.d_projectListData.target_project_id,
          'target_column_id': this.d_projectListData.target_column_id,
          'selected_project_id_list': this.d_projectListData.selected_project_id_list,
        };
        // console.log('data : ', data);
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İşlem gerçekleştiriliyor. Lütfen bekleyiniz.' } });
        PoleStarService.column_update_selected_projects(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status_code === "2000") {
              this.d_projectListData.results_project_list = resp.data.project_list;
              console.log('resp.data : ', resp.data);
            } else {
              let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
            this.$forceUpdate();
          });
        } else {
          alert('Lütfen proje seçimi yapınız.');
        }
    },
    f_openColumnFunctions: function (col_ind) {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {
        this.d_columnFunctions.col_ind = col_ind;
        this.d_columnFunctions.show = true;
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_saveWdm308: function () {
      let are_parameters_defined_eligible = true;
      try {
        for (let i in this.d_wdm308Data.data.general.kafka_send_param.list) {
          let val = this.d_wdm308Data.data.general.kafka_send_param.list[i].kafka_send_param_key.val;
          if (this.d_not_eligible_walgo_response_column_parameters.indexOf(val) !== -1) {
            are_parameters_defined_eligible = false;
            break;
          }
        }
      } catch (err) {}
      try {
        for (let i in this.d_wdm308Data.data.general.rest_send_param.list) {
          let val = this.d_wdm308Data.data.general.rest_send_param.list[i].restful_send_param_key.val;
          if (this.d_not_eligible_walgo_response_column_parameters.indexOf(val) !== -1) {
            are_parameters_defined_eligible = false;
            break;
          }
        }
      } catch (err) {}
      if (are_parameters_defined_eligible) {
        let is_new_wdm308 = false;
        if (this.d_wdm308Data.id === 'new') {
          is_new_wdm308 = true;
        }
        let return_data = ClsWdmrForever.turn(this.d_wdm308Data, this.d_wdm308OptionData.wdm308, ['name', 'require']);
        let wdmr_name = return_data.name;
        if (wdmr_name) {
          this.d_wdm308Data.label = wdmr_name;
        }
        let wdmr_require_alerts = return_data.wdmr_require_alerts;
        let only_one_param_is_filled = return_data.only_one_param_is_filled;
        if (!only_one_param_is_filled) {
          let modal_data = {
            'type': 'alert',
            'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.',
            'centered': true,
            'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
          };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }

        if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
          let require_txt = '';
          for (let r in wdmr_require_alerts) {
            require_txt += ' * ' + wdmr_require_alerts[r].label;
          }
          let modal_data = {
            'type': 'alert',
            'text': require_txt,
            'centered': true,
            'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
          };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }
        return_data = ClsWdmrForever.turn(this.d_wdm308Data, this.d_wdm308OptionData.wdm308, ['name', 'delete']);
        let data = {};
        data = { 'data': this.d_wdm308Data };
        // data.permission_control = {'value': 'wanalyzer_create-project', 'i_used_this_permission': 1};
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kolona api kaydediliyor. Lütfen bekleyiniz.' } });
        PoleStarService.save_wdm308_to_col(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === "success") {
              let wdm308_key = resp.data.result.wdm308_key;
              if (!this.column_list[this.d_selectedColumnInd].wdm308_key || (this.column_list[this.d_selectedColumnInd].wdm308_key && this.column_list[this.d_selectedColumnInd].wdm308_key !== wdm308_key)) {
                this.column_list[this.d_selectedColumnInd].wdm308_key = wdm308_key;
                this.d_showWalgorithmColumnApiModal = false;
                this.f_addEditColumnList();
              } else {
                this.d_showWalgorithmColumnApiModal = false;
              }
            } else {
              let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
            this.$forceUpdate();
          });
      } else {
        let txt = 'Parametre adları bunlar olamaz. Bu parametreler otomatik olarak sistem tarafından api yanıtından eklenmektedir. \n\r';
        for (let i in this.d_not_eligible_walgo_response_column_parameters) {
          txt += '  - ' + this.d_not_eligible_walgo_response_column_parameters[i] + ' \n\r';
        }
        let modal_data = { 'type': 'alert', 'text': txt, 'centered': true, 'title': this.$t('wdm16.13274') };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_showWalgorithmColumnApiModal: function () {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {
        if (!this.d_wdm308OptionData) {
          let data = {};
          data.bucket = 'option';
          data.key = 'wdm_wisdom_wdm308_v2.0';
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Veri modeli hazırlanıyor Lütfen bekleyiniz.' } });
          GlobalService.get_one_cb_document(data)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              this.d_wdm308OptionData = { 'wdm308': resp.data.result };
              this.f_wdm308Data();
            });
        } else {
          this.f_wdm308Data();
        }
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_wdm308Data: function () {
      this.d_wdm308Data = {
        'data': {},
        'type': 'wdm308',
        'reference_wdm': { 'owner_type': 'wisdom', 'version': '2.0', 'key': 'wdm_wisdom_wdm308_v2.0' },
        'id': 'new'
      };
      let wdm308_key = '';
      if (this.column_list[this.d_selectedColumnInd].wdm308_key && this.column_list[this.d_selectedColumnInd].wdm308_key !== '') {
        wdm308_key = this.column_list[this.d_selectedColumnInd].wdm308_key;
        let data = {};
        data.bucket = 'polestar';
        data.key = wdm308_key;
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Veri modeli hazırlanıyor Lütfen bekleyiniz.' } });
        GlobalService.get_one_cb_document(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            this.d_wdm308Data = resp.data.result;
            if (this.d_wdm308Data.data && this.d_wdm308Data.data.general && this.d_wdm308Data.data.general.api_type && this.d_wdm308Data.data.general.api_type.val && this.d_wdm308Data.data.general.api_type.val.value === 'mssql') {
              try {
                this.$delete(this.d_wdm308Data.data.general, 'mssql_username');
              } catch (err) {}
              try {
                this.$delete(this.d_wdm308Data.data.general, 'mssql_password');
              } catch (err) {}
            }
            this.d_showWalgorithmColumnApiModal = true;
          });
      } else {
        for (let i in this.d_wdm308OptionData.wdm308.param_group.param_order) {
          this.d_wdm308Data.data[i] = {};
        }
        this.d_showWalgorithmColumnApiModal = true;
      }
    },
    f_forceUpdate: function () {
      this.$forceUpdate();
    },
    f_checkRespKeyDuplicate: function (resp_key, resp_ind) {
      for (let i in this.d_algorithmColumnWalgoResponse.walgo_response) {
        if (i.toString() !== resp_ind.toString() && this.d_algorithmColumnWalgoResponse.walgo_response[i].key === resp_key) {
          return true;
        }
      }
      return false;
    },
    f_checkRespKeyDuplicateStatic: function (resp_key, resp_ind) {
      for (let i in this.d_algorithmColumnWalgoResponse.walgo_response_static) {
        if (i.toString() !== resp_ind.toString() && this.d_algorithmColumnWalgoResponse.walgo_response_static[i].key === resp_key) {
          return true;
        }
      }
      return false;
    },
    f_checkNotEligibleKeys: function (resp_key) {
      if (this.d_not_eligible_walgo_response_column_parameters.indexOf(resp_key) !== -1) {
        return false;
      }
      return true;
    },
    f_checkNotEligibleKeysStatic: function (resp_key) {
      if (this.d_not_eligible_walgo_response_column_parameters.indexOf(resp_key) !== -1) {
        return false;
      }
      return true;
    },
    f_formatRespKeyRegex: function (value, event) {
      return WisdomRegex.alphanumeric(value).value;
    },
    f_checkRespKeyRegex: function (value) {
      return WisdomRegex.alphanumeric(value).status;
    },
    f_addNewWalgorithmResponseParam: function () {
      if (!this.d_algorithmColumnWalgoResponse.walgo_response) {
        this.d_algorithmColumnWalgoResponse.walgo_response = [];
      }
      this.d_algorithmColumnWalgoResponse.walgo_response.push({ 'key': '', 'col': '0' });
      this.$forceUpdate();
    },
    f_addNewWalgorithmResponseStaticParameter: function () {
      if (!this.d_algorithmColumnWalgoResponse.walgo_response_static) {
        this.d_algorithmColumnWalgoResponse.walgo_response_static = [];
      }
      this.d_algorithmColumnWalgoResponse.walgo_response_static.push({ 'key': '', 'value': '' });
      this.$forceUpdate();
    },
    f_updateColumnWalgoResponse: function () {
      let is_eligible = true;
      let is_alpha_numeric = '';
      let is_duplicated = '';
      let is_useable_key = '';
      let is_there_not_filled_parameter = '';
      if (this.d_algorithmColumnWalgoResponse.walgo_response.length > 0 || this.d_algorithmColumnWalgoResponse.walgo_response_static.length) {
        for (let i in this.d_algorithmColumnWalgoResponse.walgo_response) {
          let key = this.d_algorithmColumnWalgoResponse.walgo_response[i].key;
          if (key === '') {
            is_there_not_filled_parameter = true;
          }
          is_alpha_numeric = WisdomRegex.alphanumeric(key).status;
          // console.log("is_alpha_numeric ", is_alpha_numeric);
          is_duplicated = this.f_checkRespKeyDuplicate(key, i);
          // console.log("is_duplicated ", is_duplicated);
          is_useable_key = this.f_checkNotEligibleKeys(key);
          // console.log("is_useable_key ", is_useable_key);
          if (!is_alpha_numeric || is_duplicated || !is_useable_key || is_there_not_filled_parameter) {
            is_eligible = false;
            break;
          }
        }
        for (let i in this.d_algorithmColumnWalgoResponse.walgo_response_static) {
          let key = this.d_algorithmColumnWalgoResponse.walgo_response_static[i].key;
          if (key === '') {
            is_there_not_filled_parameter = true;
          }
          is_alpha_numeric = WisdomRegex.alphanumeric(key).status;
          // console.log("is_alpha_numeric ", is_alpha_numeric);
          is_duplicated = this.f_checkRespKeyDuplicateStatic(key, i);
          // console.log("is_duplicated ", is_duplicated);
          is_useable_key = this.f_checkNotEligibleKeys(key);
          // console.log("is_useable_key ", is_useable_key);
          if (!is_alpha_numeric || is_duplicated || !is_useable_key || is_there_not_filled_parameter) {
            is_eligible = false;
            break;
          }
        }
        for (let i in this.d_algorithmColumnWalgoResponse.walgo_response_static) {
          let val = this.d_algorithmColumnWalgoResponse.walgo_response_static[i].value;
          if (val === '') {
            is_there_not_filled_parameter = true;
          }
          if (!is_alpha_numeric || is_duplicated || !is_useable_key || is_there_not_filled_parameter) {
            is_eligible = false;
            break;
          }
        }
        if (is_eligible) {
          this.column_list[this.d_algorithmColumnWalgoResponse.col_ind].walgo_response = [];
          this.column_list[this.d_algorithmColumnWalgoResponse.col_ind].walgo_response_static = [];
          if (this.d_algorithmColumnWalgoResponse.walgo_response && this.d_algorithmColumnWalgoResponse.walgo_response.length > 0) {
            this.column_list[this.d_algorithmColumnWalgoResponse.col_ind].walgo_response = JSON.parse(JSON.stringify(this.d_algorithmColumnWalgoResponse.walgo_response));
          } else {
            try {
              this.$delete(this.column_list[this.d_algorithmColumnWalgoResponse.col_ind], 'walgo_response');
            } catch (err) {}
          }
          if (this.d_algorithmColumnWalgoResponse.walgo_response_static && this.d_algorithmColumnWalgoResponse.walgo_response_static.length > 0) {
            this.column_list[this.d_algorithmColumnWalgoResponse.col_ind].walgo_response_static = JSON.parse(JSON.stringify(this.d_algorithmColumnWalgoResponse.walgo_response_static));
          } else {
            try {
              this.$delete(this.column_list[this.d_algorithmColumnWalgoResponse.col_ind], 'walgo_response_static');
            } catch (err) {}
          }
          this.d_algorithmColumnWalgoResponse.show = false;
          this.$forceUpdate();
        } else {
          let txt = '';
          if (is_there_not_filled_parameter) {
            txt += 'Boş parametre oluşturulamaz. \n\r';
          }
          if (!is_alpha_numeric) {
            txt += 'Key değriniz alfa numerik olmalıdır. \n\r';
          }
          if (is_duplicated) {
            txt += 'Aynı parametreler birden fazla oluşturulamaz. \n\r';
          }
          if (!is_useable_key) {
            txt += 'Parametre adları bunlar olamaz. Bu parametreler otomatik olarak sistem tarafından api yanıtından eklenmektedir. \n\r';
            for (let i in this.d_not_eligible_walgo_response_column_parameters) {
              txt += '  - ' + this.d_not_eligible_walgo_response_column_parameters[i] + ' \n\r';
            }
          }
          let modal_data = { 'type': 'alert', 'text': txt, 'centered': true, 'title': 'Algoritma yanıtında kullanılacak parametrelerin oluşturulması hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);

        }
      }
    },
    f_openAlgorithmWalgoResponseParametersModal: function () {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {
        this.d_algorithmColumnWalgoResponse.walgo_response = [];
        this.d_algorithmColumnWalgoResponse.walgo_response_static = [];
        this.d_algorithmColumnWalgoResponse.col_ind = this.d_selectedColumnInd;

        if (this.column_list[this.d_selectedColumnInd].walgo_response) {
          this.d_algorithmColumnWalgoResponse.walgo_response = JSON.parse(JSON.stringify(this.column_list[this.d_selectedColumnInd].walgo_response));
        }
        if (this.d_algorithmColumnWalgoResponse.walgo_response.length === 0) {
          this.d_algorithmColumnWalgoResponse.walgo_response.push({ 'key': '', 'col': '0' });
        }
        if (this.column_list[this.d_selectedColumnInd].walgo_response_static) {
          this.d_algorithmColumnWalgoResponse.walgo_response_static = JSON.parse(JSON.stringify(this.column_list[this.d_selectedColumnInd].walgo_response_static));
        }
        this.d_algorithmColumnWalgoResponse.show = true;
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_removeWalgorithmResponseParam: function (index) {
      this.d_algorithmColumnWalgoResponse.walgo_response.splice(index, 1);
      this.$forceUpdate();
    },
    f_removeWalgorithmResponseParamStatic: function (index) {
      this.d_algorithmColumnWalgoResponse.walgo_response_static.splice(index, 1);
      this.$forceUpdate();
    },
    f_calculatedWdmrInfoStyle: function (wdmr_info_ind) {
      if (this.d_selectedWdmrInfoInd === wdmr_info_ind) {
        return 'cursor: pointer; background-color: #acebd5;';
      } else if (wdmr_info_ind % 2 === 0) {
        return 'cursor: pointer; background-color: #ececec;';
      } else {
        return 'cursor: pointer;'
      }
    },
    f_showThisChars: function (text, count) {
      let str = '';
      try {
        text = text.toString();
        let x = text.length > count ? count : text.length;
        for (let i = 0; i < x; i++) {
          if (text[i]) {
            str += text[i];
          } else {
            str += '.';
          }
        }
        if (text.length > count) {
          str += '...';
        }
      } catch (err) {}
      return str;
    },
    f_printFillPercent: function (f_source, f_type) {
      try {
        return f_source.cell_fill_count[f_type].v.toString() + ' (%' + f_source.cell_fill_count[f_type].p.toString() + ')';
      } catch (err) {
        return 'NaN';
      }
    },
    f_dblClickToCell: function () {},
    f_updateColumnFeature: function (col_ind, type, type_name, count_type = 'one') {
      let perm = 'column_feature_' + type;
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf(perm) !== -1) {
        // console.log(col_ind, type, type_name);
        let old_wdmr226_id = '';
        let msg = '';
        if (count_type === 'one') {
          for (let i in this.column_list) {
            if (this.column_list[i][type] && parseInt(i) !== col_ind) {
              old_wdmr226_id = this.column_list[i].id;
              this.column_list[i][type] = 0;
            }
          }
        }
        let data = {
          'type': type,
          'old_wdmr226_id': old_wdmr226_id,
          'wdm3_id': this.p_selectedProjectData.id,
          'wdmr226_id': this.column_list[col_ind].id,
          'count_type': count_type
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kolona ' + type_name + ' özelliği güncelleniyor. Lütfen bekleyiniz.' } });
        ColumnService.update_column_feature(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            msg = 'işlem başarıyla gerçekleştirildi';
            if (resp.data.status === 'success') {
              if (!this.column_list[col_ind][type]) {
                this.column_list[col_ind][type] = 1;
              } else {
                this.column_list[col_ind][type] = 0;
                // this.$delete(this.column_list[col_ind], type);
              }
              this.$forceUpdate();
            } else {
              msg = 'error: ' + resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': type_name + ' işlemleri hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
      } else {
        msg = type_name + ' işlemini yapmaya yetkiniz yok. Lütfen proje yöneticisiyle temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': type_name + ' işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_downloadCellAnalyzeExcel: function () {
      let data = {
        'wdm3_id': this.p_selectedProjectData.id
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Analiz Yapılıyor. Lütfen bekleyiniz.' } });
      PoleStarService.download_project_cell_fill_method_analyze_excel(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          let msg = '';
          msg = resp.data.message;
          if (resp.data.status === 'success') {
            let file_name = this.p_selectedProjectData.id + ".xlsx";
            FileSaver.saveAs(resp.data.result.file, file_name);
            // this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
            // this.column_list.splice(0, this.column_list.length);
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İfade analiz işlemleri hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        });
    },
    f_calculateRowStyle: function (row_ind) {
      if (row_ind % 2 === 0) {
        return '';
      } else {
        return 'background-color: #ececec;';
      }
    },
    f_changeMixedRuleType: function (op) {
      if (!op.column_option_mixed_wdmr_rule_type || (op.column_option_mixed_wdmr_rule_type && op.column_option_mixed_wdmr_rule_type === 'and')) {
        op.column_option_mixed_wdmr_rule_type = 'or';
      } else {
        op.column_option_mixed_wdmr_rule_type = 'and';
      }
      this.$forceUpdate();
    },
    f_showMixedRuleType: function (op) {
      if (!op.column_option_mixed_wdmr_rule_type || (op.column_option_mixed_wdmr_rule_type && op.column_option_mixed_wdmr_rule_type === '')) {
        return '>>>and<<<';
      } else {
        return '>>>' + op.column_option_mixed_wdmr_rule_type + '<<<';
      }
    },
    f_addMissingOptionOrderParametersToColumns: function () {
      for (let i in this.column_list) {
        if (this.column_list[i].options && this.column_list[i].options.length > 0 && !this.column_list[i].option_order) {
          this.column_list[i].option_order = [...Array(this.column_list[i].options.length).keys()];
        }
      }
    },
    f_showSelectedWdmrOnAnalyzeResult: function (wdmr_info, wdmr_info_ind) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İfade seçenek otomatik eşleştirme işlemleri gerçekleştiriliyor. Lütfen bekleyiniz.' } });
      this.d_wdmrRuleAnalyzeResultWdmrShow.selected_wdmr.show = false;
      this.$forceUpdate();
      this.d_selectedWdmrInfoInd = wdmr_info_ind;
      let data = {};
      data.wisdom = { 'keys': [wdmr_info.key] };
      let reference_wdm_bucket = '';
      if (wdmr_info.reference_wdm.owner_type === 'wisdom') {
        reference_wdm_bucket = 'option';
      } else {
        reference_wdm_bucket = 'hospital';
      }
      data[reference_wdm_bucket] = { 'keys': [wdmr_info.reference_wdm.key] };
      GlobalService.get_multiple_cb_documents_by_defined_bucket(data)
        .then(resp => {
          this.d_wdmrRuleAnalyzeResultWdmrShow.selected_wdmr.data = resp.data.result[wdmr_info.key];
          this.d_wdmrRuleAnalyzeResultWdmrShow.selected_wdmr.option_data = resp.data.result[wdmr_info.reference_wdm.key];
          setTimeout(() => {
            this.d_wdmrRuleAnalyzeResultWdmrShow.selected_wdmr.show = true;
            this.$forceUpdate();
          }, 500)
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': 'İfade seçenek otomatik eşleştirme işlemleri gerçekleştiriliyor. Lütfen bekleyiniz.' } });
        });
    },
    f_showWdmrRuleAnalyzeResultWdmrShowModal: function (argument) {
      this.d_textAnalyzeOnWdmrsMatchOptionData.show = false;
      this.d_wdmrRuleAnalyzeResultWdmrShow.selected_wdmr = {};
      this.d_wdmrRuleAnalyzeResultWdmrShow.wdmr_list = argument;
      this.d_selectedWdmrInfoInd = '';
      this.d_wdmrRuleAnalyzeResultWdmrShow.show = true;
    },
    f_deleteSearchText: function (t_ind) {
      let phrase = this.column_list[this.d_selectedColumnInd].search_text_list[t_ind];
      if (this.column_list[this.d_selectedColumnInd].search_text_rule_match && this.column_list[this.d_selectedColumnInd].search_text_rule_match[phrase]) {
        this.$delete(this.column_list[this.d_selectedColumnInd].search_text_rule_match, phrase);
      }
      if (this.column_list[this.d_selectedColumnInd].search_text_rule && this.column_list[this.d_selectedColumnInd].search_text_rule[phrase]) {
        this.$delete(this.column_list[this.d_selectedColumnInd].search_text_rule, phrase);
      }
      this.column_list[this.d_selectedColumnInd].search_text_list.splice(t_ind, 1);
      if (this.column_list[this.d_selectedColumnInd].search_text_list.length === 0) {
        this.$delete(this.column_list[this.d_selectedColumnInd], 'search_text_list');
        if (this.column_list[this.d_selectedColumnInd].search_text_rule_match) {
          this.$delete(this.column_list[this.d_selectedColumnInd], 'search_text_rule_match');
        }
        if (this.column_list[this.d_selectedColumnInd].search_text_rule) {
          this.$delete(this.column_list[this.d_selectedColumnInd], 'search_text_rule');
        }
        if (this.column_list[this.d_selectedColumnInd].search_text_match) {
          this.$delete(this.column_list[this.d_selectedColumnInd], 'search_text_match');
        }
      }
      this.f_analyzeWdmrRuleMatchEligibility();
      this.$forceUpdate();
    },
    f_analyzeWdmrRuleMatchEligibility: function () {
      this.d_eligibleForWdmrRuleMatch = false;
      if (this.d_selectedColumnInd !== '' && this.column_list[this.d_selectedColumnInd].options && this.column_list[this.d_selectedColumnInd].options.length > 0) {
        for (let i in this.column_list[this.d_selectedColumnInd].options) {
          if (this.column_list[this.d_selectedColumnInd].options[i].any_of_this_regx_in_wdmr_rule_data && this.column_list[this.d_selectedColumnInd].options[i].any_of_this_regx_in_wdmr_rule_data.length > 0) {
            this.d_eligibleForWdmrRuleMatch = true;
            break;
          }
        }
      }
    },
    f_analyzeColumnOptionRuleMatchEligibility: function () {
      this.d_eligibleForColumnOptionRuleMatch = false;
      if (this.d_selectedColumnInd !== '' && this.column_list[this.d_selectedColumnInd].options && this.column_list[this.d_selectedColumnInd].options.length > 0) {
        for (let i in this.column_list[this.d_selectedColumnInd].options) {
          if (this.column_list[this.d_selectedColumnInd].options[i].rule && this.column_list[this.d_selectedColumnInd].options[i].rule.query_list && this.column_list[this.d_selectedColumnInd].options[i].rule.query_list.length > 0) {
            this.d_eligibleForColumnOptionRuleMatch = true;
            break;
          }
        }
      }
    },
    f_deleteSearchTextOld: function (t_ind) {
      if (this.column_list[this.d_selectedColumnInd].search_text_match[this.column_list[this.d_selectedColumnInd].search_text_list[t_ind]]) {
        this.$delete(this.column_list[this.d_selectedColumnInd].search_text_match, this.column_list[this.d_selectedColumnInd].search_text_list[t_ind]);
      }
      this.column_list[this.d_selectedColumnInd].search_text_list.splice(t_ind, 1);
      this.f_analyzeHimsInspectionEligibility();
      this.$forceUpdate();
    },
    f_analyzeHimsInspectionEligibility: function () {
      this.d_eligibleForHimsInspectionMatch = false;
      if (this.d_selectedColumnInd !== '' && this.column_list[this.d_selectedColumnInd].options && this.column_list[this.d_selectedColumnInd].options.length > 0) {
        for (let i in this.column_list[this.d_selectedColumnInd].options) {
          if (this.column_list[this.d_selectedColumnInd].options[i].any_of_this_regx_in_hims_inspection_data && this.column_list[this.d_selectedColumnInd].options[i].any_of_this_regx_in_hims_inspection_data.length > 0) {
            this.d_eligibleForHimsInspectionMatch = true;
            break;
          }
        }
      }
    },
    f_updateCellsByHimsInspectionMatch: function (col_type, col_ind = '', cell_type) {
      let data = {
        'col_type': col_type,
        'col_ind': col_ind,
        'wdm3_id': this.p_selectedProjectData.id,
        'cell_type': cell_type
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İfade seçenek otomatik eşleştirme işlemleri gerçekleştiriliyor. Lütfen bekleyiniz.' } });
      PoleStarService.make_auto_matching_for_hims_options(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          let msg = '';
          if (resp.data.status === 'success') {
            msg = 'Analiz işlemleri tamamlandı';
            this.f_motherChildren('get_project_cases');
            // this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
            // this.column_list.splice(0, this.column_list.length);
          } else {
            msg = resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İfade analiz işlemleri hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        });
    },
    f_updateCellsByColumnOptionRule: function (col_type, col_ind = '', cell_type) {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {
        let data = {
          'col_type': col_type,
          'col_ind': col_ind,
          'wdm3_id': this.p_selectedProjectData.id,
          'cell_type': cell_type
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İşlem başlatılıyor. Lütfen bekleyiniz' } });
        PoleStarService.update_cells_by_column_option_rule(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              msg = 'Kolonlardaki verilerden algoritmalarla hücre doldurma işlemi başlamıştır. Tamamalandığında bilgi verilecektir.';
              this.p_selectedProjectData['data']['general']['data_option_algorithm_status'] = {
                "val": {
                  "label": "devam ediyor",
                  "value": "continuing"
                }
              };
              this.f_controlAndCreateTimer();
              this.$forceUpdate();
            } else {
              msg = resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Seçeneklere ait kural algoritmaları ile çalışma işlemleri hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_updateCellsByMachineLearningModel: function (col_type, col_ind = '', cell_type) {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {
        let data = {
          'col_type': col_type,
          'col_ind': col_ind,
          'wdm3_id': this.p_selectedProjectData.id,
          'cell_type': cell_type
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İşlem başlatılıyor. Lütfen bekleyiniz' } });
        MachineLearningService.machine_learning_update_cells(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              msg = 'Kolonlardaki verilerden makine öğrenmesi ile hücre doldurma işlemi başlamıştır. Tamamalandığında bilgi verilecektir.';
              this.p_selectedProjectData['data']['general']['data_option_algorithm_status'] = {
                "val": {
                  "label": "devam ediyor",
                  "value": "continuing"
                }
              };
              this.f_controlAndCreateTimer();
              this.$forceUpdate();
            } else {
              msg = resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Seçeneklere ait makine öğrenmesi ile çalışma işlemleri hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_updateCellsByWdmrRule: function (col_type, col_ind = '', cell_type) {
      let data = {
        'col_type': col_type,
        'col_ind': col_ind,
        'wdm3_id': this.p_selectedProjectData.id,
        'cell_type': cell_type
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Seçeneklere ait kural algoritmaları ile çalışma yapılıyor. Lütfen bekleyiniz' } });
      PoleStarService.update_cells_by_wdmr_rule(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          let msg = '';
          if (resp.data.status === 'success') {
            msg = 'Analiz işlemleri tamamlandı';
            this.f_motherChildren('get_project_cases');
            // this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
            // this.column_list.splice(0, this.column_list.length);
          } else {
            msg = resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Seçeneklere ait kural algoritmaları ile çalışma işlemleri hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        });
    },
    f_updateCellsByColumnOptionMixedWdmrRule: function (col_type, col_ind = '', cell_type) {
      let data = {
        'col_type': col_type,
        'col_ind': col_ind,
        'wdm3_id': this.p_selectedProjectData.id,
        'cell_type': cell_type
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Seçeneklere ait kural algoritmaları ile çalışma yapılıyor. Lütfen bekleyiniz' } });
      PoleStarService.update_cells_by_column_option_mixed_wdmr_rule(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          let msg = '';
          if (resp.data.status === 'success') {
            msg = 'Analiz işlemleri tamamlandı';
            this.f_motherChildren('get_project_cases');
            // this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
            // this.column_list.splice(0, this.column_list.length);
          } else {
            msg = resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Seçeneklere ait kural algoritmaları ile çalışma işlemleri hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        });
    },
    f_addAnyHimsAreaMatchOptionPhrase: function () {
      if (this.d_anyHimsAreaMatchOptionData.text) {
        if (!this.column_list[this.d_anyHimsAreaMatchOptionData.col_ind].options[this.d_anyHimsAreaMatchOptionData.opt_ind].any_of_this_regx_in_hims_inspection_data) {
          this.column_list[this.d_anyHimsAreaMatchOptionData.col_ind].options[this.d_anyHimsAreaMatchOptionData.opt_ind].any_of_this_regx_in_hims_inspection_data = [];
        }
        if (this.column_list[this.d_anyHimsAreaMatchOptionData.col_ind].options[this.d_anyHimsAreaMatchOptionData.opt_ind].any_of_this_regx_in_hims_inspection_data.indexOf(this.d_anyHimsAreaMatchOptionData.text) === -1) {
          this.column_list[this.d_anyHimsAreaMatchOptionData.col_ind].options[this.d_anyHimsAreaMatchOptionData.opt_ind].any_of_this_regx_in_hims_inspection_data.push(this.d_anyHimsAreaMatchOptionData.text);
        }
        this.$forceUpdate();
      }
    },
    f_checkAnalyzedHimsAreaAlreadyMatched: function (text) {
      if (text) {
        if (!this.column_list[this.d_anyHimsAreaMatchOptionData.col_ind].options[this.d_anyHimsAreaMatchOptionData.opt_ind].any_of_this_regx_in_hims_inspection_data) {
          return false;
        }
        if (this.column_list[this.d_anyHimsAreaMatchOptionData.col_ind].options[this.d_anyHimsAreaMatchOptionData.opt_ind].any_of_this_regx_in_hims_inspection_data.indexOf(text) !== -1) {
          return true;
        }
      } else {
        return false;
      }
    },
    f_checkAnalyzedHimsAreaAlreadyMatchedAnyOption: function (text) {
      if (text) {
        if (this.column_list[this.d_anyHimsAreaMatchOptionData.col_ind].options[this.d_anyHimsAreaMatchOptionData.opt_ind].any_of_this_regx_in_hims_inspection_data && this.column_list[this.d_anyHimsAreaMatchOptionData.col_ind].options[this.d_anyHimsAreaMatchOptionData.opt_ind].any_of_this_regx_in_hims_inspection_data.indexOf(text) !== -1) {
          return this.d_anyHimsAreaMatchOptionData.opt_ind;
        }
        for (let i in this.column_list[this.d_anyHimsAreaMatchOptionData.col_ind].options) {
          if (this.column_list[this.d_anyHimsAreaMatchOptionData.col_ind].options[i].any_of_this_regx_in_hims_inspection_data && this.column_list[this.d_anyHimsAreaMatchOptionData.col_ind].options[i].any_of_this_regx_in_hims_inspection_data.indexOf(text) !== -1) {
            return i;
          }
        }
        return 'no';
      } else {
        return 'no';
      }
    },
    f_removeAnalyzedHimsAreaFromList: function (text) {
      let index = this.column_list[this.d_anyHimsAreaMatchOptionData.col_ind].options[this.d_anyHimsAreaMatchOptionData.opt_ind].any_of_this_regx_in_hims_inspection_data.indexOf(text);
      if (index !== -1) {
        this.column_list[this.d_anyHimsAreaMatchOptionData.col_ind].options[this.d_anyHimsAreaMatchOptionData.opt_ind].any_of_this_regx_in_hims_inspection_data.splice(index, 1);
      }
      this.$forceUpdate();
    },
    f_addAnalyzedHimsAreaToList: function (text) {
      if (text) {
        if (!this.column_list[this.d_anyHimsAreaMatchOptionData.col_ind].options[this.d_anyHimsAreaMatchOptionData.opt_ind].any_of_this_regx_in_hims_inspection_data) {
          this.column_list[this.d_anyHimsAreaMatchOptionData.col_ind].options[this.d_anyHimsAreaMatchOptionData.opt_ind].any_of_this_regx_in_hims_inspection_data = [];
        }
        if (this.column_list[this.d_anyHimsAreaMatchOptionData.col_ind].options[this.d_anyHimsAreaMatchOptionData.opt_ind].any_of_this_regx_in_hims_inspection_data.indexOf(text) === -1) {
          this.column_list[this.d_anyHimsAreaMatchOptionData.col_ind].options[this.d_anyHimsAreaMatchOptionData.opt_ind].any_of_this_regx_in_hims_inspection_data.push(text);
        }
        this.$forceUpdate();
      }
    },
    f_addAnyWdmrRuleMatchOptionPhrase: function () {
      if (this.d_textAnalyzeOnWdmrsMatchOptionData.text) {
        if (!this.column_list[this.d_textAnalyzeOnWdmrsMatchOptionData.col_ind].options[this.d_textAnalyzeOnWdmrsMatchOptionData.opt_ind].any_of_this_regx_in_wdmr_rule_data) {
          this.column_list[this.d_textAnalyzeOnWdmrsMatchOptionData.col_ind].options[this.d_textAnalyzeOnWdmrsMatchOptionData.opt_ind].any_of_this_regx_in_wdmr_rule_data = [];
        }
        if (this.column_list[this.d_textAnalyzeOnWdmrsMatchOptionData.col_ind].options[this.d_textAnalyzeOnWdmrsMatchOptionData.opt_ind].any_of_this_regx_in_wdmr_rule_data.indexOf(this.d_textAnalyzeOnWdmrsMatchOptionData.text) === -1) {
          this.column_list[this.d_textAnalyzeOnWdmrsMatchOptionData.col_ind].options[this.d_textAnalyzeOnWdmrsMatchOptionData.opt_ind].any_of_this_regx_in_wdmr_rule_data.push(this.d_textAnalyzeOnWdmrsMatchOptionData.text);
        }
        this.$forceUpdate();
      }
    },
    f_checkAnalyzedWdmrRuleAlreadyMatched: function (text) {
      if (text) {
        if (!this.column_list[this.d_textAnalyzeOnWdmrsMatchOptionData.col_ind].options[this.d_textAnalyzeOnWdmrsMatchOptionData.opt_ind].any_of_this_regx_in_wdmr_rule_data) {
          return false;
        }
        if (this.column_list[this.d_textAnalyzeOnWdmrsMatchOptionData.col_ind].options[this.d_textAnalyzeOnWdmrsMatchOptionData.opt_ind].any_of_this_regx_in_wdmr_rule_data.indexOf(text) !== -1) {
          return true;
        }
      } else {
        return false;
      }
    },
    f_checkAnalyzedWdmrRuleAlreadyMatchedAnyOption: function (text) {
      if (text) {
        if (this.column_list[this.d_textAnalyzeOnWdmrsMatchOptionData.col_ind].options[this.d_textAnalyzeOnWdmrsMatchOptionData.opt_ind].any_of_this_regx_in_wdmr_rule_data && this.column_list[this.d_textAnalyzeOnWdmrsMatchOptionData.col_ind].options[this.d_textAnalyzeOnWdmrsMatchOptionData.opt_ind].any_of_this_regx_in_wdmr_rule_data.indexOf(text) !== -1) {
          return this.d_textAnalyzeOnWdmrsMatchOptionData.opt_ind;
        }
        for (let i in this.column_list[this.d_textAnalyzeOnWdmrsMatchOptionData.col_ind].options) {
          if (this.column_list[this.d_textAnalyzeOnWdmrsMatchOptionData.col_ind].options[i].any_of_this_regx_in_wdmr_rule_data && this.column_list[this.d_textAnalyzeOnWdmrsMatchOptionData.col_ind].options[i].any_of_this_regx_in_wdmr_rule_data.indexOf(text) !== -1) {
            return i;
          }
        }
        return 'no';
      } else {
        return 'no';
      }
    },
    f_removeAnalyzedWdmrRuleFromList: function (text) {
      let index = this.column_list[this.d_textAnalyzeOnWdmrsMatchOptionData.col_ind].options[this.d_textAnalyzeOnWdmrsMatchOptionData.opt_ind].any_of_this_regx_in_wdmr_rule_data.indexOf(text);
      if (index !== -1) {
        this.column_list[this.d_textAnalyzeOnWdmrsMatchOptionData.col_ind].options[this.d_textAnalyzeOnWdmrsMatchOptionData.opt_ind].any_of_this_regx_in_wdmr_rule_data.splice(index, 1);
      }
      this.$forceUpdate();
    },
    f_addAnalyzedWdmrRuleToList: function (text) {
      if (text) {
        if (!this.column_list[this.d_textAnalyzeOnWdmrsMatchOptionData.col_ind].options[this.d_textAnalyzeOnWdmrsMatchOptionData.opt_ind].any_of_this_regx_in_wdmr_rule_data) {
          this.column_list[this.d_textAnalyzeOnWdmrsMatchOptionData.col_ind].options[this.d_textAnalyzeOnWdmrsMatchOptionData.opt_ind].any_of_this_regx_in_wdmr_rule_data = [];
        }
        if (this.column_list[this.d_textAnalyzeOnWdmrsMatchOptionData.col_ind].options[this.d_textAnalyzeOnWdmrsMatchOptionData.opt_ind].any_of_this_regx_in_wdmr_rule_data.indexOf(text) === -1) {
          this.column_list[this.d_textAnalyzeOnWdmrsMatchOptionData.col_ind].options[this.d_textAnalyzeOnWdmrsMatchOptionData.opt_ind].any_of_this_regx_in_wdmr_rule_data.push(text);
        }
        this.$forceUpdate();
      }
    },



    f_deleteAnyOfThisRegxFromWdmrRule: function (col_ind, opt_ind, any_ind) {
      this.column_list[col_ind]['options'][opt_ind].any_of_this_regx_in_wdmr_rule_data.splice(any_ind, 1);
      this.$forceUpdate();
    },


    f_openAnyHimsOptionModal: function (col_ind, opt_ind) {
      this.d_anyHimsAreaMatchOptionData = {
        'show': true,
        'text': '',
        'col_ind': col_ind,
        'opt_ind': opt_ind
      };
    },
    f_openTextAnalyzeOnWdmrsMatchModal: function (col_ind, opt_ind) {
      this.d_textAnalyzeOnWdmrsMatchOptionData = {
        'show': true,
        'text': '',
        'col_ind': col_ind,
        'opt_ind': opt_ind
      };
    },
    f_deleteAnyOfThisRegx: function (col_ind, opt_ind, any_ind) {
      this.column_list[col_ind]['options'][opt_ind].any_of_this_regx_in_hims_inspection_data.splice(any_ind, 1);
      this.$forceUpdate();
    },
    f_searchTextAnalyzeInspection: function () {
      let will_use_column_index = '';
      if (this.column_index !== undefined) {
        will_use_column_index = this.column_index;
      } else {
        will_use_column_index = this.d_selectedColumnInd;
      }
      if (this.column_list[will_use_column_index].search_text_list && this.column_list[will_use_column_index].search_text_list.length > 0) {
        let data = {
          'col_type': 'this',
          'col_ind': will_use_column_index,
          'search_text_list': this.column_list[will_use_column_index].search_text_list,
          'wdm3_id': this.p_selectedProjectData.id
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İfade analiz işlemleri gerçekleştiriliyor. Lütfen bekleyiniz.' } });
        PoleStarService.search_text_analyze_inspection(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              msg = 'Analiz işlemleri tamamlandı';
              this.f_motherChildren('get_project_cases');
              // this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
              // this.column_list.splice(0, this.column_list.length);
            } else {
              msg = resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İfade analiz işlemleri hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
      }
    },
    f_searchTextAnalyzeOnWdmrs: function () {
      let will_use_column_index = '';
      if (this.column_index !== undefined) {
        will_use_column_index = this.column_index;
      } else {
        will_use_column_index = this.d_selectedColumnInd;
      }
      if (this.column_list[will_use_column_index].search_text_list && this.column_list[will_use_column_index].search_text_list.length > 0) {
        let data = {
          'col_type': 'this',
          'col_ind': will_use_column_index,
          'wdm3_id': this.p_selectedProjectData.id
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İfade analiz işlemleri gerçekleştiriliyor. Lütfen bekleyiniz.' } });
        PoleStarService.search_text_analyze_on_wdmrs(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              msg = 'İfade analiz işlemleri başlatıldı. Tamamalandığında bilgi verilecektir.';
              this.p_selectedProjectData['data']['general']['data_mining_status'] = {
                "val": {
                  "label": "devam ediyor",
                  "value": "continuing"
                }
              };
              this.f_controlAndCreateTimer();
              this.$forceUpdate();

              // this.f_motherChildren('get_project_data');
              // this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
              // this.column_list.splice(0, this.column_list.length);
            } else {
              msg = resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İfade analiz işlemleri hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
      }
    },
    f_addTextToSearchList: function () {
      if (!this.column_list[this.d_selectedColumnInd].search_text_list) {
        this.column_list[this.d_selectedColumnInd].search_text_list = [];
      }
      if (this.column_list[this.d_selectedColumnInd].search_text_list.indexOf(this.d_searchText) === -1) {
        this.column_list[this.d_selectedColumnInd].search_text_list.push(this.d_searchText);
      }
      this.$forceUpdate();
    },
    f_deleteStillNotCreatedColumn: function (col_ind) {
      this.d_selectedColumnInd = '';
      this.column_list.splice(col_ind, 1);
      this.$forceUpdate();
    },
    f_deleteAllColumns: function () {
      for (let c in this.column_list) {
        this.column_list[c].deleted = 1;
      }
      this.$forceUpdate();
    },
    f_waimControl: function (col_ind, wai_type) {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {
        if (!this.column_list[col_ind][wai_type]) {
          this.column_list[col_ind][wai_type] = 1;
        } else {
          this.$delete(this.column_list[col_ind], wai_type);
        }
        this.$forceUpdate();
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_motherChildren: function (op, project_data = {}) {
      if (op === 'update_project_data') {
        this.mother_children.d_selectedProjectData = project_data;
      }
      if (this.mother_children[op]) {
        this.mother_children[op] = 0;
      } else {
        this.mother_children[op] = 1;
      }
    },
    f_addEditColumnList: function () {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {
        for (let i in this.column_list) {
          if (this.column_list[i].type === 'manuel') {
            if (this.column_list[i].loc !== undefined) {
              this.$delete(this.column_list[i], 'loc');
            }
            if (this.column_list[i].layer_rules !== undefined) {
              this.$delete(this.column_list[i], 'layer_rules');
            }
            if (this.column_list[i].date_ability_type !== undefined) {
              this.$delete(this.column_list[i], 'date_ability_type');
            }
            if (this.column_list[i].wdm_type !== undefined) {
              this.$delete(this.column_list[i], 'wdm_type');
            }
            if (this.column_list[i].date_ability_limit !== undefined) {
              this.$delete(this.column_list[i], 'date_ability_limit');
            }
            if (this.column_list[i].wdm_name !== undefined) {
              this.$delete(this.column_list[i], 'wdm_name');
            }
            if (this.column_list[i].date_ability !== undefined) {
              this.$delete(this.column_list[i], 'date_ability');
            }
            if (this.column_list[i].field_operation !== undefined) {
              this.$delete(this.column_list[i], 'field_operation');
            }
            if (this.column_list[i].parameter_values !== undefined) {
              this.$delete(this.column_list[i], 'parameter_values');
            }
            if (this.column_list[i].record_count !== undefined) {
              this.$delete(this.column_list[i], 'record_count');
            }
            if (this.column_list[i].parameter_val_list !== undefined) {
              this.$delete(this.column_list[i], 'parameter_val_list');
            }
            if (this.column_list[i].string_usage !== undefined) {
              this.$delete(this.column_list[i], 'string_usage');
            }
            if (this.column_list[i].numeric_string !== undefined) {
              this.$delete(this.column_list[i], 'numeric_string');
            }
          }
        }
        this.d_selectedColumnInd = '';
        let data = {
          'column_list': this.column_list,
          'project_id': this.p_selectedProjectData.id
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kolon işlemleri gerçekleştiriliyor. Lütfen bekleyiniz.' } });
        PoleStarService.edit_column_list(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              msg = 'Kayıt işlemi gerçekleştirildi';
              // this.d_selectedColumnInd = '';
              this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
              this.column_list.splice(0, this.column_list.length);
              for (let i in this.p_selectedProjectData.design.column_list) {
                this.column_list.push(this.p_selectedProjectData.design.column_list[i]);
              }
              this.f_analyzeWdmrRuleMatchEligibility();
              this.f_analyzeColumnOptionRuleMatchEligibility();
              // this.f_analyzeHimsInspectionEligibility();
            } else {
              msg = resp.data.message;
            }
            this.f_motherChildren('get_project_cases');
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Veri Seti Kayıt işlemi' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
        
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_updateColumnOptionsByDataset: function () {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {
        this.d_selectedColumnInd = '';
        let data = {
          'project_id': this.p_selectedProjectData.id
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kolon işlemleri gerçekleştiriliyor. Lütfen bekleyiniz.' } });
        PoleStarService.update_column_options_by_dataset(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              msg = 'Kayıt işlemi gerçekleştirildi';
              this.f_motherChildren('get_project_cases');
            } else {
              msg = resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Veri Seti Kayıt işlemi' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          });
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_openProjectlistForColumnSelection: function () {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Proje listeniz getiriliyor. Lütfen bekleyiniz.' } });
        ClsPolestar.f_getUserPolestarProjects(this.user.username)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            // console.log(resp);
            this.d_projectListData.target_project_id = '';
            this.d_projectListData.target_column_id = '';
            this.d_projectListData.target_column_index = '';
            this.d_projectListData.select_mode = 'one';
            this.d_projectListData.column_add_button = 'yes';
            this.d_projectListData.button_column_update_on_other_projects = 'no';
            this.d_projectListData.list = resp;
            this.d_projectListData.show = true;
          }, resp => {
            alert('error : ', resp);
          });
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_showLayerColumnRuleModal: function (column_type, wdm246_key = '', col_ind = '') {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {
        let layer_key = this.p_selectedProjectData.data.general.layer.val.value;
        if (wdm246_key) {
          layer_key = wdm246_key;
        }
        let layer_tree_name = '';
        let layer_region = 'authorized_layers';
        try {
          layer_region = this.p_selectedProjectData.data.general.layer_region.val.value;
        } catch(err) {}
        try {
          layer_tree_name = this.p_selectedProjectData.data.general.layer_tree_name.val.value;
        } catch(err) {}

        if (layer_region === 'authorized_layers') {
          let data = { 'wdm246_key': layer_key, 'wdm22_id': this.p_selectedProjectData.owner_id };
          // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
          LayerService.get_sub_layer_wdm_list(data)
            .then(resp => {
              // this.$store.commit('Loading', { 'status': false, 'data': {} });
              if (resp.data.status === 'success') {
                if (resp.data.result.wdm246_sub_list && resp.data.result.wdm246_sub_list.length > 0) {
                  this.d_columnRule = JSON.parse(JSON.stringify(this.d_wdmRuleANew));
                  this.d_columnRule.layer_key = layer_key;
                  this.d_columnRule.wdm_list = resp.data.result.wdm246_sub_list;
                  if (col_ind !== '' && column_type === 'edit') {
                    this.column_rule_operation_type = 'edit';
                    this.d_selectedColumnInd = col_ind;
                    if (this.column_list[col_ind].layer_rules) {
                      if (this.column_list[col_ind].layer_rules[layer_key]) {
                        this.d_columnRule.compile_str_array = this.column_list[col_ind].layer_rules[layer_key]['compile_str_array'];
                        if (this.column_list[col_ind].layer_rules[layer_key]['compile_str_date']) {
                          this.d_columnRule.compile_str_date = this.column_list[col_ind].layer_rules[layer_key]['compile_str_date'];
                        }
                        if (this.column_list[col_ind].layer_rules[layer_key]['compile_str_text']) {
                          this.d_columnRule.compile_str_text = this.column_list[col_ind].layer_rules[layer_key]['compile_str_text'];
                        }
                        this.d_columnRule.query_list = this.column_list[col_ind].layer_rules[layer_key]['query_list'];
                      }
                    }
                  }
                  this.d_showColumnRuleModal = true;
                }
              } else {
                // console.log('error: ', resp.data.message);
                alert('Error: ' + resp.data.message);
              }
            });
        } else if (layer_region === 'layer_tree') {
          let data = {};
          PoleStarService.layer_tree_list_get(data)
            .then(resp => {
              if (resp.data.status === "success") {
                // console.log(resp.data);
                // if (resp.data.layer_regions.length > 0) {}
                // if (resp.data.layer_tree_list.length > 0) {}

                // her bir standart katman ağacı için ayrı kontrol yapıyoruz. Zaten bu ağaçlar standart.
                if (layer_tree_name === 'hospital_patient') {
                  if (resp.data.hospital_patient.length > 0) {
                    this.d_columnRule = JSON.parse(JSON.stringify(this.d_wdmRuleANew));
                    this.d_columnRule.layer_key = layer_key;
                    this.d_columnRule.wdm_list = [];
                    for (let i in resp.data.hospital_patient) {
                      let new_wdm_item = resp.data.hospital_patient[i];
                      // type değişkeni komponent içinde querynin type.valuesi için kullanılıyor.
                      new_wdm_item.type = new_wdm_item.value;
                      this.d_columnRule.wdm_list.push(new_wdm_item);
                    }
                    if (col_ind !== '' && column_type === 'edit') {
                      this.column_rule_operation_type = 'edit';
                      this.d_selectedColumnInd = col_ind;
                      if (this.column_list[col_ind].layer_rules) {
                        if (this.column_list[col_ind].layer_rules[layer_key]) {
                          this.d_columnRule.compile_str_array = this.column_list[col_ind].layer_rules[layer_key]['compile_str_array'];
                          if (this.column_list[col_ind].layer_rules[layer_key]['compile_str_date']) {
                            this.d_columnRule.compile_str_date = this.column_list[col_ind].layer_rules[layer_key]['compile_str_date'];
                          }
                          if (this.column_list[col_ind].layer_rules[layer_key]['compile_str_text']) {
                            this.d_columnRule.compile_str_text = this.column_list[col_ind].layer_rules[layer_key]['compile_str_text'];
                          }
                          this.d_columnRule.query_list = this.column_list[col_ind].layer_rules[layer_key]['query_list'];
                        }
                      }
                    }
                    this.d_showColumnRuleModal = true;
                  }
                }



              }
            });
        }
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }


    },
    f_showColumnRuleModal: function (type, col_ind = '') {
      this.d_columnRule = JSON.parse(JSON.stringify(this.d_wdmRuleANew));
      if (col_ind) {
        this.d_selectedColumnInd = col_ind;
      }
      this.column_rule_operation_type = type;
      if (type === 'new') {
        //
      } else if (type === 'edit') {
        if (this.column_list[this.d_selectedColumnInd].rule.query_list.length > 0) {
          this.d_columnRule.query_list = this.column_list[this.d_selectedColumnInd].rule.query_list;
          this.d_columnRule.compile_str_array = this.column_list[this.d_selectedColumnInd].rule.compile_str_array;
          if (this.column_list[this.d_selectedColumnInd].rule.compile_str_date) {
            this.d_columnRule.compile_str_date = this.column_list[this.d_selectedColumnInd].rule.compile_str_date;
          }
          if (this.column_list[this.d_selectedColumnInd].rule.compile_str_text) {
            this.d_columnRule.compile_str_text = this.column_list[this.d_selectedColumnInd].rule.compile_str_text;
          }
        }
      }
      this.d_columnRule.wdm_list = ClsPolestar.f_createProjectWdmList(this.p_selectedProjectData);
      this.d_showColumnRuleModal = true;
    },
    f_addEditColumnRule: function () {
      let parenthesis_control = ClsRule.f_controlParanthesisDesign(this.d_columnRule.compile_str_array);
      // console.log('parenthesis_control : ', parenthesis_control);
      if (parenthesis_control) {
        if (this.d_columnRule.wra_settings.mode === 'param') {
          ClsRule.f_paramToQuery(this.d_columnRule.query_list, this.d_columnRule.compile_str_array, this.d_columnRule.wps_data);
        } else {
          if (this.d_columnRule.compile_str_array.length < 2 || (this.d_columnRule.compile_str_array.length >= 2 && (this.d_columnRule.compile_str_array[0] !== '(' || this.d_columnRule.compile_str_array[0] !== ')'))) {
            alert('Lütfen algoritma operatörleriyle mantık ilişkisini tanımlayınız. Örneğin (1. param and 2. param)');
            return;
          }
        }
        let col_rule_data = {
          'compile_str_array': JSON.parse(JSON.stringify(this.d_columnRule.compile_str_array)),
          'compile_str_text': JSON.parse(JSON.stringify(this.d_columnRule.compile_str_text)),
          'compile_str_date': JSON.parse(JSON.stringify(this.d_columnRule.compile_str_date)),
          'query_list': JSON.parse(JSON.stringify(this.d_columnRule.query_list))
        };
        let det = false;
        let column_field_data = '';
        let wdm_name = '';
        let wdm_type = '';
        let field_operation = '=';
        let column_val_analyze_type = '';
        let parameter_val_list = [];
        let parameter_values = [];
        for (let i in this.d_columnRule.query_list) {
          for (let f in this.d_columnRule.query_list[i].fields) {
            if (this.d_columnRule.query_list[i].fields[f].is_column) {
              det = true;
              if (this.d_columnRule.query_list[i].fields[f].val_list.length > 0) {
                field_operation = this.d_columnRule.query_list[i].fields[f].operation;
                parameter_val_list = this.d_columnRule.query_list[i].fields[f].val_list;
                parameter_values = this.d_columnRule.query_list[i].fields[f].values;
                column_val_analyze_type = this.d_columnRule.query_list[i].fields[f].column_val_analyze_type;
              }
              column_field_data = this.d_columnRule.query_list[i].fields[f];
              wdm_name = this.d_columnRule.query_list[i].type;
              wdm_type = this.d_columnRule.query_list[i].type.value;
              break;
            }
          }
          if (det) {
            break;
          }
        }
        // console.log('column_field_data : ', column_field_data);
        let options = [];
        let param_type = '';
        if (column_val_analyze_type === 'yesno') {
          param_type = 'select';
          options = [{ 'label': 'evet', 'value': 'yes' }, { 'label': 'hayir', 'value': 'no' }];
        } else if (column_val_analyze_type === 'count') {
          param_type = 'integer';
        } else if (column_val_analyze_type === 'sum') {
          param_type = 'float';
        } else {
          param_type = column_field_data.value_type;
        }
        let col_index_list = [];
        for (let lo_index in column_field_data.loc) {
          col_index_list.push(column_field_data.loc[lo_index]['value']);
        }
        // console.log('col_index_list ', col_index_list);
        let wdm_data = this.d_columnRule.option_data[wdm_type];
        let param_data = ClsWdm.getParamDataWithIndexList(wdm_data, col_index_list);
        /*
          // options may be very much for wdm columns, then it is cancelled. While selecting an option in wdmrulea, system gets it from db.
          if (param_data) {
            if (param_data.options && param_data.options.length > 0) {
              options = [];
              for (let op_index in param_data.options) {
                options.push({ 'label': param_data.options[op_index]['label'], 'value': param_data.options[op_index]['value'] });
              }
            }
          }
        */
        let column_label = this.f_createLabelFromLoc(column_field_data.loc);
        // console.log('column_label : ', column_label);

        if (this.column_rule_operation_type === 'edit') {
          this.column_list[this.d_selectedColumnInd].parameter_val_list = parameter_val_list;
          this.column_list[this.d_selectedColumnInd].parameter_values = parameter_values;
          this.column_list[this.d_selectedColumnInd].column_val_analyze_type = column_val_analyze_type;
          this.column_list[this.d_selectedColumnInd].field_operation = field_operation;
          this.column_list[this.d_selectedColumnInd].loc = column_field_data.loc;
          this.column_list[this.d_selectedColumnInd].wdm_type = wdm_type;
          this.column_list[this.d_selectedColumnInd].wdm_name = wdm_name;
          this.column_list[this.d_selectedColumnInd].param_type = param_type;
          this.column_list[this.d_selectedColumnInd].date_ability = this.d_columnRule.option_data[wdm_type].date_ability ? this.d_columnRule.option_data[wdm_type].date_ability : '';
          this.column_list[this.d_selectedColumnInd].date_ability_limit = this.d_columnRule.option_data[wdm_type].date_ability_limit ? this.d_columnRule.option_data[wdm_type].date_ability_limit : '';
          this.column_list[this.d_selectedColumnInd].date_ability_type = this.d_columnRule.option_data[wdm_type].date_ability_type ? this.d_columnRule.option_data[wdm_type].date_ability_type : '';
          this.column_list[this.d_selectedColumnInd].record_count = this.d_columnRule.option_data[wdm_type].record_count ? this.d_columnRule.option_data[wdm_type].record_count : '';
          // First we control whether our data type is layer or not
          if (this.p_selectedProjectData && this.p_selectedProjectData.data && this.p_selectedProjectData.data.general && this.p_selectedProjectData.data.general.layer && this.p_selectedProjectData.data.general.layer.val && this.p_selectedProjectData.data.general.layer.val.value) {
            if (!this.column_list[this.d_selectedColumnInd].layer_rules) {
              this.column_list[this.d_selectedColumnInd].layer_rules = {};
            }
            let layer_key = this.p_selectedProjectData.data.general.layer.val.value;
            this.column_list[this.d_selectedColumnInd].layer_rules[layer_key] = col_rule_data;
          } else {
            this.column_list[this.d_selectedColumnInd].rule = col_rule_data;
          }
          if (column_field_data.anatomy_use) {
            this.column_list[this.d_selectedColumnInd].anatomy_use = column_field_data.anatomy_use;
          }
          if (options.length > 0) {
            this.column_list[this.d_selectedColumnInd].options = options;
          }
          if (param_type !== column_field_data.value_type) {
            this.column_list[this.d_selectedColumnInd].param_type_real = column_field_data.value_type;
          }
        } else {
          let new_col_data = {
            'new_column': 1,
            'anonym': 0,
            'label': column_label,
            'parameter_val_list': parameter_val_list,
            'parameter_values': parameter_values,
            'column_val_analyze_type': column_val_analyze_type,
            'loc': column_field_data.loc,
            'operation': field_operation,
            'type': 'wdm',
            'wdm_type': wdm_type,
            'wdm_name': wdm_name,
            'param_type': param_type,
            'date_ability': this.d_columnRule.option_data[wdm_type].date_ability ? this.d_columnRule.option_data[wdm_type].date_ability : '',
            'date_ability_limit': this.d_columnRule.option_data[wdm_type].date_ability_limit ? this.d_columnRule.option_data[wdm_type].date_ability_limit : '',
            'date_ability_type': this.d_columnRule.option_data[wdm_type].date_ability_type ? this.d_columnRule.option_data[wdm_type].date_ability_type : '',
            'record_count': this.d_columnRule.option_data[wdm_type].record_count ? this.d_columnRule.option_data[wdm_type].record_count : ''
          };
          // First we control whether our data type is layer or not
          if (this.p_selectedProjectData && this.p_selectedProjectData.data && this.p_selectedProjectData.data.general && this.p_selectedProjectData.data.general.layer && this.p_selectedProjectData.data.general.layer.val && this.p_selectedProjectData.data.general.layer.val.value) {
            if (!new_col_data.layer_rules) {
              new_col_data.layer_rules = {};
            }
            let layer_key = this.p_selectedProjectData.data.general.layer.val.value;
            new_col_data.layer_rules[layer_key] = col_rule_data;
          } else {
            new_col_data.rule = col_rule_data;
          }
          if (['select', 'checkbox', 'object_list_options', 'radiogroup'].indexOf(param_type) !== -1) {
            new_col_data.value_label = 'label';
          }
          if (new_col_data.record_count === 'many_times') {
            if (['integer', 'float'].indexOf(param_type) !== -1) {
              new_col_data.numeric_string = 'numeric';
              new_col_data.numeric_usage = 'mean';
            } else {
              new_col_data.numeric_string = 'string';
              new_col_data.string_usage = '2';
              new_col_data.multi_wdmr_approach = '1';
              new_col_data.list_creation_approach = '1';
            }
          }
          if (options.length > 0) {
            new_col_data.options = options;
          }
          if (param_type !== column_field_data.value_type) {
            new_col_data.param_type_real = column_field_data.value_type;
          }
          if (column_field_data.anatomy_use) {
            new_col_data.anatomy_use = column_field_data.anatomy_use;
          }
          this.column_list.push(new_col_data);
          if (!this.p_selectedProjectData.design) {
            this.p_selectedProjectData.design = { 'column_list': {} };
          }
          this.p_selectedProjectData.design.column_list = this.column_list;
        }
        this.d_showColumnRuleModal = false;
        this.$forceUpdate();
      } else {
        let msg = 'Lütfen parentezlerinizi kontrol ediniz.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Algoritma kuralı oluşturma işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_createLabelFromLoc: function (loc) {
      let x = '';
      for (let i in loc) {
        if (parseInt(i) > 0 || (parseInt(i) === 0 && loc[i]['type'] !== 'group')) {
          x += loc[i]['label'];
          if (parseInt(i) !== loc.length - 1) {
            x += ' ';
          }
        }
      }
      return x;
    },
    f_addEditDataCreationRule: function () {
      let parenthesis_control = ClsRule.f_controlParanthesisDesign(this.d_dataCreationRule.compile_str_array);
      // console.log('parenthesis_control : ', parenthesis_control);
      if (parenthesis_control) {
        // When we write our code with mode param, we have to convert it to query_list format to make a filter.
        if (this.d_dataCreationRule.wra_settings.mode === 'param') {
          ClsRule.f_paramToQuery(this.d_dataCreationRule.query_list, this.d_dataCreationRule.compile_str_array, this.d_dataCreationRule.wps_data);
        }
        // console.log(JSON.stringify(this.d_dataCreationRule.query_list));
        this.column_list[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].query_list = JSON.parse(JSON.stringify(this.d_dataCreationRule.query_list));
        this.column_list[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].compile_str_array = JSON.parse(JSON.stringify(this.d_dataCreationRule.compile_str_array));
        this.column_list[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].compile_str_date = JSON.parse(JSON.stringify(this.d_dataCreationRule.compile_str_date));
        if (this.column_list[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].compile_str_text) {
          this.column_list[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].compile_str_text = JSON.parse(JSON.stringify(this.d_dataCreationRule.compile_str_text));
        }
        this.d_dataCreationRuleModal = false;
        this.$forceUpdate();
      } else {
        let msg = 'Lütfen parentezlerinizi kontrol ediniz.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Algoritma kuralı oluşturma işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_addEditStyleRule: function () {
      let parenthesis_control = ClsRule.f_controlParanthesisDesign(this.d_styleRule.compile_str_array);
      // console.log('parenthesis_control : ', parenthesis_control);
      if (parenthesis_control) {
        // When we write our code with mode param, we have to convert it to query_list format to make a filter.
        if (this.d_styleRule.wra_settings.mode === 'param') {
          ClsRule.f_paramToQuery(this.d_styleRule.query_list, this.d_styleRule.compile_str_array, this.d_styleRule.wps_data);
        }
        // console.log(JSON.stringify(this.d_styleRule.query_list));
        this.column_list[this.d_selectedColumnInd].style_rule[this.d_styleRule.rule_ind].query_list = JSON.parse(JSON.stringify(this.d_styleRule.query_list));
        this.column_list[this.d_selectedColumnInd].style_rule[this.d_styleRule.rule_ind].compile_str_array = JSON.parse(JSON.stringify(this.d_styleRule.compile_str_array));
        this.column_list[this.d_selectedColumnInd].style_rule[this.d_styleRule.rule_ind].compile_str_date = JSON.parse(JSON.stringify(this.d_styleRule.compile_str_date));
        if (this.column_list[this.d_selectedColumnInd].style_rule[this.d_styleRule.rule_ind].compile_str_text) {
          this.column_list[this.d_selectedColumnInd].style_rule[this.d_styleRule.rule_ind].compile_str_text = JSON.parse(JSON.stringify(this.d_styleRule.compile_str_text));
        }
        this.d_styleRuleModal = false;
        this.$forceUpdate();
      } else {
        let msg = 'Lütfen parentezlerinizi kontrol ediniz.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Algoritma kuralı oluşturma işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_addEditColumnOptionRule: function () {
      let parenthesis_control = ClsRule.f_controlParanthesisDesign(this.d_columnOptionRule.compile_str_array);
      // console.log('parenthesis_control : ', parenthesis_control);
      if (parenthesis_control) {
        // When we write our code with mode param, we have to convert it to query_list format to make a filter.
        if (this.d_columnOptionRule.wra_settings.mode === 'param') {
          ClsRule.f_paramToQuery(this.d_columnOptionRule.query_list, this.d_columnOptionRule.compile_str_array, this.d_columnOptionRule.wps_data);
        }
        // console.log(JSON.stringify(this.d_columnOptionRule.query_list));
        this.column_list[this.d_selectedColumnInd].options[this.d_columnOptionRule.option_ind].rule = {};
        this.column_list[this.d_selectedColumnInd].options[this.d_columnOptionRule.option_ind].rule.query_list = JSON.parse(JSON.stringify(this.d_columnOptionRule.query_list));
        this.column_list[this.d_selectedColumnInd].options[this.d_columnOptionRule.option_ind].rule.compile_str_array = JSON.parse(JSON.stringify(this.d_columnOptionRule.compile_str_array));
        this.column_list[this.d_selectedColumnInd].options[this.d_columnOptionRule.option_ind].rule.compile_str_date = JSON.parse(JSON.stringify(this.d_columnOptionRule.compile_str_date));
        if (this.column_list[this.d_selectedColumnInd].options[this.d_columnOptionRule.option_ind].rule.compile_str_text) {
          this.column_list[this.d_selectedColumnInd].options[this.d_columnOptionRule.option_ind].rule.compile_str_text = JSON.parse(JSON.stringify(this.d_columnOptionRule.compile_str_text));
        }
        this.d_columnOptionRuleModal = false;
        this.$forceUpdate();
      } else {
        let msg = 'Lütfen parentezlerinizi kontrol ediniz.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Algoritma kuralı oluşturma işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_addEditTextToSearchRule: function () {
      let parenthesis_control = ClsRule.f_controlParanthesisDesign(this.d_columnRule.compile_str_array);
      // console.log('parenthesis_control : ', parenthesis_control);
      if (parenthesis_control) {
        // When we write our code with mode param, we have to convert it to query_list format to make a filter.
        let search_text = this.column_list[this.d_selectedColumnInd].search_text_list[this.d_columnRule.t_ind];
        if (this.d_columnRule.wra_settings.mode === 'param') {
          ClsRule.f_paramToQuery(this.d_columnRule.query_list, this.d_columnRule.compile_str_array, this.d_columnRule.wps_data);
        }
        // console.log(JSON.stringify(this.d_columnRule.query_list));
        if (!this.column_list[this.d_selectedColumnInd].search_text_rule) {
          this.column_list[this.d_selectedColumnInd].search_text_rule = {};
        }
        if (!this.column_list[this.d_selectedColumnInd].search_text_rule[search_text]) {
          this.column_list[this.d_selectedColumnInd].search_text_rule[search_text] = {
            'rule': { 'query_list': [], 'compile_str_date': [], 'compile_str_text': [], 'compile_str_array': [] }
          };
        }
        this.column_list[this.d_selectedColumnInd].search_text_rule[search_text].rule.query_list = JSON.parse(JSON.stringify(this.d_columnRule.query_list));
        this.column_list[this.d_selectedColumnInd].search_text_rule[search_text].rule.compile_str_array = JSON.parse(JSON.stringify(this.d_columnRule.compile_str_array));
        this.column_list[this.d_selectedColumnInd].search_text_rule[search_text].rule.compile_str_date = JSON.parse(JSON.stringify(this.d_columnRule.compile_str_date));
        if (this.column_list[this.d_selectedColumnInd].search_text_rule[search_text].rule.compile_str_text) {
          this.column_list[this.d_selectedColumnInd].search_text_rule[search_text].rule.compile_str_text = JSON.parse(JSON.stringify(this.d_columnRule.compile_str_text));
        }
        this.d_showTextToSearchRuleModal = false;
        this.$forceUpdate();
      } else {
        let msg = 'Lütfen parentezlerinizi kontrol ediniz.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Algoritma kuralı oluşturma işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_dataCreationRuleShow: function (rule_ind) {
      this.d_dataCreationRule = JSON.parse(JSON.stringify(this.d_wdmRuleANew));
      this.d_dataCreationRule.rule_ind = rule_ind;
      if (this.column_list[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].query_list.length > 0) {
        this.d_dataCreationRule.query_list = this.column_list[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].query_list;
        this.d_dataCreationRule.compile_str_array = this.column_list[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].compile_str_array;
        if (this.column_list[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].compile_str_date) {
          this.d_dataCreationRule.compile_str_date = this.column_list[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].compile_str_date;
        }
        if (this.column_list[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].compile_str_text) {
          this.d_dataCreationRule.compile_str_text = this.column_list[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].compile_str_text;
        }
      }
      ClsPolestar.f_prepareColumnRuleOptionData(this.d_dataCreationRule, this.column_list);
      this.d_dataCreationRuleModal = true;
    },
    f_styleRuleShow: function (rule_ind) {
      this.d_styleRule = JSON.parse(JSON.stringify(this.d_wdmRuleANew));
      this.d_styleRule.rule_ind = rule_ind;
      let style_rule = false;
      try {
        style_rule = this.column_list[this.d_selectedColumnInd].style_rule[rule_ind];
      } catch (err) {}
      if (style_rule && style_rule.query_list.length > 0) {
        this.d_styleRule.query_list = style_rule.query_list;
        this.d_styleRule.compile_str_array = style_rule.compile_str_array;
        if (style_rule.compile_str_date) {
          this.d_styleRule.compile_str_date = style_rule.compile_str_date;
        }
        if (style_rule.compile_str_text) {
          this.d_styleRule.compile_str_text = style_rule.compile_str_text;
        }
      }
      ClsPolestar.f_prepareColumnRuleOptionData(this.d_styleRule, this.column_list);
      this.d_styleRuleModal = true;
    },
    f_columnOptionRuleShow: function (option_ind) {
      this.d_columnOptionRule = JSON.parse(JSON.stringify(this.d_wdmRuleANew));
      this.d_columnOptionRule.option_ind = option_ind;
      let option_rule = false;
      try {
        option_rule = this.column_list[this.d_selectedColumnInd].options[option_ind].rule;
      } catch (err) {}
      if (option_rule && option_rule.query_list.length > 0) {
        this.d_columnOptionRule.query_list = option_rule.query_list;
        this.d_columnOptionRule.compile_str_array = option_rule.compile_str_array;
        if (option_rule.compile_str_date) {
          this.d_columnOptionRule.compile_str_date = option_rule.compile_str_date;
        }
        if (option_rule.compile_str_text) {
          this.d_columnOptionRule.compile_str_text = option_rule.compile_str_text;
        }
      }
      ClsPolestar.f_prepareColumnRuleOptionData(this.d_columnOptionRule, this.column_list);
      this.d_columnOptionRuleModal = true;
    },
    f_showTextToSearchRuleModal: function (wdm246_key = '', t_ind) {
      let layer_key = this.p_selectedProjectData.data.general.layer.val.value;
      if (wdm246_key) {
        layer_key = wdm246_key;
      }

      let layer_tree_name = '';
      let layer_region = 'authorized_layers';
      try {
        layer_region = this.p_selectedProjectData.data.general.layer_region.val.value;
      } catch(err) {}
      try {
        layer_tree_name = this.p_selectedProjectData.data.general.layer_tree_name.val.value;
      } catch(err) {}

      if (layer_region === 'authorized_layers') {
        let data = { 'wdm246_key': layer_key, 'wdm22_id': this.p_selectedProjectData.owner_id };
        // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
        LayerService.get_sub_layer_wdm_list(data)
          .then(resp => {
            // this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              if (resp.data.result.wdm246_sub_list && resp.data.result.wdm246_sub_list.length > 0) {
                this.d_columnRule = JSON.parse(JSON.stringify(this.d_wdmRuleANew));
                this.d_columnRule.t_ind = t_ind;
                this.d_columnRule.layer_key = layer_key;
                this.d_columnRule.wdm_list = resp.data.result.wdm246_sub_list;
                let col_ind = this.d_selectedColumnInd;
                let search_text = this.column_list[col_ind].search_text_list[t_ind];
                if (!this.column_list[col_ind].search_text_rule) {
                  this.column_list[col_ind].search_text_rule = {};
                }
                if (!this.column_list[col_ind].search_text_rule[search_text]) {
                  this.column_list[col_ind].search_text_rule[search_text] = {
                    'rule': { 'query_list': [], 'compile_str_date': [], 'compile_str_text': [], 'compile_str_array': [] }
                  };
                }
                let text_rule = this.column_list[col_ind].search_text_rule[search_text].rule;
                if (text_rule && text_rule.query_list.length > 0) {
                  this.d_columnRule.query_list = text_rule.query_list;
                  this.d_columnRule.compile_str_array = text_rule.compile_str_array;
                  if (text_rule.compile_str_date) {
                    this.d_columnRule.compile_str_date = text_rule.compile_str_date;
                  }
                  if (text_rule.compile_str_text) {
                    this.d_columnRule.compile_str_text = text_rule.compile_str_text;
                  }
                }
                this.d_showTextToSearchRuleModal = true;
              }
            } else {
              // console.log('error: ', resp.data.message);
              alert('Error: ' + resp.data.message);
            }
          });
      } else if (layer_region === 'layer_tree') {
        let data = {};
        PoleStarService.layer_tree_list_get(data)
          .then(resp => {
            if (resp.data.status === "success") {
              // console.log(resp.data);
              // if (resp.data.layer_regions.length > 0) {}
              // if (resp.data.layer_tree_list.length > 0) {}

              // her bir standart katman ağacı için ayrı kontrol yapıyoruz. Zaten bu ağaçlar standart.
              if (layer_tree_name === 'hospital_patient') {
                if (resp.data.hospital_patient.length > 0) {
                  this.d_columnRule = JSON.parse(JSON.stringify(this.d_wdmRuleANew));
                  this.d_columnRule.t_ind = t_ind;
                  this.d_columnRule.layer_key = layer_key;
                  this.d_columnRule.wdm_list = [];
                  for (let i in resp.data.hospital_patient) {
                    let new_wdm_item = resp.data.hospital_patient[i];
                    // type değişkeni komponent içinde querynin type.valuesi için kullanılıyor.
                    new_wdm_item.type = new_wdm_item.value;
                    this.d_columnRule.wdm_list.push(new_wdm_item);
                  }
                  let col_ind = this.d_selectedColumnInd;
                  for (let i in resp.data.hospital_patient) {
                    let new_wdm_item = resp.data.hospital_patient[i];
                    // type değişkeni komponent içinde querynin type.valuesi için kullanılıyor.
                    new_wdm_item.type = new_wdm_item.value;
                    this.d_columnRule.wdm_list.push(new_wdm_item);
                  }
                  let search_text = this.column_list[col_ind].search_text_list[t_ind];
                  if (!this.column_list[col_ind].search_text_rule) {
                    this.column_list[col_ind].search_text_rule = {};
                  }
                  if (!this.column_list[col_ind].search_text_rule[search_text]) {
                    this.column_list[col_ind].search_text_rule[search_text] = {
                      'rule': { 'query_list': [], 'compile_str_date': [], 'compile_str_text': [], 'compile_str_array': [] }
                    };
                  }
                  let text_rule = this.column_list[col_ind].search_text_rule[search_text].rule;
                  if (text_rule && text_rule.query_list.length > 0) {
                    this.d_columnRule.query_list = text_rule.query_list;
                    this.d_columnRule.compile_str_array = text_rule.compile_str_array;
                    if (text_rule.compile_str_date) {
                      this.d_columnRule.compile_str_date = text_rule.compile_str_date;
                    }
                    if (text_rule.compile_str_text) {
                      this.d_columnRule.compile_str_text = text_rule.compile_str_text;
                    }
                  }
                  this.d_showTextToSearchRuleModal = true;
                }
              }



            }
          });
      }
    },
    f_calculateLeftSideCol: function () {
      if (this.p_columnSelectionMode) {
        return 12;
      }
      return 6;
    },
    f_leftSideShow: function () {
      if (this.column_index !== undefined) {
        if (!this.p_columnSelectionMode) {
          return false;
        }
      }
      return true;
    },
    f_rightSideShow: function () {
      if (this.p_columnSelectionMode) {
        return false;
      }
      if (this.d_selectedColumnInd === '') {
        return false;
      }
      return true;
    },
    f_cancelShowSelectedColumns: function () {
      this.d_tableModeSettings.show_selected_columns = [];
      this.$forceUpdate();
    },
    addEventListenerToTable: function () {
      const handler = (evt) => {
        var scrollHeight = Math.max(
          document.body.scrollHeight, document.getElementById('id_column_list').scrollHeight,
          document.body.offsetHeight, document.getElementById('id_column_list').offsetHeight,
          document.body.clientHeight, document.getElementById('id_column_list').clientHeight
        );
        // console.log('math. scrollHeight ', scrollHeight);
        // console.log("getBoundingClientRect(): ", document.getElementById('id_column_list').getBoundingClientRect());
        // console.log("scrollHeight: ", document.getElementById('id_column_list').scrollHeight);
        // console.log("window.innerHeight: ", window.innerHeight);
        // console.log("clientHeight: ", document.getElementById('id_column_list').clientHeight);
        // console.log("offsetHeight: ", document.getElementById('id_column_list').offsetHeight);
        // console.log('scrollTop: ', document.getElementById('id_column_list').scrollTop);
        let max_scrolltop = document.getElementById('id_column_list').clientHeight + document.getElementById('id_column_list').scrollTop + 20;
        // console.log('max_scrolltop ', max_scrolltop);
        if (max_scrolltop >= scrollHeight) {
          if (this.column_list.length > this.d_columnSettings.end) {
            this.d_columnSettings.current += 1;
            this.d_columnSettings.start = ((this.d_columnSettings.current - 1) * this.d_columnSettings.perpage) - 5;
            this.d_columnSettings.end += this.d_columnSettings.perpage;
            document.getElementById('id_column_list').scrollTop = 10;
            this.$forceUpdate();
          }
        } else if (document.getElementById('id_column_list').scrollTop === 5) {
          if (this.d_columnSettings.current !== 1) {
            this.d_columnSettings.current -= 1;
            if (this.d_columnSettings.current === 1) {}
            this.d_columnSettings.start = ((this.d_columnSettings.current - 1) * this.d_columnSettings.perpage);
            this.d_columnSettings.end -= this.d_columnSettings.perpage;
            setTimeout(function () {
              scrollHeight = Math.max(
                document.body.scrollHeight, document.getElementById('id_column_list').scrollHeight,
                document.body.offsetHeight, document.getElementById('id_column_list').offsetHeight,
                document.body.clientHeight, document.getElementById('id_column_list').clientHeight
              );
              // console.log('scrollHeight =>>>>> ', scrollHeight);
              document.getElementById('id_column_list').scrollTop = scrollHeight - document.getElementById('id_column_list').clientHeight - 50;
            }, 500);
          }
        }
      };
      setTimeout(function () {
        // console.log(document.getElementById('id_column_list'));
        if (document.getElementById('id_column_list')) {
          var div = document.getElementById("id_column_list");
          div.addEventListener("scroll", handler);
        }
      }, 50);
    },
    f_selectToShowThisColumn: function (col_ind) {
      if (this.d_tableModeSettings.show_selected_columns.indexOf(col_ind) !== -1) {
        this.d_tableModeSettings.show_selected_columns.splice(this.d_tableModeSettings.show_selected_columns.indexOf(col_ind), 1);
      } else {
        this.d_tableModeSettings.show_selected_columns.push(col_ind);
      }
      this.$forceUpdate();
    },
    f_controlColumnFeatures: function (column_list, col_index, type = 'this') {
      ClsPolestar.f_controlColumnFeatures(column_list, col_index, type)
      this.$forceUpdate();
    },
    f_addToRuleValue: function (column_index, rule_index, val) {
      this.column_list[column_index].data_creation_rule[rule_index].value += val;
      this.$forceUpdate();
    },
    f_filterColumn: function (col, col_ind) {
      let search_text = this.d_searchColumnText.toLocaleLowerCase();
      if (search_text) {
        if (this.d_columnSettings.filter_wdm_list.length === 0) {
          if (col.label.toLocaleLowerCase().indexOf(search_text) !== -1) {
            return true;
          }
        } else {
          if (col.label.toLocaleLowerCase().indexOf(search_text) !== -1) {
            if (col.type === 'wdm' && col.wdm_type) {
              for (let i in this.d_columnSettings.filter_wdm_list) {
                if (this.d_columnSettings.filter_wdm_list[i].type === col.wdm_type) {
                  return true;
                }
              }
            }
          }
        }
      } else {
        if (this.d_columnSettings.filter_wdm_list.length === 0) {
          if (col_ind >= this.d_columnSettings.start && col_ind < this.d_columnSettings.end) {
            return true;
          }
        } else {
          if (col.type === 'wdm' && col.wdm_type) {
            for (let i in this.d_columnSettings.filter_wdm_list) {
              if (this.d_columnSettings.filter_wdm_list[i].type === col.wdm_type) {
                return true;
              }
            }
          }
        }
      }
      return false;
    },
    f_calculateStyleColumnList: function () {
      let height = 0;
      if (this.d_fullScreen) {
        height = window.innerHeight - 170;
      } else if (this.d_fullScreenColumnList) {
        height = window.innerHeight - 100;
      } else {
        if (this.mother === 'project_details') {
          height = window.innerHeight - 410;
        } else if (this.mother === 'polestar_project_copy') {
          height = window.innerHeight - 300;
        } else {
          height = window.innerHeight - 510;
        }
      }
      return 'overflow-y: auto; height: ' + height.toString() + 'px; overflow-x: hidden; margin-bottom: 0px;';
    },
    f_calculateStyleRule: function () {
      let height = 0;
      if (this.d_fullScreen) {
        height = window.innerHeight - 120;
      } else {
        height = window.innerHeight - 480;
      }
      return 'overflow-y: auto; height: ' + height.toString() + 'px; overflow-x: hidden; margin-bottom: 0px;';
    },
    f_calculateStyleColumnOptions: function () {
      let height = 0;
      if (this.d_fullScreen) {
        height = window.innerHeight - 153;
      } else {
        height = window.innerHeight - 490;
      }
      return 'overflow-y: auto; height: ' + height.toString() + 'px; overflow-x: hidden; margin-bottom: 0px;';
    },
    f_addNewDataCreationRule: function () {
      if (!this.column_list[this.d_selectedColumnInd].data_creation_rule) {
        this.column_list[this.d_selectedColumnInd].data_creation_rule = [];
      }
      this.column_list[this.d_selectedColumnInd].data_creation_rule.push({ 'value': '', 'query_list': [], 'compile_str_array': [], 'compile_str_date': [], 'compile_str_text': [] });
      this.$forceUpdate();
    },
    f_addNewColumnStyleRule: function () {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {
        if (!this.column_list[this.d_selectedColumnInd].style_rule) {
          this.column_list[this.d_selectedColumnInd].style_rule = [];
        }
        this.column_list[this.d_selectedColumnInd].style_rule.push({ 'style': { 'backgroundColor': '' }, 'query_list': [], 'compile_str_array': [], 'compile_str_date': [], 'compile_str_text': [] });
        this.$forceUpdate();
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_deleteDataCreationRule: function (rule_ind) {
      this.column_list[this.d_selectedColumnInd].data_creation_rule.splice(rule_ind, 1);
      if (this.column_list[this.d_selectedColumnInd].data_creation_rule.length === 0) {
        delete this.column_list[this.d_selectedColumnInd].data_creation_rule;
      }
      this.$forceUpdate();
    },
    f_deleteStyleRule: function (rule_ind) {
      this.column_list[this.d_selectedColumnInd].style_rule.splice(rule_ind, 1);
      if (this.column_list[this.d_selectedColumnInd].style_rule.length === 0) {
        delete this.column_list[this.d_selectedColumnInd].style_rule;
      }
      this.$forceUpdate();
    },
    f_cancelNewColumn: function (col_ind) {
      this.d_selectedColumnInd = '';
      this.column_list.splice(col_ind, 1);
      this.$forceUpdate();
    },
    f_randomDataCreateNow: function (col_ind) {
      if (!this.column_list[col_ind].random_data_creation_now) {
        this.column_list[col_ind].random_data_creation_now = 1;
        this.d_selectedColumnInd = col_ind;
      } else {
        this.$delete(this.column_list[col_ind], 'random_data_creation_now');
      }
      this.$forceUpdate();
    },
    f_ruleDataCreateNow: function (col_ind) {
      if (!this.column_list[col_ind].rule_data_creation_now) {
        this.column_list[col_ind].rule_data_creation_now = 1;
        this.d_selectedColumnInd = col_ind;
      } else {
        this.$delete(this.column_list[col_ind], 'rule_data_creation_now');
      }
      this.$forceUpdate();
    },
    f_deleteControl: function (col_ind) {
      this.d_columnFunctions.show = false;
      let modal_data = { 'type': 'confirm', 'text': 'Kolonu silmek istediğinize emin misiniz ? Öncelikle silinmeye uygunluk analizi yapılacaktır.', 'centered': true, 'title': 'Kolon Silme İşlemi Hakkında', 'function_name': 'f_deleteControlConfirmed', 'confirm': false, 'arguments': [col_ind, 'no'] };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    f_deleteControlConfirmed: function (args) {
      let col_ind = args[0]
      let complete_deletion = args[1]
        // console.log('col_ind ', col_ind);
        // console.log('complete_deletion ', complete_deletion);
      let data = { 'id': this.column_list[col_ind].id, 'project_id': this.p_selectedProjectData.id, 'complete_deletion': complete_deletion };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kolon kullanım analizi yapılıyor. Lütfen bekleyiniz.' } });
      ColumnService.control_deletion_ability(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === "success") {
            if (complete_deletion === 'yes') {
              this.d_selectedColumnInd = '';
              this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
              this.column_list.splice(0, this.column_list.length);
              for (let i in this.p_selectedProjectData.design.column_list) {
                this.column_list.push(this.p_selectedProjectData.design.column_list[i]);
              }
              this.f_analyzeWdmrRuleMatchEligibility();
              this.f_analyzeColumnOptionRuleMatchEligibility();
              // this.f_analyzeHimsInspectionEligibility();
              this.f_motherChildren('get_project_cases');
            } else {
              let txt = '';
              if (resp.data.result.column_usage_list && resp.data.result.column_usage_list.length > 0) {
                for (let i in resp.data.result.column_usage_list) {
                  txt += (parseInt(i) + 1).toString() + ' ) ' + resp.data.result.column_usage_list[i].text + '\n\r';
                  txt += '\n\r';
                }
              } else {
                //
              }
              if (resp.data.result.eligible_for_deletion === 'yes') {
                txt += 'Kolon silme işlemi gerçekleştirilsin mi ? Bu işlem geri alınamaz. \n\r';
                txt += '\n\r';
                let modal_data = { 'type': 'confirm', 'text': txt, 'centered': true, 'title': 'Kolon Silme İşlemi Hakkında', 'function_name': 'f_deleteControlConfirmed', 'confirm': false, 'arguments': [col_ind, 'yes'] };
                this.$store.commit('modal', modal_data);
                this.$store.commit('modal_show', true);
              } else {
                txt += '!!! Kolon silmeye uygun değil. Belirtilen alanlarda ilgili kolonla oluşturulan işlemler mevcut. !!! \n\r';
                txt += '\n\r';
                let modal_data = { 'type': 'alert', 'text': txt, 'centered': true, 'title': 'Kolon kullanımı ve silme işlemi hakkında' };
                this.$store.commit('modal', modal_data);
                this.$store.commit('modal_show', true);
              }
            }
          } else {
            let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
          this.$forceUpdate();
        });
    },
    f_deleteControlOld: function (col_ind) {
      if (!this.column_list[col_ind].deleted) {
        let modal_data = { 'type': 'confirm', 'text': 'Kolonu silmek istediğinize emin misiniz? Bu işlem geri alınamaz', 'centered': true, 'title': 'Kolon Silme İşlemi Hakkında', 'function_name': 'f_deleteControlConfirmedOld', 'confirm': false, 'arguments': [col_ind] };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        // this.d_selectedColumnInd = col_ind;
      } else {
        this.$delete(this.column_list[col_ind], 'deleted');
        this.d_selectedColumnInd = '';
      }
      this.$forceUpdate();
    },
    f_deleteControlConfirmedOld: function (col_ind) {
      this.column_list[col_ind].deleted = 1;
      this.f_addEditColumnList();
    },
    f_addNewManuelColumn: function () {
      if (this.p_userPolestarPermissions.indexOf('owner') !== -1 || this.p_userPolestarPermissions.indexOf('admin') !== -1 || this.p_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {
        let new_column = {
          "data_creation_type": 'manuel',
          "new_column": 1,
          "label": "",
          "param_type": "select",
          "type": "manuel" // ['by_rule_from_other_columns', 'manuel'] If we create a new column from the other columns' by rules, this will be, 'by_rule_from_other_columns'
        };
        this.column_list.push(new_column);
        this.d_selectedColumnInd = this.column_list.length - 1;
        this.$forceUpdate();
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_addDateRelation: function () {
      if (!this.column_list[this.d_selectedColumnInd].date_relation) {
        this.column_list[this.d_selectedColumnInd].date_relation = [];
      }
      let x = { 'op': this.d_selectedDateRelation.op, 'col_ind': this.d_selectedDateRelation.col_ind };
      let det = false;
      for (let i in this.column_list[this.d_selectedColumnInd].date_relation) {
        if (this.column_list[this.d_selectedColumnInd].date_relation[i].col_ind === x.col_ind) {
          det = true;
          this.column_list[this.d_selectedColumnInd].date_relation[i] = x;
        }
      }
      if (!det) {
        this.column_list[this.d_selectedColumnInd].date_relation.push(x);
      }
      this.$forceUpdate();
    },
    f_deleteOption: function (col_ind, op_ind) {
      this.column_list[col_ind].options.splice(op_ind, 1);
      this.$forceUpdate();
    },
    f_addNewOptionCopy: function () {
      if (this.d_newOption) {
        let det = false;
        if (!this.column_list[this.d_selectedColumnInd].options) {
          this.column_list[this.d_selectedColumnInd].options = [];
        } else {
          for (let i in this.column_list[this.d_selectedColumnInd].options) {
            if (this.column_list[this.d_selectedColumnInd].options[i].label === this.d_newOption) {
              det = true;
              break;
            }
          }
        }
        if (!det) {
          let new_opt = JSON.parse(JSON.stringify(this.column_list[this.d_selectedColumnInd].options[this.d_newOptionData.op_ind]));
          new_opt.label = this.d_newOption;
          for (let k = 0; k < 10000; k++) {
            let new_val = 'new_' + k;
            let val_det = false;
            for (let i in this.column_list[this.d_selectedColumnInd].options) {
              if (this.column_list[this.d_selectedColumnInd].options[i].value === new_val) {
                val_det = true;
                break;
              }
            }
            if (!val_det) {
              new_opt.value = new_val;
              break;
            }
          }
          this.column_list[this.d_selectedColumnInd].options.push(new_opt);
        } else {
          alert('Zaten eklidir');
        }
      }
      this.$forceUpdate();
    },
    f_addNewOption: function () {
      if (this.d_newOption) {
        let det = false;
        if (!this.column_list[this.d_selectedColumnInd].options) {
          this.column_list[this.d_selectedColumnInd].options = [];
        } else {
          for (let i in this.column_list[this.d_selectedColumnInd].options) {
            if (this.column_list[this.d_selectedColumnInd].options[i].label === this.d_newOption) {
              det = true;
              break;
            }
          }
        }
        if (!det) {
          let new_opt = { 'label': this.d_newOption, 'value': '' };
          for (let k = 0; k < 10000; k++) {
            let new_val = 'new_' + k;
            let val_det = false;
            for (let i in this.column_list[this.d_selectedColumnInd].options) {
              if (this.column_list[this.d_selectedColumnInd].options[i].value === new_val) {
                val_det = true;
                break;
              }
            }
            if (!val_det) {
              new_opt.value = new_val;
              break;
            }
          }
          this.column_list[this.d_selectedColumnInd].options.push(new_opt);
        } else {
          alert('Zaten eklidir');
        }
      }
      this.$forceUpdate();
    },
    f_controlDateEligibility: function (selected_col_ind, target_col_ind) {
      if (!this.column_list[target_col_ind].data_selection || (this.column_list[target_col_ind].data_selection && ['2', '3'].indexOf(this.column_list[target_col_ind].data_selection) === -1)) {
        return false;
      }
      if (selected_col_ind !== target_col_ind) {
        if (!this.column_list[target_col_ind].date_ability || (this.column_list[target_col_ind].date_ability && this.column_list[target_col_ind].date_ability !== 'yes')) {
          return false;
        }
        // seçili kolon başka bir kolonun içerisinde kullanıldıysa da o kolon tarih ilişkisi seçilemez
        for (let i in this.column_list) {
          if (this.column_list[i].date_relation) {
            for (let d in this.column_list[i].date_relation) {
              if (this.column_list[i].date_relation[d].col_ind === selected_col_ind && parseInt(i) === target_col_ind) {
                return false;
              }
            }
          }
        }
      } else {
        return false;
      }
      return true;
    },
    f_selectColumn: function (col_ind) {
      this.d_selectedColumnInd = col_ind;
      if (this.d_navColumnDetails === 'data_creation_type_rule') {
        if (this.column_list[col_ind].type !== 'manuel') {
          this.d_navColumnDetails = 'column_general_info';
        }
      } else if (this.d_navColumnDetails === 'wdm_param_column_rule') {
        if (this.column_list[col_ind].type === 'wdm') {
          this.f_showTrueFalseColumnRule();
        } else {
          this.d_navColumnDetails = 'column_general_info';
        }
      }
      this.f_analyzeWdmrRuleMatchEligibility();
      this.f_analyzeColumnOptionRuleMatchEligibility();
      // this.f_analyzeHimsInspectionEligibility();
      this.$forceUpdate();
    },
    f_showTrueFalseColumnRule: function () {
      this.d_showWdmParamColumnRule = false;
      setTimeout(() => {
        this.d_showWdmParamColumnRule = true;
      }, 50)
    },
    f_deleteColumn: function (col_ind) {
      this.column_list.splice(col_ind, 1);
      this.$forceUpdate();
    },
    DateFormat: function (date) {
      return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
    },
    f_showValList: function () {
      let x = '';
      for (let i in this.col.parameter_val_list) {
        if (this.col.parameter_val_list[i].label) {
          x += this.col.parameter_val_list[i].label;
        } else {
          x += this.col.parameter_val_list[i];
        }
        if (parseInt(i) !== this.col.parameter_val_list.length - 1) {
          x += ',';
        }
      }
      return x;
    },
    f_getWdm308Data: function () {
      if (this.column_list[this.d_selectedColumnInd].wdm308_key && this.column_list[this.d_selectedColumnInd].wdm308_key !== '') {
        let data = {};
        let wdm308_key = this.column_list[this.d_selectedColumnInd].wdm308_key;
        data.bucket = 'polestar';
        data.key = wdm308_key;
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Api dokümanı hazırlanıyor. Lütfen bekleyiniz.' } });
        GlobalService.get_one_cb_document(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            this.d_wdm308Data = resp.data.result;
            if (this.d_wdm308Data.data && this.d_wdm308Data.data.general && this.d_wdm308Data.data.general.api_type && this.d_wdm308Data.data.general.api_type.val && this.d_wdm308Data.data.general.api_type.val.value === 'mssql') {
              try {
                this.$delete(this.d_wdm308Data.data.general, 'mssql_username');
              } catch (err) {}
              try {
                this.$delete(this.d_wdm308Data.data.general, 'mssql_password');
              } catch (err) {}
            }
            setTimeout(() => {
              this.d_showWdm308Data = true;
              this.$forceUpdate();
            }, 50)
          });
      }
    },
    f_prepareWdm308Show: function () {
      this.d_showWdm308Data = false;
      this.d_wdm308Data = {
        'data': {},
        'type': 'wdm308',
        'reference_wdm': { 'owner_type': 'wisdom', 'version': '2.0', 'key': 'wdm_wisdom_wdm308_v2.0' },
        'id': 'new'
      };
      if (!this.d_wdm308OptionData) {
        let data = {};
        data.bucket = 'option';
        data.key = 'wdm_wisdom_wdm308_v2.0';
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Api dokümanı hazırlanıyor. Lütfen bekleyiniz.' } });
        GlobalService.get_one_cb_document(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            this.d_wdm308OptionData = { 'wdm308': resp.data.result };
            this.f_getWdm308Data();
          });
      } else {
        this.f_getWdm308Data();
      }
    }
  },
  watch: {
    'd_selectedColumnInd': function () {
      this.d_columnMachineLearningDataWdm163 = '';
      if (this.d_selectedColumnInd !== '') {
        this.d_wdm308Data = '';
        if (!this.column_list[this.d_selectedColumnInd].wdmr24_id && (this.d_navColumnDetails === 'walgorithm_response_param_design' || this.d_navColumnDetails === 'walgorithm_wdm308')) {
          this.d_navColumnDetails = 'column_general_info';
        } else if (this.column_list[this.d_selectedColumnInd].wdmr24_id && (this.d_navColumnDetails === 'walgorithm_wdm308' || this.d_navColumnDetails === 'walgorithm_response_param_design')) {
          this.f_prepareWdm308Show();
        } else if (this.column_list[this.d_selectedColumnInd].machine_learning_model_id && this.d_navColumnDetails === 'machine_learning') {
          this.f_getColumnMachineLearningData();
        }
      }
    },
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'f_deleteControlConfirmed' && this.StoreModal.data.confirm) {
          this.f_deleteControlConfirmed(this.StoreModal.data.arguments);
        }
      }
    },
    'mother_children.update_column_eligibility': function () {
      if (this.mother_children.update_column_eligibility) {
        this.f_analyzeWdmrRuleMatchEligibility();
        this.f_analyzeColumnOptionRuleMatchEligibility();
        this.mother_children.update_column_eligibility = 0;
      }
    },
    'mother_children.save_columns': function () {
      if (this.mother_children.save_columns) {
        this.mother_children.save_columns = 0;
      }
    },
    'd_navColumnDetails': function () {
      if (this.d_navColumnDetails === 'wdm_param_column_rule') {
        //
      } else if (this.d_navColumnDetails === 'walgorithm_wdm308' || this.d_navColumnDetails === 'walgorithm_response_param_design') {
        this.f_prepareWdm308Show();
      } else if (this.d_navColumnDetails === 'machine_learning') {
        this.f_getColumnMachineLearningData();
      }
    },
    'd_columnSettings.current': function () {
      this.d_columnSettings.start = (this.d_columnSettings.current - 1) * this.d_columnSettings.perpage;
      this.d_columnSettings.end = (this.d_columnSettings.perpage * this.d_columnSettings.current);
      // document.getElementById('id_column_list').scrollTop = 0;
    }
  }
};

</script>

<style type="text/css">
.normal-mode {}

.full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

.wisdomTable {
  border: solid 1px;
  width: 100%;
}

.wisdomTableTr {
  border: solid 1px;
}

.wisdomTableTd {
  border: solid 1px;
}

.wisdomTableTh {
  border: solid 1px;
  text-align: center;
  background-color: #ececec;
}

table,
tr,
th,
td {
  border: solid 0.5px lightseagreen;
  font-size: 12px;
  text-align: center;
}

</style>

